import MyLocation from '../icons/crosshairs-gps.svg';
const iconurl = MyLocation;
const iconSize = [32, 32];
export const el = document.createElement('div');
el.className = 'marker';
el.style.backgroundImage = `url('${iconurl}')`;
el.style.backgroundSize = `${iconSize[0]}px ${iconSize[1]}px`;
el.style.width = `${iconSize[0]}px`;
el.style.height = `${iconSize[1]}px`;
el.style.marginLeft = `-${Math.floor(iconSize[0] / 2)}px`;
el.style.marginTop = `-${Math.floor(iconSize[1] / 2)}px`;
el.style.pointerEvents = 'none';
