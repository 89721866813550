export const HBEFA_CLASS_RIGID_TRUCK = [
  '1 - 7.5',
  '7.5 - 12',
  '12 - 14',
  '14 - 20',
  '20 - 26',
  '26 - 28',
  '28 - 32',
  '32 - 99',
];

export const HBEFA_CLASS_ARTICULATED_TRUCK = [
  '7.5 - 14',
  '20 - 28',
  '28 - 34',
  '34 - 40',
  '40 - 50',
  '50 - 60',
];
