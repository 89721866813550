import { Box } from '@mui/system';
import { FilterMaterialCondition } from '@/stores/marketplace-filter.store';
import { useEffect, useState } from 'react';
import CustomCheckBox from '@components/commons/CustomCheckBox';
import FilterSelectionButton from './FilterSelectButton';
import { FormControlLabel } from '@mui/material';

interface ComponentProps {
  onReturn: () => void;
  materialCondition: FilterMaterialCondition;
  onSelect?: () => void;
  searchOnChange?: boolean;
}

const FilterSelectMaterialCondition = (props: ComponentProps) => {
  const { onReturn, materialCondition, onSelect, searchOnChange } = props;

  const [hasLosAngelesVal, setHasLosAngelesVal] = useState<boolean>(
    materialCondition.hasLosAngelesVal
  );

  const [hasMicroDevalVal, setHasMicroDevalVal] = useState<boolean>(
    materialCondition.hasMicroDevalVal
  );

  const [selectedItems, setSelectedItems] = useState<number>(0);

  useEffect(() => {
    setSelectedItems(getSelectedItems());
    // if (searchOnChange) {
    //   materialCondition.hasLosAngelesVal = hasLosAngelesVal;
    //   materialCondition.hasMicroDevalVal = hasMicroDevalVal;
    //   onSelect();
    // }
  }, [hasLosAngelesVal, hasMicroDevalVal]);

  const getSelectedItems = () => {
    let selectedItems = 0;

    if (hasLosAngelesVal) {
      selectedItems++;
    }

    if (hasMicroDevalVal) {
      selectedItems++;
    }

    return selectedItems;
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          px: 2,
        }}
      >
        <FormControlLabel
          control={
            <CustomCheckBox
              sx={{
                '&.MuiCheckbox-root': {
                  color: 'inherit',
                },
              }}
              isChecked={hasLosAngelesVal}
              handleChange={(value) => {
                const val = value.target.checked;
                setHasLosAngelesVal(val);
                if (searchOnChange) {
                  materialCondition.hasLosAngelesVal = val;
                  onSelect();
                }
              }}
            />
          }
          sx={{
            '&.MuiFormControlLabel-root': {
              color: '#fff',
            },
          }}
          label='Har Los Angeles verdi?'
        />
        <FormControlLabel
          control={
            <CustomCheckBox
              sx={{
                '&.MuiCheckbox-root': {
                  color: 'inherit',
                },
              }}
              isChecked={hasMicroDevalVal}
              handleChange={(value) => {
                const val = value.target.checked;
                setHasMicroDevalVal(val);
                if (searchOnChange) {
                  materialCondition.hasMicroDevalVal = val;
                  onSelect();
                }
              }}
            />
          }
          sx={{
            '&.MuiFormControlLabel-root': {
              color: '#fff',
            },
          }}
          label='Har Micro Deval verdi?'
        />
      </Box>
      {!searchOnChange && (
        <FilterSelectionButton
          selectedItems={selectedItems}
          onReturn={onReturn}
          onSelect={function (): void {
            materialCondition.hasMicroDevalVal = hasMicroDevalVal;
            materialCondition.hasLosAngelesVal = hasLosAngelesVal;
            onSelect();
          }}
        ></FilterSelectionButton>
      )}
    </>
  );
};

export default FilterSelectMaterialCondition;
