import { asd } from './data';
import {
  roundToClosestValidValue,
  parseVehicleData,
  searchVehicleData,
  computeCO2EmissionsPerKm,
} from './emissions';
import http from '@/apis';

const mapBoxQuery =
  'https://api.mapbox.com/directions/v5/mapbox/driving-traffic/?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=pk.eyJ1IjoidWxyaWtuZXRwb3dlciIsImEiOiJjbDZhdHI0cnAwM3lxM2RsN3VzaHljc3ZxIn0.3vv0fkPZ2kH1z6FLmNDvfg&annotations=maxspeed,congestion';

const altitudeQuery =
  'https://ws.geonorge.no/hoydedata/v1/punkt?koordsys=4258&nord=0&ost=0&geojson=false';

const vehicleDataQuery =
  'https://www.vegvesen.no/ws/no/vegvesen/kjoretoy/felles/datautlevering/enkeltoppslag/kjoretoydata?kjennemerke=';

enum Congestion {
  Freeflow = 'Freeflow',
  Satured = 'Saturated',
  Stopgo = 'Stopgo',
  Unknown = 'Unknown',
}

interface GradientStretch {
  distance: number;
  gradient?: number;
  speedLimit: number;
  congestion: Congestion;
  points: number[][];
  terrain?: string;
  emission?: number;
}

export interface Route {
  distance: number;
  emissions: number;
  stretches: GradientStretch[];
  gradient: number;
  avgCongestion: Congestion;
}

type ObjectZ = {
  distanceInMeters: number;
  emissionsPerKmInGrams: number;
};

export const getVehicleData = async (regNr: string) => {
  const query = vehicleDataQuery + regNr;

  const result = await http.get(`trip/offer/vehicledata/${regNr}`);
  return result.data.result;
};

export const getDir = async (
  map: mapboxgl.Map,
  fromLocation: number[],
  toLocation: number[],
  vehicleWeight?: number,
  departAt?: string,
  regNr?: string
) => {
  const locationData =
    fromLocation[0].toString() +
    ',' +
    fromLocation[1].toString() +
    ';' +
    toLocation[0].toString() +
    ',' +
    toLocation[1].toString();
  const split = mapBoxQuery.split('?');
  const query =
    split[0] +
    locationData +
    '?' +
    split[1] +
    (departAt ? `&depart_at=${departAt}` : '');
  const response = await fetch(query, {
    method: 'GET',
    headers: new Headers(),
    redirect: 'follow',
  });

  const result = await response.text();

  const data = await JSON.parse(result);

  const route = data.routes[0];

  const stretches: GradientStretch[] = [];

  const coords = route.geometry.coordinates;

  const mapBounds = findBounds(coords);

  map.fitBounds(mapBounds.southWest.concat(mapBounds.northEast));

  if (map.getLayer('stretch')) map.removeLayer('stretch');
  if (map.getSource('route')) map.removeSource('route');
  map.addSource('route', {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: coords,
      },
    },
  });

  map.addLayer({
    id: 'stretch',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#0000FF',
      'line-width': 4,
    },
  });

  if (!vehicleWeight || !regNr) return;

  const congestion: any[] = [];
  const speedLimit: any[] = [];

  let allCords: number[][] = [];

  route.legs.forEach((leg: any) => {
    leg.annotation.congestion.forEach((cong: any) => {
      congestion.push(cong);
    });
    leg.annotation.maxspeed.forEach((speed: any) => {
      speedLimit.push(speed);
    });
  });

  for (let index = 0; index < coords.length; index++) {
    if (index === 0) continue;

    const previousCong = congestion[index - 1];
    const previousSpeed = speedLimit[index - 1];

    const currentCong = congestion[index];
    const currentSpeed = speedLimit[index];

    if (
      previousCong === currentCong &&
      (previousSpeed?.unknown === currentSpeed?.unknown ||
        previousSpeed?.speed === currentSpeed?.speed)
    )
      continue;
    else {
      const stretch = stretches[stretches.length - 1];

      let indexOfLastPointOnStretch = 0;

      if (stretch) {
        indexOfLastPointOnStretch = coords.indexOf(
          stretch.points[stretch.points.length - 1]
        );
      }

      const stretchPoints = coords.slice(
        indexOfLastPointOnStretch === 0 ? 0 : indexOfLastPointOnStretch + 1,
        coords.length - 1 === index ? coords.length : index
      );

      allCords = [...allCords, ...stretchPoints];

      let distance = 0;

      for (let i = 0; i < stretchPoints.length; i++) {
        if (i === 0) continue;

        const previousPoint = stretchPoints[i - 1];
        const currentPoint = stretchPoints[i];

        distance += getDistanceFromLatLonMeters(
          previousPoint[1],
          previousPoint[0],
          currentPoint[1],
          currentPoint[0]
        );
      }

      stretches.push({
        distance: distance,
        gradient: undefined,
        speedLimit: getSpeed(previousSpeed),
        congestion: getCongestion(previousCong),
        points: stretchPoints,
      });
    }
  }

  for (let i = 0; i < stretches.length; i++) {
    const stretch = stretches[i];

    for (let j = 0; j < stretch.points.length; j += 50) {
      const pointsAlt = stretch.points.slice(j, j + 50);

      const param = coordinatesToRequestParam(pointsAlt);

      const url = altitudeQuery + '&punkter=' + param;

      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers(),
        redirect: 'follow',
      });

      const result = await response.text();

      const data = await JSON.parse(result);

      const bList: ObjectB[] = [];

      data.punkter.forEach((item: any) => {
        const terrain = item.terreng;

        console.log(terrain);

        const b: ObjectB = {
          longitude: item.x,
          latitude: item.y,
          altitude: item.z,
        };

        bList.push(b);
      });

      const gradient = calculateGradientFromObjectB(bList);

      const gradientRounded = roundToClosestValidValue(
        Number.isNaN(gradient) ? 0 : gradient
      );

      stretch.gradient = gradientRounded;

      console.log(gradient);

      console.log('stop');
    }
  }

  return getRoute(stretches, vehicleWeight, regNr);
};

async function getRoute(
  stretches: GradientStretch[],
  vehicleWeight: number,
  regNr: string
): Promise<Route> {
  let distance = 0;
  let gradient = 0;

  stretches.forEach((stretch) => {
    distance += stretch.distance;
    gradient += stretch?.gradient ?? 0;
  });

  const data = parseVehicleData(asd);

  let totalEmissions = 0;

  const regData = await getVehicleData(regNr);

  let euroClass: string;

  if (regData !== '') {
    const values = regData.split(';');

    const euro = values[0][0];

    switch (euro) {
      case '0':
        euroClass = 'Euro-0';
        break;
      case '1':
        euroClass = 'Euro-I';
        break;
      case '2':
        euroClass = 'Euro-II';
        break;
      case '3':
        euroClass = 'Euro-III';
        break;
      case '4':
        euroClass = 'Euro-IV';
        break;
      case '5':
        euroClass = 'Euro-V';
        break;
      case '6':
        euroClass = 'Euro-VI';
        break;
    }

    vehicleWeight += Number(values[1]) / 1000;
  }

  stretches.forEach((stretch) => {
    const vehicles = searchVehicleData(
      stretch.speedLimit,
      stretch.congestion,
      stretch?.gradient ?? 0,
      data,
      euroClass
    );

    if (vehicles.length === 0) {
      console.log();
    }

    const emmision = computeCO2EmissionsPerKm(vehicles, vehicleWeight);

    stretch.emission = emmision;

    totalEmissions += emmision;
  });

  const objects = stretches.map((stretch) => {
    return {
      distanceInMeters: stretch.distance,
      emissionsPerKmInGrams: stretch.emission,
    };
  });

  totalEmissions = calculateTotalEmissions(objects);

  const distanceInKM = Math.floor(distance / 1000);

  const avgGradient = gradient / stretches.length;

  const avgCongestion = getAverageCongestion(stretches);

  return {
    distance: distanceInKM,
    gradient: avgGradient,
    stretches: stretches,
    emissions: totalEmissions,
    avgCongestion: avgCongestion,
  };
}

function calculateTotalEmissions(objects: ObjectZ[]): number {
  let totalEmissions = 0;
  for (const object of objects) {
    totalEmissions +=
      (object.distanceInMeters / 1000) * (object.emissionsPerKmInGrams / 1000);
  }
  return Math.floor(totalEmissions);
}

function coordinatesToRequestParam(coordinates: number[][]): string {
  let result = '';
  for (const coordinate of coordinates) {
    result += '[' + coordinate[0] + ',' + coordinate[1] + '],';
  }
  return '[' + result.slice(0, -1) + ']';
}

function getDistanceFromLatLonMeters(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d * 1000;
}

function deg2rad(deg: any) {
  return deg * (Math.PI / 180);
}

type ObjectA = {
  meters: number;
  altitudeInMeters: number;
};

type ObjectB = {
  longitude: number;
  latitude: number;
  altitude: number;
};

function calculateGradient(objectList: ObjectA[]): number {
  let totalDistance = 0;
  let totalElevation = 0;
  for (let i = 0; i < objectList.length - 1; i++) {
    totalDistance += objectList[i].meters;
    totalElevation +=
      objectList[i + 1].altitudeInMeters - objectList[i].altitudeInMeters;
  }
  return totalElevation / totalDistance;
}

function calculateGradientFromObjectB(objectList: ObjectB[]): number {
  const objectAList: ObjectA[] = [];
  let previousObject: ObjectB | undefined;
  for (let i = 0; i < objectList.length; i++) {
    const currentObject = objectList[i];
    if (previousObject) {
      const meters = getDistanceFromLatLonMeters(
        previousObject.latitude,
        previousObject.longitude,
        currentObject.latitude,
        currentObject.longitude
      );
      objectAList.push({
        meters,
        altitudeInMeters: currentObject.altitude,
      });
    }
    previousObject = currentObject;
  }
  return calculateGradient(objectAList);
}

function getCongestion(congestion: string): Congestion {
  switch (congestion) {
    case 'unknown':
      return Congestion.Freeflow;
    case 'low':
      return Congestion.Freeflow;
    case 'moderate':
      return Congestion.Satured;

    case 'heavy':
      return Congestion.Satured;

    case 'severe':
      return Congestion.Stopgo;
  }
  return Congestion.Freeflow;
}

function getSpeed(speed: any) {
  if (speed?.unknown === true) {
    return 50;
  } else {
    return speed.speed;
  }
}

export function removeMapboxSourcesAndLayers(map: mapboxgl.Map) {
  map.getStyle().layers.forEach((layer: any) => {
    if (
      layer.source &&
      (layer.source.includes('stretch') || layer.source.includes('route'))
    ) {
      map.removeLayer(layer.id);
    }
  });
  const sources = Object.keys(map.getStyle().sources);
  sources.forEach((source) => {
    if (source.includes('route')) {
      map.removeSource(source);
    }
  });
}

function getAverageCongestion(stretches: GradientStretch[]): Congestion {
  if (stretches.length === 0) {
    return Congestion.Unknown;
  }

  let freeflow = 0;
  let saturated = 0;
  let stopgo = 0;
  let unknown = 0;
  let totalDistance = 0;

  for (const stretch of stretches) {
    switch (stretch.congestion) {
      case Congestion.Freeflow:
        freeflow += stretch.distance;
        break;
      case Congestion.Satured:
        saturated += stretch.distance;
        break;
      case Congestion.Stopgo:
        stopgo += stretch.distance;
        break;
      case Congestion.Unknown:
        unknown += stretch.distance;
        break;
    }

    totalDistance += stretch.distance;
  }

  if (unknown === totalDistance) {
    return Congestion.Unknown;
  }

  const freeflowPercentage = freeflow / totalDistance;
  const saturatedPercentage = saturated / totalDistance;
  const stopgoPercentage = stopgo / totalDistance;

  if (freeflowPercentage > 0.5) {
    return Congestion.Freeflow;
  } else if (saturatedPercentage > 0.5) {
    return Congestion.Satured;
  } else if (stopgoPercentage > 0.5) {
    return Congestion.Stopgo;
  } else {
    return Congestion.Unknown;
  }
}

function findBounds(coordinates: number[][]): any {
  const minLat = Math.min(...coordinates.map((c) => c[0]));
  const minLng = Math.min(...coordinates.map((c) => c[1]));
  const maxLat = Math.max(...coordinates.map((c) => c[0]));
  const maxLng = Math.max(...coordinates.map((c) => c[1]));

  return {
    southWest: [minLat, minLng],
    northEast: [maxLat, maxLng],
  };
}
