import { SIDE_BAR_WIDTH } from '@/constants/css.constants';
import { theme } from '@/themes/MolunderTheme';
import Close from '@mui/icons-material/CloseOutlined';
import ArrowBack from '@mui/icons-material/ArrowBack';

import Notification from '@mui/icons-material/NotificationsActiveOutlined';

import { Button, Drawer, IconButton, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import FilterSelection from './FilterSelect';
import { useStore } from '@/RootStoreProvider';

interface ComponentProps {
  open: boolean;
  handleDrawerClose: () => void;
  asNotification: boolean;
  fromSupply: boolean;
}

const MarketPlaceFilter = (props: ComponentProps) => {
  const { open, handleDrawerClose, asNotification, fromSupply } = props;

  const isWeb = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const { requestFilterStore, supplyFilterStore } = useStore();

  const [isSupply, setIsSupply] = useState<boolean>(fromSupply);

  const [selectionActive, setSelectionActive] = useState<boolean>(false);

  const activeFilter = isSupply ? supplyFilterStore : requestFilterStore;

  const [filterItemCount, setFilterItemCount] = useState<number>(
    activeFilter.filterItemCount()
  );

  useEffect(() => {
    if (activeFilter.filterItemCount() > 0) {
      setActive(true);
    }
  }, [selectionActive]);

  const [active, setActive] = useState<boolean>(
    activeFilter.filterItemCount() > 0
  );

  return (
    <>
      <Drawer
        key={'filter'}
        variant='temporary'
        hideBackdrop
        disableEnforceFocus
        // disableScrollLock
        anchor={'right'}
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: (theme) => theme.palette.background.container,
            boxShadow: 'none',
            borderRight: '1px solid',
            borderColor: (theme) => theme.palette.divider,
            width: isWeb ? SIDE_BAR_WIDTH : '100%',
          },
        }}
        sx={{
          width: `${SIDE_BAR_WIDTH}px`,
        }}
      >
        {/* HEADER */}
        <Box
          key='header'
          sx={{
            minHeight: '64px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0px 24px',
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          <span>Filter</span>
          <IconButton
            onClick={() =>
              selectionActive ? setSelectionActive(false) : handleDrawerClose()
            }
          >
            {selectionActive ? (
              <ArrowBack fontSize='small' />
            ) : (
              <Close fontSize='small' />
            )}
          </IconButton>
        </Box>
        {/* Scroll Container */}
        <Box
          key='scrollContainer'
          sx={{
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {/* BODY */}
          {!selectionActive && asNotification && (
            <Box
              sx={{
                border: '1px solid #E0E0E0',
                borderRadius: '4px',
                minHeight: '48px',
                display: 'flex',
                alignItems: 'center',
                margin: '16px',
                justifyContent: 'space-between',
                textAlign: 'center',
              }}
            >
              <Box
                sx={{
                  width: '50%',
                  height: '100%',
                  backgroundColor: isSupply ? 'rgba(1, 99, 178, 0.12)' : '',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setIsSupply(true)}
              >
                <text style={{ color: '#0163B2' }}>Supply</text>
              </Box>
              <Box
                sx={{
                  width: '50%',
                  backgroundColor: !isSupply ? 'rgba(1, 99, 178, 0.12)' : '',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setIsSupply(false)}
              >
                <text style={{ color: '#0163B2' }}>Request</text>
              </Box>
            </Box>
          )}{' '}
          {!selectionActive && !asNotification && (
            <Box
              sx={{
                // width: '100%',
                minHeight: '48px',
                margin: '16px',
                borderRadius: '4px',
                backgroundColor: 'rgba(1, 99, 178, 0.12)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // cursor: 'pointer',
              }}
              onClick={() => setIsSupply(true)}
            >
              <text style={{ color: '#0163B2' }}>
                {isSupply ? 'Supply' : 'Request'}
              </text>
            </Box>
          )}
          <FilterSelection
            isSelectionActive={selectionActive}
            isSupply={isSupply}
            setSelectionActive={(selection) => setSelectionActive(selection)}
            selectedMaterials={activeFilter.materials}
            volume={activeFilter.volume}
            price={activeFilter.price}
            location={activeFilter.location}
            materialCondition={activeFilter.materialCondition}
            onUpdateCount={() => {
              setFilterItemCount(activeFilter.filterItemCount());
              activeFilter.update();
            }}
          />
        </Box>
        {/* FOOTER */}
        {!selectionActive && (
          <Box
            sx={{
              position: 'absolute',
              bottom: '0px',
              minHeight: '76px',
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'space-between',
              padding: '0px 16px',
              width: '100%',
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
          >
            {/* <IconButton
              sx={{
                border: active ? '1px solid #E0E0E0' : '',
                color: active ? '#0163B3' : '#878787',
              }}
              onClick={() => setActive(!active)}
            >
              <Notification fontSize='small' />
            </IconButton> */}

            <Box
              sx={{
                width: '100%',
              }}
            >
              {filterItemCount > 0 ? (
                <div
                  style={{
                    fontSize: '16px',
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.primary.background,
                      color: (theme) => theme.palette.primary.main,
                      margin: '0px 10px',
                      width: '100%',
                    }}
                    variant={'contained'}
                    color={'primary'}
                    fullWidth
                    onClick={() => {
                      activeFilter.reset();
                      setActive(false);
                      setFilterItemCount(0);
                    }}
                  >
                    <text
                      style={{
                        color: 'white',
                      }}
                    >
                      {`Tøm (${filterItemCount})`}
                    </text>
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    fontSize: '14px',
                    display: 'flex',
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: '#E6E6E6',
                      color: '#7F7E7E',
                      width: '100%',
                      margin: '0px 10px',
                    }}
                    variant='contained'
                    fullWidth
                    disabled
                  >
                    <text
                      style={{
                        color: 'white',
                      }}
                    >
                      Tøm
                    </text>
                  </Button>
                </div>
              )}
            </Box>
          </Box>
        )}
      </Drawer>
    </>
  );
};

export default MarketPlaceFilter;
