import http from '@/apis';
import { InfoCo2Emission } from '@/dto/settings/InfoCo2Emission.dto';
import { UpdateCo2Emission } from '@/dto/settings/UpdateCo2Emission.dto';

class Co2EmissionService {
  prefix = 'admin/co2-emissions';

  public async getCo2Emission(): Promise<InfoCo2Emission> {
    const result = await http.get(`${this.prefix}`);
    return result.data.result;
  }

  public async editCo2Emission(model: UpdateCo2Emission): Promise<boolean> {
    const result = await http.put(`${this.prefix}`, model);
    return result.data.result.data;
  }
}

export default new Co2EmissionService();
