import receptionControlService from '@/apis/reception-control.service';
import { ReceptionControl } from '@/dto/reception-control/reception-control.dto';
import { initialReceptionControlFormValues } from '@customTypes/forms/receptionControlForm.types';
import { action, makeObservable, observable } from 'mobx';

class ReceptionControlStore {
  receptionControl: ReceptionControl;

  constructor() {
    this.receptionControl = initialReceptionControlFormValues;

    makeObservable(this, {
      receptionControl: observable,
      getReceptionControl: action,
    });
  }

  async getReceptionControl(id: number): Promise<void> {
    const receptionControl = await receptionControlService.getReceptionControl(
      id
    );
    this.receptionControl = receptionControl;
  }

  async getReceptionControlsByDeclaration(
    declarationId: number
  ): Promise<ReceptionControl[]> {
    const receptionControls =
      await receptionControlService.getReceptionControlsByDeclaration(
        declarationId
      );
    return receptionControls;
  }

  async createReceptionControl(model: ReceptionControl): Promise<number> {
    const result = await receptionControlService.createReceptionControl(model);
    return result;
  }

  async editReceptionControl(model: ReceptionControl): Promise<boolean> {
    const result = await receptionControlService.editReceptionControl(model);
    return result;
  }
}

export default ReceptionControlStore;
