import SearchMass from '@components/commons/SearchMass';
import { I18N } from '@/enums/i18n.enum';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery } from '@mui/material';
import { theme } from '@/themes/MolunderTheme';
import { LANGUAGE } from '@/enums/lang.enum';
import { useStore } from '@/RootStoreProvider';
import { observer } from 'mobx-react';

interface ComponentProps {
  handleSearchMass: (model: any) => void;
}

const SearchBar = (props: ComponentProps) => {
  const { handleSearchMass } = props;
  const { massStore, exchangeFilterStore } = useStore();
  const { t, i18n } = useTranslation();
  const isWeb = useMediaQuery(theme.breakpoints.up('sm'));

  const { HOME_SELECT_MATERIAL_TYPE } = I18N;
  const { exchangeOptions } = massStore;

  const setSearchKey = (searchKey: string): void => {
    exchangeFilterStore.setSearchKey(searchKey);
  };

  const handleSearch = (value: string) => {
    massStore.debounceSearchExchange({
      ...exchangeFilterStore.transformToFilterQuery,
      search: value,
      langCode: i18n.language ?? LANGUAGE.NORWAY,
    });
  };

  return (
    <>
      <Box
        sx={{
          height: '42px',
          borderRadius: '4px',
          border: '1px solid rgba(112, 112, 112, 0.3)',
        }}
      >
        {isWeb && (
          <SearchMass
            searchPlaceholder={t(HOME_SELECT_MATERIAL_TYPE)}
            data={exchangeOptions}
            onSearch={handleSearch}
            handleSearchMass={handleSearchMass}
            setSearchKey={setSearchKey}
          />
        )}
        <div style={{ flex: 1 }} />
      </Box>
      {!isWeb && (
        <SearchMass
          searchPlaceholder={t(HOME_SELECT_MATERIAL_TYPE)}
          data={exchangeOptions}
          onSearch={handleSearch}
          handleSearchMass={handleSearchMass}
          setSearchKey={setSearchKey}
        />
      )}
    </>
  );
};

export default observer(SearchBar);
