import { defaultRoutes } from '@/routers/routes';
import { theme } from '@/themes/MolunderTheme';
import NavigationBar from '@components/commons/NavigationBar';
import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';
// import PageNotFound from '@/pages/PageNotFound';

export default observer(() => {
  const isWeb = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <>
      <NavigationBar isWeb={isWeb} />
      <Switch>
        {defaultRoutes
          .filter((item: any) => !item.isLayout)
          .map((item: any) => (
            <Route
              key={item.path}
              path={item.path}
              component={item.component}
              exact={item.exact}
            />
          ))}
        {/* <Route component={PageNotFound} /> */}
      </Switch>
    </>
  );
});
