import {
  getCountryName,
  getCountryNameAlpha2,
  LANGUAGE,
} from '@/enums/lang.enum';
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { saveToStorage } from '@utils/storage.util';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import { theme } from '@/themes/MolunderTheme';
import { SxProps } from '@mui/system';
import { useStore } from '@/RootStoreProvider';

interface IComponentProps {
  sx?: SxProps<Theme>;
}

const SwitchLanguages = (props: IComponentProps) => {
  const { sx } = props;
  const { languageStore } = useStore();
  const { i18n } = useTranslation();

  const onChangeLanguage = async (key: LANGUAGE) => {
    saveToStorage('lang', key);
    languageStore.setActiveLanguage(key);
    await i18n.changeLanguage(key);
    await languageStore.getPrivacyPolicy(key);
    await languageStore.getUseOfCookies(key);
    handleClose();
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const isWeb = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          color: 'common.white',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          width: '5rem',
          ...sx,
        }}
      >
        <LanguageIcon
          sx={{ width: '1.25rem', height: '1.25rem', marginRight: '4px' }}
        />
        <Typography variant='subtitle1'>
          {getCountryName(languageStore.activeLanguage as LANGUAGE)}
        </Typography>
      </Box>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-paper': {
            borderRadius: 0,
            padding: 0,
          },
          '& .MuiMenu-list': {
            padding: '5px 0',
            marginTop: isWeb ? '5px' : 0,
          },
          marginTop: '5px',
        }}
      >
        {Object.values(LANGUAGE).map((v: LANGUAGE) => (
          <MenuItem
            key={v}
            value={v}
            onClick={async () => await onChangeLanguage(v)}
            sx={{
              padding: isWeb ? '5px 8px' : '3px 8px',
              '&:last-child': {
                paddingBottom: 0,
              },
              display: 'flex',
              alignItems: 'center',
              minHeight: 'auto',
            }}
          >
            <i className={`famfamfam-flags ${getCountryNameAlpha2(v)}`}></i>
            <Typography variant='subtitle2' sx={{ marginLeft: '5px' }}>
              {getCountryName(v)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default observer(SwitchLanguages);
