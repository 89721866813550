import { InfoAsset } from '@/dto/asset/InfoAsset.dto';
import { InfoUser } from '@/dto/users/InfoUser.dto';
import { RCSubmitButtonType } from '@enums/reception-control.enum';

interface ReceptionControlPopupInformation {
  customer?: {
    name: string;
    email: string;
  };
  contactPerson?: {
    name: string;
    email: string;
  };
  isFullyDelivery?: boolean;
}

interface ReceptionControlFormValues {
  id?: number;
  controlDate: Date | string;
  volume: number;
  collieNumber?: number;
  isVisuallyInspectedAndApproved?: boolean;
  testTaken?: boolean;
  imageId?: number;
  image?: File | InfoAsset;
  createdBy?: number;
  createdByUser?: InfoUser;
  submitButton?: RCSubmitButtonType;
  popupInformation?: ReceptionControlPopupInformation;
}

export const initialReceptionControlFormValues = {
  controlDate: null,
  volume: 0,
  collieNumber: 1,
  isVisuallyInspectedAndApproved: false,
  testTaken: false,
  image: undefined,
  submitButton: undefined,
};

export type { ReceptionControlFormValues };
