import { I18N } from './i18n.enum';

export enum PROJECT_STATUS {
  PLANNED = 'PLANNED', // project & storage
  ACTIVE = 'ACTIVE', // project & storage
  COMPLETED = 'COMPLETED', // project
  CANCELLED = 'CANCELLED', // project
  CLOSED = 'CLOSED', // storage
}

// export enum ProjectMassType {
//   UNCONTAMINATED = 'UNCONTAMINATED',
//   STORED = 'STORED',
//   CONTAMINATED = 'CONTAMINATED',
//   REUSED = 'REUSED',
//   NEW_MINERAL_RESOURCE = 'NEW_MINERAL_RESOURCE',
// }

export enum ProjectMassType {
  NEW_MINERAL_RESOURCE = 'NEW_MINERAL_RESOURCE',
  INTERNAL_PROJECT = 'INTERNAL_PROJECT',
  EXTERNAL_PROJECT = 'EXTERNAL_PROJECT',
  STORAGE = 'STORAGE',
  DEPOT = 'DEPOT',
  REUSED = 'REUSED',
  OTHER = 'OTHER', // To display "Other" option
}

export enum ProjectMassOrigin {
  IN = 'IN',
  OUT = 'OUT',
}

export enum ProjectType {
  PROJECT = 'PROJECT',
  STORAGE = 'STORAGE',
}

export enum ProjectSupplyDemandType {
  SUPPLY = 'SUPPLY',
  DEMAND = 'DEMAND',
  SUPPLY_AND_DEMAND = 'SUPPLY_AND_DEMAND',
}

export const getProjectStatuses = (t: any) => {
  return [
    {
      key: PROJECT_STATUS.PLANNED,
      label: t(I18N.STATUS_PROJECT_PLANNED),
      backgroundColor: 'action.disabledBackground',
      labelColor: 'text.medium',
    },
    {
      key: PROJECT_STATUS.ACTIVE,
      label: t(I18N.STATUS_PROJECT_ACTIVE),
      backgroundColor: 'info.background',
      labelColor: 'info.content',
    },
    {
      key: PROJECT_STATUS.COMPLETED,
      label: t(I18N.STATUS_PROJECT_COMPLETED),
      backgroundColor: 'success.background',
      labelColor: 'success.content',
    },
    {
      key: PROJECT_STATUS.CANCELLED,
      label: t(I18N.STATUS_PROJECT_CANCELLED),
      backgroundColor: 'action.disabledBackground',
      labelColor: 'text.medium',
    },
    {
      key: PROJECT_STATUS.CLOSED,
      label: t(I18N.STATUS_PROJECT_CLOSED),
      backgroundColor: 'action.disabledBackground',
      labelColor: 'text.medium',
    },
  ];
};

export const getProjectMassFactor = (
  type: ProjectMassType,
  origin: ProjectMassOrigin
) => {
  if (origin === ProjectMassOrigin.OUT) {
    switch (type) {
      case ProjectMassType.INTERNAL_PROJECT:
      case ProjectMassType.EXTERNAL_PROJECT:
      case ProjectMassType.STORAGE:
      case ProjectMassType.DEPOT:
        return 1;
      case ProjectMassType.REUSED:
        return 0;
    }
  }

  if (origin === ProjectMassOrigin.IN) {
    switch (type) {
      case ProjectMassType.INTERNAL_PROJECT:
      case ProjectMassType.EXTERNAL_PROJECT:
      case ProjectMassType.NEW_MINERAL_RESOURCE:
        return 1;
      case ProjectMassType.STORAGE:
      case ProjectMassType.REUSED:
        return 0;
      case ProjectMassType.DEPOT:
        return 0.5;
    }
  }
};
