import http from '@/apis';
import { InfoAsset } from '@/dto/asset/InfoAsset.dto';
import { CreateUpdateTripFeedback } from '@/dto/trip-feedback/CreateUpdateTripFeedback.dto';
import { InfoTripFeedback } from '@/dto/trip-feedback/InfoTripFeedback.dto';

class TripFeedbackService {
  prefix = 'trip-feedback';

  public async createTripFeedback(
    model: CreateUpdateTripFeedback,
    file: File | null
  ): Promise<boolean> {
    const form = new FormData();
    form.append('model', JSON.stringify(model));

    if (file) {
      form.append('file', file);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await http.post(`${this.prefix}`, form, config);
    return result.data.result;
  }

  public async updateTripFeedback(
    id: number,
    model: CreateUpdateTripFeedback,
    asset: InfoAsset | null,
    file: File | null
  ): Promise<boolean> {
    const form = new FormData();
    form.append('model', JSON.stringify(model));

    if (file) {
      form.append('file', file);
    }

    if (asset) {
      form.append('assetId', asset.id);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await http.put(`${this.prefix}/${id}`, form, config);
    return result.data.result;
  }

  public async getTripFeedback(tripId: number): Promise<InfoTripFeedback> {
    const result = await http.get(`${this.prefix}/trip/${tripId}`);
    return result.data.result;
  }
}

export default new TripFeedbackService();
