import customerService from '@/apis/customer.service';
import { INIT_CUSTOMER_FORM } from '@/constants/customer.constants';
import {
  PaginationRequest,
  PaginationResult,
} from '@/dto/commons/PaginationRequest.dto';
import { InfoCustomer } from '@/dto/customer/createNewCustomer.dto';
import { Customer } from '@/dto/customer/customer.dto';
import { makeObservable, observable, action } from 'mobx';

class CustomerStore {
  customer: InfoCustomer;
  customers: Customer[];
  customerCount: number;

  constructor() {
    this.customer = INIT_CUSTOMER_FORM;
    this.customers = [];
    this.customerCount = 0;

    makeObservable(this, {
      customer: observable,
      customerCount: observable,
      customers: observable,
      getCustomer: action,
      getCustomers: action,
      createCustomer: action,
      editCustomer: action,
      initCurrentCustomer: action,
      getCustomerByRefreshToken: action,
    });
  }

  async getCustomer(id: number): Promise<void> {
    const customer = await customerService.getCustomer(id);
    this.customer = customer;
  }

  async getCustomerByRefreshToken(token: string): Promise<InfoCustomer> {
    const customer = await customerService.getCustomerByRefreshToken(token);
    return customer;
  }

  async getCustomers(query?: PaginationRequest): Promise<void> {
    const result = await customerService.getCustomers(query);
    this.customers = result.data;
    this.customerCount = result.count;
  }

  async createCustomer(model: InfoCustomer): Promise<boolean> {
    const result = await customerService.createCustomer(model);
    return result;
  }

  async editCustomer(model: InfoCustomer): Promise<boolean> {
    const result = await customerService.editCustomer(model);
    const customers = [...this.customers];
    const index = this.customers.findIndex((c) => c.id == model.id);
    customers[index] = model;
    this.customers = customers;
    return result;
  }
  initCurrentCustomer = () => {
    this.customer = INIT_CUSTOMER_FORM;
  };
}

export default CustomerStore;
