import http from '@/apis';
import { PaginationRequest } from '@/dto/commons/PaginationRequest.dto';
import { EalCode } from '@/dto/declaration/eal-code.dto';
import { prepareGetQuery } from '@utils/api.utils';

class EalCodeService {
  prefix = 'eal-code';

  public async getEalCodes(query?: PaginationRequest<EalCode>) {
    let url = `${this.prefix}`;
    if (query) {
      url += prepareGetQuery(query);
    }
    const result = await http.get(url);

    return result.data.result;
  }
}

export default new EalCodeService();
