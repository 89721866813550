import transportService from '@/apis/transport.service';
import { INIT_SETTINGS_TRANSPORT } from '@/constants/settings.containts';
import { SelectOption } from '@/dto/commons/SelectOption.dto';
import { SettingsTransport } from '@/dto/settings/SettingsTransport.dto';
import { SettingsTransportCreate } from '@/dto/settings/SettingsTransportCreate.dto';
import { action, computed, makeObservable, observable } from 'mobx';
import { TransportPaginationRequest } from '@/dto/transports/TransportPaginationRequest.dto';

class TransportStore {
  transports: SettingsTransport[];
  transportForm: SettingsTransport;
  transportCount: number;

  constructor() {
    this.transports = [];
    this.transportForm = INIT_SETTINGS_TRANSPORT;
    this.transportCount = 0;

    makeObservable(this, {
      transports: observable,
      transportForm: observable,
      transportCount: observable,
      createTransport: action,
      editTransport: action,
      getTransport: action,
      getTransports: action,
      setTransports: action,
      transportOptions: computed,
      setEmptyTransportForm: action,
    });
  }

  async createTransport(transport: SettingsTransportCreate): Promise<void> {
    await transportService.createTransport(transport);

    return history.back();
  }

  async getTransports(query?: TransportPaginationRequest): Promise<void> {
    const data = await transportService.getTransports(query);
    this.transports = data.data;
    this.transportCount = data.count;
  }

  async editTransport(transport: SettingsTransport): Promise<void> {
    await transportService.editTransport(transport);

    const transports = [...this.transports];
    const index = this.transports.findIndex((x) => x.id == transport.id);
    transports[index] = transport;
    this.transports = transports;
  }

  async getTransport(id: number): Promise<void> {
    const data = await transportService.getTransport(id);
    this.transportForm = data;
  }

  async handleReset(id: number): Promise<void> {
    await this.getTransport(id);
  }

  setEmptyTransportForm(): void {
    this.transportForm = INIT_SETTINGS_TRANSPORT;
  }

  async minified() {
    const result = await transportService.minified();
    this.setTransports(result);
  }

  get transportOptions(): SelectOption[] {
    return this.transports.map((c) => ({
      label: `${c.name}`,
      value: c.id || 0,
    }));
  }

  setTransports(data: any[]) {
    this.transports = data;
  }
}

export default TransportStore;
