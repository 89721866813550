import { InfoChangeLog } from '@/dto/change-log/change-log.dto';
import { PaginationRequest } from '@/dto/commons/PaginationRequest.dto';
import { prepareGetQuery } from '@utils/api.utils';
import http from '@/apis';

class ChangeLogService {
  prefix = 'change-log';

  public async getChangeLogs(
    query?: PaginationRequest<InfoChangeLog>
  ): Promise<InfoChangeLog[]> {
    let url = this.prefix;
    if (query) {
      url += prepareGetQuery(query as Record<string, unknown>);
    }

    const result = await http.get(url);
    return result.data.result;
  }
}

export default new ChangeLogService();
