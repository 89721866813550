import DefaultLayout from '@components/layouts/Default';
import GuardLayout from '@components/layouts/GuardLayout';
import { Box, CircularProgress } from '@mui/material';
import { getLanguageCurrent } from '@utils/language.utils';
import { retrieveFromCookie, retrieveFromStorage } from '@utils/storage.util';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { MenuNames } from './RouteCategoryName.enum';
import {
  CustomRoute,
  defaultRoutes,
  guardRoutes,
  publicRoutes,
} from './routes';
import { useStore } from '@/RootStoreProvider';
import { useQuery } from '@utils/api.utils';
import { AbilityContext } from '@components/casl/Can';

const Router = () => {
  const { authenticationStore, languageStore, notificationStore } = useStore();
  const ability = useContext(AbilityContext);

  const history = useHistory();
  const redirectTo = useQuery().get('redirectTo');
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const [publicRouters, setPublicRouters] = React.useState<CustomRoute[]>();

  const notGuardRoutes = guardRoutes.filter((r) => !r.isGuarded);

  const logout = async (): Promise<void> => {
    await authenticationStore.logout(history);
    notificationStore.setNotificationsInit();
  };

  const checkAuth = React.useCallback(async () => {
    if (!authenticationStore.loggedUser) {
      setPublicRouters([...publicRoutes, ...notGuardRoutes]);
    } else {
      setPublicRouters(publicRoutes);
    }

    const accessToken =
      retrieveFromStorage('accessToken') || retrieveFromCookie('accessToken');
    const refreshToken =
      retrieveFromStorage('refreshToken') || retrieveFromCookie('refreshToken');
    const noAuthRoute = [
      ...defaultRoutes.filter((r) => r.name !== MenuNames.LOGIN),
      ...publicRoutes,
      ...notGuardRoutes,
    ];

    const failedAuth =
      (!accessToken || !refreshToken) &&
      !noAuthRoute.map((r) => r.path).includes(history.location.pathname) &&
      !noAuthRoute.find((r) => matchPath(history.location.pathname, r));

    if (failedAuth) {
      const isPathMatched =
        guardRoutes.map((r) => r.path).includes(history.location.pathname) ||
        guardRoutes.find((r) => matchPath(history.location.pathname, r));

      // Set a redirected url so that user can go into guarded route if they successfully login
      if (!authenticationStore.loggedUser && isPathMatched)
        authenticationStore.setRedirectUrl(history.location.pathname);
    }

    if ((accessToken || refreshToken) && !authenticationStore.loggedUser) {
      const storageLoggedUser = retrieveFromCookie('loggedUser');
      const visitedAsGuest = storageLoggedUser
        ? JSON.parse(storageLoggedUser)?.visitedAsGuest
        : false;
      const customerDeclarationLink = storageLoggedUser
        ? JSON.parse(storageLoggedUser)?.customerDeclarationLink
        : null;

      if (
        visitedAsGuest &&
        customerDeclarationLink &&
        history.location.pathname !== customerDeclarationLink
      ) {
        setIsChecked(true);
        return logout();
      }

      await authenticationStore
        .checkToken(
          accessToken,
          refreshToken,
          visitedAsGuest,
          ability,
          customerDeclarationLink
        )
        .then(() => {
          if (redirectTo) history.push(redirectTo);
        });
    }

    if (authenticationStore.loggedUser && !notificationStore.listening) {
      notificationStore.listenNotification(authenticationStore.loggedUser.id);
    }

    setIsChecked(true);
  }, [authenticationStore, authenticationStore.loggedUser, history]);

  React.useEffect(() => {
    if (!navigator.onLine) {
      const loggedUser =
        retrieveFromStorage('loggedUser') || retrieveFromCookie('loggedUser');

      if (loggedUser) {
        authenticationStore.saveUser(JSON.parse(loggedUser), ability);
      }
    }
  }, []);

  React.useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  React.useEffect(() => {
    const lang = getLanguageCurrent();

    if (lang) {
      languageStore.setActiveLanguage(lang);
      languageStore.getPrivacyPolicy(lang);
      languageStore.getUseOfCookies(lang);
    }
  }, [languageStore]);

  return (
    <>
      {isChecked ? (
        <Switch>
          {/* Public route */}
          {publicRouters
            .filter((item: any) => !item.isLayout)
            .map((item: any) => (
              <Route
                key={item.path}
                path={item.path}
                component={item.component}
                exact={item.exact}
              />
            ))}
          {/* Guard route */}
          {(authenticationStore?.loggedUser != null || !navigator.onLine) && (
            <Route
              path='/*'
              render={(props: any) => <GuardLayout {...props} exact />}
            />
          )}
          {/* No guard route */}
          <Route
            path='/'
            render={(props: any) => <DefaultLayout {...props} exact />}
          />
        </Switch>
      ) : (
        <Box className='center-spin'>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default observer(Router);
