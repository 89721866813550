import {
  Box,
  Menu,
  Typography,
  useMediaQuery,
  Theme,
  Avatar,
  IconButton,
  Badge,
  Stack,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '@/themes/MolunderTheme';
import { SxProps } from '@mui/system';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Notification } from '@/dto/notifications/Notification.dto';
import NotificationCard from '../NotificationCard';
import { formatDate, isToday, isYesterday } from '@utils/date.utils';
import { CreateUpdateNotification } from '@/dto/notifications/CreateUpdateNotification.dto';
import { useHistory } from 'react-router-dom';
import { I18N } from '@/enums/i18n.enum';
import { useStore } from '@/RootStoreProvider';

interface IComponentProps {
  sx?: SxProps<Theme>;
}

const NotificationList = (props: IComponentProps) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { notificationStore, userStore, massStore } = useStore();
  const { t } = useTranslation();
  const {
    NOTIFICATION_NOTIFICATIONS,
    NOTIFICATION_TODAY,
    NOTIFICATION_YESTERDAY,
  } = I18N;

  const notification = [];
  const unread = notificationStore.unreadNotificationCount;

  notificationStore.notificationGroupByDate.forEach(
    (items: Notification[], key: number) => {
      items.map((item, index) => {
        if (index > 0) {
          notification.push({ ...item });
        } else {
          notification.push({ ...item, key });
        }
        return;
      });
    }
  );

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const handleClickNotification = (notification) => {
    if (!isWeb) handleClose();
    massStore.setIsUpdateSupply(false);
    massStore.setIsUpdateRequest(false);

    history.push(JSON.parse(notification.information).uri);
    const model: CreateUpdateNotification = {
      readTime: new Date(),
    };
    if (!notification.readTime)
      notificationStore.updateNotification(model, notification.id);
  };

  const getDate = (date: Date) => {
    if (isToday(date)) return t(NOTIFICATION_TODAY);
    if (isYesterday(date)) return t(NOTIFICATION_YESTERDAY);
    return formatDate(new Date(date), 'DD MMMM YYYY');
  };

  const isWeb = useMediaQuery(theme.breakpoints.up('sm'));

  const notificationList = (
    <Stack
      direction='column'
      justifyContent='center'
      spacing={1}
      sx={{
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        mb: theme.spacing(1),
      }}
    >
      {isWeb && (
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={0}
        >
          <Typography variant='h5'>{t(NOTIFICATION_NOTIFICATIONS)}</Typography>
        </Stack>
      )}
      {notification.map((n: Notification & { key: number }, index) => (
        <Box key={index}>
          {n.key && (
            <Box sx={{ my: 2 }} key={n.id}>{`${getDate(n.createdAt)}`}</Box>
          )}
          <NotificationCard
            notification={n}
            handleClick={handleClickNotification}
            active={!n.readTime}
          />
        </Box>
      ))}
    </Stack>
  );

  return (
    <>
      <IconButton sx={{ p: 0, ml: 1 }} color='inherit' onClick={handleClick}>
        <Avatar
          sx={{ bgcolor: 'info.background', color: 'info.content' }}
          alt={userStore.currentUser.fullName}
        >
          <Badge
            badgeContent={unread}
            color={'secondary'}
            max={99}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              marginRight: unread > 0 ? '4px' : '0px',
              marginTop: unread > 0 ? '4px' : '0px',
              '& .MuiBadge-badge': {
                padding: '0px',
                fontSize: '8px',
                top: '3px',
                right: '3px',
                width: '20px',
                height: '20px',
                borderRadius: '10px',
                marginRight: '2px',
                marginTop: '2px',
              },
            }}
          >
            <NotificationsNoneIcon />
          </Badge>
        </Avatar>
      </IconButton>

      <Menu
        id='basic-menu'
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: isWeb ? '25%' : '100%',
            left: 0,
            right: 0,
            bottom: '80px',
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-paper': {
            borderRadius: 0,
            padding: 0,
          },
          '& .MuiMenu-list': {
            padding: '5px 0',
            marginTop: isWeb ? '5px' : 0,
          },
          marginTop: '13px',
        }}
        marginThreshold={0}
      >
        {isWeb ? (
          <Box>{notificationList}</Box>
        ) : (
          <Box>
            <Box m={0}>
              <Box
                pt={2}
                pr={2}
                pl={2}
                sx={{
                  backgroundColor: 'white.main',
                }}
              >
                <Stack justifyContent='space-between' spacing={2}>
                  <Stack
                    direction='column'
                    alignItems='flex-start'
                    justifyContent='space-between'
                  >
                    <Typography component='h1' variant={isWeb ? 'h3' : 'h4'}>
                      {t(NOTIFICATION_NOTIFICATIONS)}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Box>
            <Box pr={isWeb ? 3 : 2} pl={isWeb ? 3 : 2} mb={isWeb ? 1 : 1.25}>
              {notificationList}
            </Box>
          </Box>
        )}
      </Menu>
    </>
  );
};

export default observer(NotificationList);
