import React from 'react';
import { BOTTOM_NAVIGATION_HEIGHT } from '@/constants/css.constants';
import {
  CustomRoute,
  getDefaultRouteByCategory,
  getRouteByPathname,
  RouteCategory,
} from '@/routers/routes';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import { getTitle } from '@utils/name.utils';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CategoryNames } from '@/routers/RouteCategoryName.enum';

interface ComponentProps {
  menuItems: CustomRoute[];
  categoryItems: RouteCategory[];
}

const BottomNavigationBar = (props: ComponentProps) => {
  const { menuItems, categoryItems } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const getCategory = (item: CustomRoute): RouteCategory | undefined => {
    return categoryItems.find((category) => category.name === item.category);
  };

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <BottomNavigation
          showLabels
          value={
            getRouteByPathname(history.location.pathname)?.category ||
            CategoryNames.MY_PROJECTS
          }
          onChange={(event, newValue) => {
            const route = getDefaultRouteByCategory(newValue);
            if (route) history.push(route.path);
          }}
          sx={{
            backgroundColor: 'primary.light',
            pl: 0.5,
            pr: 0.5,
            height: BOTTOM_NAVIGATION_HEIGHT,
          }}
        >
          {menuItems.map((item) => (
            <BottomNavigationAction
              disableRipple
              key={`${item.path}-menu-mobile`}
              label={item.category ? getTitle(item.category, t) : item.category}
              icon={getCategory(item)?.icon}
              value={item.category}
            />
          ))}
        </BottomNavigation>
      </Box>
    </>
  );
};

export default BottomNavigationBar;
