import conversationService from '@/apis/conversation.service';
import messageService from '@/apis/message.service';
import { INIT_CONVERSATION } from '@/constants/conservation.constants';
import {
  Conversation,
  CreateConversation,
} from '@dto/conversation/conversation.dto';
import { CreateMessage, Message } from '@dto/message/message.dto';
import { OBJECT_TYPE } from '@enums/conversation.enum';
import { clone } from '@utils/object.utils';
import { action, makeObservable, observable } from 'mobx';

class ConversationStore {
  listening: boolean;
  conversation: Conversation;

  constructor() {
    this.conversation = INIT_CONVERSATION;
    this.listening = false;

    makeObservable(this, {
      conversation: observable,
      listening: observable,
      setConversation: action,
      createConversation: action,
      createMessage: action,
    });
  }

  async getConversationByObjectType(
    objectType: OBJECT_TYPE,
    objectTypeId: number
  ): Promise<void> {
    const conversation = await conversationService.getConversationByObjectType(
      objectType,
      objectTypeId
    );
    this.setConversation(conversation ?? INIT_CONVERSATION);
  }

  setConversation(data: Conversation): void {
    this.conversation = data;
  }

  async createConversation(
    conversation: CreateConversation
  ): Promise<Conversation> {
    const result = await conversationService.createConversation(conversation);
    this.setConversation(result);
    return result;
  }

  async createMessage(message: CreateMessage): Promise<Message> {
    const result = await messageService.createMessage(message);
    return result;
  }

  listenNotification = (conversationId: number) => {
    const eventSource = messageService.listenNotification(conversationId);

    eventSource.onmessage = function logEvents(event) {
      this.setConversation({
        ...this.conversation,
        messages: [
          ...this.conversation.messages,
          JSON.parse(clone(event.data)),
        ],
      });
    }.bind(this);
    this.listening = true;
  };
}

export default ConversationStore;
