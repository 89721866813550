import http from '@/apis';
import { CreateMessage, Message } from '@dto/message/message.dto';

class MessageService {
  prefix = 'message';
  ssePrefix = this.prefix + '/sse';

  public listenNotification(declarationId: number) {
    const eventSource = new EventSource(
      `${process.env.REACT_APP_BACKEND_URL}/${this.ssePrefix}/${declarationId}`
    );
    return eventSource;
  }

  public async createMessage(model: CreateMessage): Promise<Message> {
    const result = await http.post(`${this.prefix}`, model);
    return result.data.result;
  }
}

export default new MessageService();
