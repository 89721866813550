import React from 'react';
import { Button, Paper, Typography } from '@mui/material';
import MapboxMap from '@components/commons/Map';
import { Box } from '@mui/system';
import mapboxgl from 'mapbox-gl';
import { convertDMS } from '@utils/convertToDMS';
import { el } from '@components/commons/Map/MapUtils/myLocationMarker';
import { useTranslation } from 'react-i18next';
import { I18N } from '@/enums/i18n.enum';
import { MAP_CENTER, MAP_MAX_BOUNDS } from '@/constants/map.constants';

interface ComponentProps {
  open: boolean;
  defaultLocation?: [];
  locationCallback?: (value: any) => void;
}
const ChooseLocation = (props: ComponentProps) => {
  const { open, defaultLocation, locationCallback } = props;
  const [location, setLocation] = React.useState<any>();
  const [dmsLocation, setDmsLocation] = React.useState<any>(null);
  const { t } = useTranslation();
  const {
    MAP_GEOLOCATION_PERMISSION,
    MAP_SELECT_LOCATION,
    MAP_BUTTON_ADD_LOCATION,
    MAP_CURRENT_LOCATION,
  } = I18N;
  let marker: any;

  React.useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (currentLocation) => {
          const bounds = new mapboxgl.LngLatBounds(
            MAP_MAX_BOUNDS[0] as mapboxgl.LngLatLike,
            MAP_MAX_BOUNDS[1] as mapboxgl.LngLatLike
          );

          const isUserInBbx = bounds.contains([
            currentLocation.coords.longitude,
            currentLocation.coords.latitude,
          ]);

          MAP_MAX_BOUNDS.includes([
            currentLocation.coords.longitude,
            currentLocation.coords.latitude,
          ]);
          if (isUserInBbx) {
            setLocation([
              currentLocation.coords.longitude,
              currentLocation.coords.latitude,
            ]);
          } else {
            setLocation(defaultLocation ?? MAP_CENTER);
          }
        },
        () => {
          console.error(t(MAP_GEOLOCATION_PERMISSION));
          setLocation(defaultLocation ?? MAP_CENTER);
        }
      );
    }
  }, []);

  React.useEffect(() => {
    if (location) setDmsLocation(convertDMS(location[1], location[0]));
  }, [location]);

  if (open) {
    return (
      <Paper
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          width: '100%',
        }}
      >
        <Box p={2}>
          <Typography variant='body1' mb={2}>
            {t(MAP_SELECT_LOCATION)}
          </Typography>
          <div
            style={{
              height: 250,
            }}
          >
            {location ? (
              <MapboxMap
                minZoom={3}
                initialPosition={location}
                onLoaded={(map) => {
                  marker = new mapboxgl.Marker(el)
                    .setLngLat(location)
                    .addTo(map);
                }}
                onMove={(map) => {
                  if (marker) {
                    marker.setLngLat(map.getCenter());
                    setLocation(map.getCenter().toArray());
                  }
                }}
              />
            ) : null}
          </div>
          <Typography variant='subtitle1' mt={2}>
            {`${t(MAP_CURRENT_LOCATION)}:`}
          </Typography>
          <Typography variant='subtitle2' mt={1} mb={2}>
            {dmsLocation}
          </Typography>
          <Button
            onClick={() =>
              locationCallback ? locationCallback(location) : null
            }
            variant='contained'
            size='large'
            fullWidth
          >
            {t(MAP_BUTTON_ADD_LOCATION)}
          </Button>
        </Box>
      </Paper>
    );
  } else {
    return null;
  }
};

export default ChooseLocation;
