export enum Action {
  Read = 'read',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Manage = 'manage', // manage: CASL keyword for any actions
}

/** Action which cannot be covered by CRUD Action*/
export enum DeclarationExtendedAction {
  MakeReceptionControl = 'make-reception-control',
}

export enum BusinessModule {
  Marketplace = 'Marketplace',
  Planner = 'Planner',
  Declaration = 'Declaration',
  Shared = 'Shared',
}
