import http from '@/apis';
import { Report } from '@/dto/reports/Report.dto';
import { ReportExport } from '@/dto/reports/ReportExport.dto';
import { ReportPeriod } from '@/dto/reports/ReportPeriod.dto';
import { prepareGetQuery } from '@utils/api.utils';

class ReportService {
  prefix = 'report';

  public async getReport(period: ReportPeriod): Promise<Report[]> {
    const result = await http.get(`${this.prefix}/${prepareGetQuery(period)}`);
    return result.data.result;
  }

  public async export(period: ReportPeriod): Promise<ReportExport[]> {
    const result = await http.get(
      `${this.prefix}/export/${prepareGetQuery(period)}`
    );
    return result.data.result;
  }
}

export default new ReportService();
