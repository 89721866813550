import { useStore } from '@/RootStoreProvider';
import {
  FilterLocation,
  FilterMaterialCondition,
  FilterPrice,
  FilterVolume,
} from '@/stores/marketplace-filter.store';
import { theme } from '@/themes/MolunderTheme';
import FilterSelectLocation from '@components/filter/FilterSelect/FilterSelectLocation';
import FilterSelectMaterial from '@components/filter/FilterSelect/FilterSelectMaterial';
import FilterSelectMaterialCondition from '@components/filter/FilterSelect/FilterSelectMaterialCondition';
import FilterSelectPrice from '@components/filter/FilterSelect/FilterSelectPrice';
import FilterSelectVolume from '@components/filter/FilterSelect/FilterSelectVolume';
import { LANGUAGE } from '@enums/lang.enum';
import { FILTER_MENU_ITEM_TYPE } from '@enums/map.enum';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Button, Collapse, Divider } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  open: boolean;
  selectedMaterials: Set<string>;
  volume: FilterVolume;
  location: FilterLocation;
  price: FilterPrice;
  materialCondition: FilterMaterialCondition;
  selectedFilter: FILTER_MENU_ITEM_TYPE;
  setSelectedFilter: (selectedFilter: FILTER_MENU_ITEM_TYPE) => void;
}

interface SearchExchangeFilter {
  materialTypes: string[];
  volume?: FilterVolume;
  price?: FilterPrice;
  location?: FilterLocation;
  materialCondition?: FilterMaterialCondition;
}

export type FilterMenuItem = {
  name: string;
  type: FILTER_MENU_ITEM_TYPE;
};

const menuItems = [
  {
    name: 'Material Type',
    type: FILTER_MENU_ITEM_TYPE.MATERIAL,
  },
  {
    name: 'Volume',
    type: FILTER_MENU_ITEM_TYPE.VOLUMNE,
  },
  {
    name: 'Price',
    type: FILTER_MENU_ITEM_TYPE.PRICE,
  },
  { name: 'Location', type: FILTER_MENU_ITEM_TYPE.LOCATION },
  {
    name: 'Material Condition',
    type: FILTER_MENU_ITEM_TYPE.MATERIAL_CONDITION,
  },
];

const FilterMassPanel = React.forwardRef((props: ComponentProps, ref) => {
  const {
    open,
    volume,
    price,
    location,
    materialCondition,
    selectedMaterials,
    selectedFilter,
    setSelectedFilter,
  } = props;

  const { massStore, exchangeFilterStore } = useStore();
  const { i18n } = useTranslation();

  const resetFilter = () => {
    setSelectedFilter(null);
  };

  const getAvailableMaterials = (): Set<string> => {
    const materials = new Set<string>();
    const exchangeList = massStore.exchangeList;
    exchangeList.forEach((e) => {
      materials.add(e.materialName);
    });
    return materials;
  };

  const handleFilterExchange = () => {
    const filter = exchangeFilterStore.transformToFilterQuery();
    massStore.getMinifiedExchange({
      ...filter,
      search: exchangeFilterStore.currentSearchKey,
      langCode: i18n.language ?? LANGUAGE.NORWAY,
    });
  };

  const getSelection = (): any => {
    switch (selectedFilter) {
      case FILTER_MENU_ITEM_TYPE.MATERIAL:
        return (
          <FilterSelectMaterial
            onReturn={resetFilter}
            selectedMaterials={selectedMaterials}
            materials={getAvailableMaterials()}
            onSelect={handleFilterExchange}
            searchOnChange
          />
        );
      case FILTER_MENU_ITEM_TYPE.VOLUMNE:
        return (
          <FilterSelectVolume
            onReturn={resetFilter}
            volume={volume}
            onSelect={handleFilterExchange}
          />
        );

      case FILTER_MENU_ITEM_TYPE.PRICE:
        return (
          <FilterSelectPrice
            onReturn={resetFilter}
            price={price}
            onSelect={handleFilterExchange}
          />
        );

      case FILTER_MENU_ITEM_TYPE.LOCATION:
        return (
          <FilterSelectLocation
            onReturn={resetFilter}
            filterLocation={location}
            onSelect={handleFilterExchange}
            // searchOnChange
          />
        );

      case FILTER_MENU_ITEM_TYPE.MATERIAL_CONDITION:
        return (
          <FilterSelectMaterialCondition
            onReturn={resetFilter}
            materialCondition={materialCondition}
            onSelect={handleFilterExchange}
            searchOnChange
          />
        );
      default:
        return <div>None</div>;
    }
  };

  return (
    <Collapse in={open}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: (theme) => theme.palette.primary.main,
          padding: 3,
        }}
        ref={ref}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '12px',
            mb: 2,
          }}
        >
          {menuItems.map((item, index) => (
            <Box key={index}>
              <Button
                key={index}
                sx={{
                  fontSize: '0.7em',
                  color:
                    selectedFilter === item.type
                      ? '#fff'
                      : theme.palette.primary.main,
                  backgroundColor:
                    selectedFilter === item.type
                      ? theme.palette.primary['400']
                      : '#fff',
                  border: '1px solid #fff',
                  padding: '0px 6px',
                  '&:hover': {
                    color: '#fff',
                  },
                }}
                size='small'
                variant='contained'
                onClick={() => {
                  setSelectedFilter(item.type);
                }}
                endIcon={<ArrowDropDownIcon />}
              >
                {item.name}
              </Button>
            </Box>
          ))}
        </Box>
        <Divider sx={{ backgroundColor: '#fff', mb: 2 }} />
        {selectedFilter && getSelection()}
      </Box>
    </Collapse>
  );
});

export default FilterMassPanel;
