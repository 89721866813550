import { SIDE_BAR_WIDTH } from '@/constants/css.constants';
import { currentRoute } from '@/routers/routes';
import { theme } from '@/themes/MolunderTheme';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { getInitials } from '@utils/name.utils';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Logo from '../Logo';
import SwitchLanguages from '@components/languages/SwitchLanguages';
import NotificationList from '@components/notifications/NotificationList';
import SearchBar from '@components/toolbar';
import { useStore } from '@/RootStoreProvider';
import { MenuNames } from '@/routers/RouteCategoryName.enum';
import { I18N } from '@enums/i18n.enum';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
interface ComponentProps {
  logout?: () => Promise<void>;
  isWeb: boolean;
  openLeft?: boolean;
  openRight?: boolean;
  handleDrawerMenuClick?: any;
  isTransparent?: boolean;
}

const NavigationBar = (props: ComponentProps) => {
  const { isWeb, openRight, handleDrawerMenuClick, isTransparent, logout } =
    props;
  const location = useLocation();
  const isHidden =
    location.pathname === '/login' || location.pathname === '/register';
  const route = currentRoute();
  const showSearchBar = route && route.name === MenuNames.HOME_EXCHANGE;
  const history = useHistory();
  const { userStore, authenticationStore, notificationStore, massStore } =
    useStore();
  const { t } = useTranslation();
  const { loggedUser } = authenticationStore;

  useEffect(() => {
    if (loggedUser) {
      userStore.getUser();
      notificationStore.getNotifications({
        userId: authenticationStore.loggedUser.id,
      });
    }
  }, []);

  const goHome = (): void => {
    if (!loggedUser?.visitedAsGuest) history.push('/my-projects');
  };

  const handleSearchExchange = async (model: any) => {
    await massStore.searchExchange({
      id: model.massSelected.id,
      type: model.massSelected.type,
      lat: model.selectedLocation.latitude,
      lng: model.selectedLocation.longitude,
    });
  };

  if (isHidden) return null;

  return (
    <>
      <AppBar
        position='fixed'
        elevation={isTransparent ? 0 : 1}
        color={isTransparent ? 'transparent' : 'primary'}
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          backgroundColor: theme.palette.primary['400'],
          ...(openRight && {
            marginRight: SIDE_BAR_WIDTH,
            width: `calc(100% - ${SIDE_BAR_WIDTH}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        }}
      >
        <Toolbar>
          {isWeb && loggedUser && !loggedUser.visitedAsGuest && (
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerMenuClick}
              edge='start'
            >
              <MenuIcon />
            </IconButton>
          )}
          {isWeb && (
            <Box
              sx={{
                flexGrow: isWeb ? 1 : 0,
                ml: isWeb ? (theme) => theme.spacing(0) : 'initial',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Logo sx={{ cursor: 'pointer' }} goHome={goHome} />
            </Box>
          )}
          {!isWeb && (
            <Typography sx={{ flexGrow: 1 }} variant='h6' color='inherit'>
              {route?.title}
            </Typography>
          )}
          {showSearchBar && (
            <SearchBar handleSearchMass={handleSearchExchange} />
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <SwitchLanguages />
            {loggedUser?.visitedAsGuest && (
              <Button
                onClick={logout}
                sx={{
                  color: 'common.white',
                }}
              >
                <Typography variant='subtitle1' ml={3}>
                  Login
                </Typography>
              </Button>
            )}
            {loggedUser && !loggedUser.visitedAsGuest && <NotificationList />}
            {!loggedUser?.visitedAsGuest && (
              <Tooltip
                title={!loggedUser ? t(I18N.TITLE_USER_RECOMMENDATION) : null}
                placement='top-end'
              >
                <IconButton
                  sx={{ p: 0, ml: 1 }}
                  color='inherit'
                  onClick={() => {
                    history.push(loggedUser ? '/profile' : '/login');
                  }}
                >
                  <Avatar
                    sx={{ bgcolor: 'info.background', color: 'info.content' }}
                    alt={authenticationStore.loggedUser?.fullName}
                  >
                    {authenticationStore.loggedUser ? (
                      <Typography variant='subtitle1'>
                        {getInitials(
                          authenticationStore.loggedUser.fullName || ''
                        )}
                      </Typography>
                    ) : (
                      <PersonIcon />
                    )}
                  </Avatar>
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default observer(NavigationBar);
