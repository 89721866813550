import {
  CLUSTER_LAYER_PREFIX,
  MAP_LAYER_DATA_GRAVEL_AND_HARD_ROCK,
  MAP_LAYER_DATA_MINERAL_EXTRACTION,
  MAP_LAYER_DATA_POLLUTION,
  MAP_LAYER_DATA_SUPERFICIAL,
} from '@/constants/map.constants';
import { Request } from '@/dto/mass/request/Request.dto';
import { Supply } from '@/dto/mass/supply/Supply.dto';
import { MAP_TYPE } from '@/enums/map.enum';
import mapboxgl, { LngLatLike } from 'mapbox-gl';
import {
  onMapMouseEnter,
  onMapMouseLeave,
} from '../../../../components/commons/Map/MapUtils/commonEvents';
import { MAX_ITEM_COUNT } from '@/constants/map.constants';
import RequestCustom from '../../../../assets/images/common/custom-map-icons/request-custom.svg';
import LocationCustom from '../../../../assets/images/common/custom-map-icons/location-custom.svg';
import createCustomIcon from '../MapUtils/createCustomIcon';

export const DEFAULT_MASS_LAYOUT_TEXT_FIELD: mapboxgl.Expression = [
  'format',
  [
    'case',
    [
      'all',
      ['>', ['get', 'count'], 1],
      ['<=', ['get', 'count'], MAX_ITEM_COUNT],
    ],
    ['get', 'count'],
    ['>', ['get', 'count'], MAX_ITEM_COUNT],
    ['concat', MAX_ITEM_COUNT, '+'],
    '',
  ],
  {
    'font-scale': 1,
  },
];
export const DEFAULT_MASS_LAYOUT_TEXT_OFFSET = [0.8, -1.0];
export const DEFAULT_MASS_LAYOUT_ICON_IMAGE: mapboxgl.Expression = [
  'match',
  ['get', 'type'],
  MAP_TYPE.SUPPLY,
  ['case', ['>', ['get', 'count'], 1], 'location-custom', 'location-custom'],
  MAP_TYPE.REQUEST,
  ['case', ['>', ['get', 'count'], 1], 'request-custom', 'request-custom'],
  'location-custom',
];
export const DEFAULT_MASS_LAYOUT_ICON_SIZE = 1;

const DEFAULT_MASS_LAYOUT: mapboxgl.SymbolLayout = {
  'text-field': DEFAULT_MASS_LAYOUT_TEXT_FIELD,
  'text-offset': DEFAULT_MASS_LAYOUT_TEXT_OFFSET,
  'icon-image': DEFAULT_MASS_LAYOUT_ICON_IMAGE,
  'icon-size': DEFAULT_MASS_LAYOUT_ICON_SIZE,
};

export const loadMass = (
  map: mapboxgl.Map,
  massData: Supply[] | Request[],
  type: MAP_TYPE
) => {
  const sourceId = type;
  if (!map.getSource(sourceId)) {
    map.addSource(sourceId, {
      type: 'geojson',
      data: convertMassToGeojson(massData, type),
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
      clusterProperties: {
        only_supply: ['all', ['==', ['get', 'type'], MAP_TYPE.SUPPLY], 'false'],
        only_request: [
          'all',
          ['==', ['get', 'type'], MAP_TYPE.REQUEST],
          'false',
        ],
      },
    });
  }

  const layerId = type;
  if (!map.getLayer(layerId)) {
    map.addLayer({
      id: layerId,
      type: 'symbol',
      source: sourceId,
      filter: ['!', ['has', 'point_count']],
      layout: DEFAULT_MASS_LAYOUT,
      paint: {
        'text-color': '#ffffff',
        'text-halo-color': '#449494',
        'text-halo-width': 3,
      },
    });

    map.addLayer({
      id: `${CLUSTER_LAYER_PREFIX}${layerId}`,
      type: 'circle',
      source: sourceId,
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': '#ffffff',
        'circle-radius': 20,
      },
    });
    map.addLayer({
      id: `${CLUSTER_LAYER_PREFIX}${layerId}-count`,
      type: 'symbol',
      source: sourceId,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-size': 16,
      },
    });

    map.on('click', `${layerId}`, (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: [`${layerId}`],
      });
    });

    map.on('click', `${CLUSTER_LAYER_PREFIX}${layerId}`, (e) => {
      e.originalEvent.stopPropagation();

      const features = map.queryRenderedFeatures(e.point, {
        layers: [`${CLUSTER_LAYER_PREFIX}${layerId}`],
      });

      const clusterId = features[0].properties.cluster_id;
      const source: mapboxgl.GeoJSONSource = map.getSource(
        sourceId
      ) as mapboxgl.GeoJSONSource;

      source.getClusterChildren(clusterId, (err, features) => {
        if (err) return;
        const geometry = features[0].geometry;
        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          let center: LngLatLike = e.lngLat;
          let centerCoordinates = [];
          if (geometry.type !== 'GeometryCollection') {
            centerCoordinates = geometry.coordinates;
            center = new mapboxgl.LngLat(
              centerCoordinates[0],
              centerCoordinates[1]
            );
          }

          map.easeTo({
            center,
            zoom: zoom + 1.5,
          });
        });
      });
    });

    map.on('mouseenter', `${CLUSTER_LAYER_PREFIX}${layerId}`, () =>
      onMapMouseEnter(map)
    );
    map.on('mouseleave', `${CLUSTER_LAYER_PREFIX}${layerId}`, () =>
      onMapMouseLeave(map)
    );
  }

  createCustomIcon(RequestCustom, 'request-custom', map);
  createCustomIcon(LocationCustom, 'location-custom', map);
};

const convertMassToGeojson = (
  massData: Supply[] | Request[],
  type: MAP_TYPE
): any => {
  const ids = massData.map((o: any) => o.locationId);
  const uniqueMassData = massData.filter(
    ({ locationId }: Supply | Request, index: number) =>
      !ids.includes(locationId, index + 1)
  );

  return {
    type: 'FeatureCollection',
    features: uniqueMassData.map((massItem: Supply | Request) => {
      const count = massData.filter(
        (obj: Supply | Request) => obj.locationId === massItem.locationId
      ).length;

      return {
        type: 'Feature',
        properties: {
          id: massItem.id,
          locationId: massItem.locationId,
          type,
          count,
        },
        geometry: {
          type: massItem.location?.type,
          coordinates: [
            Number(massItem.location?.longitude),
            Number(massItem.location?.latitude),
          ],
        },
      };
    }),
  };
};

export const loadMassLayerGravelAndHardRock = (
  map: mapboxgl.Map,
  id: string,
  layers: string[]
): void => {
  loadMassLayer(
    map,
    id,
    layers,
    MAP_LAYER_DATA_GRAVEL_AND_HARD_ROCK.serverUrl,
    MAP_LAYER_DATA_GRAVEL_AND_HARD_ROCK.sourceId
  );
};

export const loadMassLayer3 = (
  map: mapboxgl.Map,
  id: string,
  layers: string[]
): void =>
  loadMassLayer(
    map,
    id,
    layers,
    MAP_LAYER_DATA_SUPERFICIAL.serverUrl,
    MAP_LAYER_DATA_SUPERFICIAL.sourceId
  );

export const loadMassLayerPollution = (
  map: mapboxgl.Map,
  id: string,
  layers: string[]
): void => {
  loadMassLayer(
    map,
    id,
    layers,
    MAP_LAYER_DATA_POLLUTION.serverUrl,
    MAP_LAYER_DATA_POLLUTION.sourceId
  );
};

export const loadMassLayerMineralExtraction = (
  map: mapboxgl.Map,
  id: string,
  layers: string[]
): void =>
  loadMassLayer(
    map,
    id,
    layers,
    MAP_LAYER_DATA_MINERAL_EXTRACTION.serverUrl,
    MAP_LAYER_DATA_MINERAL_EXTRACTION.sourceId
  );

const loadMassLayer = (
  map: mapboxgl.Map,
  id: string,
  layers: string[],
  serverUrl: string,
  sourceId: string
): void => {
  // Remove source and layer old
  removeSourceAndLayer(map, sourceId, id);

  // Add source and layer
  if (layers.length) {
    addSourceAndLayer(map, sourceId, id, [`${serverUrl}${layers.join(',')}`]);
  }
};

const removeSourceAndLayer = (
  map: mapboxgl.Map,
  sourceId: string,
  layerId: string
): void => {
  if (map.getLayer(layerId)) {
    map.removeLayer(layerId);
  }

  if (map.getSource(sourceId)) {
    map.removeSource(sourceId);
  }
};

const addSourceAndLayer = (
  map: mapboxgl.Map,
  sourceId: string,
  layerId: string,
  tiles: string[]
): void => {
  map.addSource(sourceId, {
    type: 'raster',
    tiles,
    tileSize: 1024,
  });

  map.addLayer(
    {
      id: layerId,
      type: 'raster',
      source: sourceId,
      paint: {},
    },
    'aeroway-line'
  );
};
