import { InfoCo2Emission } from '@/dto/settings/InfoCo2Emission.dto';
import { SettingsHub } from '@/dto/settings/SettingsHub.dto';
import { SettingsMaterial } from '@/dto/settings/SettingsMaterial.dto';
import {
  HUB_CATEGORY_TYPE,
  HUB_SUBCATEGORY_TYPE,
} from '@/enums/hubCategory.enum';
import { INIT_LOCATION } from './location.constants';
import {
  TRANSPORT_EURO_STANDARD,
  FUEL,
  TRUCK_TYPE,
} from '../enums/transport-euro-standard.enum';
import { HBEFA_CLASS_RIGID_TRUCK } from '../constants/transport.constants';
import { SettingsTransport } from '@/dto/settings/SettingsTransport.dto';
import { Association } from '@/dto/association/association.dto';

export const INIT_SETTINGS_MATERIAL: SettingsMaterial = {
  id: 0,
  alternativeName: '',
  sizeFrom: 0,
  sizeTo: 0,
  isActive: false,
  updatedAt: new Date(),
  name: '',
  description: '',
  languageCode: 'no',
  table: 'material',
  ealCodes: [],
  nsCodes: [],
  isWaste: false,
  isHazardous: false,
};

export const INIT_SETTINGS_HUB: SettingsHub = {
  id: 0,
  title: '',
  name: '',
  mobileNumber: '',
  isActive: false,
  updatedAt: new Date(),
  location: INIT_LOCATION,
  locationId: 0,
  description: '',
  website: '',
  category: HUB_CATEGORY_TYPE.HUB_DEPOT,
  subCategory: HUB_SUBCATEGORY_TYPE.SUB_HUB_UNSPECIFIELD,
  asset: null,
  color: null,
  languageCode: 'no',
  organizationId: '',
  isAcceptingPollutedMass: false,
  isPrivate: false,
  isWash: false,
  class: [],
  depotCat: [],
  sales: false,
};

export const INIT_SETTINGS_TRANSPORT: SettingsTransport = {
  id: 0,
  name: '',
  euroStandard: TRANSPORT_EURO_STANDARD.EURO_I,
  capacity: 7.5,
  emissions: '0',
  truckType: TRUCK_TYPE.RIGID_TRUCK,
  fuelType: FUEL.DIESEL,
  hbefaClass: HBEFA_CLASS_RIGID_TRUCK[0],
};

export const INIT_HUB_FORM_ERROR: Record<string, boolean> = {
  name: false,
  mobileNumber: false,
  location: false,
  category: false,
  asset: false,
  // Following fields are for Occurrence category only
  subCategory: false,
  description: false,
  mainRock: false,
  significance: false,
  organizationId: false,
};

export const I18N_HUB_PREFIX = 'HUB_';

export const INIT_SETTINGS_CO2EMISSIONS: InfoCo2Emission = {
  value: 0,
  updatedAt: new Date(),
};

export const INIT_HUB_FILTER = {
  category: HUB_CATEGORY_TYPE.HUB_ALL,
};
