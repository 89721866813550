import http from '@/apis';
import { PaginationResult } from '@/dto/commons/PaginationRequest.dto';
import { InfoTrip } from '@/dto/trip/InfoTrip.dto';

class TripService {
  prefix = 'trip';

  public async registerTrip(
    model: InfoTrip,
    file: File | null
  ): Promise<boolean> {
    const form = new FormData();

    form.append('model', JSON.stringify(model));

    if (file) {
      form.append('file', file);
    }
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const result = await http.post(`${this.prefix}`, form, config);

    return result.data.result;
  }

  public async getTripById(id: number): Promise<InfoTrip> {
    const result = await http.get(`${this.prefix}/${id}`);
    return result.data.result;
  }

  public async getTripsByProject(projectId: number): Promise<InfoTrip[]> {
    const result = await http.get(`${this.prefix}/project/${projectId}`);
    return result.data.result;
  }

  public async updateTrip(
    model: InfoTrip,
    file: File | null
  ): Promise<boolean> {
    const form = new FormData();

    form.append('model', JSON.stringify(model));

    if (file) {
      form.append('file', file);
    }
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await http.put(`${this.prefix}/${model.id}`, form, config);
    return result.data.result;
  }

  public async getAllTransactionTrips(
    id: number
  ): Promise<PaginationResult<InfoTrip>> {
    const result = await http.get(`${this.prefix}/${id}`);
    return result.data.result;
  }
}

export default new TripService();
