import { getLanguageCurrent } from '@utils/language.utils';
import { LANGUAGE } from './lang.enum';

export enum HUB_CATEGORY_TYPE {
  HUB_ALL = 'ALL',
  HUB_DEPOT = 'HUB_DEPOT',
  HUB_PORT = 'HUB_PORT',
  HUB_RECYCLE = 'HUB_RECYCLE',
  HUB_OCCURRENCE = 'HUB_OCCURRENCE',
  HUB_CUSTOM = 'HUB_CUSTOM',
}

export enum HUB_SUBCATEGORY_TYPE {
  SUB_HUB_UNSPECIFIELD = '',
  SUB_HUB_QUARRY = 'SUB_HUB_QUARRY',
  SUB_HUB_MASS_ROOF = 'SUB_HUB_MASS_ROOF',
  SUB_HUB_GRAVEL_ROOF = 'SUB_HUB_GRAVEL_ROOF',
}

export enum HUB_OBJECT_TYPE {
  UNSPECIFIELD = 'UNSPECIFIELD',
  PUKK = 'PUKK',
  SAND_GRUS = 'SAND_GRUS',
}

export enum HUB_SIGNIFICANCE_TYPE {
  // Local significance - Lokal betydning
  LOCAL_SIGNIFICANCE = 'LOCAL_SIGNIFICANCE',
  // Little significance - Liten betydning
  LITTLE_SIGNIFICANCE = 'LITTLE_SIGNIFICANCE',
  // Not rated - Ikke vurdert
  NOT_RATED = 'NOT_RATED',
  // National significance - Nasjonal betydning
  NATIONAL_SIGNIFICANCE = 'NATIONAL_SIGNIFICANCE',
  // Interational significance - Internasjonal  betydning
  INTERNATIONAL_SIGNIFICANCE = 'INTERNATIONAL_SIGNIFICANCE',
}

export const getNameCategory = (category: HUB_CATEGORY_TYPE): string => {
  let name = '';
  const lang = getLanguageCurrent();
  switch (category) {
    case HUB_CATEGORY_TYPE.HUB_DEPOT:
      if (lang === LANGUAGE.NORWAY) {
        name = 'Depot';
      } else if (lang === LANGUAGE.ICELAND) {
        name = 'Geymsla';
      } else {
        name = 'Depot';
      }
      break;
    case HUB_CATEGORY_TYPE.HUB_PORT:
      if (lang === LANGUAGE.NORWAY) {
        name = 'Havn';
      } else if (lang === LANGUAGE.ICELAND) {
        name = 'Höfn';
      } else {
        name = 'Port';
      }
      break;
    case HUB_CATEGORY_TYPE.HUB_RECYCLE:
      if (lang === LANGUAGE.NORWAY) {
        name = 'Gjenvinningsanlegg';
      } else if (lang === LANGUAGE.ICELAND) {
        name = 'Endurvinnsluaðstaða';
      } else {
        name = 'Recycling facility';
      }
      break;
    case HUB_CATEGORY_TYPE.HUB_OCCURRENCE:
      if (lang === LANGUAGE.NORWAY) {
        name = 'Occurrence';
      } else if (lang === LANGUAGE.ICELAND) {
        name = 'Occurrence';
      } else {
        name = 'Occurrence';
      }
      break;
    case HUB_CATEGORY_TYPE.HUB_CUSTOM:
      if (lang === LANGUAGE.NORWAY) {
        name = 'Custom';
      } else if (lang === LANGUAGE.ICELAND) {
        name = 'Custom';
      } else {
        name = 'Custom';
      }
      break;
  }

  return name;
};

export interface HubDisplayedFields {
  description: boolean;
  icon: boolean;
  subcategory: boolean;
  mainRock: boolean;
  significance: boolean;
  website: boolean;
  company: boolean;
  title: boolean;
  organizationId: boolean;
  isAcceptingPollutedMass: boolean;
  profileImage: boolean;
  class: boolean;
  depotCat: boolean;
  isPrivate: boolean;
  isWash: boolean;
}

export const getDisplayedFields = (
  category: HUB_CATEGORY_TYPE
): HubDisplayedFields => {
  switch (category) {
    case HUB_CATEGORY_TYPE.HUB_CUSTOM:
      return {
        description: true,
        icon: true,
        subcategory: false,
        mainRock: false,
        significance: false,
        website: true,
        company: true,
        title: true,
        organizationId: false,
        isAcceptingPollutedMass: false,
        profileImage: true,
        class: false,
        depotCat: false,
        isPrivate: false,
        isWash: false,
      };
    case HUB_CATEGORY_TYPE.HUB_OCCURRENCE:
      return {
        description: true,
        icon: false,
        subcategory: true,
        mainRock: true,
        significance: true,
        website: true,
        company: false,
        title: false,
        organizationId: false,
        isAcceptingPollutedMass: false,
        profileImage: false,
        class: false,
        depotCat: false,
        isPrivate: false,
        isWash: false,
      };
    case HUB_CATEGORY_TYPE.HUB_PORT:
      return {
        description: true,
        icon: true,
        subcategory: false,
        mainRock: false,
        significance: false,
        website: true,
        company: false,
        title: false,
        organizationId: false,
        isAcceptingPollutedMass: false,
        profileImage: true,
        class: false,
        depotCat: false,
        isPrivate: false,
        isWash: false,
      };
    case HUB_CATEGORY_TYPE.HUB_DEPOT:
      return {
        description: true,
        icon: true,
        subcategory: false,
        mainRock: false,
        significance: false,
        website: true,
        company: false,
        title: false,
        organizationId: true,
        isAcceptingPollutedMass: true,
        profileImage: true,
        class: true,
        depotCat: true,
        isPrivate: true,
        isWash: true,
      };
    case HUB_CATEGORY_TYPE.HUB_RECYCLE:
      return {
        description: true,
        icon: false,
        subcategory: false,
        mainRock: false,
        significance: false,
        website: true,
        company: false,
        title: false,
        organizationId: true,
        isAcceptingPollutedMass: true,
        profileImage: false,
        class: false,
        depotCat: false,
        isPrivate: false,
        isWash: false,
      };
    default:
      return {
        description: false,
        icon: false,
        subcategory: false,
        mainRock: false,
        significance: false,
        website: false,
        company: false,
        title: false,
        organizationId: false,
        isAcceptingPollutedMass: false,
        profileImage: false,
        class: false,
        depotCat: false,
        isPrivate: false,
        isWash: false,
      };
  }
};
