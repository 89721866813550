import './App.css';
import 'famfamfam-flags/dist/sprite/famfamfam-flags.min.css';
import RouterComponent from '@/routers';
import { AbilityContext, appAbility } from '@components/casl/Can';
import { observer } from 'mobx-react';

function App() {
  return (
    <AbilityContext.Provider value={appAbility}>
      <RouterComponent />
    </AbilityContext.Provider>
  );
}

export default observer(App);
