import { Box, CircularProgress } from '@mui/material';

const AppSkeleton = () => {
  return (
    <>
      <Box className='center-spin'>
        <CircularProgress />
      </Box>
    </>
  );
};

export default AppSkeleton;
