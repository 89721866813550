import { MAP_CENTER } from '@/constants/map.constants';
import { Request } from '@/dto/mass/request/Request.dto';
import { Supply } from '@/dto/mass/supply/Supply.dto';
import { SettingsHub } from '@/dto/settings/SettingsHub.dto';
import { MAP_SEARCH_MODE } from '@/enums/map.enum';
import { action, makeObservable, observable } from 'mobx';

class MapStore {
  selectedSupplies: Supply[];
  selectedRequests: Request[];
  selectedExchanges: (Request | Supply)[];
  selectedHubs: SettingsHub[];
  filteredHubs: SettingsHub[];
  selectedUserIdExchange: number;
  openSelectedCard: boolean;
  mapCenter: any[] | undefined;
  displayedRoute: any[] | undefined;
  searchMode: MAP_SEARCH_MODE;

  constructor() {
    this.selectedExchanges = [];
    this.selectedHubs = [];
    this.filteredHubs = [];
    this.selectedUserIdExchange = null;
    this.openSelectedCard = false;
    this.mapCenter = MAP_CENTER;
    this.displayedRoute = undefined;
    this.searchMode = MAP_SEARCH_MODE.NONE;

    makeObservable(this, {
      selectedExchanges: observable,
      selectedHubs: observable,
      filteredHubs: observable,
      openSelectedCard: observable,
      selectedUserIdExchange: observable,
      mapCenter: observable,
      displayedRoute: observable,
      searchMode: observable,
      updateSelectedHubs: action,
      updateSelectedExchanges: action,
      updateFilteredHubs: action,
      setOpenSelectedCard: action,
      setMapCenter: action,
      removeMapCenter: action,
      setSearchMode: action,
      setDisplayedRoute: action,
    });
  }

  updateSelectedExchanges(exchanges: (Request | Supply)[]): void {
    if (!!exchanges.length) this.selectedUserIdExchange = exchanges[0].userId;
    this.selectedExchanges = exchanges;
    this.openSelectedCard = true;
  }

  updateSelectedHubs(hubs: SettingsHub[]): void {
    this.selectedHubs = hubs;
    this.openSelectedCard = true;
  }

  updateFilteredHubs(hubs: SettingsHub[]): void {
    this.filteredHubs = hubs;
  }

  setOpenSelectedCard(value: boolean): void {
    this.openSelectedCard = value;
  }

  setMapCenter(lat: number, lng: number): void {
    this.mapCenter = [lng, lat];
  }

  setDisplayedRoute(from: number[], to: number[]): void {
    this.displayedRoute = [from, to];
  }

  removeMapCenter(): void {
    this.mapCenter = undefined;
  }

  setSearchMode(searchMode: MAP_SEARCH_MODE): void {
    this.searchMode = searchMode;
  }
}

export default MapStore;
