import { SIDE_BAR_WIDTH } from '@/constants/css.constants';
import { SelectOption } from '@/dto/commons/SelectOption.dto';
import { I18N } from '@/enums/i18n.enum';
import { CommonStoreContext } from '@/stores';
import { theme } from '@/themes/MolunderTheme';
import {
  Box,
  Stack,
  useMediaQuery,
  ClickAwayListener,
  Popper,
  MenuItem,
  Menu,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { createRef, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Search } from '@mui/icons-material';
import { useStore } from '@/RootStoreProvider';
import { observer } from 'mobx-react';
import { FILTER_MENU_ITEM_TYPE, MAP_SEARCH_MODE } from '@/enums/map.enum';
import FilterMassPanel from '../FilterMassPanel';

interface ComponentProps {
  searchPlaceholder: string;
  data: SelectOption[];
  onSearch: (value: string, langCode: string) => void;
  handleSearchMass: (model: any) => void;
  setSearchKey: (key: string) => void;
}

const searchMassTheme = {
  mobile: {
    position: 'absolute',
    zIndex: 1,
    top: {
      sidebarOpen: 62,
      sidebarClosed: 89,
    },
    left: '40%',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: {
      sidebarOpen: '100vw',
      sidebarClosed: 'calc(100vw - 140px)',
    },
    height: '58px',
    order: 0,
    flexGrow: 0,
    border: '1px solid transparent',
  },
  desktop: {
    width: '724px',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
    top: '6%',
    left: '40%',
    transform: {
      sidebarOpen: `translateX(calc(-50% + ${SIDE_BAR_WIDTH}px / 2))`,
      sidebarClosed: 'translateX(-50%)',
    },
  },
} as const;

const SearchMass = (props: ComponentProps) => {
  const { searchPlaceholder, data, onSearch, handleSearchMass, setSearchKey } =
    props;

  const isWeb = useMediaQuery(theme.breakpoints.up('sm'));
  const { t, i18n } = useTranslation();
  const commonStore = useContext(CommonStoreContext);
  const { authenticationStore, mapStore, exchangeFilterStore } = useStore();
  const searchPopperRef = useRef(null);

  const [massSelected, setMassSelected] = useState<number>(0);
  const [filterMassOpen, setFilterMassOpen] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] =
    useState<FILTER_MENU_ITEM_TYPE | null>(FILTER_MENU_ITEM_TYPE.MATERIAL);

  const { isSidebarOpen } = commonStore;
  const { REGISTER_MATERIAL_NAME } = I18N;

  const handleSearch = (search: any) => {
    exchangeFilterStore.setCurrentSearchKey(search);
    onSearch(search, i18n.language);
  };

  const handleSelectedMass = (data: any) => {
    if (data) {
      setMassSelected(data.value);
      setSearchKey(data.label);
    }

    const user = authenticationStore.loggedUser;
    const location = user.focusLocation ?? user.location;

    handleSearchMass({
      massSelected: {
        id: data.value,
        type: data.type,
      },
      selectedLocation: {
        latitude: location.latitude,
        longitude: location.longitude,
      },
    });
  };

  const sxTheme = isWeb
    ? {
        ...searchMassTheme.desktop,
        transform: isSidebarOpen
          ? searchMassTheme.desktop.transform.sidebarOpen
          : searchMassTheme.desktop.transform.sidebarClosed,
      }
    : {
        ...searchMassTheme.mobile,
        top:
          mapStore.searchMode !== MAP_SEARCH_MODE.NONE
            ? searchMassTheme.mobile.top.sidebarOpen
            : searchMassTheme.mobile.top.sidebarClosed,
        width:
          mapStore.searchMode !== MAP_SEARCH_MODE.NONE
            ? searchMassTheme.mobile.width.sidebarOpen
            : searchMassTheme.mobile.width.sidebarClosed,
      };

  const SearchPopper = (props) => {
    return (
      <Popper
        {...props}
        open={filterMassOpen}
        children={(childProps) => {
          return (
            <>
              {isWeb && (
                <FilterMassPanel
                  ref={searchPopperRef}
                  open={filterMassOpen}
                  selectedMaterials={exchangeFilterStore.materials}
                  volume={exchangeFilterStore.volume}
                  price={exchangeFilterStore.price}
                  location={exchangeFilterStore.location}
                  materialCondition={exchangeFilterStore.materialCondition}
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                />
              )}
              {!data.length && (
                <MenuItem
                  value={''}
                  sx={{
                    backgroundColor: '#fff',
                    '&:hover': {
                      backgroundColor: theme.palette.background.default,
                    },
                  }}
                >
                  No result found
                </MenuItem>
              )}
              {data.map((option: any, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={option.value}
                    sx={{
                      backgroundColor: '#fff',
                      '&:hover': {
                        backgroundColor: theme.palette.background.default,
                      },
                    }}
                    onClick={(event) => {
                      handleSelectedMass(option);
                      setMassSelected(0);
                      mapStore.setSearchMode(MAP_SEARCH_MODE.EXCHANGE);
                      setFilterMassOpen(false);
                    }}
                  >
                    {option.label}
                  </MenuItem>
                );
              })}
            </>
          );
        }}
        placement='bottom-start'
        sx={{ zIndex: 9999 }}
      />
    );
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setFilterMassOpen(false);
      }}
    >
      <Box sx={sxTheme}>
        <Autocomplete
          freeSolo
          id='free-solo-2-demo'
          clearOnBlur={false}
          options={[
            ...new Map(data.map((item) => [item['label'], item])).values(),
          ]}
          onClose={(event, reason) => {
            if (reason === 'selectOption')
              mapStore.setSearchMode(MAP_SEARCH_MODE.EXCHANGE);
          }}
          onMouseDown={(e) => e.preventDefault()}
          renderInput={(params) => (
            <TextField
              {...params}
              onFocus={() => {
                setFilterMassOpen(true);
              }}
              sx={{
                backgroundColor: theme.palette.primary['300'],
                '.MuiFormLabel-root': {
                  color: '#fff',
                },
                '& input': {
                  color: '#fff',
                },
              }}
              label={t(REGISTER_MATERIAL_NAME)}
              variant='filled'
              color='white'
              onChange={(value) => {
                handleSearch(value.target.value);
              }}
              placeholder={searchPlaceholder}
              InputProps={{
                ...params.InputProps,
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                  }
                },
                endAdornment: (
                  <Stack
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='center'
                    sx={{
                      mt: -2,
                      color: '#fff',
                    }}
                  >
                    {massSelected < 1 ? (
                      <Search
                        sx={{
                          color: '#fff',
                        }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Stack>
                ),
              }}
            />
          )}
          sx={{
            width: '100%',
            '.MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
              color: '#fff',
            },
          }}
          PopperComponent={SearchPopper}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default observer(SearchMass);
