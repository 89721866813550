import { Box } from '@mui/system';
import { FilterLocation } from '@/stores/marketplace-filter.store';
import { useEffect, useState } from 'react';
import FilterSelectionButton from './FilterSelectButton';
import AddressAutocomplete from '@components/commons/AddressAutocomplete';
import { Location } from '@/dto/locations/Location.dto';
import { useTranslation } from 'react-i18next';
import { I18N } from '@/enums/i18n.enum';
import Slider from '@mui/material/Slider';
import FilterSelectLocationMap from './FilterSelectLocationMap';
import { LOCATION_TYPE } from '@/enums/location.enum';

interface ComponentProps {
  onReturn: () => void;
  filterLocation: FilterLocation;
  onSelect?: () => void;
  searchOnChange?: boolean;
}

const FilterSelectLocation = (props: ComponentProps) => {
  const { onReturn, filterLocation, onSelect, searchOnChange } = props;

  const { t } = useTranslation();
  const { TITLE_REQUIRE_ADDRESS } = I18N;

  const [location, setLocation] = useState<Location | undefined>(
    filterLocation.location
  );

  const [radius, setRadius] = useState<number | undefined>(
    filterLocation.radius
  );

  const [localRadius, setLocalRadius] = useState<number | undefined>(
    filterLocation.radius
  );

  const [selectedItems, setSelectedItems] = useState<number>(0);

  const [mapPosition, setMapPosition] = useState<number[] | undefined>(
    location && location.longitude
      ? [location.longitude, location.latitude]
      : filterLocation && filterLocation.location?.longitude
      ? [filterLocation.location.longitude, filterLocation.location.latitude]
      : undefined
  );

  useEffect(() => {
    setSelectedItems(getSelectedItems());

    if (location?.latitude !== undefined) {
      setMapPosition([location.longitude, location.latitude]);
    }
  }, [location, radius]);

  const getSelectedItems = () => {
    let selectedItems = 0;

    if (location !== undefined) {
      selectedItems++;
      selectedItems++;
    }

    return selectedItems;
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AddressAutocomplete
          label={'Adresse'}
          required
          defaultOption={location}
          color='white'
          onChange={(location: Location) => {
            if (location.latitude === 0) return;

            if (radius === undefined) {
              setRadius(1);
              setLocalRadius(1);
            }

            setLocation(location);
            setMapPosition([location.longitude, location.latitude]);
            filterLocation.location = location;
            filterLocation.radius = radius;

            if (searchOnChange) {
              onSelect();
            }
          }}
          //   hasError={errors.location}
          errorText={t(TITLE_REQUIRE_ADDRESS)}
        />
        <div
          style={{ width: '50%', height: '16px', color: 'transparent' }}
        ></div>
        <FilterSelectLocationMap
          key={'map'}
          mapPosition={mapPosition}
          radius={radius ?? 1}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '16px',
            paddingBottom: '8px',
          }}
        >
          <text>Avstand</text>
          <text>{radius ?? 0} km</text>
        </Box>
        <Slider
          sx={{
            color: '#fff',
          }}
          value={localRadius}
          disabled={location === undefined}
          aria-label='Default'
          valueLabelDisplay='auto'
          min={1}
          max={200}
          onChangeCommitted={(event, value) => {
            const newRadius = value as number;
            setRadius(newRadius);
            filterLocation.location = location;
            filterLocation.radius = newRadius;
            if (searchOnChange) {
              onSelect();
            }
          }}
          onChange={(event, value) => {
            setLocalRadius(value as number);
          }}
        />
      </Box>
      {!searchOnChange && (
        <FilterSelectionButton
          selectedItems={selectedItems}
          onReturn={onReturn}
          onSelect={() => {
            filterLocation.location = location;
            filterLocation.radius = radius;
            onSelect();
          }}
        ></FilterSelectionButton>
      )}
    </>
  );
};

export default FilterSelectLocation;
