// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from '!mapbox-gl';
import { MAP_CENTER, MAP_MAX_BOUNDS } from '@/constants/map.constants';
import { useResizeObserver } from '@utils/resizeObserver';
import 'mapbox-gl/dist/mapbox-gl.css';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MapboxCircle from 'mapbox-gl-circle';
import { useStore } from '@/RootStoreProvider';

interface MapboxMapProps {
  initialOptions?: Omit<mapboxgl.MapboxOptions, 'container'>;
  onCreated?(map: mapboxgl.Map): void;
  onLoaded?(map: mapboxgl.Map): void;
  onMove?(map: mapboxgl.Map): void;
  onClickCallback?(feature: any): void;
  onRemoved?(): void;
  mapPosition?: number[];
  radius?: number;
  minZoom?: number;
  onRadiusChange?(radius: number): void;
  onCenterChange?(center: number[]): void;
}

function MapboxMap({
  initialOptions = {},
  onCreated,
  onLoaded,
  onRemoved,
  onMove,
  mapPosition,
  radius,
  minZoom,
  onRadiusChange,
  onCenterChange,
}: MapboxMapProps) {
  const { mapLayerStore, userStore } = useStore();
  const [map, setMap] = useState<mapboxgl.Map>();
  const [circle, setCircle] = useState<MapboxCircle | undefined>();
  const mapNode = useRef(null);
  let mapboxMap: mapboxgl.Map;
  const mapContainerResizeCallback = () => {
    window.dispatchEvent(new Event('resize'));
  };

  const [canOnCenterChange, setCanOnCenterChange] = useState(true);

  const focusLocation = userStore.currentUser.focusLocation
    ? [
        userStore.currentUser.focusLocation.longitude,
        userStore.currentUser.focusLocation.latitude,
      ]
    : userStore.currentUser.location
    ? [
        userStore.currentUser.location.longitude,
        userStore.currentUser.location.latitude,
      ]
    : MAP_CENTER;

  useResizeObserver(mapNode, mapContainerResizeCallback);

  const setMapBounds = (circleObj: MapboxCircle) => {
    if (!circleObj || !map) return;

    const circleObjBounds = circleObj.getBounds();

    const newBounds = [
      [circleObjBounds.sw.lng, circleObjBounds.sw.lat],
      [circleObjBounds.ne.lng, circleObjBounds.ne.lat],
    ];

    map.fitBounds(newBounds, { padding: 25 });
  };

  useEffect(() => {
    setMapBounds(circle);
  }, [circle]);

  useEffect(() => {
    const node = mapNode.current;

    if (typeof window === 'undefined' || node === null) return;

    mapboxMap = new mapboxgl.Map({
      container: node,
      accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
      style: 'mapbox://styles/henrikmaa/ckvve8b403vqq15pb4cwak5dg',
      center: mapPosition ?? focusLocation, // starting position [lng, lat]
      zoom: mapPosition ? 11 : 7.5,
      minZoom: minZoom ?? 1, //6.202882378398238,
      trackResize: true,
      maxBounds: MAP_MAX_BOUNDS,
      ...initialOptions,
    });

    setMap(mapboxMap);

    if (onCreated) onCreated(mapboxMap);
    if (onMove) mapboxMap.on('move', () => onMove(mapboxMap));

    mapboxMap.on('load', () => {
      if (onLoaded) {
        onLoaded(mapboxMap);
      }
    });

    return () => {
      mapLayerStore.clearAllLayers();
      mapboxMap.remove();
      if (onRemoved) onRemoved();
    };
  }, []);

  useEffect(() => {
    if (radius && map) {
      // if no circle create one
      if (!circle) {
        const myCircle = new MapboxCircle(
          { lat: mapPosition[1] ?? 59.9221, lng: mapPosition[0] ?? 10.68891 },
          radius * 1000,
          {
            editable: true,
            minRadius: 1000,
            fillColor: '#29AB87',
            maxRadius: 200000,
          }
        ).addTo(map);

        myCircle.on('radiuschanged', (circleObj: MapboxCircle) => {
          !!onRadiusChange ?? onRadiusChange(circleObj.getRadius());
          setMapBounds(circleObj);
        });

        myCircle.on('centerchanged', (circleObj: MapboxCircle) => {
          if (!canOnCenterChange) {
            setCanOnCenterChange(true);
            return;
          }

          const center = circleObj.getCenter();

          if (mapPosition && center) {
            const centerComparator =
              center.lng.toString().substring(0, 6) +
              center.lat.toString().substring(0, 6);

            const mapPositionComparator =
              mapPosition[0].toString().substring(0, 6) +
              mapPosition[1].toString().substring(0, 6);

            if (centerComparator === mapPositionComparator) {
              setMapBounds(circleObj);

              return;
            }
          }

          !!onCenterChange ?? onCenterChange(center);
          setMapBounds(circleObj);
        });

        setCircle(myCircle);
      } else {
        circle.setRadius(radius * 1000);

        if (mapPosition) {
          setCanOnCenterChange(false);

          circle.setCenter({ lat: mapPosition[1], lng: mapPosition[0] });
        }
      }
    }
  }, [radius, mapPosition]);

  return (
    <>
      <div
        ref={mapNode}
        style={{ width: '100%', height: '434px', display: 'flex' }}
      ></div>
    </>
  );
}

export default observer(MapboxMap);
