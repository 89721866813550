export const asd =
  'HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	85.5511474609375	86.3023071289063	84.8638458251953	812.357360839844	702.329345703125	24.4012288411459	885.338562011719	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	86.1458969116211	86.3023071289063	86.0713958740234	801.143859863281	670.324768066406	25.4401611328125	861.1259765625	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	86.2971267700195	86.3023071289063	86.2947845458984	765.272644042969	640.416870117188	24.0620768229167	820.882446289063	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	86.2975463867188	86.3023071289063	86.295166015625	752.750061035156	629.300720214844	24.20322265625	810.824890136719	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	86.2991256713867	86.3023071289063	86.2959213256836	732.97998046875	625.884643554688	24.3264892578124	808.333312988281	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	86.2975463867188	86.3023071289063	86.2927627563477	725.9404296875	619.903625488281	24.0847005208333	800.538879394531	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	86.3018264770508	86.3023071289063	86.3013305664063	710.749572753906	609.474182128906	23.15771484375	783.157043457031	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	86.3016967773438	86.3023071289063	86.3010635375977	697.064208984375	597.776062011719	22.7031819661459	768.049926757813	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	86.3017654418945	86.3023071289063	86.3012313842773	665.185363769531	565.472534179688	22.6232503255208	735.146911621094	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	86.3016738891602	86.3023071289063	86.3010635375977	626.772827148438	534.185729980469	20.9400065104167	691.235778808594	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	86.3023071289063	86.3023071289063	86.3023071289063	19.7120056152344	20.2793292999268	-0.111712900797534	19.4414825439453	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	86.3023071289063	86.3023071289063	86.3023071289063	18.8174686431885	19.1961555480957	-0.0727485656738267	18.650541305542	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	86.3023071289063	86.3023071289063	86.3023071289063	20.2711277008057	20.6264171600342	-0.0681381225585999	20.1153812408447	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	86.3023071289063	86.3023071289063	86.3023071289063	20.703950881958	20.8956623077393	-0.0368848164876402	20.619026184082	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	86.3023071289063	86.3023071289063	86.3023071289063	5.6321120262146	5.82988452911377	-0.0390378952026374	5.53710031509399	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	86.3023071289063	86.3023071289063	86.3023071289063	5.61809015274048	5.81396627426147	-0.0386636098225907	5.52398920059204	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	86.3023071289063	86.3023071289063	86.3023071289063	16.0347175598145	16.1977005004883	-0.0321940104166664	15.9562454223633	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	86.3023071289063	86.3023071289063	86.3023071289063	16.0186653137207	16.1784400939941	-0.0315601348876937	15.9417390823364	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	86.3023071289063	86.3023071289063	86.3023071289063	13.6784086227417	13.733344078064	-0.0108553568522133	13.6519289016724	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	86.3023071289063	86.3023071289063	86.3023071289063	13.9417591094971	14.0337114334106	-0.01817423502604	13.8974046707153	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	86.3023071289063	86.3023071289063	86.3023071289063	37.7075080871582	72.3110046386719	-6.89385732014975	20.6070747375488	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	86.3023071289063	86.3023071289063	86.3023071289063	30.2743320465088	54.3554420471191	-4.63275782267252	19.6097583770752	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	86.3023071289063	86.3023071289063	86.3023071289063	30.6322231292725	52.4277076721191	-4.1824717203776	21.0591697692871	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	86.3023071289063	86.3023071289063	86.3023071289063	26.8226413726807	38.901668548584	-2.33224156697592	21.4098567962646	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	86.3023071289063	86.3023071289063	86.3023071289063	12.3756847381592	23.8012027740479	-2.31686763763428	6.42469549179077	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	86.3023071289063	86.3023071289063	86.3023071289063	12.2965841293335	23.6119518280029	-2.29452838897705	6.40298891067505	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	86.3023071289063	86.3023071289063	86.3023071289063	21.7962303161621	31.2085876464844	-1.91966679890951	16.8110866546631	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	86.3023071289063	86.3023071289063	86.3023071289063	21.666166305542	30.892297744751	-1.88168436686199	16.7796649932861	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	86.3023071289063	86.3023071289063	86.3023071289063	17.1249866485596	22.451566696167	-1.09321924845377	14.2524223327637	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	86.3023071289063	86.3023071289063	86.3023071289063	18.020809173584	24.5471248626709	-1.33305943806967	14.5491790771484	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	86.3023071289063	86.3023071289063	86.3023071289063	198.37971496582	338.653656005859	-30.7888987223307	107.736915588379	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	86.3023071289063	86.3023071289063	86.3023071289063	160.32878112793	305.378936767578	-28.1505900065104	94.24951171875	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	86.3023071289063	86.3023071289063	86.3023071289063	153.356872558594	287.200469970703	-25.7718566894531	93.9115447998047	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	86.3023071289063	86.3023071289063	86.3023071289063	140.953765869141	262.118957519531	-23.6694376627604	84.5981750488281	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	86.3023071289063	86.3023071289063	86.3023071289063	145.069305419922	252.946350097656	-23.944341023763	73.3637924194336	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	86.3023071289063	86.3023071289063	86.3023071289063	143.706329345703	250.537292480469	-23.7119862874349	72.697395324707	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	86.3023071289063	86.3023071289063	86.3023071289063	149.360794067383	249.817504882813	-22.458388264974	81.3795928955078	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	86.3023071289063	86.3023071289063	86.3023071289063	146.708068847656	245.177429199219	-22.013949584961	80.0728073120117	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	86.3023071289063	86.3023071289063	86.3023071289063	120.358810424805	209.438995361328	-19.7521845499674	61.2976112365723	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	86.3023071289063	86.3023071289063	86.3023071289063	123.611000061035	211.746673583984	-19.5309315999348	65.2646865844727	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	81.0410690307617	85.6360855102539	77.5695495605469	1604.08203125	1142.96606445313	103.196386718749	1916.93896484375	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	80.3490753173828	86.2887649536133	77.571044921875	1619.97265625	1107.89685058594	99.5510742187493	1854.52990722656	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	83.4110946655273	86.3023071289063	82.1053314208984	1570.19140625	1050.75061035156	100.084391276043	1801.38354492188	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	83.4187316894531	86.3023071289063	81.9840316772461	1563.44555664063	1044.80725097656	101.636930338543	1807.08422851563	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	84.2366485595703	86.3023071289063	82.1970443725586	1503.34313964844	1049.16821289063	102.855891927083	1820.58740234375	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	84.0532608032227	86.3023071289063	81.8307495117188	1486.92822265625	1039.14050292969	101.328336588541	1799.10302734375	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	85.8224716186523	86.3023071289063	85.3463287353516	1467.09973144531	1022.56115722656	101.904361979167	1786.84387207031	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	85.7262802124023	86.3023071289063	85.151123046875	1437.32983398438	1002.77099609375	99.5659179687507	1749.51538085938	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	85.7577438354492	86.3023071289063	85.2322769165039	1392.97082519531	980.261962890625	93.5473795572913	1681.86730957031	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	85.690185546875	86.3023071289063	85.1088714599609	1312.55651855469	904.353942871094	92.5003824869795	1598.10681152344	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	57.0383033752441	82.8631286621094	37.576473236084	2360.2099609375	1559.53015136719	180.695589192708	2914.7470703125	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	53.2996788024902	84.1167602539063	39.0434188842773	2424.87084960938	1530.23889160156	174.134781901043	2836.24975585938	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	54.9219627380371	86.0624313354492	40.9694633483887	2326.73681640625	1484.72399902344	162.283740234375	2701.85205078125	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	63.0975875854492	86.2636795043945	52.1086196899414	2349.70263671875	1497.85900878906	167.163850911459	2751.587890625	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	67.2728652954102	86.2615661621094	53.3119964599609	2252.91479492188	1513.01220703125	169.242610677084	2782.33178710938	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	60.6070327758789	86.2568359375	41.3866195678711	2207.15234375	1498.49255371094	162.463850911459	2716.97143554688	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	70.7119293212891	86.297981262207	58.3583145141602	2196.13720703125	1468.3603515625	166.202636718751	2714.88012695313	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	70.1137924194336	86.2968597412109	57.4290809631348	2148.95166015625	1439.81750488281	162.091129557292	2655.5009765625	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	70.3960800170898	86.295768737793	58.1963043212891	2061.39599609375	1409.35375976563	148.716438802083	2524.72705078125	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	70.877067565918	86.2972564697266	59.3024787902832	1951.78112792969	1305.21008300781	145.285628255209	2394.85229492188	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	49.6296424865723	76.9187774658203	36.3950004577637	3208.10009765625	1974.28063964844	278.924267578125	4066.21264648438	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	42.3917198181152	76.1445159912109	27.1764392852783	3319.73486328125	1926.82153320313	271.059993489583	3959.771484375	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	52.4938468933105	80.8466491699219	40.06396484375	3195.3876953125	1891.67224121094	251.340738932292	3776.72778320313	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	45.2819938659668	79.7373886108398	29.4513816833496	3212.30053710938	1916.9912109375	254.343391927084	3824.56665039063	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	48.9722366333008	79.7759552001953	31.526330947876	3053.83032226563	1944.54235839844	254.532307942708	3853.53466796875	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	49.079044342041	79.0728988647461	32.5719108581543	3010.00805664063	1918.81372070313	250.699674479167	3799.06127929688	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	53.9205741882324	83.6756134033203	33.6724395751953	2967.8525390625	1930.3515625	238.533235677084	3719.35083007813	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	53.5418014526367	83.3268585205078	33.6966896057129	2901.40966796875	1888.86743164063	232.858430989583	3635.3056640625	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	52.7801284790039	83.0212936401367	33.1753616333008	2824.7333984375	1818.03833007813	233.409244791667	3568.60766601563	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Freeflow	100	Freeflow	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	54.154426574707	83.4817657470703	34.103687286377	2635.373046875	1719.07727050781	208.520166015625	3282.978515625	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	53.8909950256348	54.555980682373	53.3678131103516	1007.21124267578	730.356628417969	61.6802815755215	1192.95874023438	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	53.7955131530762	54.5565452575684	53.4443550109863	1019.94207763672	708.306335449219	60.5923095703121	1162.74865722656	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	54.0699157714844	54.7025032043457	53.7883186340332	985.801086425781	683.172790527344	58.3169677734381	1120.55004882813	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	54.0576438903809	54.6991996765137	53.7558898925781	985.113708496094	687.171875	58.4006347656253	1125.17663574219	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	53.9677124023438	54.5557670593262	53.5195999145508	936.086608886719	677.641479492188	58.6150390625003	1117.25427246094	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	54.0059280395508	54.5578842163086	53.60986328125	926.651611328125	671.13720703125	57.857307942708	1105.06701660156	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	54.2676239013672	54.703800201416	53.9292831420898	916.471435546875	665.722412109375	57.1258300781247	1094.16613769531	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	54.2676811218262	54.7000999450684	53.908203125	897.892639160156	652.930541992188	55.8096842447923	1071.50317382813	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	54.2177085876465	54.6347694396973	53.8561820983887	861.239501953125	623.558166503906	53.7177164713539	1026.44104003906	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	54.1151542663574	54.6361808776855	53.6803970336914	812.296813964844	585.394958496094	51.1141031901041	968.750732421875	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	54.7075653076172	54.7146186828613	54.7009620666504	143.835556030273	118.37410736084	5.83376159667974	162.127319335938	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	54.7053565979004	54.7146186828613	54.7009468078613	143.346817016602	113.988204956055	5.71990559895827	156.887496948242	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	54.6069526672363	54.7146186828613	54.5582580566406	139.592697143555	112.309608459473	5.262075805664	151.775177001953	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	54.6106185913086	54.7146186828613	54.5587196350098	138.009490966797	111.380897521973	5.23713277180987	150.659393310547	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	54.637393951416	54.7146186828613	54.5596809387207	115.842880249023	91.5328521728516	5.64131469726565	133.842712402344	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	54.6362991333008	54.7146186828613	54.5575714111328	114.818161010742	90.7089996337891	5.59298909505212	132.65641784668	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	54.6761627197266	54.7146186828613	54.6367340087891	123.698921203613	100.713249206543	5.33555399576827	140.729904174805	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	54.6759757995605	54.7146186828613	54.6363525390625	121.615692138672	99.0890731811523	5.22862854003903	138.303787231445	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	54.6789665222168	54.7146186828613	54.6435508728027	115.761711120605	92.441764831543	5.34658711751307	132.541168212891	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	54.6788139343262	54.7146186828613	54.6439971923828	109.987968444824	88.7184524536133	4.86303812662756	125.19123840332	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	54.6657066345215	54.7146186828613	54.643913269043	265.427062988281	218.625381469727	10.8010925292968	299.633575439453	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	54.6528511047363	54.7146186828613	54.6253395080566	264.186737060547	206.23649597168	11.2924011230468	290.929504394531	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	54.6282196044922	54.7146186828613	54.5892486572266	256.578125	200.082870483398	10.895985921224	281.802764892578	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	54.6284828186035	54.7146186828613	54.5859222412109	253.415054321289	196.282440185547	11.2230305989583	280.455169677734	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	54.6519508361816	54.7146186828613	54.5928077697754	227.605285644531	176.419692993164	11.7956441243489	264.887023925781	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	54.6467781066895	54.7146186828613	54.5836982727051	225.78825378418	174.779541015625	11.7584228515625	262.967712402344	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	54.6216430664063	54.7146186828613	54.5551605224609	232.222671508789	182.712417602539	11.4880310058593	268.872650146484	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	54.620189666748	54.7146186828613	54.552661895752	227.803482055664	179.471923828125	11.2085815429688	263.536285400391	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	54.6195602416992	54.7146186828613	54.5527000427246	215.588195800781	167.679504394531	10.9675862630209	249.936401367188	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	54.6518363952637	54.7146186828613	54.6167411804199	205.539291381836	160.549194335938	10.2966796875	237.774291992188	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	54.4533615112305	54.7141304016113	54.2568168640137	516.826843261719	427.995666503906	19.9477376302084	577.603698730469	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	54.3811569213867	54.7142791748047	54.2282562255859	511.471588134766	408.958190917969	19.9401123046875	558.509033203125	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	54.4797859191895	54.7137260437012	54.3763656616211	494.859222412109	394.351959228516	19.3720255533853	539.642150878906	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	54.4938278198242	54.7144432067871	54.3933334350586	492.569732666016	390.863525390625	19.9566813151041	540.538635253906	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	54.4103012084961	54.6395378112793	54.2403984069824	463.989868164063	374.284423828125	20.4569986979167	527.7119140625	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	54.4992370605469	54.6393241882324	54.3576965332031	459.440124511719	370.765869140625	20.2134033203125	522.366394042969	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	54.4703712463379	54.7143440246582	54.3101692199707	461.719177246094	371.977478027344	20.4914632161459	525.663452148438	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	54.3804512023926	54.7138824462891	54.1550254821777	453.042419433594	365.007965087891	20.1343872070312	516.015869140625	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	54.4183731079102	54.7146186828613	54.2338638305664	427.213134765625	339.735137939453	19.7930338541667	488.182891845703	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	54.4472427368164	54.7146186828613	54.2412338256836	405.895965576172	324.238525390625	18.4393269856771	462.533477783203	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	52.0134429931641	54.3928604125977	50.2121658325195	1662.43225097656	1102.43029785156	124.869059244792	2038.9482421875	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	51.7870101928711	54.5630683898926	50.5050582885742	1699.46423339844	1074.18566894531	121.542708333333	1985.75598144531	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	52.4693984985352	54.6430778503418	51.4969863891602	1644.56958007813	1034.85168457031	117.479150390625	1915.9453125	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	52.4743881225586	54.5064888000488	51.4957504272461	1647.01489257813	1047.11083984375	117.536669921875	1928.63586425781	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	52.7181816101074	54.5551147460938	51.2908172607422	1568.76916503906	1046.89416503906	117.931738281251	1931.38220214844	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	52.6903877258301	54.5407943725586	51.274356842041	1552.06262207031	1037.54711914063	116.378206380208	1910.38366699219	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	53.2696990966797	54.5422325134277	52.2261047363281	1536.79724121094	1027.63793945313	115.969108072916	1897.40625	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	53.1496200561523	54.3933181762695	52.1728401184082	1504.71691894531	1007.86090087891	113.179923502604	1856.71032714844	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	53.1064949035645	54.3941192626953	52.0868225097656	1448.69995117188	976.553955078125	106.583154296875	1775.92761230469	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	53.2693634033203	54.5541954040527	52.289005279541	1365.95715332031	905.734802246094	103.591121419271	1682.66821289063	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	44.498046875	53.6314926147461	37.3301200866699	2385.5087890625	1496.93627929688	198.446712239583	2985.28662109375	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	43.1549224853516	53.7521553039551	38.231143951416	2453.40380859375	1469.92541503906	191.237972005209	2904.21020507813	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	44.6345901489258	54.1498184204102	40.3673324584961	2366.09936523438	1423.19848632813	181.688736979167	2785.86401367188	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	46.8427810668945	54.3197631835938	43.276741027832	2383.42846679688	1448.52966308594	183.266389973959	2823.02758789063	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	47.4227256774902	54.1146583557129	42.1639633178711	2262.8974609375	1461.04016113281	181.566748046876	2822.79077148438	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	45.9051475524902	54.0709075927734	39.6204833984375	2233.87158203125	1446.50036621094	178.945524088541	2788.591796875	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	49.2805480957031	54.4453887939453	45.2267456054688	2216.9326171875	1430.55285644531	179.065250651043	2773.54223632813	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	49.1215133666992	54.4131278991699	44.9366874694824	2169.51513671875	1402.57824707031	174.531754557292	2711.56640625	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	48.9887580871582	54.3502235412598	44.8937644958496	2078.21044921875	1363.47131347656	161.790055338543	2576.89672851563	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	49.5844039916992	54.4718322753906	45.9584541320801	1967.21813964844	1263.12670898438	158.392447916667	2451.07006835938	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	39.6406707763672	52.233642578125	32.1664886474609	3202.30151367188	1913.0498046875	289.161621093751	4081.76196289063	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	35.6763763427734	52.0845413208008	28.0838451385498	3313.26879882813	1879.42175292969	278.827164713541	3970.62548828125	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	41.4296417236328	53.1280517578125	36.2667427062988	3188.69067382813	1823.17138671875	263.168782552084	3796.93725585938	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	37.2175712585449	52.6748313903809	29.8619689941406	3206.20239257813	1862.16735839844	263.575504557292	3838.98364257813	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	39.6173858642578	52.6877708435059	31.0301666259766	3047.32373046875	1883.70642089844	265.107112630208	3872.009765625	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	39.978515625	52.381706237793	32.1668357849121	3006.33422851563	1861.15966796875	260.900260416667	3817.91162109375	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	42.0774230957031	53.3493118286133	33.5230941772461	2953.95874023438	1853.26196289063	251.117903645833	3736.64624023438	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	41.6128196716309	53.2842178344727	33.2143020629883	2888.30053710938	1814.21826171875	245.722233072917	3657.13500976563	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	41.5073585510254	53.3178787231445	32.9776992797852	2806.60205078125	1754.78979492188	240.704524739583	3560.07373046875	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/Satur.	100	Saturated	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	42.126392364502	53.3655624389648	33.7624664306641	2619.22412109375	1637.41015625	221.89296875	3301.607421875	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.4867362976074	17.7624950408936	17.3007698059082	1902.76098632813	1376.88024902344	116.346728515625	2249.48071289063	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	17.3779277801514	17.7537422180176	17.2063827514648	1923.9794921875	1343.32104492188	112.787923177083	2189.23046875	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	17.3213062286377	17.7730197906494	17.1200542449951	1888.56201171875	1304.17590332031	112.580126953125	2148.52685546875	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	17.4071407318115	17.7228393554688	17.2600803375244	1875.55480957031	1308.50048828125	111.025520833333	2141.19189453125	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	17.4668636322021	17.6959838867188	17.2766284942627	1746.90661621094	1246.75427246094	112.636344401041	2091.52685546875	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	17.4166641235352	17.6767024993896	17.2519149780273	1720.48425292969	1233.70788574219	109.317561848959	2053.58959960938	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	17.4895687103271	17.7419605255127	17.3044338226318	1702.79125976563	1218.01586914063	109.801090494791	2041.52404785156	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	17.5595607757568	17.7383880615234	17.4411869049072	1672.21875	1193.62561035156	108.559505208333	2007.82189941406	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	17.5024585723877	17.7087154388428	17.3328456878662	1578.84997558594	1117.20239257813	104.247444661457	1899.05822753906	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	17.5602741241455	17.6899681091309	17.4364566802979	1493.71801757813	1053.47729492188	98.9620442708333	1795.69262695313	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.6941661834717	17.7490291595459	17.6436576843262	876.36962890625	724.957763671875	34.220703125	981.613037109375	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	17.6887874603271	17.7892417907715	17.6419658660889	875.0888671875	704.371826171875	33.2209716796875	953.529113769531	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	17.7102947235107	17.7894382476807	17.6748924255371	848.34423828125	681.33642578125	32.1905598958333	922.765625	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	17.7321529388428	17.7894382476807	17.7045955657959	838.343139648438	677.388549804688	31.5858072916667	914.282104492188	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	17.7428531646729	17.7894382476807	17.7050437927246	737.427917480469	592.21728515625	33.27060546875	841.746826171875	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	17.7406806945801	17.7894382476807	17.7011566162109	731.426574707031	586.565246582031	33.2549723307292	835.9775390625	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	17.7566928863525	17.7894382476807	17.7324314117432	719.086181640625	581.14111328125	31.7173095703125	819.020935058594	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	17.7565727233887	17.7894382476807	17.7321910858154	702.792358398438	567.758361816406	31.0368082682292	800.534423828125	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	17.7570838928223	17.7894382476807	17.7337017059326	651.068542480469	517.3212890625	30.3832356770833	745.195556640625	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	17.7572994232178	17.7894382476807	17.7343139648438	622.152221679688	497.981353759766	28.1582316080729	709.168090820313	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.7113399505615	17.7618103027344	17.672513961792	1132.22521972656	893.525329589844	52.9442626953128	1290.60729980469	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	17.6866054534912	17.7612228393555	17.6525192260742	1134.06616210938	868.280090332031	51.6430257161465	1255.60278320313	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	17.7243785858154	17.7645072937012	17.7076644897461	1106.3193359375	842.860717773438	50.7528808593749	1223.50732421875	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	17.7045345306396	17.749303817749	17.6862163543701	1107.20556640625	838.911865234375	52.549430338542	1233.03259277344	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	17.65966796875	17.7494888305664	17.601037979126	993.325134277344	753.509216308594	54.0171630859381	1158.63793945313	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	17.7044219970703	17.764720916748	17.6865673065186	981.12158203125	745.920532226563	52.9706054687503	1143.20007324219	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	17.756628036499	17.7892723083496	17.7489395141602	971.288696289063	737.884460449219	52.9876057942708	1135.29150390625	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	17.7339324951172	17.7495460510254	17.7300071716309	952.309265136719	722.26123046875	52.2349283854173	1114.02319335938	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	17.7561531066895	17.7892684936523	17.7478275299072	888.262634277344	666.609191894531	49.9055094401039	1040.90051269531	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	17.7634468078613	17.7893257141113	17.7574481964111	843.520141601563	634.588500976563	46.8986409505208	986.328308105469	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.5150356292725	17.6896667480469	17.3741703033447	1471.07592773438	1115.9599609375	78.7993815104173	1706.95532226563	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	17.4932918548584	17.6903076171875	17.4041614532471	1481.72180175781	1084.16442871094	77.2406901041667	1663.46960449219	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	17.5684089660645	17.705114364624	17.5068531036377	1447.90942382813	1053.14196777344	76.0658854166667	1623.63610839844	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	17.6059474945068	17.7601299285889	17.5324192047119	1439.16625976563	1048.20886230469	76.5658854166667	1622.45300292969	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	17.5565376281738	17.7613315582275	17.3978881835938	1314.43640136719	974.884399414063	76.3828450520836	1547.75573730469	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	17.654972076416	17.7337989807129	17.5873508453369	1293.90209960938	964.651550292969	74.0382080078121	1519.93811035156	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	17.617467880249	17.764347076416	17.4682388305664	1287.03894042969	953.224914550781	75.8332763671879	1521.97448730469	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	17.6558170318604	17.7642402648926	17.5562419891357	1258.22570800781	931.557373046875	74.1232747395833	1487.48193359375	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	17.6078662872314	17.7641296386719	17.4541282653809	1185.89379882813	869.423950195313	71.3789062499996	1404.76574707031	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	17.6690082550049	17.7645053863525	17.5720748901367	1126.23840332031	824.145202636719	67.9903564453121	1334.07287597656	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.1572074890137	17.6983203887939	16.8123264312744	2420.947265625	1688.4951171875	162.194661458333	2904.955078125	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	16.9706802368164	17.6056060791016	16.6818332672119	2445.58325195313	1654.41955566406	153.696923828125	2807.146484375	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	17.1091747283936	17.6788635253906	16.8551959991455	2396.66723632813	1605.42297363281	152.434228515625	2748.6796875	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	17.2006587982178	17.7204360961914	16.9578685760498	2374.01196289063	1614.89343261719	148.613069661459	2729.49145507813	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	17.1998691558838	17.6443958282471	16.8429584503174	2233.654296875	1561.85437011719	151.194319661459	2695.81176757813	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	17.1986045837402	17.7081451416016	16.8123550415039	2207.9814453125	1544.28198242188	149.781770833333	2667.64526367188	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	17.196662902832	17.7155685424805	16.778491973877	2191.30126953125	1523.58557128906	150.903922526043	2655.36499023438	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	17.2955150604248	17.7009792327881	17.0078315734863	2144.15380859375	1493.29382324219	146.928564453125	2595.25805664063	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	17.3515300750732	17.752498626709	17.0875720977783	2032.056640625	1407.68786621094	140.073811848959	2458.24145507813	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	17.3034362792969	17.7669715881348	16.9726047515869	1924.51623535156	1322.31713867188	134.563216145833	2331.54125976563	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	16.6030712127686	17.6164627075195	15.9182071685791	3031.44775390625	2026.7451171875	223.1341796875	3700.25146484375	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	16.3637504577637	17.5295791625977	15.8245935440063	3096.03955078125	1987.06396484375	215.547949218751	3603.67358398438	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	16.6035633087158	17.6526622772217	16.13498878479	3007.71655273438	1931.27185058594	207.403108723959	3486.79516601563	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	16.6182594299316	17.5561485290527	16.1705684661865	2998.63061523438	1954.990234375	204.466764322917	3488.49096679688	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	16.6955451965332	17.4392242431641	16.133768081665	2814.24096679688	1914.50744628906	203.041389973959	3437.31787109375	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	16.5436496734619	17.5714225769043	15.8173036575317	2774.39794921875	1888.38220214844	199.234358723959	3382.63989257813	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	16.8575916290283	17.6361465454102	16.3655643463135	2758.31713867188	1867.62377929688	201.893261718749	3381.8232421875	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	16.9184169769287	17.597375869751	16.4253902435303	2698.1884765625	1828.11511230469	197.184944661459	3307.00219726563	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	16.9525718688965	17.6611061096191	16.4381942749023	2564.42944335938	1732.91381835938	187.471809895833	3138.95239257813	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	16.9854869842529	17.6023292541504	16.5584411621094	2430.05444335938	1629.05297851563	179.878059895833	2978.13842773438	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	15.5422945022583	17.2451362609863	14.3874683380127	3703.61987304688	2408.81469726563	288.701269531249	4574.07421875	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	15.0998964309692	17.2125091552734	14.1160097122192	3786.80834960938	2363.63110351563	276.583951822916	4438.0107421875	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	15.8649091720581	17.2928371429443	15.2292528152466	3678.38452148438	2290.24096679688	267.461100260416	4296.19921875	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	15.7933893203735	17.4720020294189	15.0019645690918	3669.6455078125	2311.06079101563	266.127636718749	4307.01806640625	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	15.9010934829712	17.3662662506104	14.8337879180908	3466.39428710938	2287.73901367188	266.950878906249	4289.87060546875	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	15.8359909057617	17.348518371582	14.7676277160645	3427.17626953125	2259.89282226563	263.856152343749	4238.81396484375	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	16.1472759246826	17.29123878479	15.2625637054443	3376.169921875	2237.13671875	257.982291666667	4172.00390625	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	16.1825351715088	17.4509086608887	15.2461032867432	3301.146484375	2190.9140625	251.794010416667	4079.369140625	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	16.1638946533203	17.3738918304443	15.2627077102661	3162.43798828125	2085.89135742188	242.785807291667	3906.78491210938	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/100/St+Go	100	Stopgo	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	16.297046661377	17.5050067901611	15.3854713439941	2978.69897460938	1953.75061035156	229.761149088543	3676.95922851563	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	85.5511474609375	86.3023071289063	84.8638458251953	812.357360839844	702.329345703125	24.4012288411459	885.338562011719	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	86.1458969116211	86.3023071289063	86.0713958740234	801.143859863281	670.324768066406	25.4401611328125	861.1259765625	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	86.2971267700195	86.3023071289063	86.2947845458984	765.272644042969	640.416870117188	24.0620768229167	820.882446289063	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	86.2975463867188	86.3023071289063	86.295166015625	752.750061035156	629.300720214844	24.20322265625	810.824890136719	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	86.2991256713867	86.3023071289063	86.2959213256836	732.97998046875	625.884643554688	24.3264892578124	808.333312988281	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	86.2975463867188	86.3023071289063	86.2927627563477	725.9404296875	619.903625488281	24.0847005208333	800.538879394531	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	86.3018264770508	86.3023071289063	86.3013305664063	710.749572753906	609.474182128906	23.15771484375	783.157043457031	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	86.3016967773438	86.3023071289063	86.3010635375977	697.064208984375	597.776062011719	22.7031819661459	768.049926757813	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	86.3017654418945	86.3023071289063	86.3012313842773	665.185363769531	565.472534179688	22.6232503255208	735.146911621094	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	86.3016738891602	86.3023071289063	86.3010635375977	626.772827148438	534.185729980469	20.9400065104167	691.235778808594	86.2844772338867	86.3022918701172	86.2273559570313	754.012451171875	616.725769042969	893.201110839844\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	86.3023071289063	86.3023071289063	86.3023071289063	19.7120056152344	20.2793292999268	-0.111712900797534	19.4414825439453	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	86.3023071289063	86.3023071289063	86.3023071289063	18.8174686431885	19.1961555480957	-0.0727485656738267	18.650541305542	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	86.3023071289063	86.3023071289063	86.3023071289063	20.2711277008057	20.6264171600342	-0.0681381225585999	20.1153812408447	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	86.3023071289063	86.3023071289063	86.3023071289063	20.703950881958	20.8956623077393	-0.0368848164876402	20.619026184082	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	86.3023071289063	86.3023071289063	86.3023071289063	5.6321120262146	5.82988452911377	-0.0390378952026374	5.53710031509399	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	86.3023071289063	86.3023071289063	86.3023071289063	5.61809015274048	5.81396627426147	-0.0386636098225907	5.52398920059204	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	86.3023071289063	86.3023071289063	86.3023071289063	16.0347175598145	16.1977005004883	-0.0321940104166664	15.9562454223633	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	86.3023071289063	86.3023071289063	86.3023071289063	16.0186653137207	16.1784400939941	-0.0315601348876937	15.9417390823364	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	86.3023071289063	86.3023071289063	86.3023071289063	13.6784086227417	13.733344078064	-0.0108553568522133	13.6519289016724	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	86.3023071289063	86.3023071289063	86.3023071289063	13.9417591094971	14.0337114334106	-0.01817423502604	13.8974046707153	86.3022994995117	86.3023071289063	86.3023071289063	14.783447265625	16.4226818084717	13.7147417068481\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	86.3023071289063	86.3023071289063	86.3023071289063	37.7075080871582	72.3110046386719	-6.89385732014975	20.6070747375488	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	86.3023071289063	86.3023071289063	86.3023071289063	30.2743320465088	54.3554420471191	-4.63275782267252	19.6097583770752	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	86.3023071289063	86.3023071289063	86.3023071289063	30.6322231292725	52.4277076721191	-4.1824717203776	21.0591697692871	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	86.3023071289063	86.3023071289063	86.3023071289063	26.8226413726807	38.901668548584	-2.33224156697592	21.4098567962646	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	86.3023071289063	86.3023071289063	86.3023071289063	12.3756847381592	23.8012027740479	-2.31686763763428	6.42469549179077	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	86.3023071289063	86.3023071289063	86.3023071289063	12.2965841293335	23.6119518280029	-2.29452838897705	6.40298891067505	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	86.3023071289063	86.3023071289063	86.3023071289063	21.7962303161621	31.2085876464844	-1.91966679890951	16.8110866546631	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	86.3023071289063	86.3023071289063	86.3023071289063	21.666166305542	30.892297744751	-1.88168436686199	16.7796649932861	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	86.3023071289063	86.3023071289063	86.3023071289063	17.1249866485596	22.451566696167	-1.09321924845377	14.2524223327637	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	7.5	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	86.3023071289063	86.3023071289063	86.3023071289063	18.020809173584	24.5471248626709	-1.33305943806967	14.5491790771484	86.3022994995117	86.3023071289063	86.3023071289063	20.2357597351074	26.2560958862305	16.847770690918\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	86.3023071289063	86.3023071289063	86.3023071289063	198.37971496582	338.653656005859	-7.21614813804625	107.736915588379	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	86.3023071289063	86.3023071289063	86.3023071289063	160.32878112793	305.378936767578	-6.59779453277588	94.24951171875	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	86.3023071289063	86.3023071289063	86.3023071289063	153.356872558594	287.200469970703	-6.04027891159057	93.9115447998047	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	86.3023071289063	86.3023071289063	86.3023071289063	140.953765869141	262.118957519531	-5.54752445220947	84.5981750488281	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	86.3023071289063	86.3023071289063	86.3023071289063	145.069305419922	252.946350097656	-5.61195492744445	73.3637924194336	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	86.3023071289063	86.3023071289063	86.3023071289063	143.706329345703	250.537292480469	-5.55749678611756	72.697395324707	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	86.3023071289063	86.3023071289063	86.3023071289063	149.360794067383	249.817504882813	-5.26368474960329	81.3795928955078	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	86.3023071289063	86.3023071289063	86.3023071289063	146.708068847656	245.177429199219	-5.15951943397523	80.0728073120117	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	86.3023071289063	86.3023071289063	86.3023071289063	120.358810424805	209.438995361328	-4.62941825389862	61.2976112365723	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	86.3023071289063	86.3023071289063	86.3023071289063	123.611000061035	211.746673583984	-4.57756209373473	65.2646865844727	86.3022994995117	86.3023071289063	86.3023071289063	97.3948211669922	190.89533996582	58.8404121398926\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	81.0410690307617	85.6360855102539	77.5695495605469	1604.08203125	1142.96606445313	24.1866531372069	1916.93896484375	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	80.3490753173828	86.2887649536133	77.571044921875	1619.97265625	1107.89685058594	23.3322830200194	1854.52990722656	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	83.4110946655273	86.3023071289063	82.1053314208984	1570.19140625	1050.75061035156	23.4572792053225	1801.38354492188	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	83.4187316894531	86.3023071289063	81.9840316772461	1563.44555664063	1044.80725097656	23.8211555480959	1807.08422851563	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	84.2366485595703	86.3023071289063	82.1970443725586	1503.34313964844	1049.16821289063	24.10684967041	1820.58740234375	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	84.0532608032227	86.3023071289063	81.8307495117188	1486.92822265625	1039.14050292969	23.7488288879394	1799.10302734375	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	85.8224716186523	86.3023071289063	85.3463287353516	1467.09973144531	1022.56115722656	23.8838348388672	1786.84387207031	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	85.7262802124023	86.3023071289063	85.151123046875	1437.32983398438	1002.77099609375	23.3357620239259	1749.51538085938	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	85.7577438354492	86.3023071289063	85.2322769165039	1392.97082519531	980.261962890625	21.9251670837402	1681.86730957031	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	85.690185546875	86.3023071289063	85.1088714599609	1312.55651855469	904.353942871094	21.6797771453858	1598.10681152344	82.8148193359375	86.2987747192383	72.5853576660156	1705.03833007813	1105.53393554688	2272.90405273438\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	57.0383033752441	82.8631286621094	37.576473236084	2360.2099609375	1559.53015136719	42.3505287170409	2914.7470703125	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	53.2996788024902	84.1167602539063	39.0434188842773	2424.87084960938	1530.23889160156	40.8128395080569	2836.24975585938	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	54.9219627380371	86.0624313354492	40.9694633483887	2326.73681640625	1484.72399902344	38.0352516174316	2701.85205078125	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	63.0975875854492	86.2636795043945	52.1086196899414	2349.70263671875	1497.85900878906	39.1790275573731	2751.587890625	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	67.2728652954102	86.2615661621094	53.3119964599609	2252.91479492188	1513.01220703125	39.6662368774416	2782.33178710938	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	60.6070327758789	86.2568359375	41.3866195678711	2207.15234375	1498.49255371094	38.0774650573731	2716.97143554688	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	70.7119293212891	86.297981262207	58.3583145141602	2196.13720703125	1468.3603515625	38.9537429809572	2714.88012695313	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	70.1137924194336	86.2968597412109	57.4290809631348	2148.95166015625	1439.81750488281	37.9901084899903	2655.5009765625	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	70.3960800170898	86.295768737793	58.1963043212891	2061.39599609375	1409.35375976563	34.8554153442381	2524.72705078125	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	70.877067565918	86.2972564697266	59.3024787902832	1951.78112792969	1305.21008300781	34.0513191223147	2394.85229492188	63.6756477355957	86.1549072265625	48.4950523376465	2632.1806640625	1619.9208984375	3646.68969726563\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	49.6296424865723	76.9187774658203	36.3950004577637	3208.10009765625	1974.28063964844	65.3728752136231	4066.21264648438	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	42.3917198181152	76.1445159912109	27.1764392852783	3319.73486328125	1926.82153320313	63.5296859741209	3959.771484375	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	52.4938468933105	80.8466491699219	40.06396484375	3195.3876953125	1891.67224121094	58.9079856872559	3776.72778320313	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	45.2819938659668	79.7373886108398	29.4513816833496	3212.30053710938	1916.9912109375	59.6117324829103	3824.56665039063	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	48.9722366333008	79.7759552001953	31.526330947876	3053.83032226563	1944.54235839844	59.6560096740722	3853.53466796875	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	49.079044342041	79.0728988647461	32.5719108581543	3010.00805664063	1918.81372070313	58.7577362060547	3799.06127929688	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	53.9205741882324	83.6756134033203	33.6724395751953	2967.8525390625	1930.3515625	55.9062271118166	3719.35083007813	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	53.5418014526367	83.3268585205078	33.6966896057129	2901.40966796875	1888.86743164063	54.5761947631834	3635.3056640625	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	52.7801284790039	83.0212936401367	33.1753616333008	2824.7333984375	1818.03833007813	54.7052917480469	3568.60766601563	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Freeflow	110	Freeflow	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	54.154426574707	83.4817657470703	34.103687286377	2635.373046875	1719.07727050781	48.8719139099122	3282.978515625	55.1072731018066	81.9352798461914	33.2388076782227	3626.85546875	2164.50170898438	5207.3955078125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	53.8909950256348	54.555980682373	53.3678131103516	1007.21124267578	730.356628417969	14.4563159942628	1192.95874023438	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	53.7955131530762	54.5565452575684	53.4443550109863	1019.94207763672	708.306335449219	14.2013225555419	1162.74865722656	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	54.0699157714844	54.7025032043457	53.7883186340332	985.801086425781	683.172790527344	13.6680393218996	1120.55004882813	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	54.0576438903809	54.6991996765137	53.7558898925781	985.113708496094	687.171875	13.6876487731934	1125.17663574219	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	53.9677124023438	54.5557670593262	53.5195999145508	936.086608886719	677.641479492188	13.7378997802735	1117.25427246094	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	54.0059280395508	54.5578842163086	53.60986328125	926.651611328125	671.13720703125	13.5603065490722	1105.06701660156	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	54.2676239013672	54.703800201416	53.9292831420898	916.471435546875	665.722412109375	13.3888664245605	1094.16613769531	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	54.2676811218262	54.7000999450684	53.908203125	897.892639160156	652.930541992188	13.0803947448732	1071.50317382813	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	54.2177085876465	54.6347694396973	53.8561820983887	861.239501953125	623.558166503906	12.5900897979736	1026.44104003906	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	54.1151542663574	54.6361808776855	53.6803970336914	812.296813964844	585.394958496094	11.9798679351807	968.750732421875	54.1554527282715	54.6474533081055	53.4975624084473	1066.03344726563	719.080749511719	1402.13623046875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	54.7075653076172	54.7146186828613	54.7009620666504	143.835556030273	118.37410736084	1.36728787422181	162.127319335938	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	54.7053565979004	54.7146186828613	54.7009468078613	143.346817016602	113.988204956055	1.34060287475584	156.887496948242	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	54.6069526672363	54.7146186828613	54.5582580566406	139.592697143555	112.309608459473	1.2332990169525	151.775177001953	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	54.6106185913086	54.7146186828613	54.5587196350098	138.009490966797	111.380897521973	1.22745299339294	150.659393310547	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	54.637393951416	54.7146186828613	54.5596809387207	115.842880249023	91.5328521728516	1.32218313217164	133.842712402344	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	54.6362991333008	54.7146186828613	54.5575714111328	114.818161010742	90.7089996337891	1.31085681915284	132.65641784668	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	54.6761627197266	54.7146186828613	54.6367340087891	123.698921203613	100.713249206543	1.25052046775819	140.729904174805	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	54.6759757995605	54.7146186828613	54.6363525390625	121.615692138672	99.0890731811523	1.22545981407165	138.303787231445	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	54.6789665222168	54.7146186828613	54.6435508728027	115.761711120605	92.441764831543	1.25310635566713	132.541168212891	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	54.6788139343262	54.7146186828613	54.6439971923828	109.987968444824	88.7184524536133	1.13977456092833	125.19123840332	54.7058296203613	54.7146186828613	54.6906585693359	141.654037475586	107.98908996582	178.617202758789\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	54.6657066345215	54.7146186828613	54.643913269043	265.427062988281	218.625381469727	2.53150606155394	299.633575439453	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	54.6528511047363	54.7146186828613	54.6253395080566	264.186737060547	206.23649597168	2.64665651321409	290.929504394531	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	54.6282196044922	54.7146186828613	54.5892486572266	256.578125	200.082870483398	2.55374670028687	281.802764892578	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	54.6284828186035	54.7146186828613	54.5859222412109	253.415054321289	196.282440185547	2.63039779663084	280.455169677734	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	54.6519508361816	54.7146186828613	54.5928077697754	227.605285644531	176.419692993164	2.76460409164428	264.887023925781	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	54.6467781066895	54.7146186828613	54.5836982727051	225.78825378418	174.779541015625	2.75588035583497	262.967712402344	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	54.6216430664063	54.7146186828613	54.5551605224609	232.222671508789	182.712417602539	2.69250726699828	268.872650146484	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	54.620189666748	54.7146186828613	54.552661895752	227.803482055664	179.471923828125	2.62701129913331	263.536285400391	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	54.6195602416992	54.7146186828613	54.5527000427246	215.588195800781	167.679504394531	2.57052803039553	249.936401367188	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	54.6518363952637	54.7146186828613	54.6167411804199	205.539291381836	160.549194335938	2.41328430175781	237.774291992188	54.6377334594727	54.7144317626953	54.5531349182129	268.141021728516	196.878860473633	344.279296875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	54.4533615112305	54.7141304016113	54.2568168640137	516.826843261719	427.995666503906	4.67525100708009	577.603698730469	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	54.3811569213867	54.7142791748047	54.2282562255859	511.471588134766	408.958190917969	4.67346382141113	558.509033203125	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	54.4797859191895	54.7137260437012	54.3763656616211	494.859222412109	394.351959228516	4.54031848907469	539.642150878906	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	54.4938278198242	54.7144432067871	54.3933334350586	492.569732666016	390.863525390625	4.67734718322753	540.538635253906	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	54.4103012084961	54.6395378112793	54.2403984069824	463.989868164063	374.284423828125	4.79460906982422	527.7119140625	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	54.4992370605469	54.6393241882324	54.3576965332031	459.440124511719	370.765869140625	4.73751640319825	522.366394042969	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	54.4703712463379	54.7143440246582	54.3101692199707	461.719177246094	371.977478027344	4.80268669128419	525.663452148438	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	54.3804512023926	54.7138824462891	54.1550254821777	453.042419433594	365.007965087891	4.71899700164794	516.015869140625	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	54.4183731079102	54.7146186828613	54.2338638305664	427.213134765625	339.735137939453	4.63899230957031	488.182891845703	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	54.4472427368164	54.7146186828613	54.2412338256836	405.895965576172	324.238525390625	4.32171726226806	462.533477783203	54.4919090270996	54.7137680053711	54.2557601928711	523.086059570313	385.549072265625	670.557495117188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	52.0134429931641	54.3928604125977	50.2121658325195	1662.43225097656	1102.43029785156	29.2661857604981	2038.9482421875	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	51.7870101928711	54.5630683898926	50.5050582885742	1699.46423339844	1074.18566894531	28.486572265625	1985.75598144531	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	52.4693984985352	54.6430778503418	51.4969863891602	1644.56958007813	1034.85168457031	27.5341758728028	1915.9453125	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	52.4743881225586	54.5064888000488	51.4957504272461	1647.01489257813	1047.11083984375	27.5476570129394	1928.63586425781	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	52.7181816101074	54.5551147460938	51.2908172607422	1568.76916503906	1046.89416503906	27.6402511596681	1931.38220214844	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	52.6903877258301	54.5407943725586	51.274356842041	1552.06262207031	1037.54711914063	27.2761421203612	1910.38366699219	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	53.2696990966797	54.5422325134277	52.2261047363281	1536.79724121094	1027.63793945313	27.1802597045897	1897.40625	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	53.1496200561523	54.3933181762695	52.1728401184082	1504.71691894531	1007.86090087891	26.5265445709228	1856.71032714844	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	53.1064949035645	54.3941192626953	52.0868225097656	1448.69995117188	976.553955078125	24.9804267883302	1775.92761230469	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	53.2693634033203	54.5541954040527	52.289005279541	1365.95715332031	905.734802246094	24.2791690826418	1682.66821289063	52.6692276000977	54.564037322998	49.5870666503906	1830.41809082031	1134.99475097656	2485.95361328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	44.498046875	53.6314926147461	37.3301200866699	2385.5087890625	1496.93627929688	46.5109481811522	2985.28662109375	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	43.1549224853516	53.7521553039551	38.231143951416	2453.40380859375	1469.92541503906	44.8213996887209	2904.21020507813	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	44.6345901489258	54.1498184204102	40.3673324584961	2366.09936523438	1423.19848632813	42.5832977294922	2785.86401367188	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	46.8427810668945	54.3197631835938	43.276741027832	2383.42846679688	1448.52966308594	42.9530601501466	2823.02758789063	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	47.4227256774902	54.1146583557129	42.1639633178711	2262.8974609375	1461.04016113281	42.5547065734866	2822.79077148438	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	45.9051475524902	54.0709075927734	39.6204833984375	2233.87158203125	1446.50036621094	41.9403572082519	2788.591796875	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	49.2805480957031	54.4453887939453	45.2267456054688	2216.9326171875	1430.55285644531	41.9684181213381	2773.54223632813	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	49.1215133666992	54.4131278991699	44.9366874694824	2169.51513671875	1402.57824707031	40.9058799743653	2711.56640625	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	48.9887580871582	54.3502235412598	44.8937644958496	2078.21044921875	1363.47131347656	37.9195442199709	2576.89672851563	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	49.5844039916992	54.4718322753906	45.9584541320801	1967.21813964844	1263.12670898438	37.1232299804687	2451.07006835938	47.8522758483887	54.2984848022461	41.2125701904297	2677.15307617188	1595.40612792969	3720.58276367188\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	39.6406707763672	52.233642578125	32.1664886474609	3202.30151367188	1913.0498046875	67.7722549438478	4081.76196289063	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	35.6763763427734	52.0845413208008	28.0838451385498	3313.26879882813	1879.42175292969	65.3501167297362	3970.62548828125	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	41.4296417236328	53.1280517578125	36.2667427062988	3188.69067382813	1823.17138671875	61.6801834106447	3796.93725585938	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	37.2175712585449	52.6748313903809	29.8619689941406	3206.20239257813	1862.16735839844	61.7755088806153	3838.98364257813	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	39.6173858642578	52.6877708435059	31.0301666259766	3047.32373046875	1883.70642089844	62.134479522705	3872.009765625	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	39.978515625	52.381706237793	32.1668357849121	3006.33422851563	1861.15966796875	61.1484985351563	3817.91162109375	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	42.0774230957031	53.3493118286133	33.5230941772461	2953.95874023438	1853.26196289063	58.8557586669922	3736.64624023438	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	41.6128196716309	53.2842178344727	33.2143020629883	2888.30053710938	1814.21826171875	57.591148376465	3657.13500976563	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	41.5073585510254	53.3178787231445	32.9776992797852	2806.60205078125	1754.78979492188	56.4151229858397	3560.07373046875	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/Satur.	110	Saturated	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	42.126392364502	53.3655624389648	33.7624664306641	2619.22412109375	1637.41015625	52.0061645507812	3301.607421875	42.7882881164551	53.3904190063477	31.09255027771	3618.22485351563	2084.61987304688	5209.84521484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.4867362976074	17.7624950408936	17.3007698059082	1902.76098632813	1376.88024902344	27.2687644958497	2249.48071289063	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	17.3779277801514	17.7537422180176	17.2063827514648	1923.9794921875	1343.32104492188	26.4346694946287	2189.23046875	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	17.3213062286377	17.7730197906494	17.1200542449951	1888.56201171875	1304.17590332031	26.3859672546387	2148.52685546875	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	17.4071407318115	17.7228393554688	17.2600803375244	1875.55480957031	1308.50048828125	26.0216064453125	2141.19189453125	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	17.4668636322021	17.6959838867188	17.2766284942627	1746.90661621094	1246.75427246094	26.3991432189941	2091.52685546875	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	17.4166641235352	17.6767024993896	17.2519149780273	1720.48425292969	1233.70788574219	25.6213035583497	2053.58959960938	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	17.4895687103271	17.7419605255127	17.3044338226318	1702.79125976563	1218.01586914063	25.7346305847166	2041.52404785156	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	17.5595607757568	17.7383880615234	17.4411869049072	1672.21875	1193.62561035156	25.4436340332031	2007.82189941406	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	17.5024585723877	17.7087154388428	17.3328456878662	1578.84997558594	1117.20239257813	24.4329948425291	1899.05822753906	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	17.5602741241455	17.6899681091309	17.4364566802979	1493.71801757813	1053.47729492188	23.1942291259766	1795.69262695313	17.4901561737061	17.7134704589844	17.2911109924316	1992.99499511719	1321.48327636719	2595.4716796875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.6941661834717	17.7490291595459	17.6436576843262	876.36962890625	724.957763671875	8.02047729492188	981.613037109375	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	17.6887874603271	17.7892417907715	17.6419658660889	875.0888671875	704.371826171875	7.78616523742675	953.529113769531	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	17.7102947235107	17.7894382476807	17.6748924255371	848.34423828125	681.33642578125	7.54466247558594	922.765625	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	17.7321529388428	17.7894382476807	17.7045955657959	838.343139648438	677.388549804688	7.40292358398438	914.282104492188	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	17.7428531646729	17.7894382476807	17.7050437927246	737.427917480469	592.21728515625	7.79779815673828	841.746826171875	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	17.7406806945801	17.7894382476807	17.7011566162109	731.426574707031	586.565246582031	7.79413414001466	835.9775390625	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	17.7566928863525	17.7894382476807	17.7324314117432	719.086181640625	581.14111328125	7.433744430542	819.020935058594	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	17.7565727233887	17.7894382476807	17.7321910858154	702.792358398438	567.758361816406	7.27425193786622	800.534423828125	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	17.7570838928223	17.7894382476807	17.7337017059326	651.068542480469	517.3212890625	7.12107086181641	745.195556640625	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	17.7572994232178	17.7894382476807	17.7343139648438	622.152221679688	497.981353759766	6.59958553314209	709.168090820313	17.7495861053467	17.7889041900635	17.6757755279541	814.602172851563	623.819702148438	1014.56787109375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.7113399505615	17.7618103027344	17.672513961792	1132.22521972656	893.525329589844	12.4088115692139	1290.60729980469	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	17.6866054534912	17.7612228393555	17.6525192260742	1134.06616210938	868.280090332031	12.1038341522218	1255.60278320313	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	17.7243785858154	17.7645072937012	17.7076644897461	1106.3193359375	842.860717773438	11.895206451416	1223.50732421875	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	17.7045345306396	17.749303817749	17.6862163543701	1107.20556640625	838.911865234375	12.3162727355958	1233.03259277344	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	17.65966796875	17.7494888305664	17.601037979126	993.325134277344	753.509216308594	12.6602725982668	1158.63793945313	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	17.7044219970703	17.764720916748	17.6865673065186	981.12158203125	745.920532226563	12.4149856567383	1143.20007324219	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	17.756628036499	17.7892723083496	17.7489395141602	971.288696289063	737.884460449219	12.4189701080322	1135.29150390625	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	17.7339324951172	17.7495460510254	17.7300071716309	952.309265136719	722.26123046875	12.2425613403322	1114.02319335938	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	17.7561531066895	17.7892684936523	17.7478275299072	888.262634277344	666.609191894531	11.6966037750243	1040.90051269531	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	17.7634468078613	17.7893257141113	17.7574481964111	843.520141601563	634.588500976563	10.9918689727783	986.328308105469	17.6868362426758	17.7879409790039	17.6278400421143	1119.20080566406	799.294982910156	1408.95983886719\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.5150356292725	17.6896667480469	17.3741703033447	1471.07592773438	1115.9599609375	18.4686050415041	1706.95532226563	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	17.4932918548584	17.6903076171875	17.4041614532471	1481.72180175781	1084.16442871094	18.1032867431641	1663.46960449219	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	17.5684089660645	17.705114364624	17.5068531036377	1447.90942382813	1053.14196777344	17.8279418945313	1623.63610839844	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	17.6059474945068	17.7601299285889	17.5324192047119	1439.16625976563	1048.20886230469	17.9451293945313	1622.45300292969	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	17.5565376281738	17.7613315582275	17.3978881835938	1314.43640136719	974.884399414063	17.9022293090821	1547.75573730469	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	17.654972076416	17.7337989807129	17.5873508453369	1293.90209960938	964.651550292969	17.352705001831	1519.93811035156	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	17.617467880249	17.764347076416	17.4682388305664	1287.03894042969	953.224914550781	17.7734241485597	1521.97448730469	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	17.6558170318604	17.7642402648926	17.5562419891357	1258.22570800781	931.557373046875	17.3726425170898	1487.48193359375	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	17.6078662872314	17.7641296386719	17.4541282653809	1185.89379882813	869.423950195313	16.7294311523437	1404.76574707031	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	17.6690082550049	17.7645053863525	17.5720748901367	1126.23840332031	824.145202636719	15.93523979187	1334.07287597656	17.6418933868408	17.7606391906738	17.4985523223877	1488.53259277344	1025.73828125	1920.53771972656\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.1572074890137	17.6983203887939	16.8123264312744	2420.947265625	1688.4951171875	38.0143737792969	2904.955078125	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	16.9706802368164	17.6056060791016	16.6818332672119	2445.58325195313	1654.41955566406	36.0227165222169	2807.146484375	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	17.1091747283936	17.6788635253906	16.8551959991455	2396.66723632813	1605.42297363281	35.7267723083497	2748.6796875	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	17.2006587982178	17.7204360961914	16.9578685760498	2374.01196289063	1614.89343261719	34.8311882019044	2729.49145507813	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	17.1998691558838	17.6443958282471	16.8429584503174	2233.654296875	1561.85437011719	35.4361686706544	2695.81176757813	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	17.1986045837402	17.7081451416016	16.8123550415039	2207.9814453125	1544.28198242188	35.1051025390625	2667.64526367188	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	17.196662902832	17.7155685424805	16.778491973877	2191.30126953125	1523.58557128906	35.3681068420412	2655.36499023438	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	17.2955150604248	17.7009792327881	17.0078315734863	2144.15380859375	1493.29382324219	34.4363822937012	2595.25805664063	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	17.3515300750732	17.752498626709	17.0875720977783	2032.056640625	1407.68786621094	32.8297996520997	2458.24145507813	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	17.3034362792969	17.7669715881348	16.9726047515869	1924.51623535156	1322.31713867188	31.5382537841797	2331.54125976563	17.2933177947998	17.7363452911377	16.8334693908691	2579.71655273438	1667.00598144531	3408.40771484375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	16.6030712127686	17.6164627075195	15.9182071685791	3031.44775390625	2026.7451171875	52.2970733642578	3700.25146484375	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	16.3637504577637	17.5295791625977	15.8245935440063	3096.03955078125	1987.06396484375	50.5190505981447	3603.67358398438	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	16.6035633087158	17.6526622772217	16.13498878479	3007.71655273438	1931.27185058594	48.6101036071778	3486.79516601563	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	16.6182594299316	17.5561485290527	16.1705684661865	2998.63061523438	1954.990234375	47.9218978881837	3488.49096679688	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	16.6955451965332	17.4392242431641	16.133768081665	2814.24096679688	1914.50744628906	47.5878257751466	3437.31787109375	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	16.5436496734619	17.5714225769043	15.8173036575317	2774.39794921875	1888.38220214844	46.6955528259278	3382.63989257813	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	16.8575916290283	17.6361465454102	16.3655643463135	2758.31713867188	1867.62377929688	47.3187332153319	3381.8232421875	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	16.9184169769287	17.597375869751	16.4253902435303	2698.1884765625	1828.11511230469	46.2152214050294	3307.00219726563	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	16.9525718688965	17.6611061096191	16.4381942749023	2564.42944335938	1732.91381835938	43.9387054443359	3138.95239257813	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	16.9854869842529	17.6023292541504	16.5584411621094	2430.05444335938	1629.05297851563	42.1589202880859	2978.13842773438	16.8518447875977	17.61106300354	15.9392080307007	3284.46508789063	2063.07861328125	4419.5888671875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	15.5422945022583	17.2451362609863	14.3874683380127	3703.61987304688	2408.81469726563	67.6643600463866	4574.07421875	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	15.0998964309692	17.2125091552734	14.1160097122192	3786.80834960938	2363.63110351563	64.8243637084959	4438.0107421875	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	15.8649091720581	17.2928371429443	15.2292528152466	3678.38452148438	2290.24096679688	62.686195373535	4296.19921875	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	15.7933893203735	17.4720020294189	15.0019645690918	3669.6455078125	2311.06079101563	62.3736648559569	4307.01806640625	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	15.9010934829712	17.3662662506104	14.8337879180908	3466.39428710938	2287.73901367188	62.5666122436522	4289.87060546875	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	15.8359909057617	17.348518371582	14.7676277160645	3427.17626953125	2259.89282226563	61.8412857055663	4238.81396484375	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	16.1472759246826	17.29123878479	15.2625637054443	3376.169921875	2237.13671875	60.464599609375	4172.00390625	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	16.1825351715088	17.4509086608887	15.2461032867432	3301.146484375	2190.9140625	59.0142211914062	4079.369140625	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	16.1638946533203	17.3738918304443	15.2627077102661	3162.43798828125	2085.89135742188	56.9029235839844	3906.78491210938	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/MW/110/St+Go	110	Stopgo	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	16.297046661377	17.5050067901611	15.3854713439941	2978.69897460938	1953.75061035156	53.8502693176272	3676.95922851563	16.1987590789795	17.512882232666	14.659462928772	4071.61059570313	2481.37451171875	5618.166015625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	80.956298828125	80.9953308105469	80.9238815307617	798.639099121094	663.283569335938	7.11065864562988	890.824645996094	80.9784240722656	80.9957046508789	80.9362716674805	758.126403808594	593.211059570313	926.729797363281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	80.971809387207	80.995735168457	80.9604949951172	783.841796875	631.789123535156	6.93187141418459	853.609008789063	80.9784240722656	80.9957046508789	80.9362716674805	758.126403808594	593.211059570313	926.729797363281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	80.9885025024414	80.995735168457	80.985237121582	748.117126464844	603.053039550781	6.55281448364259	812.743103027344	80.9784240722656	80.9957046508789	80.9362716674805	758.126403808594	593.211059570313	926.729797363281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	80.9885101318359	80.995735168457	80.9849395751953	738.187683105469	595.399536132813	6.56506156921385	805.481506347656	80.9784240722656	80.9957046508789	80.9362716674805	758.126403808594	593.211059570313	926.729797363281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	80.990608215332	80.995735168457	80.9856948852539	716.804565429688	593.506713867188	6.59458923339844	804.533569335938	80.9784240722656	80.9957046508789	80.9362716674805	758.126403808594	593.211059570313	926.729797363281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	80.9901962280273	80.995735168457	80.9849243164063	709.899475097656	587.844299316406	6.52812385559084	796.744262695313	80.9784240722656	80.9957046508789	80.9362716674805	758.126403808594	593.211059570313	926.729797363281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	80.992805480957	80.995735168457	80.9898910522461	697.097290039063	580.390502929688	6.27175903320313	781.086791992188	80.9784240722656	80.9957046508789	80.9362716674805	758.126403808594	593.211059570313	926.729797363281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	80.9926147460938	80.995735168457	80.9895248413086	683.67578125	569.263488769531	6.1483726501465	766.011413574219	80.9784240722656	80.9957046508789	80.9362716674805	758.126403808594	593.211059570313	926.729797363281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	80.9926681518555	80.995735168457	80.9897232055664	653.595336914063	537.852600097656	6.16328048706056	735.077575683594	80.9784240722656	80.9957046508789	80.9362716674805	758.126403808594	593.211059570313	926.729797363281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	80.9925384521484	80.995735168457	80.9895248413086	616.194396972656	508.738708496094	5.71008682250975	691.461486816406	80.9784240722656	80.9957046508789	80.9362716674805	758.126403808594	593.211059570313	926.729797363281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	80.995735168457	80.995735168457	80.995735168457	22.4100513458252	24.328369140625	-0.090259313583375	21.440071105957	80.9957275390625	80.995735168457	80.995735168457	14.8203620910645	16.2971878051758	13.9049587249756\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	80.995735168457	80.995735168457	80.995735168457	22.6130390167236	24.0495300292969	-0.0648274421691907	21.9750518798828	80.9957275390625	80.995735168457	80.995735168457	14.8203620910645	16.2971878051758	13.9049587249756\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	80.995735168457	80.995735168457	80.995735168457	24.8653507232666	26.2184753417969	-0.0608773827552812	24.2703990936279	80.9957275390625	80.995735168457	80.995735168457	14.8203620910645	16.2971878051758	13.9049587249756\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	80.995735168457	80.995735168457	80.995735168457	26.2465305328369	27.2193069458008	-0.0440346598625188	25.8101978302002	80.9957275390625	80.995735168457	80.995735168457	14.8203620910645	16.2971878051758	13.9049587249756\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	80.995735168457	80.995735168457	80.995735168457	6.5907621383667	7.59919261932373	-0.0480124950408934	6.06279277801514	80.9957275390625	80.995735168457	80.995735168457	14.8203620910645	16.2971878051758	13.9049587249756\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	80.995735168457	80.995735168457	80.995735168457	6.57291841506958	7.57160997390747	-0.0475488752126694	6.05004596710205	80.9957275390625	80.995735168457	80.995735168457	14.8203620910645	16.2971878051758	13.9049587249756\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	80.995735168457	80.995735168457	80.995735168457	17.1253051757813	18.0442905426025	-0.0437731146812438	16.6435508728027	80.9957275390625	80.995735168457	80.995735168457	14.8203620910645	16.2971878051758	13.9049587249756\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	80.995735168457	80.995735168457	80.995735168457	17.0879993438721	17.9888210296631	-0.0429080724716188	16.6157627105713	80.9957275390625	80.995735168457	80.995735168457	14.8203620910645	16.2971878051758	13.9049587249756\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	80.995735168457	80.995735168457	80.995735168457	14.5681285858154	15.1211242675781	-0.0263579189777375	14.2776708602905	80.9957275390625	80.995735168457	80.995735168457	14.8203620910645	16.2971878051758	13.9049587249756\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	80.995735168457	80.995735168457	80.995735168457	15.1725540161133	15.8334112167358	-0.0314393043518062	14.827353477478	80.9957275390625	80.995735168457	80.995735168457	14.8203620910645	16.2971878051758	13.9049587249756\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	80.995735168457	80.995735168457	80.995735168457	45.5505218505859	79.8779907226563	-1.62857514619828	27.7635860443115	80.9957275390625	80.995735168457	80.995735168457	24.7278728485107	34.7749938964844	19.8130378723145\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	80.995735168457	80.995735168457	80.995735168457	40.4467926025391	69.262321472168	-1.30108493566513	27.6276035308838	80.9957275390625	80.995735168457	80.995735168457	24.7278728485107	34.7749938964844	19.8130378723145\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	80.995735168457	80.995735168457	80.995735168457	41.6678771972656	68.8442993164063	-1.22293967008591	29.7102298736572	80.9957275390625	80.995735168457	80.995735168457	24.7278728485107	34.7749938964844	19.8130378723145\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	80.995735168457	80.995735168457	80.995735168457	40.4004402160645	61.6927833557129	-0.964605450630187	30.8254089355469	80.9957275390625	80.995735168457	80.995735168457	24.7278728485107	34.7749938964844	19.8130378723145\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	80.995735168457	80.995735168457	80.995735168457	23.0635604858398	45.0149955749512	-1.04966068267822	11.425853729248	80.9957275390625	80.995735168457	80.995735168457	24.7278728485107	34.7749938964844	19.8130378723145\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	80.995735168457	80.995735168457	80.995735168457	22.8865013122559	44.6256370544434	-1.03950828313828	11.3613719940186	80.9957275390625	80.995735168457	80.995735168457	24.7278728485107	34.7749938964844	19.8130378723145\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	80.995735168457	80.995735168457	80.995735168457	32.4648513793945	52.4794540405273	-0.959154069423675	21.7865238189697	80.9957275390625	80.995735168457	80.995735168457	24.7278728485107	34.7749938964844	19.8130378723145\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	80.995735168457	80.995735168457	80.995735168457	32.1244812011719	51.7434730529785	-0.940194427967072	21.6572513580322	80.9957275390625	80.995735168457	80.995735168457	24.7278728485107	34.7749938964844	19.8130378723145\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	80.995735168457	80.995735168457	80.995735168457	25.647029876709	39.0740852355957	-0.644438385963441	18.4520568847656	80.9957275390625	80.995735168457	80.995735168457	24.7278728485107	34.7749938964844	19.8130378723145\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	80.995735168457	80.995735168457	80.995735168457	27.2385807037354	42.728931427002	-0.741303384304047	19.0072231292725	80.9957275390625	80.995735168457	80.995735168457	24.7278728485107	34.7749938964844	19.8130378723145\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	80.9955596923828	80.995735168457	80.9953994750977	223.707489013672	305.301361083984	-4.08678436279297	174.524261474609	80.9954681396484	80.995735168457	80.9947509765625	145.612487792969	196.918762207031	120.307418823242\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	80.9956283569336	80.995735168457	80.9955749511719	198.413330078125	278.290069580078	-3.62459135055541	162.303146362305	80.9954681396484	80.995735168457	80.9947509765625	145.612487792969	196.918762207031	120.307418823242\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	80.9956512451172	80.995735168457	80.9956130981445	191.171325683594	265.962097167969	-3.37229013442994	158.048812866211	80.9954681396484	80.995735168457	80.9947509765625	145.612487792969	196.918762207031	120.307418823242\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	80.9956512451172	80.995735168457	80.9956130981445	183.146728515625	250.527359008789	-3.07457113265991	152.141082763672	80.9954681396484	80.995735168457	80.9947509765625	145.612487792969	196.918762207031	120.307418823242\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	80.9956741333008	80.995735168457	80.9956130981445	178.635391235352	242.572128295898	-3.23049163818359	139.196395874023	80.9954681396484	80.995735168457	80.9947509765625	145.612487792969	196.918762207031	120.307418823242\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	80.9956741333008	80.995735168457	80.9956130981445	176.956436157227	240.273635864258	-3.19918584823609	137.899688720703	80.9954681396484	80.995735168457	80.9947509765625	145.612487792969	196.918762207031	120.307418823242\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	80.995719909668	80.995735168457	80.9956970214844	181.020217895508	240.970962524414	-3.03966760635375	143.701599121094	80.9954681396484	80.995735168457	80.9947509765625	145.612487792969	196.918762207031	120.307418823242\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	80.995719909668	80.995735168457	80.9956970214844	177.747451782227	236.511474609375	-2.97949409484862	141.167663574219	80.9954681396484	80.995735168457	80.9947509765625	145.612487792969	196.918762207031	120.307418823242\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	80.9957122802734	80.995735168457	80.9956970214844	154.39909362793	204.173309326172	-2.50055146217347	124.155662536621	80.9954681396484	80.995735168457	80.9947509765625	145.612487792969	196.918762207031	120.307418823242\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	80.9957122802734	80.995735168457	80.9956970214844	153.785507202148	205.329193115234	-2.58688735961912	122.548797607422	80.9954681396484	80.995735168457	80.9947509765625	145.612487792969	196.918762207031	120.307418823242\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	78.6719589233398	80.9676895141602	76.6586303710938	1578.55920410156	1084.8876953125	25.8120613098144	1910.87365722656	78.5986480712891	80.9927062988281	71.3943328857422	1684.32385253906	1073.84692382813	2264.22973632813\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	78.0422897338867	80.9914703369141	76.6431350708008	1596.46899414063	1045.67553710938	25.0937194824219	1848.67456054688	78.5986480712891	80.9927062988281	71.3943328857422	1684.32385253906	1073.84692382813	2264.22973632813\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	80.0664825439453	80.995475769043	79.6469650268555	1542.25219726563	998.771301269531	24.5472812652587	1784.28430175781	78.5986480712891	80.9927062988281	71.3943328857422	1684.32385253906	1073.84692382813	2264.22973632813\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	80.0261077880859	80.9955291748047	79.5450439453125	1538.32604980469	1002.22491455078	24.6344928741456	1790.52868652344	78.5986480712891	80.9927062988281	71.3943328857422	1684.32385253906	1073.84692382813	2264.22973632813\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	80.3202209472656	80.9954833984375	79.6654510498047	1475.27856445313	1008.44146728516	24.8564167022703	1803.84680175781	78.5986480712891	80.9927062988281	71.3943328857422	1684.32385253906	1073.84692382813	2264.22973632813\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	80.2079162597656	80.9954833984375	79.4420700073242	1459.43322753906	998.8056640625	24.5115814208984	1783.17626953125	78.5986480712891	80.9927062988281	71.3943328857422	1684.32385253906	1073.84692382813	2264.22973632813\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	80.7568206787109	80.9956970214844	80.5221328735352	1430.83776855469	983.186157226563	24.0105667114258	1751.52429199219	78.5986480712891	80.9927062988281	71.3943328857422	1684.32385253906	1073.84692382813	2264.22973632813\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	80.7269668579102	80.9956512451172	80.4626235961914	1402.419921875	964.162963867188	23.5022964477538	1716.23645019531	78.5986480712891	80.9927062988281	71.3943328857422	1684.32385253906	1073.84692382813	2264.22973632813\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	80.7224655151367	80.9956512451172	80.4619140625	1356.95764160156	943.212524414063	21.9772834777833	1646.48559570313	78.5986480712891	80.9927062988281	71.3943328857422	1684.32385253906	1073.84692382813	2264.22973632813\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	80.7047653198242	80.9956512451172	80.4320831298828	1281.599609375	869.969055175781	21.8253421783447	1568.38000488281	78.5986480712891	80.9927062988281	71.3943328857422	1684.32385253906	1073.84692382813	2264.22973632813\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	56.3202133178711	80.5576171875	37.6431274414063	2351.4169921875	1531.95385742188	43.2175979614256	2914.9169921875	63.6685028076172	80.9192428588867	48.4414558410645	2625.72021484375	1586.09887695313	3645.81982421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	52.1627464294434	80.8018264770508	38.8642768859863	2413.32470703125	1492.99072265625	41.9699859619141	2836.0302734375	63.6685028076172	80.9192428588867	48.4414558410645	2625.72021484375	1586.09887695313	3645.81982421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	53.5533561706543	80.9661636352539	41.2396774291992	2307.3212890625	1427.18212890625	39.7485427856447	2699.13549804688	63.6685028076172	80.9192428588867	48.4414558410645	2625.72021484375	1586.09887695313	3645.81982421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	61.4733047485352	80.9735412597656	52.1176834106445	2334.37329101563	1447.95104980469	40.7397270202638	2751.62231445313	63.6685028076172	80.9192428588867	48.4414558410645	2625.72021484375	1586.09887695313	3645.81982421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	65.5036468505859	80.972900390625	53.3029327392578	2237.07006835938	1464.97924804688	41.1655044555662	2782.275390625	63.6685028076172	80.9192428588867	48.4414558410645	2625.72021484375	1586.09887695313	3645.81982421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	58.8660659790039	80.9682540893555	41.4251976013184	2189.8447265625	1450.89660644531	39.4619407653809	2713.6787109375	63.6685028076172	80.9192428588867	48.4414558410645	2625.72021484375	1586.09887695313	3645.81982421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	68.843391418457	80.990104675293	58.3518257141113	2180.4619140625	1423.14282226563	40.3655548095703	2714.84057617188	63.6685028076172	80.9192428588867	48.4414558410645	2625.72021484375	1586.09887695313	3645.81982421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	68.2561416625977	80.9893569946289	57.4182281494141	2133.69140625	1395.47314453125	39.3743667602541	2655.45288085938	63.6685028076172	80.9192428588867	48.4414558410645	2625.72021484375	1586.09887695313	3645.81982421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	68.5361709594727	80.9890213012695	58.183521270752	2045.81286621094	1364.40734863281	36.2564582824709	2524.61401367188	63.6685028076172	80.9192428588867	48.4414558410645	2625.72021484375	1586.09887695313	3645.81982421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	69.0264434814453	80.9897689819336	59.3031692504883	1938.39184570313	1266.12048339844	35.2734947204591	2394.87231445313	63.6685028076172	80.9192428588867	48.4414558410645	2625.72021484375	1586.09887695313	3645.81982421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	49.4488716125488	76.3564605712891	36.3958282470703	3206.58959960938	1968.662109375	65.5484619140625	4066.212890625	54.0721588134766	77.5842132568359	33.2169189453125	3617.8369140625	2128.07397460938	5206.7197265625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	42.2675895690918	75.6664810180664	27.2068748474121	3318.08471679688	1922.4775390625	63.6501159667969	3959.28125	54.0721588134766	77.5842132568359	33.2169189453125	3617.8369140625	2128.07397460938	5206.7197265625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	52.0428466796875	79.3694305419922	40.0615615844727	3191.09936523438	1877.60314941406	59.3472709655762	3776.7158203125	54.0721588134766	77.5842132568359	33.2169189453125	3617.8369140625	2128.07397460938	5206.7197265625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	44.9302711486816	78.6021728515625	29.4418487548828	3209.23071289063	1907.13403320313	59.9213562011719	3824.61743164063	54.0721588134766	77.5842132568359	33.2169189453125	3617.8369140625	2128.07397460938	5206.7197265625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	48.60107421875	78.6529846191406	31.5221061706543	3052.169921875	1935.06811523438	60.0482940673828	3856.61352539063	54.0721588134766	77.5842132568359	33.2169189453125	3617.8369140625	2128.07397460938	5206.7197265625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	48.7553939819336	78.1203994750977	32.5347442626953	3007.13793945313	1911.19787597656	58.9944419860841	3799.02001953125	54.0721588134766	77.5842132568359	33.2169189453125	3617.8369140625	2128.07397460938	5206.7197265625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	52.8828773498535	80.4931488037109	33.6635551452637	2958.05249023438	1900.71472167969	56.8212394714356	3718.99438476563	54.0721588134766	77.5842132568359	33.2169189453125	3617.8369140625	2128.07397460938	5206.7197265625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	52.5753326416016	80.3928985595703	33.6748390197754	2893.36376953125	1862.48876953125	55.4348678588869	3636.40454101563	54.0721588134766	77.5842132568359	33.2169189453125	3617.8369140625	2128.07397460938	5206.7197265625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	51.8789367675781	80.2837524414063	33.1635055541992	2818.04272460938	1793.52258300781	55.5527915954591	3571.2119140625	54.0721588134766	77.5842132568359	33.2169189453125	3617.8369140625	2128.07397460938	5206.7197265625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Freeflow	90	Freeflow	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	53.1669578552246	80.4184265136719	34.1216583251953	2626.74584960938	1694.50024414063	49.6011276245116	3281.736328125	54.0721588134766	77.5842132568359	33.2169189453125	3617.8369140625	2128.07397460938	5206.7197265625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	46.3056602478027	46.8488121032715	45.8789367675781	1101.0244140625	770.14892578125	17.2584342956544	1322.41882324219	46.4897384643555	46.8814163208008	45.9205741882324	1172.50512695313	771.580932617188	1559.08581542969\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	46.2138557434082	46.7949142456055	45.9424896240234	1115.8154296875	749.963684082031	16.6672649383547	1283.31616210938	46.4897384643555	46.8814163208008	45.9205741882324	1172.50512695313	771.580932617188	1559.08581542969\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	46.4341926574707	46.8844566345215	46.232852935791	1079.66333007813	723.58544921875	16.0803680419922	1238.1572265625	46.4897384643555	46.8814163208008	45.9205741882324	1172.50512695313	771.580932617188	1559.08581542969\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	46.3816566467285	46.8391075134277	46.1612892150879	1077.06799316406	731.056030273438	15.8907814025879	1239.56103515625	46.4897384643555	46.8814163208008	45.9205741882324	1172.50512695313	771.580932617188	1559.08581542969\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	46.4727249145508	46.8526992797852	46.1678695678711	1025.31396484375	719.573791503906	16.2332286834717	1239.037109375	46.4897384643555	46.8814163208008	45.9205741882324	1172.50512695313	771.580932617188	1559.08581542969\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	46.3596649169922	46.7676963806152	46.0149002075195	1011.44293212891	712.485534667969	15.8350009918213	1219.20556640625	46.4897384643555	46.8814163208008	45.9205741882324	1172.50512695313	771.580932617188	1559.08581542969\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	46.5503921508789	46.8922004699707	46.2543563842773	1000.6025390625	709.2421875	15.5425529479981	1206.60388183594	46.4897384643555	46.8814163208008	45.9205741882324	1172.50512695313	771.580932617188	1559.08581542969\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	46.5402946472168	46.8476333618164	46.2687034606934	980.773315429688	695.740173339844	15.2048244476318	1182.29455566406	46.4897384643555	46.8814163208008	45.9205741882324	1172.50512695313	771.580932617188	1559.08581542969\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	46.5536270141602	46.8501281738281	46.2971801757813	940.331420898438	665.867004394531	14.5339908599853	1130.95471191406	46.4897384643555	46.8814163208008	45.9205741882324	1172.50512695313	771.580932617188	1559.08581542969\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	46.5635261535645	46.8935508728027	46.2761764526367	884.620361328125	620.465576171875	13.9431266784667	1066.64562988281	46.4897384643555	46.8814163208008	45.9205741882324	1172.50512695313	771.580932617188	1559.08581542969\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	46.8458671569824	46.9007453918457	46.7940521240234	191.15641784668	152.320449829102	2.07244634628294	218.638732910156	46.8827781677246	46.9007453918457	46.8235893249512	192.428009033203	143.177383422852	246.084823608398\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	46.8289604187012	46.9007453918457	46.7947540283203	191.291656494141	145.004135131836	2.11273908615112	212.611785888672	46.8827781677246	46.9007453918457	46.8235893249512	192.428009033203	143.177383422852	246.084823608398\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	46.8661727905273	46.9007453918457	46.8505363464355	187.834747314453	145.613815307617	1.90802335739138	206.670562744141	46.8827781677246	46.9007453918457	46.8235893249512	192.428009033203	143.177383422852	246.084823608398\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	46.8654251098633	46.9007453918457	46.8478050231934	187.320541381836	148.068603515625	1.80707025527953	205.89485168457	46.8827781677246	46.9007453918457	46.8235893249512	192.428009033203	143.177383422852	246.084823608398\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	46.8500709533691	46.9007453918457	46.7991027832031	160.293075561523	122.30054473877	2.04626822471619	187.781127929688	46.8827781677246	46.9007453918457	46.8235893249512	192.428009033203	143.177383422852	246.084823608398\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	46.8484535217285	46.9007453918457	46.7960090637207	158.727188110352	121.181373596191	2.02141833305359	185.866760253906	46.8827781677246	46.9007453918457	46.8235893249512	192.428009033203	143.177383422852	246.084823608398\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	46.8756904602051	46.9007453918457	46.8500022888184	166.351684570313	130.699630737305	1.93146085739134	192.506378173828	46.8827781677246	46.9007453918457	46.8235893249512	192.428009033203	143.177383422852	246.084823608398\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	46.8755111694336	46.9007453918457	46.8496360778809	163.40544128418	128.484252929688	1.89156770706175	189.014419555664	46.8827781677246	46.9007453918457	46.8235893249512	192.428009033203	143.177383422852	246.084823608398\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	46.875129699707	46.9007453918457	46.8496894836426	155.420104980469	120.527755737305	1.86556386947631	180.225799560547	46.8827781677246	46.9007453918457	46.8235893249512	192.428009033203	143.177383422852	246.084823608398\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	46.8752136230469	46.9007453918457	46.8503837585449	146.991424560547	115.168922424316	1.69962143898013	169.55680847168	46.8827781677246	46.9007453918457	46.8235893249512	192.428009033203	143.177383422852	246.084823608398\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	46.8324851989746	46.9007453918457	46.7729225158691	336.984191894531	253.061828613281	4.49915695190431	397.034851074219	46.8657188415527	46.9006385803223	46.8143424987793	351.089416503906	240.041213989258	465.685974121094\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	46.871280670166	46.9007453918457	46.8576545715332	336.965667724609	236.884292602539	4.56646394729616	383.011138916016	46.8657188415527	46.9006385803223	46.8143424987793	351.089416503906	240.041213989258	465.685974121094\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	46.8535461425781	46.9007453918457	46.8327255249023	329.729675292969	232.968307495117	4.37254190444947	372.8896484375	46.8657188415527	46.9006385803223	46.8143424987793	351.089416503906	240.041213989258	465.685974121094\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	46.8515739440918	46.9007453918457	46.8311500549316	330.764739990234	236.316131591797	4.3455104827881	375.372467041016	46.8657188415527	46.9006385803223	46.8143424987793	351.089416503906	240.041213989258	465.685974121094\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	46.8785781860352	46.9007453918457	46.8633766174316	293.912353515625	211.166931152344	4.43944358825684	353.229125976563	46.8657188415527	46.9006385803223	46.8143424987793	351.089416503906	240.041213989258	465.685974121094\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	46.8661842346191	46.9007453918457	46.8485298156738	290.901092529297	209.193069458008	4.38484239578247	349.508026123047	46.8657188415527	46.9006385803223	46.8143424987793	351.089416503906	240.041213989258	465.685974121094\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	46.8392944335938	46.9007453918457	46.7957534790039	296.565124511719	217.640579223633	4.26884984970094	354.243774414063	46.8657188415527	46.9006385803223	46.8143424987793	351.089416503906	240.041213989258	465.685974121094\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	46.8140411376953	46.9007453918457	46.7447814941406	291.401824951172	213.714309692383	4.20469999313353	348.264709472656	46.8657188415527	46.9006385803223	46.8143424987793	351.089416503906	240.041213989258	465.685974121094\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	46.8384552001953	46.9007453918457	46.7949409484863	277.816680908203	201.305816650391	4.08775901794431	332.114105224609	46.8657188415527	46.9006385803223	46.8143424987793	351.089416503906	240.041213989258	465.685974121094\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	46.8645172119141	46.9007453918457	46.8467445373535	261.848449707031	189.892761230469	3.84473896026609	312.924407958984	46.8657188415527	46.9006385803223	46.8143424987793	351.089416503906	240.041213989258	465.685974121094\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	46.688663482666	46.8474617004395	46.5402717590332	594.472778320313	472.211212158203	6.45131206512453	678.653198242188	46.78076171875	46.8915634155273	46.5808372497559	606.53466796875	433.362396240234	795.562622070313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	46.661792755127	46.8472747802734	46.5737190246582	589.331298828125	452.343719482422	6.24663448333741	652.236022949219	46.78076171875	46.8915634155273	46.5808372497559	606.53466796875	433.362396240234	795.562622070313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	46.7336654663086	46.8504524230957	46.6811752319336	573.654602050781	437.083618164063	6.17004966735838	634.525207519531	46.78076171875	46.8915634155273	46.5808372497559	606.53466796875	433.362396240234	795.562622070313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	46.6958312988281	46.8491897583008	46.6218414306641	569.525573730469	436.197296142578	6.12968349456787	632.34716796875	46.78076171875	46.8915634155273	46.5808372497559	606.53466796875	433.362396240234	795.562622070313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	46.7458267211914	46.894889831543	46.6347312927246	534.981872558594	419.208648681641	6.16960430145262	616.635986328125	46.78076171875	46.8915634155273	46.5808372497559	606.53466796875	433.362396240234	795.562622070313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	46.733455657959	46.8467216491699	46.6404418945313	529.367614746094	415.391632080078	6.07872676849366	609.910888671875	46.78076171875	46.8915634155273	46.5808372497559	606.53466796875	433.362396240234	795.562622070313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	46.7592086791992	46.8505363464355	46.6775856018066	529.120544433594	415.382690429688	6.08510208129881	610.10595703125	46.78076171875	46.8915634155273	46.5808372497559	606.53466796875	433.362396240234	795.562622070313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	46.7124862670898	46.849292755127	46.5820732116699	519.202758789063	407.554992675781	5.97773551940919	598.842529296875	46.78076171875	46.8915634155273	46.5808372497559	606.53466796875	433.362396240234	795.562622070313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	46.7573890686035	46.8998870849609	46.6442413330078	490.035614013672	381.664764404297	5.77259159088134	566.3876953125	46.78076171875	46.8915634155273	46.5808372497559	606.53466796875	433.362396240234	795.562622070313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	46.7542381286621	46.9001235961914	46.6356163024902	463.258056640625	360.761474609375	5.42215919494628	534.270568847656	46.78076171875	46.8915634155273	46.5808372497559	606.53466796875	433.362396240234	795.562622070313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	44.9087295532227	46.8007507324219	43.4664268493652	1726.06225585938	1131.7333984375	30.9628448486328	2122.54443359375	45.4961204528809	46.8431434631348	42.9796180725098	1911.02160644531	1171.47570800781	2582.00732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	44.5332221984863	46.7795906066895	43.4867286682129	1761.05310058594	1101.72424316406	30.0255775451662	2062.54272460938	45.4961204528809	46.8431434631348	42.9796180725098	1911.02160644531	1171.47570800781	2582.00732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	45.2794761657715	46.86474609375	44.5686912536621	1717.52026367188	1062.83410644531	29.5634803771975	2008.86547851563	45.4961204528809	46.8431434631348	42.9796180725098	1911.02160644531	1171.47570800781	2582.00732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	45.3095436096191	46.8515357971191	44.5681343078613	1721.80859375	1078.296875	29.5518989562987	2023.95764160156	45.4961204528809	46.8431434631348	42.9796180725098	1911.02160644531	1171.47570800781	2582.00732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	45.395336151123	46.7967071533203	44.2359657287598	1634.43920898438	1077.04833984375	29.4889793395997	2020.69567871094	45.4961204528809	46.8431434631348	42.9796180725098	1911.02160644531	1171.47570800781	2582.00732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	45.1753387451172	46.7567863464355	43.8637504577637	1614.4140625	1066.67602539063	28.9499969482422	1993.07592773438	45.4961204528809	46.8431434631348	42.9796180725098	1911.02160644531	1171.47570800781	2582.00732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	45.7998657226563	46.8130073547363	45.0061149597168	1602.10375976563	1056.89367675781	29.0668983459475	1987.03442382813	45.4961204528809	46.8431434631348	42.9796180725098	1911.02160644531	1171.47570800781	2582.00732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	45.7553939819336	46.7776184082031	44.9133338928223	1570.6865234375	1036.47216796875	28.4843368530275	1947.97094726563	45.4961204528809	46.8431434631348	42.9796180725098	1911.02160644531	1171.47570800781	2582.00732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	45.8345680236816	46.7777862548828	45.0581245422363	1511.35180664063	1005.6083984375	26.7588157653809	1861.89050292969	45.4961204528809	46.8431434631348	42.9796180725098	1911.02160644531	1171.47570800781	2582.00732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	45.8672103881836	46.8213768005371	45.105167388916	1422.70642089844	929.021911621094	26.0685329437255	1763.21496582031	45.4961204528809	46.8431434631348	42.9796180725098	1911.02160644531	1171.47570800781	2582.00732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	40.3662605285645	46.3769569396973	35.8166732788086	2411.22290039063	1512.53686523438	47.0373764038084	3017.73291015625	42.3181762695313	46.696704864502	37.3256759643555	2702.76293945313	1610.75756835938	3759.01611328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	39.4776153564453	46.4274635314941	36.2670059204102	2482.74194335938	1488.91870117188	45.2987213134766	2938.47778320313	42.3181762695313	46.696704864502	37.3256759643555	2702.76293945313	1610.75756835938	3759.01611328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	39.9855995178223	46.631217956543	37.0069885253906	2401.92456054688	1438.70349121094	43.5014991760253	2830.75146484375	42.3181762695313	46.696704864502	37.3256759643555	2702.76293945313	1610.75756835938	3759.01611328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	41.4305267333984	46.6758041381836	38.9297752380371	2409.01416015625	1465.61779785156	43.3408012390137	2852.5234375	42.3181762695313	46.696704864502	37.3256759643555	2702.76293945313	1610.75756835938	3759.01611328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	41.6142539978027	46.655345916748	37.6831169128418	2282.92822265625	1474.95690917969	42.844036102295	2845.96606445313	42.3181762695313	46.696704864502	37.3256759643555	2702.76293945313	1610.75756835938	3759.01611328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	40.7533798217773	46.5798950195313	36.3544082641602	2243.6103515625	1460.81286621094	41.5925865173341	2791.77563476563	42.3181762695313	46.696704864502	37.3256759643555	2702.76293945313	1610.75756835938	3759.01611328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	42.9822654724121	46.7074012756348	40.0177612304688	2235.93798828125	1442.61767578125	42.24462890625	2794.44580078125	42.3181762695313	46.696704864502	37.3256759643555	2702.76293945313	1610.75756835938	3759.01611328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	42.8190879821777	46.6889457702637	39.7516975402832	2187.65380859375	1414.39587402344	41.1646842956544	2731.66577148438	42.3181762695313	46.696704864502	37.3256759643555	2702.76293945313	1610.75756835938	3759.01611328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	42.7638549804688	46.5907821655273	39.7747001647949	2101.73974609375	1376.73693847656	38.4275932312012	2606.419921875	42.3181762695313	46.696704864502	37.3256759643555	2702.76293945313	1610.75756835938	3759.01611328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	43.0947685241699	46.6410675048828	40.3788795471191	1980.60375976563	1270.48669433594	37.3448829650878	2465.52294921875	42.3181762695313	46.696704864502	37.3256759643555	2702.76293945313	1610.75756835938	3759.01611328125\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	35.6010589599609	45.2533493041992	29.5322265625	3195.24291992188	1913.6953125	67.3015060424806	4067.34350585938	38.5087661743164	46.1826477050781	29.6242809295654	3619.66821289063	2085.52490234375	5196.8603515625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	32.9651184082031	45.1445465087891	27.3199081420898	3309.7158203125	1877.52587890625	65.2712478637697	3966.20581054688	38.5087661743164	46.1826477050781	29.6242809295654	3619.66821289063	2085.52490234375	5196.8603515625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	37.421028137207	45.7610244750977	33.7351608276367	3185.58837890625	1827.36987304688	61.3461608886719	3790.44702148438	38.5087661743164	46.1826477050781	29.6242809295654	3619.66821289063	2085.52490234375	5196.8603515625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	35.3154335021973	45.6509704589844	30.4037208557129	3203.98828125	1863.65747070313	61.5953369140625	3834.70825195313	38.5087661743164	46.1826477050781	29.6242809295654	3619.66821289063	2085.52490234375	5196.8603515625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	36.4386978149414	45.5080451965332	30.4019470214844	3042.98364257813	1885.96813964844	61.7250862121581	3861.1708984375	38.5087661743164	46.1826477050781	29.6242809295654	3619.66821289063	2085.52490234375	5196.8603515625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	36.6581077575684	45.3525543212891	31.1616344451904	3002.03149414063	1863.61120605469	60.7900962829591	3808.89428710938	38.5087661743164	46.1826477050781	29.6242809295654	3619.66821289063	2085.52490234375	5196.8603515625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	38.046516418457	46.2228355407715	31.8794746398926	2953.48022460938	1857.9853515625	58.5487442016603	3731.54516601563	38.5087661743164	46.1826477050781	29.6242809295654	3619.66821289063	2085.52490234375	5196.8603515625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	37.847110748291	46.1139945983887	31.9001693725586	2892.88427734375	1819.66101074219	57.5431251525878	3661.041015625	38.5087661743164	46.1826477050781	29.6242809295654	3619.66821289063	2085.52490234375	5196.8603515625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	37.7578125	46.1093063354492	31.7330684661865	2804.94116210938	1758.00549316406	55.9986228942872	3549.96142578125	38.5087661743164	46.1826477050781	29.6242809295654	3619.66821289063	2085.52490234375	5196.8603515625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/Satur.	90	Saturated	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	38.2387046813965	46.217227935791	32.3323631286621	2617.06982421875	1635.74694824219	51.9249763488769	3297.34619140625	38.5087661743164	46.1826477050781	29.6242809295654	3619.66821289063	2085.52490234375	5196.8603515625\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.4849605560303	17.7624950408936	17.2992305755615	1903.6025390625	1376.916015625	27.3295593261719	2251.4619140625	17.4849720001221	17.7183456420898	17.2719211578369	1992.69030761719	1321.48010253906	2594.30200195313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	17.3781070709229	17.7537422180176	17.2075538635254	1926.79699707031	1343.35510253906	26.5631065368653	2193.37451171875	17.4849720001221	17.7183456420898	17.2719211578369	1992.69030761719	1321.48010253906	2594.30200195313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	17.3789405822754	17.7730197906494	17.2034931182861	1886.33422851563	1304.20666503906	26.2833442687991	2145.27368164063	17.4849720001221	17.7183456420898	17.2719211578369	1992.69030761719	1321.48010253906	2594.30200195313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	17.46875	17.7228393554688	17.3519725799561	1874.88439941406	1308.53295898438	25.9878997802734	2140.14575195313	17.4849720001221	17.7183456420898	17.2719211578369	1992.69030761719	1321.48010253906	2594.30200195313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	17.3716812133789	17.6959838867188	17.0884132385254	1745.77868652344	1246.75622558594	26.3274803161622	2089.23559570313	17.4849720001221	17.7183456420898	17.2719211578369	1992.69030761719	1321.48010253906	2594.30200195313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	17.4738998413086	17.6767024993896	17.3332996368408	1720.20007324219	1233.70983886719	25.6182975769044	2053.49536132813	17.4849720001221	17.7183456420898	17.2719211578369	1992.69030761719	1321.48010253906	2594.30200195313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	17.5621929168701	17.7419605255127	17.4444313049316	1701.40881347656	1218.044921875	25.6483154296875	2038.791015625	17.4849720001221	17.7183456420898	17.2719211578369	1992.69030761719	1321.48010253906	2594.30200195313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	17.543478012085	17.7383880615234	17.4050750732422	1671.74499511719	1193.65417480469	25.4227104187012	2007.18090820313	17.4849720001221	17.7183456420898	17.2719211578369	1992.69030761719	1321.48010253906	2594.30200195313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	17.4954414367676	17.7087154388428	17.3170680999756	1578.20300292969	1117.228515625	24.3893928527831	1897.68908691406	17.4849720001221	17.7183456420898	17.2719211578369	1992.69030761719	1321.48010253906	2594.30200195313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	17.5364303588867	17.6899681091309	17.3875827789307	1493.49816894531	1053.50073242188	23.1787300109862	1795.22009277344	17.4849720001221	17.7183456420898	17.2719211578369	1992.69030761719	1321.48010253906	2594.30200195313\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.6941661834717	17.7490291595459	17.6436576843262	876.400512695313	724.988647460938	8.02047538757322	981.643859863281	17.7495861053467	17.7889041900635	17.6757755279541	814.625915527344	623.842712402344	1014.59210205078\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	17.6887874603271	17.7892417907715	17.6419658660889	875.12109375	704.399963378906	7.78635025024416	953.563171386719	17.7495861053467	17.7889041900635	17.6757755279541	814.625915527344	623.842712402344	1014.59210205078\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	17.7102947235107	17.7894382476807	17.6748924255371	848.373718261719	681.362915039063	7.54479408264159	922.796325683594	17.7495861053467	17.7889041900635	17.6757755279541	814.625915527344	623.842712402344	1014.59210205078\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	17.7321529388428	17.7894382476807	17.7045955657959	838.373840332031	677.415405273438	7.40310096740722	914.314636230469	17.7495861053467	17.7889041900635	17.6757755279541	814.625915527344	623.842712402344	1014.59210205078\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	17.7428531646729	17.7894382476807	17.7050437927246	737.427917480469	592.21728515625	7.79779815673828	841.746826171875	17.7495861053467	17.7889041900635	17.6757755279541	814.625915527344	623.842712402344	1014.59210205078\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	17.7406806945801	17.7894382476807	17.7011566162109	731.426574707031	586.565246582031	7.79413414001466	835.9775390625	17.7495861053467	17.7889041900635	17.6757755279541	814.625915527344	623.842712402344	1014.59210205078\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	17.7566928863525	17.7894382476807	17.7324314117432	719.087158203125	581.14111328125	7.43380546569825	819.022888183594	17.7495861053467	17.7889041900635	17.6757755279541	814.625915527344	623.842712402344	1014.59210205078\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	17.7565727233887	17.7894382476807	17.7321910858154	702.793334960938	567.758361816406	7.27431297302247	800.536376953125	17.7495861053467	17.7889041900635	17.6757755279541	814.625915527344	623.842712402344	1014.59210205078\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	17.7570838928223	17.7894382476807	17.7337017059326	651.072570800781	517.3212890625	7.12132072448731	745.203552246094	17.7495861053467	17.7889041900635	17.6757755279541	814.625915527344	623.842712402344	1014.59210205078\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	17.7572994232178	17.7894382476807	17.7343139648438	622.152221679688	497.981353759766	6.59958744049072	709.168151855469	17.7495861053467	17.7889041900635	17.6757755279541	814.625915527344	623.842712402344	1014.59210205078\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.7113399505615	17.7618103027344	17.672513961792	1132.26000976563	893.55615234375	12.409019470215	1290.64477539063	17.6868362426758	17.7879409790039	17.6278400421143	1119.22644042969	799.318115234375	1408.990234375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	17.6866054534912	17.7612228393555	17.6525192260742	1134.09875488281	868.309020996094	12.1039981842043	1255.63696289063	17.6868362426758	17.7879409790039	17.6278400421143	1119.22644042969	799.318115234375	1408.990234375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	17.7243785858154	17.7645072937012	17.7076644897461	1106.35009765625	842.891479492188	11.8952026367188	1223.53796386719	17.6868362426758	17.7879409790039	17.6278400421143	1119.22644042969	799.318115234375	1408.990234375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	17.7045345306396	17.749303817749	17.6862163543701	1107.23706054688	838.940979003906	12.3163776397704	1233.06506347656	17.6868362426758	17.7879409790039	17.6278400421143	1119.22644042969	799.318115234375	1408.990234375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	17.65966796875	17.7494888305664	17.601037979126	993.33984375	753.509216308594	12.6611919403077	1158.66735839844	17.6868362426758	17.7879409790039	17.6278400421143	1119.22644042969	799.318115234375	1408.990234375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	17.7044219970703	17.764720916748	17.6865673065186	981.136108398438	745.920532226563	12.4158973693849	1143.22924804688	17.6868362426758	17.7879409790039	17.6278400421143	1119.22644042969	799.318115234375	1408.990234375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	17.756628036499	17.7892723083496	17.7489395141602	971.309631347656	737.884460449219	12.4200801849366	1135.32702636719	17.6868362426758	17.7879409790039	17.6278400421143	1119.22644042969	799.318115234375	1408.990234375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	17.7339324951172	17.7495460510254	17.7300071716309	952.329772949219	722.26123046875	12.24365234375	1114.05810546875	17.6868362426758	17.7879409790039	17.6278400421143	1119.22644042969	799.318115234375	1408.990234375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	17.7561531066895	17.7892684936523	17.7478275299072	888.279174804688	666.609191894531	11.6974048614503	1040.92614746094	17.6868362426758	17.7879409790039	17.6278400421143	1119.22644042969	799.318115234375	1408.990234375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	17.7634468078613	17.7893257141113	17.7574481964111	843.538024902344	634.588500976563	10.9928283691406	986.359008789063	17.6868362426758	17.7879409790039	17.6278400421143	1119.22644042969	799.318115234375	1408.990234375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.5635833740234	17.6896667480469	17.468599319458	1470.10754394531	1115.99084472656	18.40779876709	1705.04040527344	17.6439342498779	17.7606143951416	17.5071048736572	1488.93627929688	1025.76306152344	1922.30578613281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	17.5576095581055	17.6903076171875	17.4991340637207	1475.56262207031	1084.19860839844	17.8174858093263	1654.35815429688	17.6439342498779	17.7606143951416	17.5071048736572	1488.93627929688	1025.76306152344	1922.30578613281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	17.5581645965576	17.705114364624	17.4919738769531	1447.99792480469	1053.17260742188	17.83056640625	1623.75073242188	17.6439342498779	17.7606143951416	17.5071048736572	1488.93627929688	1025.76306152344	1922.30578613281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	17.6115951538086	17.7601299285889	17.540885925293	1437.40673828125	1048.24145507813	17.8611755371094	1619.79907226563	17.6439342498779	17.7606143951416	17.5071048736572	1488.93627929688	1025.76306152344	1922.30578613281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	17.5565414428711	17.7613315582275	17.3978939056396	1314.46228027344	974.884399414063	17.9035034179687	1547.79650878906	17.6439342498779	17.7606143951416	17.5071048736572	1488.93627929688	1025.76306152344	1922.30578613281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	17.6607608795166	17.7337989807129	17.5989665985107	1294.49572753906	964.651550292969	17.389585494995	1521.11828613281	17.6439342498779	17.7606143951416	17.5071048736572	1488.93627929688	1025.76306152344	1922.30578613281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	17.6174602508545	17.764347076416	17.4682216644287	1287.06628417969	953.230895996094	17.7744350433349	1522.01281738281	17.6439342498779	17.7606143951416	17.5071048736572	1488.93627929688	1025.76306152344	1922.30578613281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	17.5991058349609	17.7642402648926	17.441385269165	1258.8095703125	931.563171386719	17.4089069366457	1488.64819335938	17.6439342498779	17.7606143951416	17.5071048736572	1488.93627929688	1025.76306152344	1922.30578613281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	17.6297836303711	17.7641296386719	17.4978179931641	1185.47534179688	869.435424804688	16.7024993896485	1403.91540527344	17.6439342498779	17.7606143951416	17.5071048736572	1488.93627929688	1025.76306152344	1922.30578613281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	17.6746311187744	17.7645053863525	17.5831642150879	1126.21545410156	824.148376464844	15.9333515167236	1334.015625	17.6439342498779	17.7606143951416	17.5071048736572	1488.93627929688	1025.76306152344	1922.30578613281\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.1718368530273	17.7078895568848	16.8264999389648	2419.22338867188	1688.44921875	37.9351959228516	2902.37548828125	17.2797451019287	17.7362117767334	16.7861080169678	2579.75024414063	1666.994140625	3406.60424804688\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	16.9413414001465	17.6585311889648	16.6132793426514	2445.48291015625	1649.61938476563	36.2365798950194	2809.18994140625	17.2797451019287	17.7362117767334	16.7861080169678	2579.75024414063	1666.994140625	3406.60424804688\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	17.1112022399902	17.6778755187988	16.8578071594238	2397.662109375	1605.3984375	35.7734298706056	2750.14819335938	17.2797451019287	17.7362117767334	16.7861080169678	2579.75024414063	1666.994140625	3406.60424804688\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	17.1828765869141	17.7204360961914	16.9311828613281	2375.626953125	1614.92602539063	34.9036636352537	2731.84326171875	17.2797451019287	17.7362117767334	16.7861080169678	2579.75024414063	1666.994140625	3406.60424804688\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	17.1859664916992	17.6590023040771	16.8246212005615	2233.34130859375	1561.54992675781	35.4280433654788	2695.24731445313	17.2797451019287	17.7362117767334	16.7861080169678	2579.75024414063	1666.994140625	3406.60424804688\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	17.1849327087402	17.7081451416016	16.7871932983398	2207.84448242188	1544.30737304688	35.0914306640625	2667.23315429688	17.2797451019287	17.7362117767334	16.7861080169678	2579.75024414063	1666.994140625	3406.60424804688\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	17.206714630127	17.7155685424805	16.8023052215576	2192.0810546875	1523.62109375	35.4170303344728	2656.96606445313	17.2797451019287	17.7362117767334	16.7861080169678	2579.75024414063	1666.994140625	3406.60424804688\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	17.2541942596436	17.7009792327881	16.933723449707	2145.23583984375	1493.32885742188	34.4739532470703	2596.49536132813	17.2797451019287	17.7362117767334	16.7861080169678	2579.75024414063	1666.994140625	3406.60424804688\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	17.3511180877686	17.752498626709	17.0660934448242	2032.88366699219	1407.71374511719	32.8601646423341	2459.23901367188	17.2797451019287	17.7362117767334	16.7861080169678	2579.75024414063	1666.994140625	3406.60424804688\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	17.3126926422119	17.7669715881348	16.9987812042236	1923.24108886719	1322.34790039063	31.4500198364256	2328.74853515625	17.2797451019287	17.7362117767334	16.7861080169678	2579.75024414063	1666.994140625	3406.60424804688\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	16.6333923339844	17.6229248046875	15.9275512695313	3030.59594726563	2025.87390136719	52.3458290100097	3700.9404296875	16.851188659668	17.6339530944824	15.9560356140137	3284.28100585938	2062.57543945313	4420.0224609375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	16.3251342773438	17.4510707855225	15.8024415969849	3097.78564453125	1989.80151367188	50.4764175415037	3605.046875	16.851188659668	17.6339530944824	15.9560356140137	3284.28100585938	2062.57543945313	4420.0224609375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	16.6211795806885	17.6184215545654	16.1760101318359	3006.44946289063	1932.45825195313	48.4987030029297	3484.41674804688	16.851188659668	17.6339530944824	15.9560356140137	3284.28100585938	2062.57543945313	4420.0224609375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	16.6231784820557	17.5844821929932	16.166841506958	2996.68041992188	1953.27819824219	47.9101753234863	3486.40380859375	16.851188659668	17.6339530944824	15.9560356140137	3284.28100585938	2062.57543945313	4420.0224609375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	16.6200122833252	17.4407501220703	16.0438957214355	2816.7275390625	1913.80773925781	47.7023429870606	3440.28271484375	16.851188659668	17.6339530944824	15.9560356140137	3284.28100585938	2062.57543945313	4420.0224609375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	16.5547618865967	17.5872077941895	15.8296365737915	2774.5224609375	1888.44702148438	46.7029266357422	3382.94067382813	16.851188659668	17.6339530944824	15.9560356140137	3284.28100585938	2062.57543945313	4420.0224609375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	16.8008193969727	17.5705242156982	16.2973766326904	2759.79321289063	1869.28698730469	47.3326301574706	3383.93115234375	16.851188659668	17.6339530944824	15.9560356140137	3284.28100585938	2062.57543945313	4420.0224609375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	16.9379653930664	17.6952667236328	16.4421501159668	2698.23413085938	1828.27514648438	46.1697311401366	3305.70654296875	16.851188659668	17.6339530944824	15.9560356140137	3284.28100585938	2062.57543945313	4420.0224609375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	16.9893436431885	17.7006969451904	16.4716892242432	2562.23046875	1732.46032714844	43.8268852233888	3134.92065429688	16.851188659668	17.6339530944824	15.9560356140137	3284.28100585938	2062.57543945313	4420.0224609375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	17.0032291412354	17.6739864349365	16.5270099639893	2431.181640625	1628.287109375	42.2621841430666	2980.67700195313	16.851188659668	17.6339530944824	15.9560356140137	3284.28100585938	2062.57543945313	4420.0224609375\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	15.563835144043	17.2395324707031	14.4117612838745	3703.8544921875	2408.76220703125	67.7393951416016	4576.4228515625	16.1878128051758	17.4583530426025	14.6572332382202	4071.9697265625	2482.2724609375	5618.0732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	15.1432952880859	17.3245964050293	14.1294002532959	3783.66235351563	2358.31225585938	64.9202957153319	4435.76171875	16.1878128051758	17.4583530426025	14.6572332382202	4071.9697265625	2482.2724609375	5618.0732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	15.9090843200684	17.4271183013916	15.2334041595459	3676.57055664063	2284.63525390625	62.8579254150391	4296.0888671875	16.1878128051758	17.4583530426025	14.6572332382202	4071.9697265625	2482.2724609375	5618.0732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	15.789306640625	17.3914375305176	15.0328254699707	3670.36499023438	2315.55786132813	62.2024612426756	4306.03662109375	16.1878128051758	17.4583530426025	14.6572332382202	4071.9697265625	2482.2724609375	5618.0732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	15.8855113983154	17.362003326416	14.8429498672485	3468.8251953125	2287.63110351563	62.6219711303709	4291.5341796875	16.1878128051758	17.4583530426025	14.6572332382202	4071.9697265625	2482.2724609375	5618.0732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	15.7773294448853	17.3021640777588	14.7203350067139	3429.13256835938	2260.8955078125	61.89697265625	4241.5986328125	16.1878128051758	17.4583530426025	14.6572332382202	4071.9697265625	2482.2724609375	5618.0732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	16.2023849487305	17.3704795837402	15.3176727294922	3374.32421875	2234.63598632813	60.4965286254881	4170.52490234375	16.1878128051758	17.4583530426025	14.6572332382202	4071.9697265625	2482.2724609375	5618.0732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	16.1705436706543	17.4558601379395	15.2390928268433	3301.7470703125	2190.77954101563	58.9985198974609	4078.73217773438	16.1878128051758	17.4583530426025	14.6572332382202	4071.9697265625	2482.2724609375	5618.0732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	16.2366313934326	17.4728412628174	15.304238319397	3161.22045898438	2084.23583984375	56.9262542724609	3905.8759765625	16.1878128051758	17.4583530426025	14.6572332382202	4071.9697265625	2482.2724609375	5618.0732421875\\\
HGV	2022	REF N HB42	CO2e	MW	RUR/Semi-MW/90/St+Go	90	Stopgo	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	16.3088512420654	17.4882354736328	15.4306201934814	2977.80493164063	1952.36437988281	53.86381149292	3676.00634765625	16.1878128051758	17.4583530426025	14.6572332382202	4071.9697265625	2482.2724609375	5618.0732421875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	68.2285003662109	68.2721710205078	68.1936416625977	740.991943359375	577.665588378906	8.62208557128906	853.572326660156	68.215087890625	68.267936706543	68.1205825805664	753.619506835938	552.80322265625	959.702514648438\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	68.2135696411133	68.2718505859375	68.1862869262695	737.987854003906	554.953186035156	8.34683418273928	822.051879882813	68.215087890625	68.267936706543	68.1205825805664	753.619506835938	552.80322265625	959.702514648438\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	68.2155914306641	68.2730331420898	68.1896667480469	706.789672851563	533.94140625	7.80853652954103	783.814575195313	68.215087890625	68.267936706543	68.1205825805664	753.619506835938	552.80322265625	959.702514648438\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	68.2172164916992	68.2727661132813	68.190071105957	701.953186035156	531.45849609375	7.8408203125	782.36474609375	68.215087890625	68.267936706543	68.1205825805664	753.619506835938	552.80322265625	959.702514648438\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	68.2380599975586	68.2727661132813	68.2082061767578	677.802062988281	530.872436523438	7.8727684020996	782.801025390625	68.215087890625	68.267936706543	68.1205825805664	753.619506835938	552.80322265625	959.702514648438\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	68.2275543212891	68.2725982666016	68.1880493164063	671.098449707031	525.819763183594	7.78232192993163	774.854064941406	68.215087890625	68.267936706543	68.1205825805664	753.619506835938	552.80322265625	959.702514648438\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	68.2462921142578	68.2733535766602	68.2286834716797	663.068115234375	523.812866210938	7.49624061584472	763.692565917969	68.215087890625	68.267936706543	68.1205825805664	753.619506835938	552.80322265625	959.702514648438\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	68.2419128417969	68.2733001708984	68.2199630737305	650.253723144531	513.856750488281	7.34146118164063	748.783508300781	68.215087890625	68.267936706543	68.1205825805664	753.619506835938	552.80322265625	959.702514648438\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	68.2432098388672	68.2733001708984	68.2229461669922	620.941772460938	485.013977050781	7.24344253540041	716.804138183594	68.215087890625	68.267936706543	68.1205825805664	753.619506835938	552.80322265625	959.702514648438\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	68.2425918579102	68.2733001708984	68.2219543457031	589.44580078125	460.841583251953	6.84270000457765	679.807983398438	68.215087890625	68.267936706543	68.1205825805664	753.619506835938	552.80322265625	959.702514648438\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	68.2744674682617	68.2746887207031	68.2742614746094	37.370979309082	39.4395027160645	-0.0967854261398344	36.3423690795898	68.2743835449219	68.2746887207031	68.2739028930664	23.5855312347412	24.4647102355957	22.9257106781006\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	68.2743988037109	68.2746887207031	68.2742614746094	36.3325462341309	37.9408874511719	-0.0725293159484877	35.6199493408203	68.2743835449219	68.2746887207031	68.2739028930664	23.5855312347412	24.4647102355957	22.9257106781006\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	68.2745056152344	68.2746887207031	68.2744216918945	35.8808250427246	37.2242431640625	-0.0603684186935438	35.2924537658691	68.2743835449219	68.2746887207031	68.2739028930664	23.5855312347412	24.4647102355957	22.9257106781006\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	68.2745132446289	68.2746887207031	68.2744216918945	30.6766986846924	31.5970439910889	-0.0413610935211187	30.2734889984131	68.2743835449219	68.2746887207031	68.2739028930664	23.5855312347412	24.4647102355957	22.9257106781006\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	68.2745590209961	68.2746887207031	68.2744216918945	14.2007846832275	15.1434421539307	-0.0428273379802719	13.772967338562	68.2743835449219	68.2746887207031	68.2739028930664	23.5855312347412	24.4647102355957	22.9257106781006\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	68.2745590209961	68.2746887207031	68.2744216918945	14.1234350204468	15.0569677352905	-0.0424125194549563	13.6997671127319	68.2743835449219	68.2746887207031	68.2739028930664	23.5855312347412	24.4647102355957	22.9257106781006\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	68.2746658325195	68.2746887207031	68.2746353149414	25.6911926269531	26.3327693939209	-0.0261943340301499	25.4945507049561	68.2743835449219	68.2746887207031	68.2739028930664	23.5855312347412	24.4647102355957	22.9257106781006\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	68.2746658325195	68.2746887207031	68.2746353149414	25.5466632843018	26.1753559112549	-0.0256661176681532	25.354040145874	68.2743835449219	68.2746887207031	68.2739028930664	23.5855312347412	24.4647102355957	22.9257106781006\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	68.274658203125	68.2746887207031	68.2746353149414	23.4575748443604	23.7628593444824	-0.0110175013542156	23.4102993011475	68.2743835449219	68.2746887207031	68.2739028930664	23.5855312347412	24.4647102355957	22.9257106781006\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	68.2746353149414	68.2746887207031	68.2745819091797	23.4808864593506	23.8785133361816	-0.0160766243934626	23.3640613555908	68.2743835449219	68.2746887207031	68.2739028930664	23.5855312347412	24.4647102355957	22.9257106781006\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	68.2734375	68.2746887207031	68.2725982666016	68.7092971801758	84.6327056884766	-0.758813858032228	60.3506622314453	68.2730560302734	68.2746810913086	68.2710876464844	48.115550994873	54.2702255249023	46.2623672485352\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	68.2732543945313	68.2746887207031	68.2725982666016	64.2137069702148	77.620491027832	-0.605503678321838	58.2443733215332	68.2730560302734	68.2746810913086	68.2710876464844	48.115550994873	54.2702255249023	46.2623672485352\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	68.2734680175781	68.2746887207031	68.2729187011719	62.4726753234863	74.9244232177734	-0.560332894325256	56.9937705993652	68.2730560302734	68.2746810913086	68.2710876464844	48.115550994873	54.2702255249023	46.2623672485352\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	68.2734832763672	68.2746887207031	68.2729187011719	56.0989875793457	66.3835372924805	-0.465668439865112	51.4821472167969	68.2730560302734	68.2746810913086	68.2710876464844	48.115550994873	54.2702255249023	46.2623672485352\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	68.2737045288086	68.2746887207031	68.2729797363281	41.2425842285156	51.6803817749023	-0.497768163681028	35.7518005371094	68.2730560302734	68.2746810913086	68.2710876464844	48.115550994873	54.2702255249023	46.2623672485352\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	68.2736587524414	68.2746887207031	68.2729187011719	40.8496437072754	51.2415924072266	-0.496285676956178	35.3604507446289	68.2730560302734	68.2746810913086	68.2710876464844	48.115550994873	54.2702255249023	46.2623672485352\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	68.27392578125	68.2746887207031	68.2733535766602	51.13427734375	60.3522338867188	-0.43983590602875	46.2774848937988	68.2730560302734	68.2746810913086	68.2710876464844	48.115550994873	54.2702255249023	46.2623672485352\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	68.2738418579102	68.2746887207031	68.273193359375	50.4901008605957	59.5221519470215	-0.43092131614685	45.7326698303223	68.2730560302734	68.2746810913086	68.2710876464844	48.115550994873	54.2702255249023	46.2623672485352\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	68.2738342285156	68.2746887207031	68.273193359375	45.5518798828125	52.0913925170898	-0.308922886848447	42.2058601379395	68.2730560302734	68.2746810913086	68.2710876464844	48.115550994873	54.2702255249023	46.2623672485352\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	68.2738342285156	68.2746887207031	68.273193359375	45.1492347717285	52.2510070800781	-0.336431503295897	41.4851989746094	68.2730560302734	68.2746810913086	68.2710876464844	48.115550994873	54.2702255249023	46.2623672485352\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	68.2595367431641	68.2739410400391	68.2470703125	237.280044555664	256.406951904297	-0.878478527069094	228.295639038086	68.2588348388672	68.2736740112305	68.2578353881836	201.414138793945	199.965209960938	216.634094238281\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	68.2555465698242	68.2739410400391	68.2468643188477	224.191635131836	239.163955688477	-0.672267436981218	217.651397705078	68.2588348388672	68.2736740112305	68.2578353881836	201.414138793945	199.965209960938	216.634094238281\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	68.2574996948242	68.2743682861328	68.2498931884766	215.875106811523	230.227020263672	-0.644480705261218	209.603637695313	68.2588348388672	68.2736740112305	68.2578353881836	201.414138793945	199.965209960938	216.634094238281\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	68.2576217651367	68.2742614746094	68.2494277954102	208.039611816406	219.445816040039	-0.510429859161375	203.112060546875	68.2588348388672	68.2736740112305	68.2578353881836	201.414138793945	199.965209960938	216.634094238281\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	68.2614593505859	68.2742614746094	68.2495880126953	197.813842773438	211.002212524414	-0.59006452560425	192.120147705078	68.2588348388672	68.2736740112305	68.2578353881836	201.414138793945	199.965209960938	216.634094238281\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	68.2610931396484	68.2742080688477	68.2490158081055	195.879089355469	209.021850585938	-0.58935546875	190.162475585938	68.2588348388672	68.2736740112305	68.2578353881836	201.414138793945	199.965209960938	216.634094238281\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	68.2707214355469	68.2746887207031	68.2675933837891	201.2822265625	212.862426757813	-0.511642932891875	196.489852905273	68.2588348388672	68.2736740112305	68.2578353881836	201.414138793945	199.965209960938	216.634094238281\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	68.2705993652344	68.2745819091797	68.2674331665039	197.662124633789	209.011291503906	-0.501400947570781	192.966461181641	68.2588348388672	68.2736740112305	68.2578353881836	201.414138793945	199.965209960938	216.634094238281\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	68.2705612182617	68.2745819091797	68.2674331665039	181.010833740234	186.202545166016	-0.193699359893812	180.004165649414	68.2588348388672	68.2736740112305	68.2578353881836	201.414138793945	199.965209960938	216.634094238281\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	68.2705459594727	68.2746887207031	68.2673797607422	175.828353881836	183.73371887207	-0.333756446838375	173.053512573242	68.2588348388672	68.2736740112305	68.2578353881836	201.414138793945	199.965209960938	216.634094238281\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	67.2755355834961	68.2587966918945	66.4071578979492	1493.56652832031	977.855895996094	27.0489749908449	1843.42309570313	67.4012908935547	68.2517700195313	65.2917327880859	1643.11682128906	1019.36285400391	2260.1181640625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	66.863037109375	68.2614898681641	66.2021102905273	1523.01013183594	950.837646484375	26.0770225524902	1785.30236816406	67.4012908935547	68.2517700195313	65.2917327880859	1643.11682128906	1019.36285400391	2260.1181640625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	67.4385070800781	68.2680892944336	67.0646133422852	1461.74853515625	913.793029785156	24.7500133514404	1705.79345703125	67.4012908935547	68.2517700195313	65.2917327880859	1643.11682128906	1019.36285400391	2260.1181640625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	67.3536758422852	68.2509155273438	66.9115600585938	1462.51843261719	924.023376464844	24.7474956512452	1715.94323730469	67.4012908935547	68.2517700195313	65.2917327880859	1643.11682128906	1019.36285400391	2260.1181640625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	67.5875091552734	68.2509460449219	66.9710083007813	1400.40393066406	932.333923339844	24.9423923492433	1730.49047851563	67.4012908935547	68.2517700195313	65.2917327880859	1643.11682128906	1019.36285400391	2260.1181640625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	67.3616790771484	68.2534713745117	66.5328903198242	1384.08947753906	923.374084472656	24.5317821502686	1708.39111328125	67.4012908935547	68.2517700195313	65.2917327880859	1643.11682128906	1019.36285400391	2260.1181640625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	67.8166351318359	68.2692031860352	67.3941345214844	1359.87646484375	914.477478027344	23.8643321990968	1678.13610839844	67.4012908935547	68.2517700195313	65.2917327880859	1643.11682128906	1019.36285400391	2260.1181640625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	67.7283325195313	68.2688903808594	67.2224502563477	1332.24841308594	896.844787597656	23.3202762603761	1643.09362792969	67.4012908935547	68.2517700195313	65.2917327880859	1643.11682128906	1019.36285400391	2260.1181640625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	67.7479705810547	68.2688369750977	67.2773895263672	1285.68640136719	875.4609375	21.7798957824706	1572.41760253906	67.4012908935547	68.2517700195313	65.2917327880859	1643.11682128906	1019.36285400391	2260.1181640625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	67.7275390625	68.2692031860352	67.240592956543	1219.81616210938	811.425537109375	21.6320152282714	1503.65002441406	67.4012908935547	68.2517700195313	65.2917327880859	1643.11682128906	1019.36285400391	2260.1181640625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	51.9985618591309	68.221305847168	38.2080993652344	2314.16088867188	1419.8076171875	46.8797912597656	2919.9609375	61.1804351806641	68.2112731933594	48.0096855163574	2608.06689453125	1528.18823242188	3645.83837890625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	48.456184387207	68.2048492431641	39.1994819641113	2382.31079101563	1390.03967285156	45.2274742126466	2837.31884765625	61.1804351806641	68.2112731933594	48.0096855163574	2608.06689453125	1528.18823242188	3645.83837890625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	49.4077606201172	68.2393341064453	40.9190979003906	2285.8466796875	1333.65710449219	43.0024909973144	2709.73681640625	61.1804351806641	68.2112731933594	48.0096855163574	2608.06689453125	1528.18823242188	3645.83837890625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	53.831657409668	68.2359313964844	46.7987022399902	2302.818359375	1359.76977539063	43.3306274414062	2746.34985351563	61.1804351806641	68.2112731933594	48.0096855163574	2608.06689453125	1528.18823242188	3645.83837890625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	59.0680198669434	68.2356338500977	51.3122787475586	2198.74926757813	1378.35583496094	43.6811790466309	2776.15356445313	61.1804351806641	68.2112731933594	48.0096855163574	2608.06689453125	1528.18823242188	3645.83837890625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	53.934024810791	68.1955184936523	42.089973449707	2156.92651367188	1364.85009765625	42.2318038940431	2716.26782226563	61.1804351806641	68.2112731933594	48.0096855163574	2608.06689453125	1528.18823242188	3645.83837890625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	62.3481369018555	68.2450180053711	57.1425857543945	2144.521484375	1346.6982421875	42.6919021606447	2712.83911132813	61.1804351806641	68.2112731933594	48.0096855163574	2608.06689453125	1528.18823242188	3645.83837890625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	61.9898414611816	68.2415542602539	56.6377220153809	2099.72192382813	1320.55236816406	41.7179374694825	2655.5263671875	61.1804351806641	68.2112731933594	48.0096855163574	2608.06689453125	1528.18823242188	3645.83837890625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	61.9309921264648	68.239616394043	56.5972366333008	2011.54675292969	1290.68737792969	38.4176750183106	2520.05297851563	61.1804351806641	68.2112731933594	48.0096855163574	2608.06689453125	1528.18823242188	3645.83837890625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	62.5974159240723	68.2433547973633	57.9802589416504	1908.56652832031	1199.5595703125	37.3694076538087	2395.38061523438	61.1804351806641	68.2112731933594	48.0096855163574	2608.06689453125	1528.18823242188	3645.83837890625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	48.7685089111328	67.5406799316406	36.397029876709	3190.048828125	1891.44970703125	67.9991989135744	4067.42407226563	51.2286987304688	67.7742309570313	32.8966102600098	3599.35717773438	2050.61376953125	5209.96728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	39.647590637207	67.3477020263672	27.0872364044189	3303.77880859375	1857.205078125	65.9553680419922	3967.77685546875	51.2286987304688	67.7742309570313	32.8966102600098	3599.35717773438	2050.61376953125	5209.96728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	48.5536155700684	67.903678894043	40.0633964538574	3163.82836914063	1785.35021972656	62.2676734924319	3777.91577148438	51.2286987304688	67.7742309570313	32.8966102600098	3599.35717773438	2050.61376953125	5209.96728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	46.7665748596191	67.7223739624023	37.2132606506348	3195.77514648438	1826.76953125	62.9542083740234	3841.30419921875	51.2286987304688	67.7742309570313	32.8966102600098	3599.35717773438	2050.61376953125	5209.96728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	45.0911254882813	67.725471496582	31.5336990356445	3029.9619140625	1855.79711914063	62.6574096679687	3860.83422851563	51.2286987304688	67.7742309570313	32.8966102600098	3599.35717773438	2050.61376953125	5209.96728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	45.3330726623535	67.363883972168	32.5561561584473	2984.15747070313	1834.72351074219	61.4692649841309	3801.73999023438	51.2286987304688	67.7742309570313	32.8966102600098	3599.35717773438	2050.61376953125	5209.96728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	48.6382293701172	67.9438247680664	33.6250610351563	2929.62255859375	1811.15246582031	59.6654472351075	3720.44677734375	51.2286987304688	67.7742309570313	32.8966102600098	3599.35717773438	2050.61376953125	5209.96728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	48.0883827209473	67.8694152832031	33.6975555419922	2863.85327148438	1775.21545410156	58.2568244934084	3639.43383789063	51.2286987304688	67.7742309570313	32.8966102600098	3599.35717773438	2050.61376953125	5209.96728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	47.8298683166504	67.8494415283203	33.3740653991699	2792.65795898438	1712.85766601563	58.0721282958984	3571.16577148438	51.2286987304688	67.7742309570313	32.8966102600098	3599.35717773438	2050.61376953125	5209.96728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Freeflow	70	Freeflow	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	48.8114166259766	67.9045028686523	34.0227584838867	2603.6123046875	1616.39392089844	52.1986427307128	3286.75048828125	51.2286987304688	67.7742309570313	32.8966102600098	3599.35717773438	2050.61376953125	5209.96728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	34.9780883789063	35.2738265991211	34.757438659668	1182.94763183594	816.223022460938	19.2611465454101	1432.57971191406	35.0477676391602	35.2738494873047	34.6689300537109	1240.85131835938	805.304138183594	1679.57312011719\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	34.8512535095215	35.2739410400391	34.654613494873	1197.62573242188	792.583190917969	18.4626712799072	1383.388671875	35.0477676391602	35.2738494873047	34.6689300537109	1240.85131835938	805.304138183594	1679.57312011719\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	34.8835525512695	35.2739410400391	34.707763671875	1157.02062988281	769.606994628906	17.4995822906495	1329.59362792969	35.0477676391602	35.2738494873047	34.6689300537109	1240.85131835938	805.304138183594	1679.57312011719\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	34.9574089050293	35.2739410400391	34.8048362731934	1153.74060058594	775.323120117188	17.394027709961	1331.93200683594	35.0477676391602	35.2738494873047	34.6689300537109	1240.85131835938	805.304138183594	1679.57312011719\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	34.9614715576172	35.2739410400391	34.695987701416	1081.63366699219	750.327209472656	17.6581439971923	1315.38781738281	35.0477676391602	35.2738494873047	34.6689300537109	1240.85131835938	805.304138183594	1679.57312011719\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	34.9141540527344	35.2739410400391	34.6250343322754	1070.84790039063	743.170471191406	17.4727191925048	1302.29748535156	35.0477676391602	35.2738494873047	34.6689300537109	1240.85131835938	805.304138183594	1679.57312011719\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	35.0643692016602	35.2739410400391	34.884334564209	1052.708984375	738.637145996094	16.8467464447021	1277.73303222656	35.0477676391602	35.2738494873047	34.6689300537109	1240.85131835938	805.304138183594	1679.57312011719\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	35.0403594970703	35.2739410400391	34.835865020752	1033.21728515625	724.451843261719	16.5738735198975	1254.81579589844	35.0477676391602	35.2738494873047	34.6689300537109	1240.85131835938	805.304138183594	1679.57312011719\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	35.0624351501465	35.2739410400391	34.8863372802734	984.756896972656	686.681213378906	15.8610820770264	1194.23583984375	35.0477676391602	35.2738494873047	34.6689300537109	1240.85131835938	805.304138183594	1679.57312011719\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	35.1080055236816	35.2739410400391	34.9519844055176	927.759765625	643.439086914063	15.0839233398437	1126.12463378906	35.0477676391602	35.2738494873047	34.6689300537109	1240.85131835938	805.304138183594	1679.57312011719\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	35.2738838195801	35.2739410400391	35.2738265991211	260.160675048828	227.739639282227	1.75226354598997	283.812072753906	35.2729110717773	35.2739181518555	35.2710418701172	236.877243041992	188.065567016602	291.398834228516\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	35.2111701965332	35.2739410400391	35.1812515258789	260.805328369141	222.541534423828	1.74871158599853	278.500305175781	35.2729110717773	35.2739181518555	35.2710418701172	236.877243041992	188.065567016602	291.398834228516\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	35.2739410400391	35.2739410400391	35.2739410400391	259.189849853516	224.188049316406	1.58288097381594	274.840240478516	35.2729110717773	35.2739181518555	35.2710418701172	236.877243041992	188.065567016602	291.398834228516\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	35.2739410400391	35.2739410400391	35.2739410400391	252.727264404297	216.42219543457	1.67362451553347	269.978179931641	35.2729110717773	35.2739181518555	35.2710418701172	236.877243041992	188.065567016602	291.398834228516\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	35.2739410400391	35.2739410400391	35.2739410400391	205.643646240234	171.68196105957	1.85648250579834	231.089401245117	35.2729110717773	35.2739181518555	35.2710418701172	236.877243041992	188.065567016602	291.398834228516\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	35.2739410400391	35.2739410400391	35.2739410400391	203.724319458008	170.089080810547	1.83863973617553	228.925552368164	35.2729110717773	35.2739181518555	35.2710418701172	236.877243041992	188.065567016602	291.398834228516\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	35.2739410400391	35.2739410400391	35.2739410400391	208.952117919922	176.122863769531	1.79870033264163	233.681274414063	35.2729110717773	35.2739181518555	35.2710418701172	236.877243041992	188.065567016602	291.398834228516\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	35.2739410400391	35.2739410400391	35.2739410400391	205.194931030273	173.008071899414	1.76349449157716	229.439895629883	35.2729110717773	35.2739181518555	35.2710418701172	236.877243041992	188.065567016602	291.398834228516\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	35.2739410400391	35.2739410400391	35.2739410400391	188.540252685547	154.661926269531	1.84000110626222	213.541961669922	35.2729110717773	35.2739181518555	35.2710418701172	236.877243041992	188.065567016602	291.398834228516\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	35.2739410400391	35.2739410400391	35.2739410400391	179.898864746094	149.75114440918	1.63437032699584	202.050994873047	35.2729110717773	35.2739181518555	35.2710418701172	236.877243041992	188.065567016602	291.398834228516\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	35.2185401916504	35.2739410400391	35.1662902832031	418.569274902344	331.493560791016	4.66011619567869	480.617279052734	35.2522201538086	35.2739181518555	35.1851692199707	405.572631835938	293.324554443359	526.892028808594\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	35.2111625671387	35.2739410400391	35.1812400817871	422.641479492188	321.986236572266	4.59403896331787	468.995483398438	35.2522201538086	35.2739181518555	35.1851692199707	405.572631835938	293.324554443359	526.892028808594\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	35.2620964050293	35.2739410400391	35.2567405700684	414.606323242188	320.343353271484	4.25963401794435	456.651641845703	35.2522201538086	35.2739181518555	35.1851692199707	405.572631835938	293.324554443359	526.892028808594\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	35.2061500549316	35.2739410400391	35.1723213195801	405.8935546875	313.448303222656	4.25750637054444	449.688507080078	35.2522201538086	35.2739181518555	35.1851692199707	405.572631835938	293.324554443359	526.892028808594\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	35.1767349243164	35.2739410400391	35.0789108276367	353.070068359375	270.224517822266	4.48046112060547	413.599273681641	35.2522201538086	35.2739181518555	35.1851692199707	405.572631835938	293.324554443359	526.892028808594\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	35.2267456054688	35.2739410400391	35.1792488098145	349.534637451172	267.684051513672	4.42493915557863	409.282104492188	35.2522201538086	35.2739181518555	35.1851692199707	405.572631835938	293.324554443359	526.892028808594\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	35.2282409667969	35.2739410400391	35.1813888549805	349.014862060547	271.296203613281	4.21504211425781	406.177551269531	35.2522201538086	35.2739181518555	35.1851692199707	405.572631835938	293.324554443359	526.892028808594\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	35.2278900146484	35.2739410400391	35.1806716918945	342.513031005859	266.307342529297	4.13306617736816	398.565460205078	35.2522201538086	35.2739181518555	35.1851692199707	405.572631835938	293.324554443359	526.892028808594\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	35.2738494873047	35.2739410400391	35.2737579345703	322.386901855469	244.80876159668	4.15445375442503	377.751281738281	35.2522201538086	35.2739181518555	35.1851692199707	405.572631835938	293.324554443359	526.892028808594\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	35.2739410400391	35.2739410400391	35.2739410400391	304.804321289063	233.906753540039	3.79480028152466	355.340362548828	35.2522201538086	35.2739181518555	35.1851692199707	405.572631835938	293.324554443359	526.892028808594\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	35.1485710144043	35.2739410400391	35.060245513916	689.426513671875	529.836853027344	8.46718406677247	800.786743164063	35.1918106079102	35.273868560791	35.0746879577637	690.8359375	482.785278320313	914.577331542969\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	35.0649490356445	35.2739410400391	34.9657592773438	692.455017089844	508.932830810547	8.37337017059325	776.880676269531	35.1918106079102	35.273868560791	35.0746879577637	690.8359375	482.785278320313	914.577331542969\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	35.2188568115234	35.2739410400391	35.194881439209	671.821655273438	498.017028808594	7.85418128967284	749.350830078125	35.1918106079102	35.273868560791	35.0746879577637	690.8359375	482.785278320313	914.577331542969\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	35.1971092224121	35.2739410400391	35.1587715148926	664.837585449219	496.853637695313	7.74036788940428	744.54541015625	35.1918106079102	35.273868560791	35.0746879577637	690.8359375	482.785278320313	914.577331542969\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	35.217414855957	35.2739410400391	35.1605758666992	607.44775390625	460.168518066406	7.99597930908203	716.039855957031	35.1918106079102	35.273868560791	35.0746879577637	690.8359375	482.785278320313	914.577331542969\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	35.2202224731445	35.2739410400391	35.1994171142578	601.658264160156	455.798583984375	7.91631126403809	709.120544433594	35.1918106079102	35.273868560791	35.0746879577637	690.8359375	482.785278320313	914.577331542969\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	35.2063865661621	35.2739410400391	35.1371231079102	594.302551269531	455.515563964844	7.59720802307128	698.626220703125	35.1918106079102	35.273868560791	35.0746879577637	690.8359375	482.785278320313	914.577331542969\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	35.2425270080566	35.2739410400391	35.2103157043457	581.979858398438	447.209747314453	7.3652219772339	682.896850585938	35.1918106079102	35.273868560791	35.0746879577637	690.8359375	482.785278320313	914.577331542969\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	35.179615020752	35.2739410400391	35.0859260559082	549.733520507813	415.104949951172	7.26653385162353	647.634033203125	35.1918106079102	35.273868560791	35.0746879577637	690.8359375	482.785278320313	914.577331542969\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	35.2277946472168	35.2739410400391	35.1829261779785	519.991638183594	394.182647705078	6.79119396209719	611.500854492188	35.1918106079102	35.273868560791	35.0746879577637	690.8359375	482.785278320313	914.577331542969\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	34.1963424682617	35.1801948547363	33.4471778869629	1823.33447265625	1171.21020507813	34.2023391723631	2265.68505859375	34.5602989196777	35.2707710266113	33.4284591674805	1983.45483398438	1199.50756835938	2760.34716796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	33.9094696044922	35.2739410400391	33.2777976989746	1860.65991210938	1135.42236328125	33.0523986816406	2193.09912109375	34.5602989196777	35.2707710266113	33.4284591674805	1983.45483398438	1199.50756835938	2760.34716796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	34.3173980712891	35.2739410400391	33.8893775939941	1795.94250488281	1099.15246582031	31.4712791442872	2106.2333984375	34.5602989196777	35.2707710266113	33.4284591674805	1983.45483398438	1199.50756835938	2760.34716796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	34.3127708435059	35.2739410400391	33.8428611755371	1796.23681640625	1114.69116210938	31.3212890625	2116.97241210938	34.5602989196777	35.2707710266113	33.4284591674805	1983.45483398438	1199.50756835938	2760.34716796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	34.454402923584	35.2739410400391	33.7302322387695	1691.85302734375	1099.92150878906	31.5054893493653	2108.09716796875	34.5602989196777	35.2707710266113	33.4284591674805	1983.45483398438	1199.50756835938	2760.34716796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	34.3784980773926	35.1797370910645	33.7064895629883	1678.69409179688	1089.8671875	31.3565368652344	2093.2763671875	34.5602989196777	35.2707710266113	33.4284591674805	1983.45483398438	1199.50756835938	2760.34716796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	34.6891555786133	35.2739410400391	34.1563987731934	1647.630859375	1078.51196289063	30.4934768676756	2054.30322265625	34.5602989196777	35.2707710266113	33.4284591674805	1983.45483398438	1199.50756835938	2760.34716796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	34.688232421875	35.2739410400391	34.1551742553711	1616.36059570313	1057.65026855469	29.9436111450194	2015.84582519531	34.5602989196777	35.2707710266113	33.4284591674805	1983.45483398438	1199.50756835938	2760.34716796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	34.6566505432129	35.2739410400391	34.1442680358887	1549.40063476563	1021.70849609375	28.0699806213378	1919.94787597656	34.5602989196777	35.2707710266113	33.4284591674805	1983.45483398438	1199.50756835938	2760.34716796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	34.8314895629883	35.2739410400391	34.4323654174805	1457.56433105469	946.408264160156	27.0774097442626	1812.88537597656	34.5602989196777	35.2707710266113	33.4284591674805	1983.45483398438	1199.50756835938	2760.34716796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	31.8927593231201	35.1816635131836	29.3618412017822	2497.43041992188	1564.27844238281	48.5322303771975	3117.30981445313	33.0161514282227	35.2358055114746	29.8124122619629	2791.25146484375	1643.21826171875	3874.03540039063\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	31.4784965515137	35.0735130310059	29.8174800872803	2566.79711914063	1528.16906738281	47.3059577941897	3041.95971679688	33.0161514282227	35.2358055114746	29.8124122619629	2791.25146484375	1643.21826171875	3874.03540039063\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	31.9129810333252	35.1771850585938	30.4507999420166	2477.30834960938	1473.62548828125	45.31990814209	2923.86254882813	33.0161514282227	35.2358055114746	29.8124122619629	2791.25146484375	1643.21826171875	3874.03540039063\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	32.1297874450684	35.172290802002	30.6648826599121	2495.40161132813	1502.265625	45.6072006225588	2961.69604492188	33.0161514282227	35.2358055114746	29.8124122619629	2791.25146484375	1643.21826171875	3874.03540039063\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	32.4955024719238	35.0860023498535	30.4067821502686	2355.19653320313	1500.86560058594	45.1575813293456	2945.908203125	33.0161514282227	35.2358055114746	29.8124122619629	2791.25146484375	1643.21826171875	3874.03540039063\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	31.9265556335449	35.1210021972656	29.2136249542236	2315.6259765625	1485.69287109375	43.5391998291016	2878.947265625	33.0161514282227	35.2358055114746	29.8124122619629	2791.25146484375	1643.21826171875	3874.03540039063\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	33.2837600708008	35.2734184265137	31.5563621520996	2308.39501953125	1463.78491210938	44.8350677490234	2898.50708007813	33.0161514282227	35.2358055114746	29.8124122619629	2791.25146484375	1643.21826171875	3874.03540039063\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	33.1020126342773	35.1724624633789	31.3246021270752	2258.91625976563	1435.84216308594	43.6331672668456	2832.103515625	33.0161514282227	35.2358055114746	29.8124122619629	2791.25146484375	1643.21826171875	3874.03540039063\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	33.1635818481445	35.1717109680176	31.4065685272217	2157.865234375	1392.85717773438	40.2415161132813	2680.58569335938	33.0161514282227	35.2358055114746	29.8124122619629	2791.25146484375	1643.21826171875	3874.03540039063\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	33.3189392089844	35.1799621582031	31.7183628082275	2042.07763671875	1292.08740234375	39.4045791625978	2553.03393554688	33.0161514282227	35.2358055114746	29.8124122619629	2791.25146484375	1643.21826171875	3874.03540039063\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	28.6808471679688	34.9300537109375	24.8745250701904	3280.65771484375	1991.109375	67.7898559570312	4160.384765625	30.4711055755615	35.0928268432617	24.9907341003418	3686.44091796875	2117.17944335938	5266.4912109375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	27.4825019836426	34.7400932312012	24.1301918029785	3382.86157226563	1949.16479492188	65.3388671875	4040.00854492188	30.4711055755615	35.0928268432617	24.9907341003418	3686.44091796875	2117.17944335938	5266.4912109375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	29.4763698577881	35.1580848693848	26.9499263763428	3266.49731445313	1884.77893066406	62.4110069274903	3881.93115234375	30.4711055755615	35.0928268432617	24.9907341003418	3686.44091796875	2117.17944335938	5266.4912109375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	28.5413951873779	35.1838798522949	25.3890132904053	3276.15869140625	1926.900390625	62.0036697387697	3911.01782226563	30.4711055755615	35.0928268432617	24.9907341003418	3686.44091796875	2117.17944335938	5266.4912109375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	29.2993335723877	35.0537452697754	25.2196559906006	3104.05639648438	1935.39721679688	62.2882385253906	3928.62084960938	30.4711055755615	35.0928268432617	24.9907341003418	3686.44091796875	2117.17944335938	5266.4912109375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	29.4530353546143	35.0608863830566	25.8560619354248	3071.48168945313	1917.7294921875	61.5456848144531	3887.19140625	30.4711055755615	35.0928268432617	24.9907341003418	3686.44091796875	2117.17944335938	5266.4912109375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	30.3857326507568	35.137149810791	26.7217998504639	3015.78491210938	1892.34521484375	59.9338989257813	3810.22998046875	30.4711055755615	35.0928268432617	24.9907341003418	3686.44091796875	2117.17944335938	5266.4912109375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	30.2696151733398	35.1752014160156	26.5928821563721	2950.41821289063	1854.77612304688	58.5904006958006	3729.6689453125	30.4711055755615	35.0928268432617	24.9907341003418	3686.44091796875	2117.17944335938	5266.4912109375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	30.3805866241455	35.1232795715332	26.8974056243896	2843.36791992188	1786.28161621094	56.4005622863769	3591.099609375	30.4711055755615	35.0928268432617	24.9907341003418	3686.44091796875	2117.17944335938	5266.4912109375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/Satur.	70	Saturated	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	30.5965538024902	35.0666923522949	27.2254810333252	2669.3583984375	1667.69274902344	52.9528923034669	3362.18530273438	30.4711055755615	35.0928268432617	24.9907341003418	3686.44091796875	2117.17944335938	5266.4912109375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	14.0443658828735	14.1495780944824	14.0060443878174	2106.24829101563	1545.62243652344	29.1276588439941	2477.70751953125	13.969199180603	14.1264181137085	13.8099956512451	2208.51684570313	1485.68237304688	2881.98559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	13.9192066192627	14.0844106674194	13.8455858230591	2136.79541015625	1531.89660644531	27.5446815490725	2413.32641601563	13.969199180603	14.1264181137085	13.8099956512451	2208.51684570313	1485.68237304688	2881.98559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	14.0170221328735	14.211480140686	13.931568145752	2099.7099609375	1493.984375	27.3489913940431	2369.15209960938	13.969199180603	14.1264181137085	13.8099956512451	2208.51684570313	1485.68237304688	2881.98559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	13.9068403244019	14.2031698226929	13.7730512619019	2099.55078125	1488.15625	28.0608901977541	2386.10473632813	13.969199180603	14.1264181137085	13.8099956512451	2208.51684570313	1485.68237304688	2881.98559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	13.9693593978882	14.2035732269287	13.8047657012939	1935.00598144531	1390.46496582031	28.8822441101075	2314.69677734375	13.969199180603	14.1264181137085	13.8099956512451	2208.51684570313	1485.68237304688	2881.98559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	13.9212036132813	14.0216417312622	13.8752021789551	1915.82348632813	1383.79504394531	28.0275154113772	2280.67553710938	13.969199180603	14.1264181137085	13.8099956512451	2208.51684570313	1485.68237304688	2881.98559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	13.9335126876831	14.0923662185669	13.8203058242798	1888.78869628906	1362.88159179688	27.9394607543944	2256.9443359375	13.969199180603	14.1264181137085	13.8099956512451	2208.51684570313	1485.68237304688	2881.98559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	14.0240726470947	14.0797910690308	13.9317312240601	1842.04284667969	1337.197265625	26.9011917114259	2198.03540039063	13.969199180603	14.1264181137085	13.8099956512451	2208.51684570313	1485.68237304688	2881.98559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	14.0651397705078	14.1026468276978	14.0380716323853	1748.75146484375	1248.29968261719	26.3546409606934	2091.64819335938	13.969199180603	14.1264181137085	13.8099956512451	2208.51684570313	1485.68237304688	2881.98559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	13.9869089126587	14.1139526367188	13.8436784744263	1651.48327636719	1177.18139648438	24.9963111877441	1977.06335449219	13.969199180603	14.1264181137085	13.8099956512451	2208.51684570313	1485.68237304688	2881.98559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	14.1907024383545	14.1874628067017	14.2021265029907	1027.88757324219	883.879577636719	7.51373863220222	1124.31921386719	14.1944913864136	14.2077951431274	14.1933212280273	941.720336914063	736.1923828125	1144.48559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	14.1992473602295	14.1877336502075	14.2059478759766	1032.52661132813	867.193054199219	7.53248023986816	1108.232421875	14.1944913864136	14.2077951431274	14.1933212280273	941.720336914063	736.1923828125	1144.48559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	14.2070550918579	14.2080888748169	14.2071342468262	1015.92315673828	857.558227539063	7.15269088745116	1086.4443359375	14.1944913864136	14.2077951431274	14.1933212280273	941.720336914063	736.1923828125	1144.48559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	14.1461448669434	14.1856126785278	14.1264429092407	1007.00201416016	843.753234863281	7.50372505187997	1083.87243652344	14.1944913864136	14.2077951431274	14.1933212280273	941.720336914063	736.1923828125	1144.48559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	14.1499547958374	14.2079210281372	14.1039266586304	866.307861328125	717.684631347656	7.89536094665528	970.336181640625	14.1944913864136	14.2077951431274	14.1933212280273	941.720336914063	736.1923828125	1144.48559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	14.1610774993896	14.2080888748169	14.1255283355713	855.889892578125	711.204223632813	7.68037223815916	956.976135253906	14.1944913864136	14.2077951431274	14.1933212280273	941.720336914063	736.1923828125	1144.48559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	14.1715955734253	14.2080888748169	14.1377954483032	841.325256347656	698.1767578125	7.63673782348634	942.552368164063	14.1944913864136	14.2077951431274	14.1933212280273	941.720336914063	736.1923828125	1144.48559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	14.1833343505859	14.2080888748169	14.1615133285522	824.838806152344	681.440734863281	7.63360977172853	925.716247558594	14.1944913864136	14.2077951431274	14.1933212280273	941.720336914063	736.1923828125	1144.48559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	14.1834983825684	14.2080888748169	14.162428855896	752.618041992188	612.584167480469	7.43314361572266	850.444763183594	14.1944913864136	14.2077951431274	14.1933212280273	941.720336914063	736.1923828125	1144.48559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	14.1887922286987	14.2080888748169	14.1736364364624	723.565551757813	594.691467285156	6.8259563446045	813.1220703125	14.1944913864136	14.2077951431274	14.1933212280273	941.720336914063	736.1923828125	1144.48559570313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	14.0744428634644	14.1634731292725	13.9793081283569	1316.62707519531	1060.05859375	13.4390335083009	1490.10766601563	14.1743965148926	14.1897602081299	14.1138381958008	1274.15771484375	932.235046386719	1598.13159179688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	14.0635604858398	14.1506729125977	14.0220746994019	1326.68127441406	1044.73571777344	12.8463287353516	1455.81823730469	14.1743965148926	14.1897602081299	14.1138381958008	1274.15771484375	932.235046386719	1598.13159179688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	14.1690120697021	14.1964740753174	14.1565809249878	1299.82958984375	1026.84924316406	12.3277473449709	1421.33715820313	14.1743965148926	14.1897602081299	14.1138381958008	1274.15771484375	932.235046386719	1598.13159179688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	14.0716028213501	14.1500453948975	14.0369882583618	1294.03918457031	1015.20080566406	12.8060455322266	1424.99426269531	14.1743965148926	14.1897602081299	14.1138381958008	1274.15771484375	932.235046386719	1598.13159179688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	14.139365196228	14.1963510513306	14.1151208877563	1143.92443847656	890.017517089844	13.4850444793702	1321.53894042969	14.1743965148926	14.1897602081299	14.1138381958008	1274.15771484375	932.235046386719	1598.13159179688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	14.1136865615845	14.1736316680908	14.078462600708	1127.16381835938	877.767333984375	13.2351341247558	1301.29162597656	14.1743965148926	14.1897602081299	14.1138381958008	1274.15771484375	932.235046386719	1598.13159179688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	14.165225982666	14.1760196685791	14.1724061965942	1110.9248046875	866.132690429688	13.0888023376466	1284.97436523438	14.1743965148926	14.1897602081299	14.1138381958008	1274.15771484375	932.235046386719	1598.13159179688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	14.167064666748	14.1973419189453	14.1613731384277	1089.34924316406	851.316833496094	12.7042484283449	1257.85278320313	14.1743965148926	14.1897602081299	14.1138381958008	1274.15771484375	932.235046386719	1598.13159179688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	14.1702356338501	14.1879873275757	14.1617879867554	1011.45770263672	773.977416992188	12.5782623291016	1176.48181152344	14.1743965148926	14.1897602081299	14.1138381958008	1274.15771484375	932.235046386719	1598.13159179688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	14.1741905212402	14.1880474090576	14.1731100082397	964.986755371094	743.882629394531	11.6862659454347	1117.84313964844	14.1743965148926	14.1897602081299	14.1138381958008	1274.15771484375	932.235046386719	1598.13159179688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	13.9631280899048	14.0457773208618	13.8414907455444	1672.44812011719	1286.38732910156	20.163372039795	1931.615234375	14.1020412445068	14.1767587661743	13.9464626312256	1688.15161132813	1175.74328613281	2157.4150390625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	13.93639087677	14.0810546875	13.8653697967529	1693.94226074219	1267.3544921875	19.4347534179688	1889.2666015625	14.1020412445068	14.1767587661743	13.9464626312256	1688.15161132813	1175.74328613281	2157.4150390625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	14.0001630783081	14.1026554107666	13.9533357620239	1660.07897949219	1243.79760742188	18.7979736328125	1845.33276367188	14.1020412445068	14.1767587661743	13.9464626312256	1688.15161132813	1175.74328613281	2157.4150390625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	14.0386657714844	14.1398668289185	13.9912376403809	1649.70141601563	1231.18359375	19.2097244262697	1845.89477539063	14.1020412445068	14.1767587661743	13.9464626312256	1688.15161132813	1175.74328613281	2157.4150390625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	14.0869359970093	14.1641387939453	14.0517892837524	1486.50415039063	1115.24914550781	19.5248260498047	1740.04357910156	14.1020412445068	14.1767587661743	13.9464626312256	1688.15161132813	1175.74328613281	2157.4150390625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	14.0699472427368	14.1392612457275	14.0377635955811	1469.77734375	1101.412109375	19.3521041870119	1720.67944335938	14.1020412445068	14.1767587661743	13.9464626312256	1688.15161132813	1175.74328613281	2157.4150390625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	14.1127376556396	14.1757612228394	14.0662307739258	1449.22180175781	1087.68981933594	19.1905288696287	1701.78674316406	14.1020412445068	14.1767587661743	13.9464626312256	1688.15161132813	1175.74328613281	2157.4150390625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	14.0301322937012	14.152325630188	13.9101314544678	1422.35400390625	1068.77392578125	18.81840133667	1670.96276855469	14.1020412445068	14.1767587661743	13.9464626312256	1688.15161132813	1175.74328613281	2157.4150390625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	14.1207876205444	14.1852893829346	14.0726900100708	1342.28051757813	981.578796386719	19.065896987915	1591.6875	14.1020412445068	14.1767587661743	13.9464626312256	1688.15161132813	1175.74328613281	2157.4150390625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	14.1568956375122	14.1962375640869	14.118465423584	1269.41528320313	931.308654785156	17.8216228485108	1501.6005859375	14.1020412445068	14.1767587661743	13.9464626312256	1688.15161132813	1175.74328613281	2157.4150390625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	13.8255157470703	14.182915687561	13.6057462692261	2632.46948242188	1841.17321777344	41.1759376525878	3158.80322265625	13.9257211685181	14.186806678772	13.7058191299438	2817.57104492188	1834.31799316406	3731.46313476563\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	13.7556953430176	14.1616039276123	13.5726585388184	2690.84204101563	1815.15979003906	39.8833732604981	3091.427734375	13.9257211685181	14.186806678772	13.7058191299438	2817.57104492188	1834.31799316406	3731.46313476563\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	13.7577743530273	14.1425371170044	13.586989402771	2633.32104492188	1784.64343261719	38.3220252990722	3010.9482421875	13.9257211685181	14.186806678772	13.7058191299438	2817.57104492188	1834.31799316406	3731.46313476563\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	13.9276170730591	14.2080774307251	13.800799369812	2627.55200195313	1791.76428222656	38.3684959411622	3019.55615234375	13.9257211685181	14.186806678772	13.7058191299438	2817.57104492188	1834.31799316406	3731.46313476563\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	13.7890214920044	14.0326671600342	13.6067085266113	2450.7255859375	1712.37670898438	39.0652694702147	2962.46533203125	13.9257211685181	14.186806678772	13.7058191299438	2817.57104492188	1834.31799316406	3731.46313476563\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	13.9103403091431	14.1049947738647	13.8188943862915	2416.38500976563	1692.33703613281	38.2048530578616	2914.89233398438	13.9257211685181	14.186806678772	13.7058191299438	2817.57104492188	1834.31799316406	3731.46313476563\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	13.9283046722412	14.1373834609985	13.7842292785645	2389.41528320313	1670.68371582031	38.12952041626	2890.82836914063	13.9257211685181	14.186806678772	13.7058191299438	2817.57104492188	1834.31799316406	3731.46313476563\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	13.8894004821777	14.1491146087646	13.6578617095947	2337.67749023438	1635.35327148438	37.3968582153319	2832.052734375	13.9257211685181	14.186806678772	13.7058191299438	2817.57104492188	1834.31799316406	3731.46313476563\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	13.9057970046997	14.198187828064	13.692850112915	2226.84912109375	1545.66284179688	35.8925094604491	2694.22314453125	13.9257211685181	14.186806678772	13.7058191299438	2817.57104492188	1834.31799316406	3731.46313476563\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	13.949125289917	14.2086973190308	13.7952518463135	2089.6630859375	1445.32800292969	33.7838325500487	2526.41064453125	13.9257211685181	14.186806678772	13.7058191299438	2817.57104492188	1834.31799316406	3731.46313476563\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	13.640643119812	13.9454889297485	13.4086961746216	3231.64428710938	2182.5673828125	54.7854537963869	3935.70190429688	13.7692241668701	14.117259979248	13.2883882522583	3498.43530273438	2211.44506835938	4714.8779296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	13.5121698379517	13.9123506546021	13.3263645172119	3308.21044921875	2149.46044921875	52.7888412475587	3838.70336914063	13.7692241668701	14.117259979248	13.2883882522583	3498.43530273438	2211.44506835938	4714.8779296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	13.668363571167	14.0263090133667	13.5099973678589	3233.61059570313	2096.45288085938	51.3524398803709	3739.73095703125	13.7692241668701	14.117259979248	13.2883882522583	3498.43530273438	2211.44506835938	4714.8779296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	13.7055187225342	13.9797096252441	13.5804376602173	3224.29760742188	2117.0966796875	50.8404312133791	3743.99047851563	13.7692241668701	14.117259979248	13.2883882522583	3498.43530273438	2211.44506835938	4714.8779296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	13.7217712402344	14.0561990737915	13.4886894226074	3018.412109375	2045.5	51.4398498535156	3691.5751953125	13.7692241668701	14.117259979248	13.2883882522583	3498.43530273438	2211.44506835938	4714.8779296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	13.6286897659302	13.987530708313	13.30908203125	2989.2587890625	2034.94482421875	50.6392974853516	3655.40234375	13.7692241668701	14.117259979248	13.2883882522583	3498.43530273438	2211.44506835938	4714.8779296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	13.8029193878174	14.0691242218018	13.5997476577759	2939.25439453125	2007.24560546875	49.4570007324219	3589.86962890625	13.7692241668701	14.117259979248	13.2883882522583	3498.43530273438	2211.44506835938	4714.8779296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	13.7945652008057	14.0682716369629	13.6369915008545	2877.00561523438	1963.28125	48.4910125732422	3514.99365234375	13.7692241668701	14.117259979248	13.2883882522583	3498.43530273438	2211.44506835938	4714.8779296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	13.8144845962524	14.0792198181152	13.6545009613037	2745.62158203125	1864.03601074219	46.4684562683106	3351.02661132813	13.7692241668701	14.117259979248	13.2883882522583	3498.43530273438	2211.44506835938	4714.8779296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	13.8033037185669	14.175500869751	13.5343523025513	2578.47900390625	1735.71838378906	44.2689933776856	3152.326171875	13.7692241668701	14.117259979248	13.2883882522583	3498.43530273438	2211.44506835938	4714.8779296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	13.135856628418	13.8287496566772	12.5646066665649	3895.9404296875	2546.41821289063	70.5692825317381	4804.63525390625	13.4756479263306	14.0724353790283	12.6996879577637	4257.9599609375	2614.0654296875	5849.34130859375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	13.0210990905762	13.9909391403198	12.5732860565186	3981.50341796875	2493.44116210938	67.7865982055662	4662.6123046875	13.4756479263306	14.0724353790283	12.6996879577637	4257.9599609375	2614.0654296875	5849.34130859375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	13.2374048233032	14.0592918395996	12.869779586792	3887.17456054688	2435.19213867188	65.5722579956053	4533.50439453125	13.4756479263306	14.0724353790283	12.6996879577637	4257.9599609375	2614.0654296875	5849.34130859375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	13.1980142593384	14.0142412185669	12.8076257705688	3879.548828125	2468.13916015625	64.8216552734375	4542.43212890625	13.4756479263306	14.0724353790283	12.6996879577637	4257.9599609375	2614.0654296875	5849.34130859375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	13.2889814376831	13.905101776123	12.8003883361816	3640.32250976563	2424.82373046875	64.3408966064453	4483.732421875	13.4756479263306	14.0724353790283	12.6996879577637	4257.9599609375	2614.0654296875	5849.34130859375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	13.4042835235596	14.0495843887329	12.955096244812	3609.943359375	2399.15795898438	64.1922531127928	4453.31005859375	13.4756479263306	14.0724353790283	12.6996879577637	4257.9599609375	2614.0654296875	5849.34130859375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	13.5051937103271	14.0987415313721	13.0555057525635	3536.5859375	2364.54443359375	62.379150390625	4360.67724609375	13.4756479263306	14.0724353790283	12.6996879577637	4257.9599609375	2614.0654296875	5849.34130859375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	13.4363842010498	14.0432443618774	12.9556169509888	3469.9033203125	2318.85180664063	61.2808914184569	4279.84033203125	13.4756479263306	14.0724353790283	12.6996879577637	4257.9599609375	2614.0654296875	5849.34130859375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	13.5284032821655	14.122579574585	13.0917053222656	3314.79418945313	2205.54956054688	58.58056640625	4080.12768554688	13.4756479263306	14.0724353790283	12.6996879577637	4257.9599609375	2614.0654296875	5849.34130859375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/70/St+Go	70	Stopgo	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	13.6348628997803	14.1527338027954	13.2612524032593	3111.60498046875	2048.82421875	55.9404296875	3838.91796875	13.4756479263306	14.0724353790283	12.6996879577637	4257.9599609375	2614.0654296875	5849.34130859375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	76.9878463745117	76.995735168457	76.9807815551758	762.22314453125	625.094177246094	7.21799659729003	856.070068359375	76.9846343994141	76.9957275390625	76.9590911865234	735.395935058594	568.884033203125	906.312927246094\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	76.983528137207	76.995735168457	76.9777374267578	750.401000976563	597.025573730469	6.993106842041	820.804992675781	76.9846343994141	76.9957275390625	76.9590911865234	735.395935058594	568.884033203125	906.312927246094\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	76.9911041259766	76.995735168457	76.989013671875	716.382629394531	571.527038574219	6.54349136352538	780.918762207031	76.9846343994141	76.9957275390625	76.9590911865234	735.395935058594	568.884033203125	906.312927246094\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	76.9909591674805	76.995735168457	76.9885864257813	708.326293945313	565.729675292969	6.55648612976075	775.537231445313	76.9846343994141	76.9957275390625	76.9590911865234	735.395935058594	568.884033203125	906.312927246094\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	76.9923934936523	76.995735168457	76.9891357421875	687.081665039063	564.185119628906	6.57460212707522	774.572387695313	76.9846343994141	76.9957275390625	76.9590911865234	735.395935058594	568.884033203125	906.312927246094\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	76.9917602539063	76.995735168457	76.987907409668	680.502380371094	558.807250976563	6.51010704040525	767.130676269531	76.9846343994141	76.9957275390625	76.9590911865234	735.395935058594	568.884033203125	906.312927246094\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	76.9937362670898	76.995735168457	76.9917449951172	669.700866699219	553.234313964844	6.25983810424803	753.549133300781	76.9846343994141	76.9957275390625	76.9590911865234	735.395935058594	568.884033203125	906.312927246094\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	76.9936218261719	76.995735168457	76.9915161132813	656.813232421875	542.64013671875	6.13645172119141	739.006591796875	76.9846343994141	76.9957275390625	76.9590911865234	735.395935058594	568.884033203125	906.312927246094\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	76.9936294555664	76.995735168457	76.9915924072266	627.761474609375	511.687194824219	6.17991447448731	709.444458007813	76.9846343994141	76.9957275390625	76.9590911865234	735.395935058594	568.884033203125	906.312927246094\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	76.9934387207031	76.995735168457	76.9912567138672	592.429992675781	485.052429199219	5.70634269714356	667.655395507813	76.9846343994141	76.9957275390625	76.9590911865234	735.395935058594	568.884033203125	906.312927246094\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	76.995735168457	76.995735168457	76.995735168457	25.263011932373	26.5738315582275	-0.061868250370025	24.5940475463867	76.9957427978516	76.995735168457	76.995735168457	14.723424911499	15.8298273086548	14.0466442108154\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	76.995735168457	76.995735168457	76.995735168457	25.8900337219238	26.893575668335	-0.0452902913093594	25.4442863464355	76.9957427978516	76.995735168457	76.995735168457	14.723424911499	15.8298273086548	14.0466442108154\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	76.995735168457	76.995735168457	76.995735168457	28.8452129364014	29.8018589019775	-0.043038606643675	28.4246234893799	76.9957427978516	76.995735168457	76.995735168457	14.723424911499	15.8298273086548	14.0466442108154\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	76.995735168457	76.995735168457	76.995735168457	30.7321949005127	31.4075012207031	-0.0305663943290687	30.4293766021729	76.9957427978516	76.995735168457	76.995735168457	14.723424911499	15.8298273086548	14.0466442108154\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	76.995735168457	76.995735168457	76.995735168457	7.26285266876221	7.97720766067505	-0.0339713245630266	6.8901252746582	76.9957427978516	76.995735168457	76.995735168457	14.723424911499	15.8298273086548	14.0466442108154\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	76.995735168457	76.995735168457	76.995735168457	7.24211931228638	7.94957256317139	-0.0336432158946991	6.87298965454102	76.9957427978516	76.995735168457	76.995735168457	14.723424911499	15.8298273086548	14.0466442108154\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	76.995735168457	76.995735168457	76.995735168457	18.8828773498535	19.4675693511963	-0.0279526710510249	18.5730838775635	76.9957427978516	76.995735168457	76.995735168457	14.723424911499	15.8298273086548	14.0466442108154\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	76.995735168457	76.995735168457	76.995735168457	18.8120441436768	19.3851718902588	-0.027399957180025	18.508373260498	76.9957427978516	76.995735168457	76.995735168457	14.723424911499	15.8298273086548	14.0466442108154\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	76.995735168457	76.995735168457	76.995735168457	15.466869354248	15.8358459472656	-0.017574697732925	15.273455619812	76.9957427978516	76.995735168457	76.995735168457	14.723424911499	15.8298273086548	14.0466442108154\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	76.995735168457	76.995735168457	76.995735168457	16.6158924102783	17.0159873962402	-0.0190416574478125	16.4066543579102	76.9957427978516	76.995735168457	76.995735168457	14.723424911499	15.8298273086548	14.0466442108154\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	76.995735168457	76.995735168457	76.995735168457	43.6297607421875	69.9512176513672	-1.24854415655136	29.9978046417236	76.9957427978516	76.995735168457	76.995735168457	23.1990413665771	31.3289546966553	19.2086353302002\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	76.995735168457	76.995735168457	76.995735168457	40.1125564575195	62.1734733581543	-0.996125936508178	30.2974433898926	76.9957427978516	76.995735168457	76.995735168457	23.1990413665771	31.3289546966553	19.2086353302002\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	76.995735168457	76.995735168457	76.995735168457	42.2671661376953	63.0063934326172	-0.933283329010009	33.1413269042969	76.9957427978516	76.995735168457	76.995735168457	23.1990413665771	31.3289546966553	19.2086353302002\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	76.995735168457	76.995735168457	76.995735168457	41.7156600952148	57.4297409057617	-0.711987972259522	34.646125793457	76.9957427978516	76.995735168457	76.995735168457	23.1990413665771	31.3289546966553	19.2086353302002\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	76.995735168457	76.995735168457	76.995735168457	20.5072288513184	37.4016799926758	-0.808899164199828	11.5169067382813	76.9957427978516	76.995735168457	76.995735168457	23.1990413665771	31.3289546966553	19.2086353302002\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	76.995735168457	76.995735168457	76.995735168457	20.3584499359131	37.0894165039063	-0.801071226596834	11.4551372528076	76.9957427978516	76.995735168457	76.995735168457	23.1990413665771	31.3289546966553	19.2086353302002\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	76.995735168457	76.995735168457	76.995735168457	30.5601177215576	44.8191604614258	-0.685419797897341	22.8857269287109	76.9957427978516	76.995735168457	76.995735168457	23.1990413665771	31.3289546966553	19.2086353302002\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	76.995735168457	76.995735168457	76.995735168457	30.2585563659668	44.2356910705566	-0.671868264675141	22.7359066009521	76.9957427978516	76.995735168457	76.995735168457	23.1990413665771	31.3289546966553	19.2086353302002\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	76.995735168457	76.995735168457	76.995735168457	24.037899017334	33.5985565185547	-0.460362315177919	18.8669624328613	76.9957427978516	76.995735168457	76.995735168457	23.1990413665771	31.3289546966553	19.2086353302002\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	76.995735168457	76.995735168457	76.995735168457	25.482442855835	35.8340072631836	-0.4973503947258	19.918794631958	76.9957427978516	76.995735168457	76.995735168457	23.1990413665771	31.3289546966553	19.2086353302002\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	76.9956741333008	76.995735168457	76.9956207275391	203.084320068359	274.005096435547	-3.53883171081544	160.762481689453	76.9954986572266	76.995735168457	76.994758605957	134.978134155273	179.880081176758	113.576141357422\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	76.9956588745117	76.995735168457	76.9956207275391	182.527114868164	252.316299438477	-3.16564512252809	151.015655517578	76.9954986572266	76.995735168457	76.994758605957	134.978134155273	179.880081176758	113.576141357422\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	76.9956817626953	76.995735168457	76.9956588745117	177.000045776367	242.331329345703	-2.94529438018797	148.081909179688	76.9954986572266	76.995735168457	76.994758605957	134.978134155273	179.880081176758	113.576141357422\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	76.9956817626953	76.995735168457	76.9956588745117	169.974838256836	228.7294921875	-2.67905187606812	142.99983215332	76.9954986572266	76.995735168457	76.994758605957	134.978134155273	179.880081176758	113.576141357422\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	76.9956970214844	76.995735168457	76.9956588745117	162.578414916992	220.198211669922	-2.89637899398803	127.514083862305	76.9954986572266	76.995735168457	76.994758605957	134.978134155273	179.880081176758	113.576141357422\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	76.9956970214844	76.995735168457	76.9956588745117	161.057846069336	218.119094848633	-2.86830043792725	126.333480834961	76.9954986572266	76.995735168457	76.994758605957	134.978134155273	179.880081176758	113.576141357422\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	76.995735168457	76.995735168457	76.995735168457	164.814697265625	218.507629394531	-2.70373725891112	131.988037109375	76.9954986572266	76.995735168457	76.994758605957	134.978134155273	179.880081176758	113.576141357422\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	76.995719909668	76.995735168457	76.9956970214844	161.857009887695	214.490844726563	-2.65046358108522	129.676010131836	76.9954986572266	76.995735168457	76.994758605957	134.978134155273	179.880081176758	113.576141357422\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	76.9957122802734	76.995735168457	76.9956970214844	140.337707519531	184.078201293945	-2.18565154075622	114.137351989746	76.9954986572266	76.995735168457	76.994758605957	134.978134155273	179.880081176758	113.576141357422\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	76.9957122802734	76.995735168457	76.9956970214844	139.387054443359	185.050048828125	-2.27688694000244	112.189666748047	76.9954986572266	76.995735168457	76.994758605957	134.978134155273	179.880081176758	113.576141357422\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	76.0045471191406	76.9951934814453	75.1010208129883	1550.7353515625	1039.71081542969	26.8014221191406	1897.35632324219	75.3264999389648	76.9944915771484	70.2532272338867	1667.57556152344	1049.169921875	2260.83642578125\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	75.698616027832	76.9954147338867	75.08251953125	1575.19323730469	1005.75402832031	25.9518737792969	1836.21398925781	75.3264999389648	76.9944915771484	70.2532272338867	1667.57556152344	1049.169921875	2260.83642578125\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	76.5534896850586	76.9956970214844	76.3538436889648	1512.2626953125	963.125061035156	24.8045177459717	1756.86962890625	75.3264999389648	76.9944915771484	70.2532272338867	1667.57556152344	1049.169921875	2260.83642578125\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	76.5128021240234	76.9956588745117	76.2733612060547	1510.13891601563	968.787353515625	24.8830757141114	1765.04577636719	75.3264999389648	76.9944915771484	70.2532272338867	1667.57556152344	1049.169921875	2260.83642578125\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	76.6714782714844	76.9956588745117	76.3591690063477	1445.75598144531	975.435668945313	25.0961914062499	1778.51379394531	75.3264999389648	76.9944915771484	70.2532272338867	1667.57556152344	1049.169921875	2260.83642578125\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	76.5874938964844	76.9956207275391	76.1910934448242	1430.72680664063	966.1162109375	24.7813758850097	1759.12023925781	75.3264999389648	76.9944915771484	70.2532272338867	1667.57556152344	1049.169921875	2260.83642578125\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	76.858268737793	76.995735168457	76.7252807617188	1400.24084472656	952.619201660156	24.0275974273682	1721.50231933594	75.3264999389648	76.9944915771484	70.2532272338867	1667.57556152344	1049.169921875	2260.83642578125\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	76.8417358398438	76.995735168457	76.6912460327148	1372.66613769531	934.194458007813	23.5332679748537	1687.25903320313	75.3264999389648	76.9944915771484	70.2532272338867	1667.57556152344	1049.169921875	2260.83642578125\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	76.8469543457031	76.995735168457	76.7059555053711	1327.67077636719	915.623107910156	21.8996181488039	1616.41088867188	75.3264999389648	76.9944915771484	70.2532272338867	1667.57556152344	1049.169921875	2260.83642578125\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	76.840217590332	76.995735168457	76.6955337524414	1255.39880371094	843.636535644531	21.8493900299072	1542.81701660156	75.3264999389648	76.9944915771484	70.2532272338867	1667.57556152344	1049.169921875	2260.83642578125\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	55.1362037658691	76.9509353637695	37.6418075561523	2337.49584960938	1487.62719726563	44.6354293823241	2915.9609375	64.1439514160156	76.9369430541992	48.5297355651855	2624.46337890625	1564.71044921875	3646.98022460938\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	51.0447769165039	76.9687194824219	38.9515342712402	2401.47045898438	1452.11071777344	43.2788963317872	2837.03540039063	64.1439514160156	76.9369430541992	48.5297355651855	2624.46337890625	1564.71044921875	3646.98022460938\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	52.1778755187988	76.9908142089844	41.0060806274414	2297.68872070313	1387.38671875	41.1064224243166	2702.79223632813	64.1439514160156	76.9369430541992	48.5297355651855	2624.46337890625	1564.71044921875	3646.98022460938\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	60.2437477111816	76.9887771606445	52.1289329528809	2322.78076171875	1410.30456542969	41.9187660217284	2751.705078125	64.1439514160156	76.9369430541992	48.5297355651855	2624.46337890625	1564.71044921875	3646.98022460938\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	64.1215896606445	76.9889526367188	53.3127822875977	2224.54907226563	1428.06396484375	42.3217849731447	2782.36108398438	64.1439514160156	76.9369430541992	48.5297355651855	2624.46337890625	1564.71044921875	3646.98022460938\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	57.4603805541992	76.9858474731445	41.4161567687988	2177.5634765625	1414.35913085938	40.6286544799803	2714.47607421875	64.1439514160156	76.9369430541992	48.5297355651855	2624.46337890625	1564.71044921875	3646.98022460938\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	67.3278274536133	76.9935150146484	58.3572120666504	2168.04077148438	1390.013671875	41.40096282959	2714.84448242188	64.1439514160156	76.9369430541992	48.5297355651855	2624.46337890625	1564.71044921875	3646.98022460938\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	66.7661437988281	76.9930419921875	57.4351692199707	2121.7646484375	1362.99682617188	40.3922424316406	2655.54858398438	64.1439514160156	76.9369430541992	48.5297355651855	2624.46337890625	1564.71044921875	3646.98022460938\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	67.0534820556641	76.9927597045898	58.1983833312988	2034.43908691406	1333.47045898438	37.2277679443359	2524.75903320313	64.1439514160156	76.9369430541992	48.5297355651855	2624.46337890625	1564.71044921875	3646.98022460938\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	67.5391464233398	76.9933929443359	59.3049011230469	1927.91589355469	1237.43273925781	36.1702842712403	2394.8818359375	64.1439514160156	76.9369430541992	48.5297355651855	2624.46337890625	1564.71044921875	3646.98022460938\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	49.0945587158203	75.2185211181641	36.3996238708496	3203.3564453125	1956.86572265625	65.9185638427734	4066.259765625	53.4308280944824	75.0400924682617	33.2017631530762	3611.43823242188	2103.07836914063	5206.44091796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	41.8307189941406	74.7307586669922	26.9915523529053	3317.34350585938	1913.75427246094	64.0125694274903	3962.15649414063	53.4308280944824	75.0400924682617	33.2017631530762	3611.43823242188	2103.07836914063	5206.44091796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	51.1851921081543	76.5542678833008	40.062931060791	3182.59765625	1849.74365234375	60.2181320190431	3776.72387695313	53.4308280944824	75.0400924682617	33.2017631530762	3611.43823242188	2103.07836914063	5206.44091796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	44.1894340515137	76.272590637207	29.4054164886475	3198.26220703125	1886.16418457031	60.3607139587403	3817.70703125	53.4308280944824	75.0400924682617	33.2017631530762	3611.43823242188	2103.07836914063	5206.44091796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	47.8332595825195	76.3303604125977	31.4933261871338	3043.6064453125	1914.89489746094	60.5546531677247	3852.64379882813	53.4308280944824	75.0400924682617	33.2017631530762	3611.43823242188	2103.07836914063	5206.44091796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	48.1008453369141	76.0720443725586	32.5562858581543	3001.3720703125	1894.01623535156	59.5449790954591	3799.45556640625	53.4308280944824	75.0400924682617	33.2017631530762	3611.43823242188	2103.07836914063	5206.44091796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	51.7193374633789	76.8392028808594	33.7109184265137	2946.5791015625	1868.21936035156	57.7787742614747	3717.14013671875	53.4308280944824	75.0400924682617	33.2017631530762	3611.43823242188	2103.07836914063	5206.44091796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	51.4232330322266	76.8005447387695	33.6982917785645	2884.01489257813	1831.46997070313	56.4488983154297	3637.83471679688	53.4308280944824	75.0400924682617	33.2017631530762	3611.43823242188	2103.07836914063	5206.44091796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	50.7626342773438	76.7718048095703	33.1978302001953	2805.958984375	1762.17834472656	56.4120445251466	3567.36376953125	53.4308280944824	75.0400924682617	33.2017631530762	3611.43823242188	2103.07836914063	5206.44091796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Freeflow	80	Freeflow	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	51.9764709472656	76.8093490600586	34.0897636413574	2615.75122070313	1666.56884765625	50.3563232421875	3277.97119140625	53.4308280944824	75.0400924682617	33.2017631530762	3611.43823242188	2103.07836914063	5206.44091796875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	38.2128829956055	38.6287231445313	37.9430274963379	1104.40954589844	766.465270996094	17.7887859344483	1335.70642089844	38.3298263549805	38.6202163696289	37.8378982543945	1173.685546875	762.666076660156	1595.68286132813\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	38.063720703125	38.6294479370117	37.8074760437012	1119.66833496094	743.523559570313	17.1478385925293	1292.25439453125	38.3298263549805	38.6202163696289	37.8378982543945	1173.685546875	762.666076660156	1595.68286132813\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	38.4403762817383	38.6997528076172	38.3282012939453	1074.74389648438	717.413818359375	16.1399536132814	1233.89233398438	38.3298263549805	38.6202163696289	37.8378982543945	1173.685546875	762.666076660156	1595.68286132813\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	38.4515190124512	38.6996459960938	38.3400077819824	1082.91845703125	734.081359863281	16.0364818573	1247.24877929688	38.3298263549805	38.6202163696289	37.8378982543945	1173.685546875	762.666076660156	1595.68286132813\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	38.2075157165527	38.6303253173828	37.8040771484375	1013.66827392578	706.848449707031	16.4056873321534	1231.83044433594	38.3298263549805	38.6202163696289	37.8378982543945	1173.685546875	762.666076660156	1595.68286132813\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	38.1936149597168	38.6272850036621	37.7766914367676	1003.7060546875	700.212829589844	16.2218112945558	1219.31079101563	38.3298263549805	38.6202163696289	37.8378982543945	1173.685546875	762.666076660156	1595.68286132813\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	38.2478256225586	38.6220817565918	37.9461822509766	992.18017578125	700.804565429688	15.6344947814941	1201.1083984375	38.3298263549805	38.6202163696289	37.8378982543945	1173.685546875	762.666076660156	1595.68286132813\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	38.271354675293	38.6299171447754	37.943302154541	972.259399414063	686.090637207031	15.3634891510009	1177.72229003906	38.3298263549805	38.6202163696289	37.8378982543945	1173.685546875	762.666076660156	1595.68286132813\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	38.4670677185059	38.551342010498	38.3941497802734	927.60546875	650.754638671875	14.7286109924317	1122.07019042969	38.3298263549805	38.6202163696289	37.8378982543945	1173.685546875	762.666076660156	1595.68286132813\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	38.2171173095703	38.6221885681152	37.8728637695313	875.091491699219	612.048156738281	13.9597492218018	1058.76013183594	38.3298263549805	38.6202163696289	37.8378982543945	1173.685546875	762.666076660156	1595.68286132813\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	38.6639213562012	38.6997528076172	38.6300582885742	192.634155273438	174.686798095703	0.979022026062031	206.015502929688	38.6835556030273	38.6997337341309	38.6349258422852	165.292068481445	139.225723266602	196.74853515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	38.5993194580078	38.6997528076172	38.5514488220215	189.912551879883	168.353042602539	0.986019611358656	199.905670166016	38.6835556030273	38.6997337341309	38.6349258422852	165.292068481445	139.225723266602	196.74853515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	38.6463470458984	38.6997528076172	38.6221885681152	181.526565551758	162.644592285156	0.854203224182125	189.979095458984	38.6835556030273	38.6997337341309	38.6349258422852	165.292068481445	139.225723266602	196.74853515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	38.6479415893555	38.6997528076172	38.6220893859863	185.356567382813	167.731292724609	0.814917564392093	193.808654785156	38.6835556030273	38.6997337341309	38.6349258422852	165.292068481445	139.225723266602	196.74853515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	38.6260681152344	38.6997528076172	38.5519104003906	141.042861938477	121.772583007813	1.07038831710813	156.025009155273	38.6835556030273	38.6997337341309	38.6349258422852	165.292068481445	139.225723266602	196.74853515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	38.6259498596191	38.6997528076172	38.5516777038574	139.735733032227	120.659599304199	1.05953812599184	154.564819335938	38.6835556030273	38.6997337341309	38.6349258422852	165.292068481445	139.225723266602	196.74853515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	38.6267509460449	38.6997528076172	38.5519027709961	149.478759765625	130.960189819336	1.02939081192016	163.900695800781	38.6835556030273	38.6997337341309	38.6349258422852	165.292068481445	139.225723266602	196.74853515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	38.6265449523926	38.6997528076172	38.5514831542969	146.891342163086	128.736434936523	1.00915241241456	161.029312133789	38.6835556030273	38.6997337341309	38.6349258422852	165.292068481445	139.225723266602	196.74853515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	38.6640739440918	38.6997528076172	38.6286354064941	133.859649658203	114.235656738281	1.06342792510987	148.265350341797	38.6835556030273	38.6997337341309	38.6349258422852	165.292068481445	139.225723266602	196.74853515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	38.6638069152832	38.6997528076172	38.6288528442383	129.607452392578	111.858390808105	0.964492559433	142.722152709961	38.6835556030273	38.6997337341309	38.6349258422852	165.292068481445	139.225723266602	196.74853515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	38.595142364502	38.6997528076172	38.5186386108398	333.31201171875	284.701446533203	2.6399393081665	369.179504394531	38.6124000549316	38.6996955871582	38.5590896606445	313.880401611328	242.254302978516	395.320770263672\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	38.6476554870605	38.6997528076172	38.6245765686035	331.652770996094	274.245727539063	2.6235065460205	358.197937011719	38.6124000549316	38.6996955871582	38.5590896606445	313.880401611328	242.254302978516	395.320770263672\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	38.6471862792969	38.6997528076172	38.6241607666016	319.2607421875	264.909149169922	2.45791149139403	343.562316894531	38.6124000549316	38.6996955871582	38.5590896606445	313.880401611328	242.254302978516	395.320770263672\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	38.649658203125	38.6997528076172	38.6274108886719	324.233825683594	271.537353515625	2.43301486968994	349.393829345703	38.6124000549316	38.6996955871582	38.5590896606445	313.880401611328	242.254302978516	395.320770263672\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	38.634880065918	38.6997528076172	38.6223526000977	274.808135986328	227.270629882813	2.60690689086912	310.691650390625	38.6124000549316	38.6996955871582	38.5590896606445	313.880401611328	242.254302978516	395.320770263672\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	38.5756225585938	38.6997528076172	38.4752540588379	272.602600097656	225.142211914063	2.61475944519041	308.814514160156	38.6124000549316	38.6996955871582	38.5590896606445	313.880401611328	242.254302978516	395.320770263672\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	38.661979675293	38.6997528076172	38.6776428222656	277.334655761719	231.934219360352	2.5059151649475	312.123504638672	38.6124000549316	38.6996955871582	38.5590896606445	313.880401611328	242.254302978516	395.320770263672\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	38.6370735168457	38.6997528076172	38.5984115600586	272.2509765625	227.719482421875	2.46715068817137	306.668304443359	38.6124000549316	38.6996955871582	38.5590896606445	313.880401611328	242.254302978516	395.320770263672\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	38.6362762451172	38.6997528076172	38.5971374511719	253.295867919922	206.009704589844	2.58196449279784	288.632568359375	38.6124000549316	38.6996955871582	38.5590896606445	313.880401611328	242.254302978516	395.320770263672\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	38.6249923706055	38.6997528076172	38.601245880127	242.491592407227	200.016479492188	2.30920219421384	273.910949707031	38.6124000549316	38.6996955871582	38.5590896606445	313.880401611328	242.254302978516	395.320770263672\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	38.5959854125977	38.6997528076172	38.5483322143555	618.138793945313	486.197723388672	6.94396495819091	708.404602050781	38.5303535461426	38.6995010375977	38.3223838806152	620.173217773438	449.787994384766	790.891357421875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	38.4386825561523	38.6997528076172	38.3172912597656	617.156616210938	468.992614746094	6.75451850891113	685.13720703125	38.5303535461426	38.6995010375977	38.3223838806152	620.173217773438	449.787994384766	790.891357421875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	38.5226707458496	38.6997528076172	38.4432601928711	596.109558105469	453.575103759766	6.43882656097412	659.617553710938	38.5303535461426	38.6995010375977	38.3223838806152	620.173217773438	449.787994384766	790.891357421875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	38.5640678405762	38.6997528076172	38.4991569519043	601.743103027344	462.741119384766	6.39286136627197	667.312683105469	38.5303535461426	38.6995010375977	38.3223838806152	620.173217773438	449.787994384766	790.891357421875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	38.5024337768555	38.6997528076172	38.4115867614746	547.518676757813	424.703765869141	6.57884120941162	635.226684570313	38.5303535461426	38.6995010375977	38.3223838806152	620.173217773438	449.787994384766	790.891357421875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	38.5429344177246	38.6997528076172	38.4376335144043	542.166381835938	420.676910400391	6.51122188568115	629.036010742188	38.5303535461426	38.6995010375977	38.3223838806152	620.173217773438	449.787994384766	790.891357421875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	38.6108016967773	38.6997528076172	38.547794342041	540.690002441406	422.776306152344	6.34668540954591	625.870239257813	38.5303535461426	38.6995010375977	38.3223838806152	620.173217773438	449.787994384766	790.891357421875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	38.6118125915527	38.6997528076172	38.5467147827148	530.39306640625	414.802520751953	6.22441768646241	613.98388671875	38.5303535461426	38.6995010375977	38.3223838806152	620.173217773438	449.787994384766	790.891357421875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	38.605281829834	38.6997528076172	38.5492324829102	499.944061279297	385.45263671875	6.11058044433594	580.9912109375	38.5303535461426	38.6995010375977	38.3223838806152	620.173217773438	449.787994384766	790.891357421875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	38.5912857055664	38.6997528076172	38.5186920166016	475.612396240234	368.631195068359	5.69851016998294	550.983520507813	38.5303535461426	38.6995010375977	38.3223838806152	620.173217773438	449.787994384766	790.891357421875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	37.2035636901855	38.4058685302734	36.1679229736328	1702.05480957031	1107.6337890625	31.0705032348634	2101.88989257813	37.8138694763184	38.4899559020996	36.5347862243652	1875.25366210938	1143.82482910156	2581.1728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	36.8140754699707	38.4717102050781	36.0340843200684	1745.03393554688	1079.6982421875	30.3161926269531	2049.81640625	37.8138694763184	38.4899559020996	36.5347862243652	1875.25366210938	1143.82482910156	2581.1728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	37.5419235229492	38.6753616333008	37.0325546264648	1684.10900878906	1041.17260742188	29.0378570556641	1970.38403320313	37.8138694763184	38.4899559020996	36.5347862243652	1875.25366210938	1143.82482910156	2581.1728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	37.3281402587891	38.507007598877	36.7703895568848	1698.76477050781	1066.58935546875	29.0455932617187	1996.04833984375	37.8138694763184	38.4899559020996	36.5347862243652	1875.25366210938	1143.82482910156	2581.1728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	37.6727333068848	38.6211891174316	36.9863166809082	1594.42419433594	1050.97875976563	28.8189697265625	1973.18579101563	37.8138694763184	38.4899559020996	36.5347862243652	1875.25366210938	1143.82482910156	2581.1728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	37.4109077453613	38.4817199707031	36.5945663452148	1579.73303222656	1041.94665527344	28.5082511901856	1954.21069335938	37.8138694763184	38.4899559020996	36.5347862243652	1875.25366210938	1143.82482910156	2581.1728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	37.4807395935059	38.3997535705566	36.7871856689453	1564.64282226563	1031.83166503906	28.5072975158694	1944.06518554688	37.8138694763184	38.4899559020996	36.5347862243652	1875.25366210938	1143.82482910156	2581.1728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	37.9016418457031	38.5274429321289	37.4203720092773	1532.16076660156	1011.42163085938	27.8684234619141	1903.21118164063	37.8138694763184	38.4899559020996	36.5347862243652	1875.25366210938	1143.82482910156	2581.1728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	37.8014106750488	38.6020202636719	37.2168579101563	1470.57482910156	973.332763671875	26.4260673522948	1818.96691894531	37.8138694763184	38.4899559020996	36.5347862243652	1875.25366210938	1143.82482910156	2581.1728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	37.7786140441895	38.4714698791504	37.1954765319824	1383.96313476563	905.267944335938	25.316879272461	1715.40808105469	37.8138694763184	38.4899559020996	36.5347862243652	1875.25366210938	1143.82482910156	2581.1728515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	34.2221908569336	38.5091857910156	31.0401859283447	2402.10424804688	1502.47229003906	47.1184043884278	3010.26123046875	36.0050010681152	38.5698432922363	32.2988815307617	2689.96411132813	1589.80773925781	3768.64575195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	33.8514556884766	38.0902099609375	31.8772640228271	2482.16723632813	1473.21557617188	45.9799270629881	2944.5732421875	36.0050010681152	38.5698432922363	32.2988815307617	2689.96411132813	1589.80773925781	3768.64575195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	34.7727432250977	37.9347267150879	33.362907409668	2389.0234375	1421.39904785156	43.6969261169434	2819.70068359375	36.0050010681152	38.5698432922363	32.2988815307617	2689.96411132813	1589.80773925781	3768.64575195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	35.2640075683594	38.5231094360352	33.7391014099121	2416.19970703125	1454.61779785156	44.1709632873538	2868.08862304688	36.0050010681152	38.5698432922363	32.2988815307617	2689.96411132813	1589.80773925781	3768.64575195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	35.3592185974121	38.5167121887207	32.9622383117676	2275.33715820313	1450.84704589844	43.8740577697753	2854.81689453125	36.0050010681152	38.5698432922363	32.2988815307617	2689.96411132813	1589.80773925781	3768.64575195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	34.5534172058105	38.5054016113281	31.163143157959	2256.05297851563	1437.61950683594	43.3932762145997	2826.20434570313	36.0050010681152	38.5698432922363	32.2988815307617	2689.96411132813	1589.80773925781	3768.64575195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	35.9665069580078	38.239818572998	34.0662422180176	2227.56787109375	1421.23657226563	42.9783096313475	2796.54248046875	36.0050010681152	38.5698432922363	32.2988815307617	2689.96411132813	1589.80773925781	3768.64575195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	36.2267417907715	38.5541801452637	34.2547874450684	2179.73022460938	1393.06018066406	41.9138298034669	2734.302734375	36.0050010681152	38.5698432922363	32.2988815307617	2689.96411132813	1589.80773925781	3768.64575195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	36.1970901489258	38.6255874633789	34.1984977722168	2086.27001953125	1342.96301269531	39.3273048400881	2601.43676757813	36.0050010681152	38.5698432922363	32.2988815307617	2689.96411132813	1589.80773925781	3768.64575195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	36.3060569763184	38.6396903991699	34.5562629699707	1968.53393554688	1249.53051757813	37.9083251953125	2462.59692382813	36.0050010681152	38.5698432922363	32.2988815307617	2689.96411132813	1589.80773925781	3768.64575195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	30.9323043823242	37.9686088562012	26.8387355804443	3236.55932617188	1925.10339355469	68.8500709533691	4128.3056640625	32.9936866760254	38.0108299255371	26.3750095367432	3631.66333007813	2075.56860351563	5231.6875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	28.6819248199463	37.9051094055176	24.4343643188477	3338.9794921875	1895.17333984375	65.8147964477541	4001.24682617188	32.9936866760254	38.0108299255371	26.3750095367432	3631.66333007813	2075.56860351563	5231.6875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	32.485897064209	38.2403564453125	29.9538459777832	3219.22729492188	1833.724609375	62.5818786621094	3836.3447265625	32.9936866760254	38.0108299255371	26.3750095367432	3631.66333007813	2075.56860351563	5231.6875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	29.9755916595459	38.0458755493164	26.1121234893799	3238.02905273438	1875.12231445313	62.6447830200194	3879.75537109375	32.9936866760254	38.0108299255371	26.3750095367432	3631.66333007813	2075.56860351563	5231.6875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	31.3772277832031	37.9208297729492	26.727518081665	3060.00439453125	1882.67858886719	62.8438529968263	3893.68188476563	32.9936866760254	38.0108299255371	26.3750095367432	3631.66333007813	2075.56860351563	5231.6875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	31.1183166503906	37.5766944885254	26.9867115020752	3023.20092773438	1864.22729492188	61.9803085327147	3847.59716796875	32.9936866760254	38.0108299255371	26.3750095367432	3631.66333007813	2075.56860351563	5231.6875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	32.5572166442871	38.3351936340332	28.0183048248291	2965.17724609375	1847.98767089844	59.7514152526856	3760.03295898438	32.9936866760254	38.0108299255371	26.3750095367432	3631.66333007813	2075.56860351563	5231.6875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	32.1576156616211	37.2110862731934	28.1249523162842	2898.02905273438	1815.18078613281	58.0079536437991	3671.43530273438	32.9936866760254	38.0108299255371	26.3750095367432	3631.66333007813	2075.56860351563	5231.6875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	31.9517726898193	38.2544021606445	27.1384372711182	2819.23486328125	1747.53942871094	57.3679771423341	3583.31469726563	32.9936866760254	38.0108299255371	26.3750095367432	3631.66333007813	2075.56860351563	5231.6875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/Satur.	80	Saturated	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	32.4767646789551	38.0915374755859	28.0838108062744	2624.79248046875	1630.16931152344	52.6985588073731	3316.52319335938	32.9936866760254	38.0108299255371	26.3750095367432	3631.66333007813	2075.56860351563	5231.6875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	14.8570728302002	14.8923234939575	14.8076658248901	1970.595703125	1504.34814453125	24.2027740478516	2278.8369140625	14.9122648239136	15.0614414215088	14.7634162902832	2013.04724121094	1418.37109375	2570.78002929688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	14.8738212585449	14.9948253631592	14.8186740875244	1979.60668945313	1463.50256347656	23.4987525939944	2215.46264648438	14.9122648239136	15.0614414215088	14.7634162902832	2013.04724121094	1418.37109375	2570.78002929688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	14.9306659698486	15.0044851303101	14.8984708786011	1943.32763671875	1435.85278320313	22.9133834838866	2169.0810546875	14.9122648239136	15.0614414215088	14.7634162902832	2013.04724121094	1418.37109375	2570.78002929688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	14.897515296936	15.090479850769	14.8112163543701	1923.74401855469	1428.54382324219	22.7213706970216	2155.62768554688	14.9122648239136	15.0614414215088	14.7634162902832	2013.04724121094	1418.37109375	2570.78002929688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	14.9405879974365	15.0925264358521	14.8454818725586	1781.447265625	1344.51416015625	23.0876083374025	2083.31762695313	14.9122648239136	15.0614414215088	14.7634162902832	2013.04724121094	1418.37109375	2570.78002929688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	14.9202661514282	15.0978212356567	14.8232107162476	1757.75378417969	1329.94641113281	22.5405769348147	2051.24487304688	14.9122648239136	15.0614414215088	14.7634162902832	2013.04724121094	1418.37109375	2570.78002929688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	14.9572534561157	15.0664443969727	14.8824729919434	1739.07531738281	1314.11645507813	22.6359596252441	2038.46716308594	14.9122648239136	15.0614414215088	14.7634162902832	2013.04724121094	1418.37109375	2570.78002929688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	14.9424457550049	15.0696001052856	14.8480377197266	1704.55126953125	1289.34338378906	22.1189727783203	1997.15051269531	14.9122648239136	15.0614414215088	14.7634162902832	2013.04724121094	1418.37109375	2570.78002929688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	14.9396209716797	15.0554056167603	14.8560190200806	1595.94250488281	1194.89855957031	21.2225570678712	1874.02038574219	14.9122648239136	15.0614414215088	14.7634162902832	2013.04724121094	1418.37109375	2570.78002929688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	14.9837026596069	15.0781393051147	14.9163722991943	1524.43908691406	1138.05187988281	20.3403968811037	1788.94458007813	14.9122648239136	15.0614414215088	14.7634162902832	2013.04724121094	1418.37109375	2570.78002929688\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	15.0814218521118	15.0932674407959	15.0962324142456	945.184387207031	851.409057617188	4.81807136535631	1005.58734130859	15.0903158187866	15.1226205825806	15.0844249725342	850.143493652344	699.16064453125	983.7958984375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	15.0688705444336	15.0942182540894	15.0588645935059	923.057739257813	822.079040527344	4.59242057800294	969.036499023438	15.0903158187866	15.1226205825806	15.0844249725342	850.143493652344	699.16064453125	983.7958984375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	15.0665035247803	15.1168336868286	15.0446453094482	922.066162109375	816.206909179688	4.77851104736328	969.119262695313	15.0903158187866	15.1226205825806	15.0844249725342	850.143493652344	699.16064453125	983.7958984375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	15.0792922973633	15.105996131897	15.0688362121582	908.038696289063	801.585693359375	4.87681770324706	957.643859863281	15.0903158187866	15.1226205825806	15.0844249725342	850.143493652344	699.16064453125	983.7958984375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	15.0658645629883	15.1405267715454	15.0205745697021	790.292663574219	687.2265625	5.40695762634278	860.249206542969	15.0903158187866	15.1226205825806	15.0844249725342	850.143493652344	699.16064453125	983.7958984375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	15.0892763137817	15.140456199646	15.0684223175049	784.214538574219	680.629638671875	5.45185089111328	855.0888671875	15.0903158187866	15.1226205825806	15.0844249725342	850.143493652344	699.16064453125	983.7958984375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	15.0835933685303	15.140739440918	15.0422773361206	770.740234375	665.220825195313	5.59281349182128	844.190856933594	15.0903158187866	15.1226205825806	15.0844249725342	850.143493652344	699.16064453125	983.7958984375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	15.086085319519	15.140739440918	15.0471143722534	755.028930664063	652.364196777344	5.43869400024413	826.402404785156	15.0903158187866	15.1226205825806	15.0844249725342	850.143493652344	699.16064453125	983.7958984375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	15.0883722305298	15.140739440918	15.0481128692627	688.197021484375	583.689025878906	5.51302719116212	760.105895996094	15.0903158187866	15.1226205825806	15.0844249725342	850.143493652344	699.16064453125	983.7958984375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	15.0860977172852	15.140739440918	15.0441265106201	664.557983398438	566.919006347656	5.12453079223634	730.903991699219	15.0903158187866	15.1226205825806	15.0844249725342	850.143493652344	699.16064453125	983.7958984375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	15.0230283737183	15.0564527511597	15.0148868560791	1176.001953125	1023.77526855469	7.85510635375969	1275.138671875	15.0849237442017	15.1033334732056	15.0557146072388	1112.25390625	885.055053710938	1298.70825195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	15.0085277557373	15.0187454223633	15.0052909851074	1159.02160644531	991.966491699219	7.60526084899909	1235.33483886719	15.0849237442017	15.1033334732056	15.0557146072388	1112.25390625	885.055053710938	1298.70825195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	15.0443277359009	15.0684289932251	15.0333824157715	1145.84851074219	978.669860839844	7.54604530334488	1220.14331054688	15.0849237442017	15.1033334732056	15.0557146072388	1112.25390625	885.055053710938	1298.70825195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	15.0153284072876	15.0800971984863	14.9817543029785	1135.59252929688	961.153991699219	7.99826240539566	1217.09838867188	15.0849237442017	15.1033334732056	15.0557146072388	1112.25390625	885.055053710938	1298.70825195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	15.0404586791992	15.090537071228	15.0056409835815	1008.82952880859	855.075500488281	7.97988700866716	1110.43188476563	15.0849237442017	15.1033334732056	15.0557146072388	1112.25390625	885.055053710938	1298.70825195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	15.0890369415283	15.0812892913818	15.0823936462402	998.527893066406	845.908630371094	7.959119796753	1100.60046386719	15.0849237442017	15.1033334732056	15.0557146072388	1112.25390625	885.055053710938	1298.70825195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	15.0477838516235	15.1153936386108	14.9942026138306	992.27978515625	831.38818359375	8.49455261230469	1103.2138671875	15.0849237442017	15.1033334732056	15.0557146072388	1112.25390625	885.055053710938	1298.70825195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	15.0442552566528	15.0946388244629	14.9971008300781	971.339111328125	815.230895996094	8.24487876892081	1079.06701660156	15.0849237442017	15.1033334732056	15.0557146072388	1112.25390625	885.055053710938	1298.70825195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	15.0421018600464	15.0940608978271	14.9974498748779	892.135009765625	737.603332519531	8.10160446166994	996.854675292969	15.0849237442017	15.1033334732056	15.0557146072388	1112.25390625	885.055053710938	1298.70825195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	15.0521011352539	15.0951080322266	15.005072593689	859.599670410156	713.466369628906	7.64644241333009	958.152526855469	15.0849237442017	15.1033334732056	15.0557146072388	1112.25390625	885.055053710938	1298.70825195313\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	15.0033578872681	15.073974609375	14.9506006240845	1515.306640625	1237.74548339844	14.4964103698731	1701.63061523438	15.0414228439331	15.0479373931885	14.9589052200317	1483.20739746094	1121.3720703125	1826.22082519531\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	14.8652563095093	15.0694570541382	14.7690992355347	1526.45715332031	1208.08947753906	14.5140571594241	1672.53930664063	15.0414228439331	15.0479373931885	14.9589052200317	1483.20739746094	1121.3720703125	1826.22082519531\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	15.0183029174805	15.0720262527466	14.9936714172363	1491.42687988281	1183.69702148438	13.9001998901366	1628.50341796875	15.0414228439331	15.0479373931885	14.9589052200317	1483.20739746094	1121.3720703125	1826.22082519531\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	15.0233507156372	15.0633296966553	15.003755569458	1470.82775878906	1170.9736328125	13.7720718383791	1611.67993164063	15.0414228439331	15.0479373931885	14.9589052200317	1483.20739746094	1121.3720703125	1826.22082519531\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	14.9722146987915	15.0051498413086	14.9160623550415	1335.44482421875	1073.88610839844	13.8842506408691	1518.18212890625	15.0414228439331	15.0479373931885	14.9589052200317	1483.20739746094	1121.3720703125	1826.22082519531\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	15.0171270370483	15.0065565109253	15.0067644119263	1322.65893554688	1063.01550292969	13.7571487426756	1503.24426269531	15.0414228439331	15.0479373931885	14.9589052200317	1483.20739746094	1121.3720703125	1826.22082519531\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	15.032753944397	15.0559482574463	15.0346784591675	1299.49548339844	1044.01733398438	13.5543899536131	1477.7578125	15.0414228439331	15.0479373931885	14.9589052200317	1483.20739746094	1121.3720703125	1826.22082519531\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	15.0036611557007	15.0318021774292	15.0163917541504	1274.25366210938	1023.44146728516	13.2738323211669	1448.2041015625	15.0414228439331	15.0479373931885	14.9589052200317	1483.20739746094	1121.3720703125	1826.22082519531\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	14.9792127609253	15.0312089920044	14.9664001464844	1186.46936035156	940.633544921875	12.9860839843752	1356.18823242188	15.0414228439331	15.0479373931885	14.9589052200317	1483.20739746094	1121.3720703125	1826.22082519531\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	15.0071620941162	15.0570297241211	14.9921312332153	1134.71313476563	903.287170410156	12.1525402069092	1292.16845703125	15.0414228439331	15.0479373931885	14.9589052200317	1483.20739746094	1121.3720703125	1826.22082519531\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	14.6643695831299	14.9321422576904	14.4638929367065	2480.91967773438	1818.06408691406	34.2129020690919	2912.876953125	14.7637214660645	14.9960231781006	14.4150543212891	2625.8095703125	1770.17895507813	3377.97021484375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	14.6294002532959	15.0055723190308	14.4566822052002	2491.40112304688	1774.15075683594	32.6331367492675	2818.4111328125	14.7637214660645	14.9960231781006	14.4150543212891	2625.8095703125	1770.17895507813	3377.97021484375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	14.7165231704712	15.0958251953125	14.5477161407471	2456.07666015625	1732.90197753906	32.6463203430178	2777.58422851563	14.7637214660645	14.9960231781006	14.4150543212891	2625.8095703125	1770.17895507813	3377.97021484375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	14.6747188568115	15.0656862258911	14.4899816513062	2434.08471679688	1733.98547363281	32.1045417785647	2761.33081054688	14.7637214660645	14.9960231781006	14.4150543212891	2625.8095703125	1770.17895507813	3377.97021484375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	14.7504167556763	15.0665006637573	14.5303812026978	2278.63647460938	1659.95861816406	32.4018745422366	2696.81860351563	14.7637214660645	14.9960231781006	14.4150543212891	2625.8095703125	1770.17895507813	3377.97021484375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	14.7514963150024	15.0464029312134	14.524284362793	2252.88403320313	1643.79235839844	31.9096183776856	2664.90014648438	14.7637214660645	14.9960231781006	14.4150543212891	2625.8095703125	1770.17895507813	3377.97021484375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	14.7728681564331	14.9321422576904	14.6231803894043	2232.67651367188	1629.02844238281	31.8909797668459	2649.53979492188	14.7637214660645	14.9960231781006	14.4150543212891	2625.8095703125	1770.17895507813	3377.97021484375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	14.7967596054077	14.9984188079834	14.6251640319824	2193.09228515625	1594.1025390625	31.6141586303713	2605.75561523438	14.7637214660645	14.9960231781006	14.4150543212891	2625.8095703125	1770.17895507813	3377.97021484375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	14.7504663467407	15.0085601806641	14.5218067169189	2068.88793945313	1491.16101074219	30.3980064392091	2463.89721679688	14.7637214660645	14.9960231781006	14.4150543212891	2625.8095703125	1770.17895507813	3377.97021484375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	14.7881460189819	14.9568967819214	14.6543836593628	1974.43054199219	1415.89904785156	29.1581840515137	2348.9609375	14.7637214660645	14.9960231781006	14.4150543212891	2625.8095703125	1770.17895507813	3377.97021484375\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	14.2556962966919	14.8807125091553	13.7951231002808	3086.19409179688	2168.69140625	47.920249938965	3702.13940429688	14.4342937469482	14.975323677063	13.7777452468872	3314.33349609375	2163.703125	4398.58154296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	14.1387548446655	14.8439512252808	13.8146514892578	3157.99975585938	2119.69287109375	47.3193740844728	3633.91284179688	14.4342937469482	14.975323677063	13.7777452468872	3314.33349609375	2163.703125	4398.58154296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	14.3047256469727	15.0497159957886	13.9728107452393	3057.51586914063	2066.32177734375	44.7616577148438	3498.69482421875	14.4342937469482	14.975323677063	13.7777452468872	3314.33349609375	2163.703125	4398.58154296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	14.2776937484741	14.8581867218018	13.9991321563721	3057.94287109375	2087.11181640625	44.6120452880859	3514.697265625	14.4342937469482	14.975323677063	13.7777452468872	3314.33349609375	2163.703125	4398.58154296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	14.3072690963745	14.9296064376831	13.8466892242432	2861.70434570313	2015.20788574219	44.9149894714356	3452.48754882813	14.4342937469482	14.975323677063	13.7777452468872	3314.33349609375	2163.703125	4398.58154296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	14.2870960235596	14.9172210693359	13.8127174377441	2825.40869140625	1995.10278320313	43.9663162231444	3402.02490234375	14.4342937469482	14.975323677063	13.7777452468872	3314.33349609375	2163.703125	4398.58154296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	14.4871501922607	14.9830846786499	14.1264057159424	2803.31127929688	1969.92065429688	44.4496994018553	3392.31103515625	14.4342937469482	14.975323677063	13.7777452468872	3314.33349609375	2163.703125	4398.58154296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	14.5051021575928	14.9641761779785	14.1503343582153	2744.49560546875	1930.14599609375	43.3797073364259	3318.29663085938	14.4342937469482	14.975323677063	13.7777452468872	3314.33349609375	2163.703125	4398.58154296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	14.4072952270508	14.9226198196411	14.0123252868652	2592.72509765625	1813.99597167969	41.2267799377441	3133.2529296875	14.4342937469482	14.975323677063	13.7777452468872	3314.33349609375	2163.703125	4398.58154296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	14.5424242019653	15.0143032073975	14.1942939758301	2465.92553710938	1716.47888183594	39.4963645935059	2980.36254882813	14.4342937469482	14.975323677063	13.7777452468872	3314.33349609375	2163.703125	4398.58154296875\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	13.5613317489624	14.6442375183105	12.8163108825684	3795.55126953125	2525.45727539063	66.7476119995116	4661.380859375	13.8945407867432	14.8133544921875	12.6795244216919	4136.11865234375	2578.39477539063	5692.8603515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	13.0756607055664	14.778754234314	12.2824993133545	3893.8134765625	2469.69873046875	64.8900604248047	4546.1806640625	13.8945407867432	14.8133544921875	12.6795244216919	4136.11865234375	2578.39477539063	5692.8603515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	13.6919174194336	14.6971492767334	13.243803024292	3779.64575195313	2422.9140625	61.2845458984375	4384.01953125	13.8945407867432	14.8133544921875	12.6795244216919	4136.11865234375	2578.39477539063	5692.8603515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	13.4474649429321	14.8695411682129	12.7717847824097	3767.96484375	2422.41357421875	61.8156127929688	4400.51318359375	13.8945407867432	14.8133544921875	12.6795244216919	4136.11865234375	2578.39477539063	5692.8603515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	13.5879230499268	14.6881017684937	12.7853984832764	3538.95581054688	2380.40283203125	61.6845855712891	4354.3095703125	13.8945407867432	14.8133544921875	12.6795244216919	4136.11865234375	2578.39477539063	5692.8603515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	13.6421241760254	14.735426902771	12.9141931533813	3491.2822265625	2353.22607421875	60.6341705322266	4293.51953125	13.8945407867432	14.8133544921875	12.6795244216919	4136.11865234375	2578.39477539063	5692.8603515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	13.8409643173218	14.7330417633057	13.1091136932373	3439.19458007813	2332.0546875	59.1166534423828	4223.78759765625	13.8945407867432	14.8133544921875	12.6795244216919	4136.11865234375	2578.39477539063	5692.8603515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	13.8610162734985	14.8814840316772	13.095682144165	3361.794921875	2280.50048828125	57.7631378173828	4128.9208984375	13.8945407867432	14.8133544921875	12.6795244216919	4136.11865234375	2578.39477539063	5692.8603515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	13.8423843383789	14.8330202102661	13.0833950042725	3220.8916015625	2149.65014648438	57.0942916870116	3976.66748046875	13.8945407867432	14.8133544921875	12.6795244216919	4136.11865234375	2578.39477539063	5692.8603515625\\\
HGV	2022	REF N HB42	CO2e	Rural	RUR/Trunk/80/St+Go	80	Stopgo	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	13.8733654022217	14.7569961547852	13.1774091720581	3022.91967773438	2039.5546875	51.8761978149416	3699.59301757813	13.8945407867432	14.8133544921875	12.6795244216919	4136.11865234375	2578.39477539063	5692.8603515625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	46.2536582946777	46.4806175231934	46.2438850402832	846.510437011719	616.77294921875	12.0719814300538	1003.07635498047	46.1737289428711	46.4805030822754	45.9944725036621	887.71533203125	611.922668457031	1171.03784179688\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	46.3596649169922	46.4806594848633	46.3083992004395	853.677917480469	598.939880371094	11.6135158538818	970.572387695313	46.1737289428711	46.4805030822754	45.9944725036621	887.71533203125	611.922668457031	1171.03784179688\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	46.139404296875	46.4494972229004	46.0028190612793	825.001037597656	583.012451171875	10.9311580657959	932.809509277344	46.1737289428711	46.4805030822754	45.9944725036621	887.71533203125	611.922668457031	1171.03784179688\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	46.103946685791	46.4806594848633	45.9265365600586	825.241333007813	587.67333984375	10.9231967926025	937.215637207031	46.1737289428711	46.4805030822754	45.9944725036621	887.71533203125	611.922668457031	1171.03784179688\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	46.1287994384766	46.4806594848633	45.8713569641113	779.070251464844	571.604370117188	11.087230682373	926.395751953125	46.1737289428711	46.4805030822754	45.9944725036621	887.71533203125	611.922668457031	1171.03784179688\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	46.1098403930664	46.4806594848633	45.8703346252441	771.537109375	566.168090820313	10.9824295043945	917.605834960938	46.1737289428711	46.4805030822754	45.9944725036621	887.71533203125	611.922668457031	1171.03784179688\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	46.4346084594727	46.4806594848633	46.4117698669434	760.339599609375	564.759094238281	10.5095138549805	901.063537597656	46.1737289428711	46.4805030822754	45.9944725036621	887.71533203125	611.922668457031	1171.03784179688\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	46.4242897033691	46.4806594848633	46.4045791625977	745.719482421875	553.9931640625	10.2952842712402	883.442260742188	46.1737289428711	46.4805030822754	45.9944725036621	887.71533203125	611.922668457031	1171.03784179688\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	46.3405799865723	46.4806594848633	46.2486953735352	711.649597167969	521.530212402344	10.122522354126	845.450927734375	46.1737289428711	46.4805030822754	45.9944725036621	887.71533203125	611.922668457031	1171.03784179688\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	46.3248977661133	46.4806594848633	46.207763671875	671.975341796875	494.179992675781	9.44518661499025	796.425964355469	46.1737289428711	46.4805030822754	45.9944725036621	887.71533203125	611.922668457031	1171.03784179688\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	46.4806594848633	46.4806594848633	46.4806594848633	98.3810882568359	94.4685211181641	0.22884821891784	101.791664123535	46.4803276062012	46.4805755615234	46.4797897338867	77.9229736328125	69.5381088256836	89.0775375366211\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	46.4806594848633	46.4806594848633	46.4806594848633	95.41748046875	90.2825927734375	0.235876321792603	97.8306350708008	46.4803276062012	46.4805755615234	46.4797897338867	77.9229736328125	69.5381088256836	89.0775375366211\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	46.4806594848633	46.4806594848633	46.4806594848633	91.5660171508789	87.7303924560547	0.1739661693573	93.2973098754883	46.4803276062012	46.4805755615234	46.4797897338867	77.9229736328125	69.5381088256836	89.0775375366211\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	46.4806594848633	46.4806594848633	46.4806594848633	86.0934066772461	81.7101669311523	0.204397439956665	88.2508850097656	46.4803276062012	46.4805755615234	46.4797897338867	77.9229736328125	69.5381088256836	89.0775375366211\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	46.4806594848633	46.4806594848633	46.4806594848633	62.3989753723145	57.1520156860352	0.297502040863034	66.6720809936523	46.4803276062012	46.4805755615234	46.4797897338867	77.9229736328125	69.5381088256836	89.0775375366211\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	46.4806289672852	46.4806594848633	46.4805946350098	61.8590888977051	56.6608428955078	0.294748783111572	66.0928039550781	46.4803276062012	46.4805755615234	46.4797897338867	77.9229736328125	69.5381088256836	89.0775375366211\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	46.4806594848633	46.4806594848633	46.4806594848633	71.3977203369141	66.3371124267578	0.288152456283569	75.557991027832	46.4803276062012	46.4805755615234	46.4797897338867	77.9229736328125	69.5381088256836	89.0775375366211\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	46.4806594848633	46.4806594848633	46.4806594848633	70.3510513305664	65.3887634277344	0.28254771232605	74.430290222168	46.4803276062012	46.4805755615234	46.4797897338867	77.9229736328125	69.5381088256836	89.0775375366211\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	46.4806594848633	46.4806594848633	46.4806594848633	65.3618316650391	58.9151840209961	0.35469079017639	70.2652893066406	46.4803276062012	46.4805755615234	46.4797897338867	77.9229736328125	69.5381088256836	89.0775375366211\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	46.4806594848633	46.4806594848633	46.4806594848633	63.1721992492676	58.1568069458008	0.278067111968993	67.0549545288086	46.4803276062012	46.4805755615234	46.4797897338867	77.9229736328125	69.5381088256836	89.0775375366211\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	46.4634475708008	46.4806594848633	46.4471817016602	169.312789916992	151.713150024414	0.981063365936281	183.107177734375	46.4733428955078	46.4805488586426	46.4482727050781	153.687240600586	124.916519165039	187.249969482422\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	46.457820892334	46.4806594848633	46.4469375610352	166.471435546875	144.359405517578	1.01194763183594	176.741729736328	46.4733428955078	46.4805488586426	46.4482727050781	153.687240600586	124.916519165039	187.249969482422\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	46.4576835632324	46.4806594848633	46.4472885131836	160.165420532227	140.116302490234	0.907145977020281	169.144973754883	46.4733428955078	46.4805488586426	46.4482727050781	153.687240600586	124.916519165039	187.249969482422\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	46.4806594848633	46.4806594848633	46.4806594848633	154.891815185547	134.984497070313	0.920565128326406	164.442581176758	46.4733428955078	46.4805488586426	46.4482727050781	153.687240600586	124.916519165039	187.249969482422\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	46.430965423584	46.4806594848633	46.3809509277344	129.398254394531	110.164283752441	1.07018399238588	144.410171508789	46.4733428955078	46.4805488586426	46.4482727050781	153.687240600586	124.916519165039	187.249969482422\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	46.4639587402344	46.4806594848633	46.4471549987793	128.071212768555	109.163513183594	1.05103111267091	142.796508789063	46.4733428955078	46.4805488586426	46.4482727050781	153.687240600586	124.916519165039	187.249969482422\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	46.463550567627	46.4806594848633	46.4460105895996	136.631729125977	118.728424072266	0.997453689575187	150.646942138672	46.4733428955078	46.4805488586426	46.4482727050781	153.687240600586	124.916519165039	187.249969482422\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	46.4653358459473	46.4806594848633	46.4496269226074	134.355239868164	116.747138977051	0.981408357620219	148.152206420898	46.4733428955078	46.4805488586426	46.4482727050781	153.687240600586	124.916519165039	187.249969482422\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	46.4806594848633	46.4806594848633	46.4806594848633	125.42049407959	105.226692199707	1.09934639930725	140.405776977539	46.4733428955078	46.4805488586426	46.4482727050781	153.687240600586	124.916519165039	187.249969482422\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	46.4806594848633	46.4806594848633	46.4806594848633	119.468109130859	102.384429931641	0.933121204376219	132.24430847168	46.4733428955078	46.4805488586426	46.4482727050781	153.687240600586	124.916519165039	187.249969482422\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	46.2762985229492	46.4806594848633	46.1048240661621	357.830108642578	316.254302978516	2.32036876678466	390.506103515625	46.3677406311035	46.480598449707	46.0283699035645	342.667541503906	277.028472900391	425.396209716797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	46.2242889404297	46.4806594848633	46.1046524047852	352.141326904297	302.395721435547	2.27882385253906	375.318084716797	46.3677406311035	46.480598449707	46.0283699035645	342.667541503906	277.028472900391	425.396209716797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	46.3173789978027	46.4806594848633	46.2435302734375	340.416442871094	296.446350097656	1.99036026000978	360.137878417969	46.3677406311035	46.480598449707	46.0283699035645	342.667541503906	277.028472900391	425.396209716797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	46.3890914916992	46.4806594848633	46.3433952331543	336.288940429688	293.539611816406	1.98118495941162	356.937530517578	46.3677406311035	46.480598449707	46.0283699035645	342.667541503906	277.028472900391	425.396209716797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	46.3880958557129	46.4806594848633	46.3072357177734	307.520965576172	268.933685302734	2.18588161468506	338.881896972656	46.3677406311035	46.480598449707	46.0283699035645	342.667541503906	277.028472900391	425.396209716797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	46.3627471923828	46.4806594848633	46.244083404541	304.606384277344	266.405029296875	2.16519451141356	335.691253662109	46.3677406311035	46.480598449707	46.0283699035645	342.667541503906	277.028472900391	425.396209716797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	46.4614105224609	46.4806594848633	46.4416770935059	307.280334472656	270.886596679688	2.07154083251953	337.175903320313	46.3677406311035	46.480598449707	46.0283699035645	342.667541503906	277.028472900391	425.396209716797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	46.4636878967285	46.4806594848633	46.4462852478027	301.530731201172	265.901580810547	2.02760887145997	330.785064697266	46.3677406311035	46.480598449707	46.0283699035645	342.667541503906	277.028472900391	425.396209716797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	46.461051940918	46.4806594848633	46.441577911377	281.368316650391	240.296630859375	2.27126693725588	312.977172851563	46.3677406311035	46.480598449707	46.0283699035645	342.667541503906	277.028472900391	425.396209716797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	46.4128036499023	46.4806594848633	46.3468246459961	268.082580566406	233.726593017578	1.91412544250487	294.978607177734	46.3677406311035	46.480598449707	46.0283699035645	342.667541503906	277.028472900391	425.396209716797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	45.6483612060547	46.446891784668	45.0770530700684	1555.826171875	990.863647460938	29.6915626525879	1940.99365234375	45.9148941040039	46.4725112915039	44.9368476867676	1716.83166503906	1043.2919921875	2398.1796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	45.6014595031738	46.4462356567383	45.204776763916	1600.544921875	969.008544921875	28.7912712097167	1890.32922363281	45.9148941040039	46.4725112915039	44.9368476867676	1716.83166503906	1043.2919921875	2398.1796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	45.8941345214844	46.4800720214844	45.6350364685059	1537.18725585938	937.981628417969	27.0662631988525	1804.10205078125	45.9148941040039	46.4725112915039	44.9368476867676	1716.83166503906	1043.2919921875	2398.1796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	45.9066963195801	46.4806594848633	45.6389083862305	1544.34912109375	954.784057617188	27.1028823852538	1822.07629394531	45.9148941040039	46.4725112915039	44.9368476867676	1716.83166503906	1043.2919921875	2398.1796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	45.9403533935547	46.449390411377	45.568286895752	1461.07507324219	949.702758789063	27.311134338379	1823.65905761719	45.9148941040039	46.4725112915039	44.9368476867676	1716.83166503906	1043.2919921875	2398.1796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	45.9733085632324	46.4493217468262	45.5685768127441	1446.46118164063	940.359802246094	27.0309581756592	1805.35046386719	45.9148941040039	46.4725112915039	44.9368476867676	1716.83166503906	1043.2919921875	2398.1796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	46.0258560180664	46.4805679321289	45.6352272033691	1418.70861816406	932.154907226563	26.1209754943849	1768.02612304688	45.9148941040039	46.4725112915039	44.9368476867676	1716.83166503906	1043.2919921875	2398.1796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	46.1327438354492	46.449390411377	45.8973159790039	1390.32666015625	914.623291015625	25.5227203369142	1731.35034179688	45.9148941040039	46.4725112915039	44.9368476867676	1716.83166503906	1043.2919921875	2398.1796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	46.046817779541	46.4460029602051	45.7684326171875	1344.64477539063	888.121826171875	24.2832641601564	1665.18627929688	45.9148941040039	46.4725112915039	44.9368476867676	1716.83166503906	1043.2919921875	2398.1796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	46.1559715270996	46.4460945129395	45.9529266357422	1260.23205566406	822.117553710938	23.2418556213379	1565.85693359375	45.9148941040039	46.4725112915039	44.9368476867676	1716.83166503906	1043.2919921875	2398.1796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	40.8522186279297	46.4527244567871	36.1814918518066	2344.74975585938	1409.71240234375	48.9185485839844	2975.10595703125	43.8372840881348	46.3701248168945	38.7502632141113	2626.68286132813	1517.38720703125	3713.06005859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	40.3715896606445	46.3952903747559	37.545955657959	2420.82470703125	1384.57116699219	47.2207527160644	2895.63525390625	43.8372840881348	46.3701248168945	38.7502632141113	2626.68286132813	1517.38720703125	3713.06005859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	40.656322479248	46.3417434692383	38.0934295654297	2323.85180664063	1337.26635742188	44.5558471679688	2763.05346679688	43.8372840881348	46.3701248168945	38.7502632141113	2626.68286132813	1517.38720703125	3713.06005859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	42.7163047790527	46.4433479309082	40.8995132446289	2342.65991210938	1366.22119140625	44.8490371704103	2801.39038085938	43.8372840881348	46.3701248168945	38.7502632141113	2626.68286132813	1517.38720703125	3713.06005859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	42.9228439331055	46.3626670837402	39.8366470336914	2218.1005859375	1373.01721191406	44.8735313415528	2808.97021484375	43.8372840881348	46.3701248168945	38.7502632141113	2626.68286132813	1517.38720703125	3713.06005859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	41.8833198547363	46.1385536193848	37.9786109924316	2191.32275390625	1359.82653808594	44.0384864807128	2769.05810546875	43.8372840881348	46.3701248168945	38.7502632141113	2626.68286132813	1517.38720703125	3713.06005859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	44.1699371337891	46.3436470031738	42.1309623718262	2159.28784179688	1344.05346679688	43.5495910644531	2737.64038085938	43.8372840881348	46.3701248168945	38.7502632141113	2626.68286132813	1517.38720703125	3713.06005859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	43.9602851867676	46.3786582946777	41.7188568115234	2112.90356445313	1317.75903320313	42.4534149169922	2676.26831054688	43.8372840881348	46.3701248168945	38.7502632141113	2626.68286132813	1517.38720703125	3713.06005859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	44.0086288452148	46.4460601806641	41.8559341430664	2030.46911621094	1287.59387207031	39.3308296203616	2546.18041992188	43.8372840881348	46.3701248168945	38.7502632141113	2626.68286132813	1517.38720703125	3713.06005859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	44.3952369689941	46.3432006835938	42.6264457702637	1912.83361816406	1186.87414550781	38.2996559143069	2412.46313476563	43.8372840881348	46.3701248168945	38.7502632141113	2626.68286132813	1517.38720703125	3713.06005859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	36.6182899475098	46.2762680053711	30.701488494873	3194.02075195313	1865.93334960938	69.6538391113281	4094.85620117188	39.2381248474121	46.2407455444336	29.3271789550781	3602.07275390625	2020.28698730469	5227.41357421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	32.6299934387207	46.1126327514648	26.40114402771	3311.54907226563	1838.9228515625	67.1195144653322	3986.74731445313	39.2381248474121	46.2407455444336	29.3271789550781	3602.07275390625	2020.28698730469	5227.41357421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	38.1279792785645	46.2203712463379	34.546875	3181.35717773438	1773.09533691406	63.6067237854006	3808.51049804688	39.2381248474121	46.2407455444336	29.3271789550781	3602.07275390625	2020.28698730469	5227.41357421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	34.0800704956055	46.0369033813477	28.3465137481689	3200.27587890625	1816.33752441406	63.6066856384278	3851.75146484375	39.2381248474121	46.2407455444336	29.3271789550781	3602.07275390625	2020.28698730469	5227.41357421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	36.4957733154297	46.2735900878906	29.4938926696777	3035.080078125	1835.11120605469	63.9617576599122	3881.88745117188	39.2381248474121	46.2407455444336	29.3271789550781	3602.07275390625	2020.28698730469	5227.41357421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	36.7116050720215	46.2438583374023	30.4759883880615	2995.20971679688	1816.76977539063	62.8469009399413	3827.87060546875	39.2381248474121	46.2407455444336	29.3271789550781	3602.07275390625	2020.28698730469	5227.41357421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	38.2485427856445	46.3101844787598	31.4722347259521	2935.31469726563	1790.45581054688	61.1071624755859	3745.88500976563	39.2381248474121	46.2407455444336	29.3271789550781	3602.07275390625	2020.28698730469	5227.41357421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	37.9096145629883	46.2808952331543	31.3497848510742	2872.56469726563	1755.39990234375	59.7470779418947	3667.30639648438	39.2381248474121	46.2407455444336	29.3271789550781	3602.07275390625	2020.28698730469	5227.41357421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	37.8235702514648	46.2684135437012	31.1580448150635	2794.984375	1705.21960449219	58.3431434631347	3572.2001953125	39.2381248474121	46.2407455444336	29.3271789550781	3602.07275390625	2020.28698730469	5227.41357421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Freeflow	50	Freeflow	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	38.4036750793457	46.3443298339844	31.9106712341309	2600.6318359375	1580.29846191406	53.9700050354006	3307.33862304688	39.2381248474121	46.2407455444336	29.3271789550781	3602.07275390625	2020.28698730469	5227.41357421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	22.3985767364502	22.4870624542236	22.3296699523926	1162.42468261719	825.985290527344	17.7431583404543	1393.76635742188	22.4546928405762	22.5198745727539	22.3738174438477	1214.45971679688	815.621765136719	1623.81799316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	22.3699569702148	22.5199813842773	22.3004722595215	1177.31188964844	801.687927246094	17.1265735626221	1349.73828125	22.4546928405762	22.5198745727539	22.3738174438477	1214.45971679688	815.621765136719	1623.81799316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	22.4987258911133	22.5200710296631	22.4898109436035	1144.30261230469	793.528930664063	15.8449478149415	1300.56726074219	22.4546928405762	22.5198745727539	22.3738174438477	1214.45971679688	815.621765136719	1623.81799316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	22.4487400054932	22.5200710296631	22.4131965637207	1149.6474609375	807.941955566406	15.7153797149658	1310.83410644531	22.4546928405762	22.5198745727539	22.3738174438477	1214.45971679688	815.621765136719	1623.81799316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	22.4748497009277	22.5200710296631	22.429780960083	1062.94641113281	760.582763671875	16.1698989868164	1278.01953125	22.4546928405762	22.5198745727539	22.3738174438477	1214.45971679688	815.621765136719	1623.81799316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	22.4898853302002	22.5200710296631	22.4602832794189	1052.36547851563	751.823913574219	16.0660305023194	1265.93688964844	22.4546928405762	22.5198745727539	22.3738174438477	1214.45971679688	815.621765136719	1623.81799316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	22.5026741027832	22.5200710296631	22.4856967926025	1034.31750488281	747.048583984375	15.4329376220705	1240.90258789063	22.4546928405762	22.5198745727539	22.3738174438477	1214.45971679688	815.621765136719	1623.81799316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	22.5012340545654	22.5200710296631	22.4827976226807	1014.02667236328	732.676330566406	15.1124057769776	1216.27331542969	22.4546928405762	22.5198745727539	22.3738174438477	1214.45971679688	815.621765136719	1623.81799316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	22.4876098632813	22.5200710296631	22.4687824249268	953.012329101563	674.667419433594	14.8305759429933	1149.24584960938	22.4546928405762	22.5198745727539	22.3738174438477	1214.45971679688	815.621765136719	1623.81799316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	22.4958553314209	22.5200710296631	22.4723377227783	901.561828613281	644.440246582031	13.6682949066162	1081.82568359375	22.4546928405762	22.5198745727539	22.3738174438477	1214.45971679688	815.621765136719	1623.81799316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	22.5031261444092	22.5200710296631	22.4871120452881	232.915267944336	249.432861328125	-0.772511959075938	224.712478637695	22.5195560455322	22.5200634002686	22.5179862976074	181.786697387695	188.865478515625	191.509902954102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	22.497802734375	22.5200710296631	22.4871883392334	222.9462890625	239.20426940918	-0.734096527099625	215.713180541992	22.5195560455322	22.5200634002686	22.5179862976074	181.786697387695	188.865478515625	191.509902954102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	22.5200710296631	22.5200710296631	22.5200710296631	223.44792175293	243.630477905273	-0.908818244934063	214.548294067383	22.5195560455322	22.5200634002686	22.5179862976074	181.786697387695	188.865478515625	191.509902954102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	22.5200710296631	22.5200710296631	22.5200710296631	220.036163330078	238.80908203125	-0.847633361816406	211.684814453125	22.5195560455322	22.5200634002686	22.5179862976074	181.786697387695	188.865478515625	191.509902954102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	22.5200710296631	22.5200710296631	22.5200710296631	168.469879150391	177.687835693359	-0.391952037811281	165.145370483398	22.5195560455322	22.5200634002686	22.5179862976074	181.786697387695	188.865478515625	191.509902954102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	22.5200710296631	22.5200710296631	22.5200710296631	166.906890869141	176.035690307617	-0.388158321380593	163.614624023438	22.5195560455322	22.5200634002686	22.5179862976074	181.786697387695	188.865478515625	191.509902954102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	22.5200710296631	22.5200710296631	22.5200710296631	173.290100097656	183.113403320313	-0.427788257598906	169.424179077148	22.5195560455322	22.5200634002686	22.5179862976074	181.786697387695	188.865478515625	191.509902954102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	22.5200710296631	22.5200710296631	22.5200710296631	170.227722167969	179.852035522461	-0.419088840484625	166.441192626953	22.5195560455322	22.5200634002686	22.5179862976074	181.786697387695	188.865478515625	191.509902954102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	22.5200710296631	22.5200710296631	22.5200710296631	149.968872070313	153.455871582031	-0.108530044555656	149.98291015625	22.5195560455322	22.5200634002686	22.5179862976074	181.786697387695	188.865478515625	191.509902954102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	22.5200710296631	22.5200710296631	22.5200710296631	148.121780395508	155.105911254883	-0.282780170440688	146.056945800781	22.5195560455322	22.5200634002686	22.5179862976074	181.786697387695	188.865478515625	191.509902954102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	22.5137424468994	22.5200710296631	22.5182437896729	393.926513671875	366.898254394531	1.48944377899172	414.560455322266	22.5189628601074	22.5200653076172	22.519287109375	353.141479492188	311.892181396484	406.021820068359\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	22.5173568725586	22.5200710296631	22.5178565979004	385.881378173828	353.368041992188	1.48643779754637	400.934051513672	22.5189628601074	22.5200653076172	22.519287109375	353.141479492188	311.892181396484	406.021820068359\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	22.5198268890381	22.5200710296631	22.5197162628174	384.169006347656	357.276977539063	1.21717453002928	396.2265625	22.5189628601074	22.5200653076172	22.519287109375	353.141479492188	311.892181396484	406.021820068359\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	22.5200710296631	22.5200710296631	22.5200710296631	382.556030273438	357.747283935547	1.15070056915284	394.569702148438	22.5189628601074	22.5200653076172	22.519287109375	353.141479492188	311.892181396484	406.021820068359\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	22.5200710296631	22.5200710296631	22.5200710296631	321.846038818359	295.358917236328	1.47916698455813	342.692260742188	22.5189628601074	22.5200653076172	22.519287109375	353.141479492188	311.892181396484	406.021820068359\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	22.5200710296631	22.5200710296631	22.5200710296631	318.808410644531	292.575256347656	1.46498012542725	339.454620361328	22.5189628601074	22.5200653076172	22.519287109375	353.141479492188	311.892181396484	406.021820068359\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	22.5193176269531	22.5200710296631	22.5185432434082	324.36474609375	297.946044921875	1.48565959930419	345.487152099609	22.5189628601074	22.5200653076172	22.519287109375	353.141479492188	311.892181396484	406.021820068359\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	22.5195083618164	22.5200710296631	22.5189323425293	317.907958984375	292.417236328125	1.43050861358644	338.193511962891	22.5189628601074	22.5200653076172	22.519287109375	353.141479492188	311.892181396484	406.021820068359\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	22.5200710296631	22.5200710296631	22.5200710296631	283.544036865234	252.344787597656	1.70073890686038	306.768432617188	22.5189628601074	22.5200653076172	22.519287109375	353.141479492188	311.892181396484	406.021820068359\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	22.5200710296631	22.5200710296631	22.5200710296631	275.147552490234	251.320541381836	1.30561685562134	293.100280761719	22.5189628601074	22.5200653076172	22.519287109375	353.141479492188	311.892181396484	406.021820068359\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	22.5014400482178	22.5200710296631	22.4844627380371	690.609436035156	560.748596191406	6.90139007568359	781.593078613281	22.5100841522217	22.5200481414795	22.479808807373	678.939086914063	517.369323730469	853.824462890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	22.492826461792	22.5200710296631	22.4798774719238	688.367248535156	540.690551757813	6.73838806152344	756.318969726563	22.5100841522217	22.5200481414795	22.479808807373	678.939086914063	517.369323730469	853.824462890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	22.4675941467285	22.5200710296631	22.4438591003418	679.279418945313	541.065063476563	6.24573135375975	740.928466796875	22.5100841522217	22.5200481414795	22.479808807373	678.939086914063	517.369323730469	853.824462890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	22.5193729400635	22.5200710296631	22.5190238952637	679.220642089844	547.470153808594	6.06430435180663	741.527893066406	22.5100841522217	22.5200481414795	22.479808807373	678.939086914063	517.369323730469	853.824462890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	22.5195732116699	22.5200710296631	22.5190734863281	609.381652832031	489.272338867188	6.482946395874	696.726623535156	22.5100841522217	22.5200481414795	22.479808807373	678.939086914063	517.369323730469	853.824462890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	22.5192775726318	22.5200710296631	22.5184803009033	603.297668457031	482.834686279297	6.4953145980835	690.684753417969	22.5100841522217	22.5200481414795	22.479808807373	678.939086914063	517.369323730469	853.824462890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	22.5008163452148	22.5200710296631	22.4816188812256	599.564392089844	484.727783203125	6.213773727417	683.568542480469	22.5100841522217	22.5200481414795	22.479808807373	678.939086914063	517.369323730469	853.824462890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	22.5014839172363	22.5200710296631	22.4827823638916	587.964782714844	475.515411376953	6.09425258636475	670.531494140625	22.5100841522217	22.5200481414795	22.479808807373	678.939086914063	517.369323730469	853.824462890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	22.5034656524658	22.5200710296631	22.4869709014893	537.269226074219	422.525360107422	6.13286304473878	618.776977539063	22.5100841522217	22.5200481414795	22.479808807373	678.939086914063	517.369323730469	853.824462890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	22.5033550262451	22.5200710296631	22.4871006011963	516.169189453125	413.829528808594	5.46536445617675	588.72119140625	22.5100841522217	22.5200481414795	22.479808807373	678.939086914063	517.369323730469	853.824462890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	22.3007793426514	22.5182189941406	22.1041984558105	1766.75988769531	1151.53479003906	32.3520698547366	2186.80102539063	22.4037590026855	22.5198554992676	22.2217864990234	1914.103515625	1181.33337402344	2650.08544921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	22.3439331054688	22.5190849304199	22.2613639831543	1808.75524902344	1124.35229492188	31.1988296508787	2122.71484375	22.4037590026855	22.5198554992676	22.2217864990234	1914.103515625	1181.33337402344	2650.08544921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	22.2814922332764	22.5191497802734	22.1746044158936	1754.65795898438	1103.34289550781	29.4213790893556	2044.82702636719	22.4037590026855	22.5198554992676	22.2217864990234	1914.103515625	1181.33337402344	2650.08544921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	22.3382625579834	22.5200710296631	22.2495079040527	1757.35571289063	1127.28857421875	28.9643402099609	2054.1474609375	22.4037590026855	22.5198554992676	22.2217864990234	1914.103515625	1181.33337402344	2650.08544921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	22.3575305938721	22.5200710296631	22.2531795501709	1649.07556152344	1091.71740722656	29.7373161315919	2043.3115234375	22.4037590026855	22.5198554992676	22.2217864990234	1914.103515625	1181.33337402344	2650.08544921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	22.3802719116211	22.5200710296631	22.2924461364746	1630.74499511719	1081.27905273438	29.3166160583494	2019.41076660156	22.4037590026855	22.5198554992676	22.2217864990234	1914.103515625	1181.33337402344	2650.08544921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	22.3529605865479	22.5188465118408	22.2155017852783	1600.587890625	1072.15161132813	28.3359794616697	1978.90295410156	22.4037590026855	22.5198554992676	22.2217864990234	1914.103515625	1181.33337402344	2650.08544921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	22.3911533355713	22.5200710296631	22.2926979064941	1567.13732910156	1049.05615234375	27.7545166015625	1937.20068359375	22.4037590026855	22.5198554992676	22.2217864990234	1914.103515625	1181.33337402344	2650.08544921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	22.3919296264648	22.5200710296631	22.2912540435791	1502.02514648438	993.527526855469	27.0633754730225	1859.55554199219	22.4037590026855	22.5198554992676	22.2217864990234	1914.103515625	1181.33337402344	2650.08544921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	22.4501552581787	22.5200710296631	22.4064655303955	1403.07849121094	928.875549316406	25.1636409759523	1734.11206054688	22.4037590026855	22.5198554992676	22.2217864990234	1914.103515625	1181.33337402344	2650.08544921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	22.0925674438477	22.4817085266113	21.781566619873	2477.10864257813	1530.35546875	49.7372360229494	3121.94702148438	22.2004776000977	22.5068531036377	21.7423076629639	2731.30834960938	1606.37133789063	3862.16455078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	22.0134010314941	22.4408626556396	21.812442779541	2550.47290039063	1503.23315429688	47.7354583740234	3030.76782226563	22.2004776000977	22.5068531036377	21.7423076629639	2731.30834960938	1606.37133789063	3862.16455078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	22.1743106842041	22.405933380127	22.0724716186523	2459.73657226563	1466.27563476563	44.8698959350584	2902.1123046875	22.2004776000977	22.5068531036377	21.7423076629639	2731.30834960938	1606.37133789063	3862.16455078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	22.1973094940186	22.4871139526367	22.0559902191162	2471.83740234375	1494.85266113281	44.90930557251	2931.95043945313	22.2004776000977	22.5068531036377	21.7423076629639	2731.30834960938	1606.37133789063	3862.16455078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	22.2470417022705	22.4871139526367	22.0513401031494	2330.6259765625	1477.82446289063	45.4288330078125	2931.54711914063	22.2004776000977	22.5068531036377	21.7423076629639	2731.30834960938	1606.37133789063	3862.16455078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	22.1867065429688	22.5178985595703	21.973560333252	2306.63842773438	1463.16723632813	44.9112396240234	2900.32690429688	22.2004776000977	22.5068531036377	21.7423076629639	2731.30834960938	1606.37133789063	3862.16455078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	22.3052597045898	22.5183734893799	22.1832714080811	2259.0244140625	1447.43481445313	43.4326782226563	2837.28051757813	22.2004776000977	22.5068531036377	21.7423076629639	2731.30834960938	1606.37133789063	3862.16455078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	22.2443313598633	22.4872035980225	22.0242481231689	2213.22729492188	1417.74304199219	42.5898933410644	2780.61962890625	22.2004776000977	22.5068531036377	21.7423076629639	2731.30834960938	1606.37133789063	3862.16455078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	22.2040138244629	22.5197277069092	21.9184799194336	2128.68530273438	1359.62048339844	40.8058357238769	2665.4072265625	22.2004776000977	22.5068531036377	21.7423076629639	2731.30834960938	1606.37133789063	3862.16455078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	22.3451042175293	22.5200710296631	22.2238578796387	1983.33264160156	1254.9375	38.5469284057619	2488.43920898438	22.2004776000977	22.5068531036377	21.7423076629639	2731.30834960938	1606.37133789063	3862.16455078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	21.4470901489258	22.4650230407715	20.6466503143311	3281.09130859375	1949.60925292969	69.8656501770019	4185.31005859375	21.699068069458	22.4507350921631	20.1321620941162	3659.97216796875	2075.43041992188	5273.7109375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	20.8906288146973	22.361457824707	20.202392578125	3394.08935546875	1917.67639160156	67.2949180603028	4071.11376953125	21.699068069458	22.4507350921631	20.1321620941162	3659.97216796875	2075.43041992188	5273.7109375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	21.4315605163574	22.368127822876	21.0090408325195	3266.19506835938	1865.6220703125	63.2617721557619	3889.99877929688	21.699068069458	22.4507350921631	20.1321620941162	3659.97216796875	2075.43041992188	5273.7109375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	21.771354675293	22.4835300445557	21.4292411804199	3284.48315429688	1902.98803710938	63.4948501586913	3934.8232421875	21.699068069458	22.4507350921631	20.1321620941162	3659.97216796875	2075.43041992188	5273.7109375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	21.5274219512939	22.4824485778809	20.6750316619873	3104.72534179688	1902.58361816406	64.0750999450684	3952.98681640625	21.699068069458	22.4507350921631	20.1321620941162	3659.97216796875	2075.43041992188	5273.7109375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	21.6105651855469	22.4255542755127	20.8776512145996	3070.51708984375	1884.90344238281	63.2154121398928	3907.79663085938	21.699068069458	22.4507350921631	20.1321620941162	3659.97216796875	2075.43041992188	5273.7109375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	21.8247127532959	22.4822750091553	21.246639251709	3001.10546875	1860.19140625	61.1025238037109	3815.47216796875	21.699068069458	22.4507350921631	20.1321620941162	3659.97216796875	2075.43041992188	5273.7109375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	21.7563037872314	22.4302978515625	21.14377784729	2939.74877929688	1823.802734375	59.7595901489259	3736.10961914063	21.699068069458	22.4507350921631	20.1321620941162	3659.97216796875	2075.43041992188	5273.7109375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	21.8580894470215	22.4826755523682	21.3293991088867	2836.27075195313	1763.15686035156	57.0209693908694	3587.82788085938	21.699068069458	22.4507350921631	20.1321620941162	3659.97216796875	2075.43041992188	5273.7109375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/Satur.	50	Saturated	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	21.829460144043	22.3644962310791	21.4199447631836	2644.1015625	1618.67614746094	54.2957344055175	3356.1396484375	21.699068069458	22.4507350921631	20.1321620941162	3659.97216796875	2075.43041992188	5273.7109375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	12.1837673187256	12.3333683013916	12.0842533111572	2053.42456054688	1499.43774414063	28.9753799438475	2426.64990234375	12.2829942703247	12.3709421157837	12.1238565444946	2069.90087890625	1388.38732910156	2737.27075195313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	12.1998891830444	12.3423166275024	12.1360111236572	2075.16381835938	1465.97985839844	27.7549705505372	2354.13891601563	12.2829942703247	12.3709421157837	12.1238565444946	2069.90087890625	1388.38732910156	2737.27075195313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	12.2419300079346	12.3447380065918	12.1960582733154	2030.59155273438	1443.58032226563	26.5112609863281	2291.94067382813	12.2829942703247	12.3709421157837	12.1238565444946	2069.90087890625	1388.38732910156	2737.27075195313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	12.2586460113525	12.355076789856	12.2139177322388	2010.86938476563	1434.10656738281	26.4975624084475	2282.02856445313	12.2829942703247	12.3709421157837	12.1238565444946	2069.90087890625	1388.38732910156	2737.27075195313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	12.2202701568604	12.3524341583252	12.119460105896	1830.26013183594	1312.97863769531	27.5523033142091	2194.65234375	12.2829942703247	12.3709421157837	12.1238565444946	2069.90087890625	1388.38732910156	2737.27075195313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	12.255241394043	12.3551387786865	12.1809167861938	1808.03955078125	1299.03247070313	27.0782546997069	2165.53662109375	12.2829942703247	12.3709421157837	12.1238565444946	2069.90087890625	1388.38732910156	2737.27075195313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	12.2962675094604	12.3717498779297	12.2372226715088	1764.03979492188	1270.72131347656	26.454471588135	2117.26440429688	12.2829942703247	12.3709421157837	12.1238565444946	2069.90087890625	1388.38732910156	2737.27075195313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	12.2789516448975	12.3534917831421	12.217698097229	1728.69396972656	1243.84875488281	26.0016136169434	2075.900390625	12.2829942703247	12.3709421157837	12.1238565444946	2069.90087890625	1388.38732910156	2737.27075195313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	12.299108505249	12.3719539642334	12.2469673156738	1622.11437988281	1148.5625	25.1956367492675	1954.82287597656	12.2829942703247	12.3709421157837	12.1238565444946	2069.90087890625	1388.38732910156	2737.27075195313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	12.3089656829834	12.3719539642334	12.2609033584595	1529.39074707031	1086.91931152344	23.4465599060059	1837.20922851563	12.2829942703247	12.3709421157837	12.1238565444946	2069.90087890625	1388.38732910156	2737.27075195313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	12.3539209365845	12.3719539642334	12.3432703018188	981.538024902344	875.344604492188	5.67647933959975	1056.99194335938	12.3688097000122	12.3718214035034	12.359486579895	830.277770996094	684.924194335938	989.151306152344\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	12.3312540054321	12.3718595504761	12.3128795623779	964.400695800781	845.557800292969	5.42389106750503	1019.12231445313	12.3688097000122	12.3718214035034	12.359486579895	830.277770996094	684.924194335938	989.151306152344\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	12.3536357879639	12.3719539642334	12.3453521728516	945.414245605469	839.007385253906	4.80965995788575	992.91650390625	12.3688097000122	12.3718214035034	12.359486579895	830.277770996094	684.924194335938	989.151306152344\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	12.3683280944824	12.3719539642334	12.3665246963501	927.119506835938	819.344177246094	4.96498107910156	978.223571777344	12.3688097000122	12.3718214035034	12.359486579895	830.277770996094	684.924194335938	989.151306152344\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	12.3600082397461	12.3719539642334	12.3545417785645	776.462585449219	670.665466308594	5.70177268981934	853.122192382813	12.3688097000122	12.3718214035034	12.359486579895	830.277770996094	684.924194335938	989.151306152344\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	12.3597059249878	12.3719539642334	12.3543434143066	767.793884277344	663.301513671875	5.62340164184572	843.250366210938	12.3688097000122	12.3718214035034	12.359486579895	830.277770996094	684.924194335938	989.151306152344\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	12.3634805679321	12.3719539642334	12.354793548584	750.720886230469	648.16943359375	5.54600143432619	825.641479492188	12.3688097000122	12.3718214035034	12.359486579895	830.277770996094	684.924194335938	989.151306152344\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	12.3636245727539	12.3719539642334	12.3550853729248	733.980590820313	635.639404296875	5.33512496948244	806.363403320313	12.3688097000122	12.3718214035034	12.359486579895	830.277770996094	684.924194335938	989.151306152344\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	12.3687725067139	12.3719539642334	12.3656129837036	660.53173828125	559.271057128906	5.41906356811525	732.681091308594	12.3688097000122	12.3718214035034	12.359486579895	830.277770996094	684.924194335938	989.151306152344\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	12.3679838180542	12.3719539642334	12.3641223907471	637.869506835938	547.704284667969	4.82652664184569	702.153137207031	12.3688097000122	12.3718214035034	12.359486579895	830.277770996094	684.924194335938	989.151306152344\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	12.3087997436523	12.3719539642334	12.2650709152222	1244.97973632813	1035.43005371094	11.0215148925781	1388.11853027344	12.3527688980103	12.371787071228	12.3086366653442	1138.39379882813	867.160705566406	1419.13757324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	12.3038282394409	12.3516931533813	12.2812776565552	1241.69458007813	1005.11291503906	10.7874069213869	1350.30993652344	12.3527688980103	12.371787071228	12.3086366653442	1138.39379882813	867.160705566406	1419.13757324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	12.3295707702637	12.3719539642334	12.3106870651245	1215.43041992188	994.432189941406	9.98432731628419	1313.9306640625	12.3527688980103	12.371787071228	12.3086366653442	1138.39379882813	867.160705566406	1419.13757324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	12.3317394256592	12.3719539642334	12.3120098114014	1195.90087890625	976.770690917969	10.0805187225341	1299.34729003906	12.3527688980103	12.371787071228	12.3086366653442	1138.39379882813	867.160705566406	1419.13757324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	12.3361358642578	12.3719539642334	12.303129196167	1038.86669921875	839.098571777344	10.6885890960693	1181.13342285156	12.3527688980103	12.371787071228	12.3086366653442	1138.39379882813	867.160705566406	1419.13757324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	12.3350982666016	12.371919631958	12.3011484146118	1027.39599609375	828.077087402344	10.6603374481202	1169.20788574219	12.3527688980103	12.371787071228	12.3086366653442	1138.39379882813	867.160705566406	1419.13757324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	12.3487710952759	12.3719539642334	12.3322057723999	998.729370117188	807.146240234375	10.306755065918	1136.96240234375	12.3527688980103	12.371787071228	12.3086366653442	1138.39379882813	867.160705566406	1419.13757324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	12.3487968444824	12.3719539642334	12.3322496414185	977.163818359375	791.484802246094	10.0051593780518	1111.64990234375	12.3527688980103	12.371787071228	12.3086366653442	1138.39379882813	867.160705566406	1419.13757324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	12.3547096252441	12.3719539642334	12.3443183898926	897.468383789063	711.823669433594	9.91637229919425	1029.14758300781	12.3527688980103	12.371787071228	12.3086366653442	1138.39379882813	867.160705566406	1419.13757324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	12.3635339736938	12.3719539642334	12.3614540100098	858.918701171875	689.678283691406	9.0259075164795	978.50732421875	12.3527688980103	12.371787071228	12.3086366653442	1138.39379882813	867.160705566406	1419.13757324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	12.2521991729736	12.3523721694946	12.178765296936	1603.68872070313	1242.46887207031	18.98002243042	1849.82958984375	12.318380355835	12.3716888427734	12.2470712661743	1542.95812988281	1099.44555664063	1987.91491699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	12.2025012969971	12.3315620422363	12.1422653198242	1614.83581542969	1212.16906738281	18.3545265197756	1799.51391601563	12.318380355835	12.3716888427734	12.2470712661743	1542.95812988281	1099.44555664063	1987.91491699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	12.2326717376709	12.3527994155884	12.1787366867065	1575.81555175781	1194.9619140625	17.2029609680175	1745.45666503906	12.318380355835	12.3716888427734	12.2470712661743	1542.95812988281	1099.44555664063	1987.91491699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	12.2806272506714	12.3717927932739	12.236180305481	1557.04528808594	1181.56030273438	17.2652778625487	1734.04919433594	12.318380355835	12.3716888427734	12.2470712661743	1542.95812988281	1099.44555664063	1987.91491699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	12.3107719421387	12.3719539642334	12.2592840194702	1384.38293457031	1046.322265625	18.1034507751466	1625.63269042969	12.318380355835	12.3716888427734	12.2470712661743	1542.95812988281	1099.44555664063	1987.91491699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	12.3005084991455	12.3719539642334	12.2395753860474	1368.0302734375	1037.06848144531	17.6876525878906	1603.07336425781	12.318380355835	12.3716888427734	12.2470712661743	1542.95812988281	1099.44555664063	1987.91491699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	12.3111371994019	12.3719539642334	12.266300201416	1332.98571777344	1013.56353759766	17.1009159088134	1560.79284667969	12.318380355835	12.3716888427734	12.2470712661743	1542.95812988281	1099.44555664063	1987.91491699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	12.3209438323975	12.3719539642334	12.2791996002197	1302.96484375	990.74267578125	16.7137756347656	1525.58349609375	12.318380355835	12.3716888427734	12.2470712661743	1542.95812988281	1099.44555664063	1987.91491699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	12.3264389038086	12.3719539642334	12.2878532409668	1211.98852539063	903.196594238281	16.4557399749756	1429.7802734375	12.318380355835	12.3716888427734	12.2470712661743	1542.95812988281	1099.44555664063	1987.91491699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	12.3397188186646	12.3719539642334	12.3173084259033	1150.26416015625	863.948486328125	15.2142791748048	1350.80541992188	12.318380355835	12.3716888427734	12.2470712661743	1542.95812988281	1099.44555664063	1987.91491699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	12.2095899581909	12.2602272033691	12.1675825119019	2597.5947265625	1811.21899414063	41.0621337890625	3125.20727539063	12.2290382385254	12.3526992797852	12.1491117477417	2707.47607421875	1731.87866210938	3640.57446289063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	12.2010889053345	12.3008117675781	12.157299041748	2641.81103515625	1774.13391113281	39.5289421081544	3039.06005859375	12.2290382385254	12.3526992797852	12.1491117477417	2707.47607421875	1731.87866210938	3640.57446289063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	12.1887588500977	12.332839012146	12.1253938674927	2576.89892578125	1736.99621582031	37.9284477233888	2950.70654296875	12.2290382385254	12.3526992797852	12.1491117477417	2707.47607421875	1731.87866210938	3640.57446289063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	12.2513437271118	12.3413410186768	12.2098379135132	2554.85693359375	1731.17236328125	37.8315582275391	2941.7822265625	12.2290382385254	12.3526992797852	12.1491117477417	2707.47607421875	1731.87866210938	3640.57446289063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	12.2564840316772	12.3412399291992	12.2142496109009	2356.62158203125	1628.1904296875	38.5553207397462	2861.96069335938	12.2290382385254	12.3526992797852	12.1491117477417	2707.47607421875	1731.87866210938	3640.57446289063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	12.2684917449951	12.3684864044189	12.2338285446167	2333.9365234375	1609.39025878906	38.3631553649903	2837.01123046875	12.2290382385254	12.3526992797852	12.1491117477417	2707.47607421875	1731.87866210938	3640.57446289063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	12.2798442840576	12.3432216644287	12.2196159362793	2283.74267578125	1580.32836914063	37.5521469116209	2781.9970703125	12.2290382385254	12.3526992797852	12.1491117477417	2707.47607421875	1731.87866210938	3640.57446289063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	12.2813777923584	12.3450508117676	12.2168216705322	2234.2783203125	1547.19323730469	36.6785316467284	2720.90625	12.2290382385254	12.3526992797852	12.1491117477417	2707.47607421875	1731.87866210938	3640.57446289063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	12.2761430740356	12.3620672225952	12.2054920196533	2121.11889648438	1442.88415527344	35.9340171813966	2592.77270507813	12.2290382385254	12.3526992797852	12.1491117477417	2707.47607421875	1731.87866210938	3640.57446289063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	12.3001461029053	12.3550081253052	12.2630109786987	1990.3544921875	1353.88818359375	33.6160049438478	2429.60034179688	12.2290382385254	12.3526992797852	12.1491117477417	2707.47607421875	1731.87866210938	3640.57446289063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	12.1091327667236	12.3016328811646	11.9667453765869	3223.080078125	2151.74194335938	55.9287490844725	3941.4619140625	12.1753120422363	12.3545265197754	11.9058380126953	3433.42456054688	2118.365234375	4683.38427734375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	12.0239734649658	12.291482925415	11.9022226333618	3280.95654296875	2109.71533203125	53.3505325317384	3816.93237304688	12.1753120422363	12.3545265197754	11.9058380126953	3433.42456054688	2118.365234375	4683.38427734375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	12.1570158004761	12.3326473236084	12.0790824890137	3193.73608398438	2061.01635742188	51.1506271362303	3697.83642578125	12.1753120422363	12.3545265197754	11.9058380126953	3433.42456054688	2118.365234375	4683.38427734375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	12.1299886703491	12.3603887557983	12.0205411911011	3190.28588867188	2063.3359375	51.7654037475588	3719.82885742188	12.1753120422363	12.3545265197754	11.9058380126953	3433.42456054688	2118.365234375	4683.38427734375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	12.1549558639526	12.3534526824951	12.0032939910889	2977.0927734375	1981.38916015625	52.8320922851563	3672.01611328125	12.1753120422363	12.3545265197754	11.9058380126953	3433.42456054688	2118.365234375	4683.38427734375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	12.129186630249	12.3333292007446	11.9604358673096	2943.8642578125	1960.89672851563	52.2203216552734	3631.94702148438	12.1753120422363	12.3545265197754	11.9058380126953	3433.42456054688	2118.365234375	4683.38427734375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	12.1490669250488	12.3416919708252	12.0456733703613	2882.37841796875	1920.43811035156	51.2525215148928	3560.51879882813	12.1753120422363	12.3545265197754	11.9058380126953	3433.42456054688	2118.365234375	4683.38427734375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	12.214695930481	12.3644456863403	12.1555728912354	2817.76391601563	1879.55529785156	49.9370918273928	3477.54223632813	12.1753120422363	12.3545265197754	11.9058380126953	3433.42456054688	2118.365234375	4683.38427734375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	12.2036056518555	12.3644123077393	12.1254301071167	2690.072265625	1777.03784179688	48.2588806152344	3321.32202148438	12.1753120422363	12.3545265197754	11.9058380126953	3433.42456054688	2118.365234375	4683.38427734375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	12.1091117858887	12.3126659393311	11.9731121063232	2522.12451171875	1655.83483886719	45.6259727478028	3115.86596679688	12.1753120422363	12.3545265197754	11.9058380126953	3433.42456054688	2118.365234375	4683.38427734375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	11.8280344009399	12.1464538574219	11.5724334716797	3901.98266601563	2535.57666015625	71.308837890625	4817.45947265625	12.0069599151611	12.3430070877075	11.5214672088623	4228.06005859375	2543.65576171875	5825.3330078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	11.7743406295776	12.1767253875732	11.5849075317383	3989.87646484375	2487.07153320313	68.4686813354491	4678.0693359375	12.0069599151611	12.3430070877075	11.5214672088623	4228.06005859375	2543.65576171875	5825.3330078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	11.9125814437866	12.24045753479	11.766450881958	3870.9326171875	2420.89111328125	65.4805908203125	4516.27001953125	12.0069599151611	12.3430070877075	11.5214672088623	4228.06005859375	2543.65576171875	5825.3330078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	11.9130611419678	12.2997932434082	11.7306365966797	3869.05029296875	2430.46899414063	66.0506362915038	4544.08935546875	12.0069599151611	12.3430070877075	11.5214672088623	4228.06005859375	2543.65576171875	5825.3330078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	11.9264106750488	12.3315553665161	11.6062908172607	3628.6416015625	2369.76000976563	66.5317153930662	4498.77490234375	12.0069599151611	12.3430070877075	11.5214672088623	4228.06005859375	2543.65576171875	5825.3330078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	11.9408502578735	12.3318099975586	11.6529712677002	3592.55493164063	2343.02099609375	65.9984283447266	4454.970703125	12.0069599151611	12.3430070877075	11.5214672088623	4228.06005859375	2543.65576171875	5825.3330078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	12.0794973373413	12.3470811843872	11.8750638961792	3515.65405273438	2296.2431640625	64.7587432861328	4368.52294921875	12.0069599151611	12.3430070877075	11.5214672088623	4228.06005859375	2543.65576171875	5825.3330078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	12.0710592269897	12.3181247711182	11.8749189376831	3442.64306640625	2250.40942382813	63.2906570434569	4275.71044921875	12.0069599151611	12.3430070877075	11.5214672088623	4228.06005859375	2543.65576171875	5825.3330078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	12.0598564147949	12.3700656890869	11.8306579589844	3291.9765625	2138.84643554688	60.7661819458006	4083.3642578125	12.0069599151611	12.3430070877075	11.5214672088623	4228.06005859375	2543.65576171875	5825.3330078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/50/St+Go	50	Stopgo	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	12.0897188186646	12.3391160964966	11.9032897949219	3081.55859375	1986.65832519531	57.62561416626	3830.67797851563	12.0069599151611	12.3430070877075	11.5214672088623	4228.06005859375	2543.65576171875	5825.3330078125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	53.5035781860352	54.0240936279297	53.0120162963867	717.14453125	558.832580566406	8.33013725280762	825.39697265625	53.9439735412598	54.0240669250488	53.7163963317871	735.712768554688	545.586120605469	931.445007324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	53.3052406311035	54.0240936279297	52.9638710021973	715.941650390625	538.785827636719	8.07616806030272	797.223205566406	53.9439735412598	54.0240669250488	53.7163963317871	735.712768554688	545.586120605469	931.445007324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	53.5908088684082	54.0240936279297	53.3957748413086	687.306945800781	517.36279296875	7.67678260803222	763.019836425781	53.9439735412598	54.0240669250488	53.7163963317871	735.712768554688	545.586120605469	931.445007324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	53.6661758422852	54.0240936279297	53.4875679016113	684.50537109375	515.66162109375	7.76427078247072	764.118286132813	53.9439735412598	54.0240669250488	53.7163963317871	735.712768554688	545.586120605469	931.445007324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	53.7581520080566	54.0240936279297	53.4905128479004	656.018249511719	510.554412841797	7.77490520477294	759.351379394531	53.9439735412598	54.0240669250488	53.7163963317871	735.712768554688	545.586120605469	931.445007324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	53.7021179199219	54.0240936279297	53.395622253418	650.02587890625	505.708160400391	7.71458721160887	752.574951171875	53.9439735412598	54.0240669250488	53.7163963317871	735.712768554688	545.586120605469	931.445007324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	53.8791847229004	54.0240936279297	53.7306137084961	641.867553710938	504.797515869141	7.36507892608641	740.480041503906	53.9439735412598	54.0240669250488	53.7163963317871	735.712768554688	545.586120605469	931.445007324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	53.8798942565918	54.0240936279297	53.7320442199707	629.592224121094	495.207153320313	7.22084426879881	726.274169921875	53.9439735412598	54.0240669250488	53.7163963317871	735.712768554688	545.586120605469	931.445007324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	53.8776550292969	54.0240936279297	53.7322006225586	596.686096191406	460.956817626953	7.22675800323487	692.213073730469	53.9439735412598	54.0240669250488	53.7163963317871	735.712768554688	545.586120605469	931.445007324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	53.9754371643066	54.0240936279297	53.9281272888184	566.238403320313	441.438354492188	6.63066673278806	653.619689941406	53.9439735412598	54.0240669250488	53.7163963317871	735.712768554688	545.586120605469	931.445007324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	54.0240936279297	54.0240936279297	54.0240936279297	52.2108688354492	54.9298706054688	-0.134140491485597	50.6373748779297	54.0240936279297	54.0240936279297	54.0240936279297	38.5273971557617	39.9968414306641	37.5671234130859\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	54.0240936279297	54.0240936279297	54.0240936279297	49.2983245849609	51.8629417419434	-0.116228818893434	48.1436195373535	54.0240936279297	54.0240936279297	54.0240936279297	38.5273971557617	39.9968414306641	37.5671234130859\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	54.0240936279297	54.0240936279297	54.0240936279297	45.7693481445313	47.4155387878418	-0.0741823911666875	45.0417022705078	54.0240936279297	54.0240936279297	54.0240936279297	38.5273971557617	39.9968414306641	37.5671234130859\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	54.0240936279297	54.0240936279297	54.0240936279297	43.1736717224121	44.9559555053711	-0.0813800096511843	42.3517951965332	54.0240936279297	54.0240936279297	54.0240936279297	38.5273971557617	39.9968414306641	37.5671234130859\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	54.0240936279297	54.0240936279297	54.0240936279297	27.3559589385986	28.1110515594482	-0.0409845709800719	26.7995452880859	54.0240936279297	54.0240936279297	54.0240936279297	38.5273971557617	39.9968414306641	37.5671234130859\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	54.0240936279297	54.0240936279297	54.0240936279297	27.1513347625732	27.8991546630859	-0.0405897498130781	26.6002826690674	54.0240936279297	54.0240936279297	54.0240936279297	38.5273971557617	39.9968414306641	37.5671234130859\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	54.0240936279297	54.0240936279297	54.0240936279297	39.2144660949707	40.1663970947266	-0.0486979484558125	38.6080627441406	54.0240936279297	54.0240936279297	54.0240936279297	38.5273971557617	39.9968414306641	37.5671234130859\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	54.0240936279297	54.0240936279297	54.0240936279297	38.8029937744141	39.735912322998	-0.0477250814437842	38.2087097167969	54.0240936279297	54.0240936279297	54.0240936279297	38.5273971557617	39.9968414306641	37.5671234130859\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	54.0240936279297	54.0240936279297	54.0240936279297	34.2774391174316	34.9400444030762	-0.0329344272613532	33.8861427307129	54.0240936279297	54.0240936279297	54.0240936279297	38.5273971557617	39.9968414306641	37.5671234130859\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	54.0240936279297	54.0240936279297	54.0240936279297	34.6376342773438	35.4770317077637	-0.042196393013	34.1267471313477	54.0240936279297	54.0240936279297	54.0240936279297	38.5273971557617	39.9968414306641	37.5671234130859\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	54.0240936279297	54.0240936279297	54.0240936279297	83.4817123413086	77.1535568237305	0.343775987625122	88.1543884277344	54.0240936279297	54.0240936279297	54.0240936279297	72.9847106933594	64.1415939331055	83.7211303710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	54.0240936279297	54.0240936279297	54.0240936279297	80.7414855957031	73.1555633544922	0.346636772155763	84.2479400634766	54.0240936279297	54.0240936279297	54.0240936279297	72.9847106933594	64.1415939331055	83.7211303710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	54.0240936279297	54.0240936279297	54.0240936279297	75.1509323120117	68.0061569213867	0.3230881690979	78.3449783325195	54.0240936279297	54.0240936279297	54.0240936279297	72.9847106933594	64.1415939331055	83.7211303710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	54.0240936279297	54.0240936279297	54.0240936279297	72.6283264160156	65.5954895019531	0.3247811794281	75.9884872436523	54.0240936279297	54.0240936279297	54.0240936279297	72.9847106933594	64.1415939331055	83.7211303710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	54.0240936279297	54.0240936279297	54.0240936279297	56.3276443481445	49.8719863891602	0.354925751686094	61.2296104431152	54.0240936279297	54.0240936279297	54.0240936279297	72.9847106933594	64.1415939331055	83.7211303710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	54.0240936279297	54.0240936279297	54.0240936279297	55.8446388244629	49.4509963989258	0.351515412330628	60.6994895935059	54.0240936279297	54.0240936279297	54.0240936279297	72.9847106933594	64.1415939331055	83.7211303710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	54.0240936279297	54.0240936279297	54.0240936279297	66.6671829223633	60.3987503051758	0.345101356506347	71.4419937133789	54.0240936279297	54.0240936279297	54.0240936279297	72.9847106933594	64.1415939331055	83.7211303710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	54.0240936279297	54.0240936279297	54.0240936279297	65.7154998779297	59.5694313049316	0.338361382484437	70.3969955444336	54.0240936279297	54.0240936279297	54.0240936279297	72.9847106933594	64.1415939331055	83.7211303710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	54.0240936279297	54.0240936279297	54.0240936279297	60.6723899841309	53.4605407714844	0.39324951171875	66.0445251464844	54.0240936279297	54.0240936279297	54.0240936279297	72.9847106933594	64.1415939331055	83.7211303710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	54.0240936279297	54.0240936279297	54.0240936279297	59.0280914306641	52.8766136169434	0.336272716522215	63.6373405456543	54.0240936279297	54.0240936279297	54.0240936279297	72.9847106933594	64.1415939331055	83.7211303710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	54.0240173339844	54.0240936279297	54.0239486694336	196.63200378418	229.291488647461	-1.51871395111084	180.692642211914	54.0180854797363	54.0240936279297	53.9954948425293	171.185256958008	182.650604248047	184.897811889648\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	53.9574966430664	54.0240936279297	53.9257545471191	185.506851196289	213.478775024414	-1.25820016860963	173.216369628906	54.0180854797363	54.0240936279297	53.9954948425293	171.185256958008	182.650604248047	184.897811889648\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	54.0240936279297	54.0240936279297	54.0240936279297	175.803009033203	204.208953857422	-1.27691936492919	163.347534179688	54.0180854797363	54.0240936279297	53.9954948425293	171.185256958008	182.650604248047	184.897811889648\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	54.0240936279297	54.0240936279297	54.0240936279297	169.9677734375	193.452346801758	-1.05663013458253	159.640182495117	54.0180854797363	54.0240936279297	53.9954948425293	171.185256958008	182.650604248047	184.897811889648\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	54.0240936279297	54.0240936279297	54.0240936279297	158.110641479492	181.089263916016	-1.04292917251587	147.715530395508	54.0180854797363	54.0240936279297	53.9954948425293	171.185256958008	182.650604248047	184.897811889648\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	54.0240936279297	54.0240936279297	54.0240936279297	156.648941040039	179.406204223633	-1.03288030624391	146.354034423828	54.0180854797363	54.0240936279297	53.9954948425293	171.185256958008	182.650604248047	184.897811889648\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	54.0240936279297	54.0240936279297	54.0240936279297	162.997436523438	183.714660644531	-0.937714099884031	153.707809448242	54.0180854797363	54.0240936279297	53.9954948425293	171.185256958008	182.650604248047	184.897811889648\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	54.0240936279297	54.0240936279297	54.0240936279297	160.141952514648	180.44010925293	-0.918687343597406	151.042114257813	54.0180854797363	54.0240936279297	53.9954948425293	171.185256958008	182.650604248047	184.897811889648\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	54.0240936279297	54.0240936279297	54.0240936279297	144.313613891602	155.741394042969	-0.477441787719719	140.463256835938	54.0180854797363	54.0240936279297	53.9954948425293	171.185256958008	182.650604248047	184.897811889648\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	54.0240936279297	54.0240936279297	54.0240936279297	139.799011230469	155.409774780273	-0.689054965972876	133.360015869141	54.0180854797363	54.0240936279297	53.9954948425293	171.185256958008	182.650604248047	184.897811889648\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	53.6624336242676	54.0240936279297	53.3922653198242	1491.80139160156	953.232421875	28.3948211669922	1861.86669921875	53.5911178588867	54.0238075256348	53.1016426086426	1656.2978515625	1010.75311279297	2324.37817382813\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	53.4317779541016	54.0240936279297	53.1551628112793	1532.20874023438	931.215209960938	27.4046554565429	1808.16418457031	53.5911178588867	54.0238075256348	53.1016426086426	1656.2978515625	1010.75311279297	2324.37817382813\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	53.5526695251465	54.0240936279297	53.3450927734375	1463.98864746094	896.700500488281	25.6258754730225	1716.728515625	53.5911178588867	54.0238075256348	53.1016426086426	1656.2978515625	1010.75311279297	2324.37817382813\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	53.4005928039551	54.0240936279297	53.1083679199219	1467.45141601563	912.099914550781	25.5331096649172	1729.15942382813	53.5911178588867	54.0238075256348	53.1016426086426	1656.2978515625	1010.75311279297	2324.37817382813\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	53.5200157165527	54.0240936279297	53.2033767700195	1395.92041015625	916.959106445313	25.604160308838	1736.29223632813	53.5911178588867	54.0238075256348	53.1016426086426	1656.2978515625	1010.75311279297	2324.37817382813\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	53.2126350402832	54.0240936279297	52.6371612548828	1383.03735351563	908.206787109375	25.3831100463867	1720.46630859375	53.5911178588867	54.0238075256348	53.1016426086426	1656.2978515625	1010.75311279297	2324.37817382813\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	53.5740242004395	54.0240936279297	53.1486053466797	1354.39990234375	900.019165039063	24.3900184631349	1680.49975585938	53.5911178588867	54.0238075256348	53.1016426086426	1656.2978515625	1010.75311279297	2324.37817382813\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	53.7219390869141	54.0240936279297	53.5013618469238	1327.67724609375	882.675415039063	23.8782348632812	1646.77893066406	53.5911178588867	54.0238075256348	53.1016426086426	1656.2978515625	1010.75311279297	2324.37817382813\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	53.7641143798828	54.0240936279297	53.5557975769043	1289.57580566406	857.115417480469	22.9937038421632	1592.91394042969	53.5911178588867	54.0238075256348	53.1016426086426	1656.2978515625	1010.75311279297	2324.37817382813\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	53.3363761901855	54.0240936279297	52.6846084594727	1212.77978515625	794.8974609375	22.1861915588378	1504.85559082031	53.5911178588867	54.0238075256348	53.1016426086426	1656.2978515625	1010.75311279297	2324.37817382813\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	44.3862342834473	53.8801536560059	35.598804473877	2333.63232421875	1381.0986328125	49.5695114135744	2967.32299804688	50.8656387329102	54.0083694458008	42.8087921142578	2617.83422851563	1500.47863769531	3691.59887695313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	42.2515525817871	53.9269218444824	36.7045860290527	2397.67895507813	1354.35266113281	47.5062904357913	2874.55395507813	50.8656387329102	54.0083694458008	42.8087921142578	2617.83422851563	1500.47863769531	3691.59887695313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	44.3752212524414	54.0239486694336	40.0149803161621	2305.02172851563	1302.69567871094	45.26908493042	2751.30639648438	50.8656387329102	54.0083694458008	42.8087921142578	2617.83422851563	1500.47863769531	3691.59887695313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	48.6039123535156	54.0240936279297	45.9245681762695	2329.70751953125	1330.54968261719	45.9205513000487	2800.00732421875	50.8656387329102	54.0083694458008	42.8087921142578	2617.83422851563	1500.47863769531	3691.59887695313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	49.1396713256836	54.0240936279297	44.7832107543945	2205.57421875	1345.25549316406	45.7820930480959	2810.28247070313	50.8656387329102	54.0083694458008	42.8087921142578	2617.83422851563	1500.47863769531	3691.59887695313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	46.3877334594727	53.9289283752441	39.1734733581543	2172.0263671875	1332.60998535156	44.6727256774903	2762.13720703125	50.8656387329102	54.0083694458008	42.8087921142578	2617.83422851563	1500.47863769531	3691.59887695313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	51.7683334350586	54.0240936279297	49.6180419921875	2150.41333007813	1319.77062988281	44.5983161926272	2746.91674804688	50.8656387329102	54.0083694458008	42.8087921142578	2617.83422851563	1500.47863769531	3691.59887695313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	51.2244110107422	54.0240936279297	48.5587615966797	2104.1611328125	1294.18212890625	43.4522552490234	2684.654296875	50.8656387329102	54.0083694458008	42.8087921142578	2617.83422851563	1500.47863769531	3691.59887695313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	51.374095916748	54.0240936279297	48.9925651550293	2020.17346191406	1268.39868164063	39.9917907714844	2548.13598632813	50.8656387329102	54.0083694458008	42.8087921142578	2617.83422851563	1500.47863769531	3691.59887695313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	52.289234161377	54.0240936279297	50.7127418518066	1912.59533691406	1171.75537109375	39.1025390625	2423.03662109375	50.8656387329102	54.0083694458008	42.8087921142578	2617.83422851563	1500.47863769531	3691.59887695313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	40.5136604309082	53.0123062133789	34.2672538757324	3188.48291015625	1850.794921875	70.1925659179688	4096.95703125	44.203254699707	53.8409652709961	30.9001331329346	3604.6240234375	2020.39770507813	5228.310546875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	35.2989654541016	53.8074913024902	26.8553581237793	3308.23388671875	1828.77697753906	67.4266929626466	3986.43115234375	44.203254699707	53.8409652709961	30.9001331329346	3604.6240234375	2020.39770507813	5228.310546875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	42.3382263183594	53.7292327880859	37.3351554870605	3171.80712890625	1744.59899902344	64.4593086242675	3807.296875	44.203254699707	53.8409652709961	30.9001331329346	3604.6240234375	2020.39770507813	5228.310546875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	36.8145217895508	53.2955627441406	29.0303440093994	3185.54174804688	1788.89636230469	64.1781044006347	3842.595703125	44.203254699707	53.8409652709961	30.9001331329346	3604.6240234375	2020.39770507813	5228.310546875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	39.3705673217773	53.4892997741699	30.22705078125	3027.56860351563	1811.76696777344	64.6599998474122	3880.88696289063	44.203254699707	53.8409652709961	30.9001331329346	3604.6240234375	2020.39770507813	5228.310546875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	39.6633720397949	53.6331253051758	31.0644607543945	2986.00439453125	1794.21728515625	63.4405441284181	3824.31469726563	44.203254699707	53.8409652709961	30.9001331329346	3604.6240234375	2020.39770507813	5228.310546875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	42.2909698486328	53.4390640258789	32.599796295166	2926.25952148438	1769.33666992188	61.4754867553709	3736.55224609375	44.203254699707	53.8409652709961	30.9001331329346	3604.6240234375	2020.39770507813	5228.310546875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	41.8739280700684	53.8262786865234	32.4017677307129	2861.72729492188	1733.80200195313	60.1182479858397	3657.5859375	44.203254699707	53.8409652709961	30.9001331329346	3604.6240234375	2020.39770507813	5228.310546875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	41.6328010559082	53.6813163757324	31.9809017181396	2793.45581054688	1689.76721191406	59.1407661437991	3582.27172851563	44.203254699707	53.8409652709961	30.9001331329346	3604.6240234375	2020.39770507813	5228.310546875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Freeflow	60	Freeflow	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	42.3521919250488	53.6821327209473	32.6198501586914	2598.47265625	1578.45654296875	53.7552871704103	3298.62573242188	44.203254699707	53.8409652709961	30.9001331329346	3604.6240234375	2020.39770507813	5228.310546875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	26.7636013031006	27.0221633911133	26.4936275482178	1444.55029296875	965.658813476563	25.0346183776855	1766.7666015625	26.9240894317627	27.3322334289551	26.4694290161133	1559.77294921875	972.7646484375	2134.22778320313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	26.7823486328125	27.1766815185547	26.6049175262451	1475.78295898438	938.518432617188	24.4780426025391	1721.81579589844	26.9240894317627	27.3322334289551	26.4694290161133	1559.77294921875	972.7646484375	2134.22778320313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	26.675235748291	27.3304309844971	26.3837223052979	1436.19836425781	913.245361328125	23.618076324463	1669.02380371094	26.9240894317627	27.3322334289551	26.4694290161133	1559.77294921875	972.7646484375	2134.22778320313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	26.8648452758789	27.3328628540039	26.64186668396	1440.54541015625	931.283142089844	23.4030208587646	1680.17980957031	26.9240894317627	27.3322334289551	26.4694290161133	1559.77294921875	972.7646484375	2134.22778320313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	27.0342197418213	27.33251953125	26.8306713104248	1341.34130859375	897.664184570313	23.592628479004	1652.62829589844	26.9240894317627	27.3322334289551	26.4694290161133	1559.77294921875	972.7646484375	2134.22778320313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	26.8878536224365	27.3331432342529	26.6045227050781	1328.60705566406	889.109313964844	23.3534832000733	1636.42077636719	26.9240894317627	27.3322334289551	26.4694290161133	1559.77294921875	972.7646484375	2134.22778320313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	27.0916080474854	27.2947101593018	27.039379119873	1305.99438476563	883.806335449219	22.5747394561769	1606.19799804688	26.9240894317627	27.3322334289551	26.4694290161133	1559.77294921875	972.7646484375	2134.22778320313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	27.0278377532959	27.3339328765869	26.8285484313965	1282.33544921875	865.428771972656	22.3257808685304	1579.85375976563	26.9240894317627	27.3322334289551	26.4694290161133	1559.77294921875	972.7646484375	2134.22778320313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	27.0979404449463	27.3339328765869	26.9711475372314	1215.63842773438	819.334228515625	21.0748176574708	1493.72839355469	26.9240894317627	27.3322334289551	26.4694290161133	1559.77294921875	972.7646484375	2134.22778320313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	27.0864200592041	27.3339328765869	26.9102020263672	1147.6298828125	764.534118652344	20.2942142486574	1413.94897460938	26.9240894317627	27.3322334289551	26.4694290161133	1559.77294921875	972.7646484375	2134.22778320313\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	27.2730751037598	27.3339328765869	27.2159366607666	453.83056640625	371.880035400391	4.39670467376709	512.574584960938	27.3280143737793	27.3339157104492	27.3122673034668	433.249053955078	325.808197021484	550.498657226563\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	27.2288589477539	27.3339328765869	27.1787853240967	455.044982910156	360.932281494141	4.29675483703612	498.428436279297	27.3280143737793	27.3339157104492	27.3122673034668	433.249053955078	325.808197021484	550.498657226563\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	27.3325710296631	27.3339328765869	27.3319568634033	444.388580322266	360.463073730469	3.79318141937256	481.844879150391	27.3280143737793	27.3339157104492	27.3122673034668	433.249053955078	325.808197021484	550.498657226563\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	27.3333187103271	27.3339328765869	27.3330116271973	443.581268310547	360.576568603516	3.82375526428222	482.936737060547	27.3280143737793	27.3339157104492	27.3122673034668	433.249053955078	325.808197021484	550.498657226563\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	27.3339328765869	27.3339328765869	27.3339328765869	377.193237304688	298.699371337891	4.23869609832762	434.337646484375	27.3280143737793	27.3339157104492	27.3122673034668	433.249053955078	325.808197021484	550.498657226563\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	27.3339328765869	27.3339328765869	27.3339328765869	373.072082519531	294.530700683594	4.24029827117919	430.220245361328	27.3280143737793	27.3339157104492	27.3122673034668	433.249053955078	325.808197021484	550.498657226563\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	27.3337287902832	27.3339328765869	27.3335208892822	377.011993408203	303.063049316406	4.02712440490725	431.931030273438	27.3280143737793	27.3339157104492	27.3122673034668	433.249053955078	325.808197021484	550.498657226563\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	27.3339328765869	27.3339328765869	27.3339328765869	369.234527587891	297.446655273438	3.90312862396237	422.346771240234	27.3280143737793	27.3339157104492	27.3122673034668	433.249053955078	325.808197021484	550.498657226563\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	27.3339328765869	27.3339328765869	27.3339328765869	345.753112792969	270.725738525391	4.02650260925291	399.573822021484	27.3280143737793	27.3339157104492	27.3122673034668	433.249053955078	325.808197021484	550.498657226563\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	27.3339328765869	27.3339328765869	27.3339328765869	327.068817138672	259.932800292969	3.60022830963134	375.140106201172	27.3280143737793	27.3339157104492	27.3122673034668	433.249053955078	325.808197021484	550.498657226563\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	26.9662303924561	27.3334140777588	26.6815605163574	690.809204101563	509.645477294922	9.61199855804444	817.229431152344	27.206205368042	27.3336715698242	26.7794704437256	690.332214355469	477.82763671875	922.872741699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	26.9114971160889	27.3338241577148	26.716028213501	700.254638671875	494.391784667969	9.39450454711913	795.015930175781	27.206205368042	27.3336715698242	26.7794704437256	690.332214355469	477.82763671875	922.872741699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	27.0158386230469	27.3339328765869	26.8719806671143	679.513305664063	488.984710693359	8.61024570465091	764.512573242188	27.206205368042	27.3336715698242	26.7794704437256	690.332214355469	477.82763671875	922.872741699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	27.1511154174805	27.3339328765869	27.0599269866943	671.70849609375	492.037780761719	8.270658493042	756.698852539063	27.206205368042	27.3336715698242	26.7794704437256	690.332214355469	477.82763671875	922.872741699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	27.236307144165	27.3339328765869	27.1380577087402	598.062927246094	440.640686035156	8.48069953918459	712.023071289063	27.206205368042	27.3336715698242	26.7794704437256	690.332214355469	477.82763671875	922.872741699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	27.214635848999	27.3339328765869	27.0945777893066	592.72265625	435.612182617188	8.4650650024414	706.494262695313	27.206205368042	27.3336715698242	26.7794704437256	690.332214355469	477.82763671875	922.872741699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	27.2565536499023	27.3339328765869	27.177453994751	588.914306640625	438.892944335938	8.12503051757812	698.893920898438	27.206205368042	27.3336715698242	26.7794704437256	690.332214355469	477.82763671875	922.872741699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	27.2756958007813	27.3339328765869	27.2159843444824	576.4912109375	430.603607177734	7.90396213531497	683.530395507813	27.206205368042	27.3336715698242	26.7794704437256	690.332214355469	477.82763671875	922.872741699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	27.2362670898438	27.3339328765869	27.1392593383789	542.857543945313	398.380004882813	7.72367477416991	645.53759765625	27.206205368042	27.3336715698242	26.7794704437256	690.332214355469	477.82763671875	922.872741699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	27.2339916229248	27.3339328765869	27.1368103027344	511.675964355469	377.003723144531	7.200927734375	607.433410644531	27.206205368042	27.3336715698242	26.7794704437256	690.332214355469	477.82763671875	922.872741699219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	27.0261745452881	27.1414165496826	26.9060802459717	1010.01666259766	705.311401367188	16.0072593688966	1217.54370117188	27.0137233734131	27.3336086273193	26.4158954620361	1057.79235839844	690.722106933594	1437.01025390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	27.0539722442627	27.2167339324951	26.9841022491455	1028.20654296875	683.424499511719	15.7149753570557	1186.3037109375	27.0137233734131	27.3336086273193	26.4158954620361	1057.79235839844	690.722106933594	1437.01025390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	26.90744972229	27.3330459594727	26.7172241210938	998.848388671875	670.036804199219	14.8521900177003	1145.30688476563	27.0137233734131	27.3336086273193	26.4158954620361	1057.79235839844	690.722106933594	1437.01025390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	26.8452301025391	27.3339328765869	26.6043567657471	999.935241699219	679.408264160156	14.7407360076904	1151.11181640625	27.0137233734131	27.3336086273193	26.4158954620361	1057.79235839844	690.722106933594	1437.01025390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	27.1436347961426	27.2559032440186	27.058650970459	918.414001464844	642.422546386719	14.7575550079347	1114.66430664063	27.0137233734131	27.3336086273193	26.4158954620361	1057.79235839844	690.722106933594	1437.01025390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	27.1123676300049	27.3339328765869	26.9449253082275	909.785827636719	635.107299804688	14.684352874756	1105.00659179688	27.0137233734131	27.3336086273193	26.4158954620361	1057.79235839844	690.722106933594	1437.01025390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	26.9603710174561	27.3339328765869	26.6062393188477	897.167785644531	630.213439941406	14.3874950408936	1090.61328125	27.0137233734131	27.3336086273193	26.4158954620361	1057.79235839844	690.722106933594	1437.01025390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	26.9188575744629	27.3339328765869	26.5661125183105	880.195495605469	618.16015625	14.1147651672363	1069.83264160156	27.0137233734131	27.3336086273193	26.4158954620361	1057.79235839844	690.722106933594	1437.01025390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	26.9982032775879	27.3339328765869	26.7174377441406	830.97509765625	579.897033691406	13.4068641662598	1008.91668701172	27.0137233734131	27.3336086273193	26.4158954620361	1057.79235839844	690.722106933594	1437.01025390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	26.9951229095459	27.3339328765869	26.7174606323242	783.540832519531	544.086364746094	12.7441787719727	951.900085449219	27.0137233734131	27.3336086273193	26.4158954620361	1057.79235839844	690.722106933594	1437.01025390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	26.3921775817871	27.215892791748	25.7720317840576	2024.74401855469	1291.30187988281	38.3188743591309	2517.505859375	26.6023960113525	27.3193607330322	25.7691383361816	2220.21801757813	1334.17077636719	3063.16040039063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	26.2497329711914	27.0594615936279	25.8732414245605	2071.37841796875	1267.10620117188	36.6442108154297	2439.72094726563	26.6023960113525	27.3193607330322	25.7691383361816	2220.21801757813	1334.17077636719	3063.16040039063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	26.4774627685547	27.2532348632813	26.1314239501953	2005.79858398438	1225.72924804688	35.2273788452147	2353.00537109375	26.6023960113525	27.3193607330322	25.7691383361816	2220.21801757813	1334.17077636719	3063.16040039063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	26.42160987854	27.1010360717773	26.088830947876	2016.74157714844	1251.27038574219	35.162311553955	2376.46435546875	26.6023960113525	27.3193607330322	25.7691383361816	2220.21801757813	1334.17077636719	3063.16040039063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	26.5505886077881	27.1811809539795	25.9864921569824	1893.28601074219	1229.02685546875	35.2033996582031	2355.53564453125	26.6023960113525	27.3193607330322	25.7691383361816	2220.21801757813	1334.17077636719	3063.16040039063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	26.4306659698486	27.2553882598877	25.8451957702637	1872.34814453125	1216.40466308594	34.6991081237794	2326.77612304688	26.6023960113525	27.3193607330322	25.7691383361816	2220.21801757813	1334.17077636719	3063.16040039063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	26.7447128295898	27.3317432403564	26.3136081695557	1845.31958007813	1201.5908203125	34.3039398193359	2299.31689453125	26.6023960113525	27.3193607330322	25.7691383361816	2220.21801757813	1334.17077636719	3063.16040039063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	26.6470546722412	27.3336048126221	26.0555477142334	1808.64025878906	1177.23400878906	33.6468238830569	2253.93237304688	26.6023960113525	27.3193607330322	25.7691383361816	2220.21801757813	1334.17077636719	3063.16040039063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	26.7317180633545	27.3337898254395	26.2809162139893	1725.37194824219	1124.39526367188	31.8444595336913	2143.41796875	26.6023960113525	27.3193607330322	25.7691383361816	2220.21801757813	1334.17077636719	3063.16040039063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	26.7976703643799	27.3339328765869	26.4174938201904	1626.84204101563	1047.01159667969	30.5757789611816	2025.4365234375	26.6023960113525	27.3193607330322	25.7691383361816	2220.21801757813	1334.17077636719	3063.16040039063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	25.4601497650146	26.8315124511719	24.4325752258301	2685.5703125	1664.02087402344	53.2218971252441	3367.12158203125	26.0209865570068	27.2068481445313	24.375093460083	2966.986328125	1746.72546386719	4095.966796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	25.1629390716553	27.1042385101318	24.2664546966553	2753.36572265625	1633.23266601563	51.0232849121094	3265.97778320313	26.0209865570068	27.2068481445313	24.375093460083	2966.986328125	1746.72546386719	4095.966796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	25.586483001709	27.2155818939209	24.8583068847656	2670.33276367188	1581.65930175781	49.1614570617678	3154.82592773438	26.0209865570068	27.2068481445313	24.375093460083	2966.986328125	1746.72546386719	4095.966796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	25.6362190246582	27.0596466064453	24.957857131958	2672.42407226563	1614.21655273438	48.5804977416991	3168.79248046875	26.0209865570068	27.2068481445313	24.375093460083	2966.986328125	1746.72546386719	4095.966796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	25.7133922576904	27.1767425537109	24.5469608306885	2530.423828125	1605.79711914063	48.900634765625	3170.61743164063	26.0209865570068	27.2068481445313	24.375093460083	2966.986328125	1746.72546386719	4095.966796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	25.6920871734619	27.2162952423096	24.5387268066406	2499.978515625	1589.66821289063	48.1102066040038	3129.19482421875	26.0209865570068	27.2068481445313	24.375093460083	2966.986328125	1746.72546386719	4095.966796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	26.1607398986816	27.2559127807617	25.2909030914307	2465.53491210938	1570.02673339844	47.6209449768066	3093.89697265625	26.0209865570068	27.2068481445313	24.375093460083	2966.986328125	1746.72546386719	4095.966796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	26.0292263031006	27.1772537231445	25.0896282196045	2415.78247070313	1540.20739746094	46.5543174743653	3029.94555664063	26.0209865570068	27.2068481445313	24.375093460083	2966.986328125	1746.72546386719	4095.966796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	26.1592540740967	27.1760234832764	25.3717041015625	2306.31372070313	1473.65673828125	43.9562683105469	2880.25732421875	26.0209865570068	27.2068481445313	24.375093460083	2966.986328125	1746.72546386719	4095.966796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	26.1543159484863	27.2541847229004	25.3242473602295	2175.3349609375	1368.31921386719	42.4573097229003	2726.953125	26.0209865570068	27.2068481445313	24.375093460083	2966.986328125	1746.72546386719	4095.966796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	23.8418064117432	26.9075298309326	21.5737571716309	3375.56616210938	2061.23461914063	68.4502029418944	4251.64111328125	24.7555961608887	27.1410827636719	21.6012020111084	3780.54931640625	2188.26904296875	5320.83984375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	22.9235553741455	26.864767074585	21.0941429138184	3464.09326171875	2026.03552246094	65.4952583312987	4121.8837890625	24.7555961608887	27.1410827636719	21.6012020111084	3780.54931640625	2188.26904296875	5320.83984375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	23.923318862915	26.9841613769531	22.5532703399658	3367.40747070313	1962.19714355469	63.4551658630372	3992.76245117188	24.7555961608887	27.1410827636719	21.6012020111084	3780.54931640625	2188.26904296875	5320.83984375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	24.2170848846436	27.0226917266846	22.8776569366455	3375.91772460938	1999.36145019531	63.1984367370606	4021.71142578125	24.7555961608887	27.1410827636719	21.6012020111084	3780.54931640625	2188.26904296875	5320.83984375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	24.2930297851563	26.9436225891113	22.2607841491699	3193.0380859375	2005.15209960938	62.8484420776366	4016.30224609375	24.7555961608887	27.1410827636719	21.6012020111084	3780.54931640625	2188.26904296875	5320.83984375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	24.2581062316895	26.9398574829102	22.3122406005859	3176.17553710938	1985.29467773438	63.1743850708006	4006.875	24.7555961608887	27.1410827636719	21.6012020111084	3780.54931640625	2188.26904296875	5320.83984375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	24.8818302154541	27.0948352813721	23.1501541137695	3106.9013671875	1961.02600097656	60.7607231140138	3905.369140625	24.7555961608887	27.1410827636719	21.6012020111084	3780.54931640625	2188.26904296875	5320.83984375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	24.8270931243896	27.0188808441162	23.0760326385498	3043.42211914063	1923.23413085938	59.4244003295897	3824.81494140625	24.7555961608887	27.1410827636719	21.6012020111084	3780.54931640625	2188.26904296875	5320.83984375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	24.9991683959961	27.1397190093994	23.3579921722412	2911.47631835938	1844.49401855469	56.2761116027831	3645.32958984375	24.7555961608887	27.1410827636719	21.6012020111084	3780.54931640625	2188.26904296875	5320.83984375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/Satur.	60	Saturated	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	25.1508808135986	27.2168407440186	23.5602722167969	2740.9091796875	1712.79162597656	54.0153388977053	3441.28247070313	24.7555961608887	27.1410827636719	21.6012020111084	3780.54931640625	2188.26904296875	5320.83984375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	12.2138833999634	12.3333683013916	12.1367626190186	2050.966796875	1499.43774414063	28.8484573364256	2422.58837890625	12.2717227935791	12.3710384368896	12.1162786483765	2071.47827148438	1388.38354492188	2739.0478515625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	12.1936922073364	12.3423166275024	12.1263818740845	2075.14794921875	1465.97985839844	27.7543144226075	2354.11791992188	12.2717227935791	12.3710384368896	12.1162786483765	2071.47827148438	1388.38354492188	2739.0478515625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	12.1578969955444	12.3344764709473	12.0786466598511	2033.28723144531	1444.07482910156	26.6108665466309	2295.62255859375	12.2717227935791	12.3710384368896	12.1162786483765	2071.47827148438	1388.38354492188	2739.0478515625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	12.2464303970337	12.355076789856	12.1956787109375	2010.57446289063	1434.10656738281	26.4834327697756	2281.57641601563	12.2717227935791	12.3710384368896	12.1162786483765	2071.47827148438	1388.38354492188	2739.0478515625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	12.2417526245117	12.3524341583252	12.1608152389526	1829.03063964844	1312.97863769531	27.4809303283694	2192.36840820313	12.2717227935791	12.3710384368896	12.1162786483765	2071.47827148438	1388.38354492188	2739.0478515625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	12.2156419754028	12.3551387786865	12.1162776947021	1809.16223144531	1299.03247070313	27.1318283081053	2167.2509765625	12.2717227935791	12.3710384368896	12.1162786483765	2071.47827148438	1388.38354492188	2739.0478515625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	12.3021106719971	12.3717498779297	12.2521619796753	1762.8603515625	1270.72131347656	26.3770027160647	2114.78540039063	12.2717227935791	12.3710384368896	12.1162786483765	2071.47827148438	1388.38354492188	2739.0478515625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	12.2930107116699	12.3534917831421	12.241753578186	1726.79870605469	1243.84875488281	25.8898582458497	2072.32421875	12.2717227935791	12.3710384368896	12.1162786483765	2071.47827148438	1388.38354492188	2739.0478515625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	12.2941465377808	12.3719539642334	12.2370767593384	1622.36657714844	1148.5625	25.2113494873047	1955.32568359375	12.2717227935791	12.3710384368896	12.1162786483765	2071.47827148438	1388.38354492188	2739.0478515625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	12.2885589599609	12.3719539642334	12.2142295837402	1528.79345703125	1086.91931152344	23.4160537719725	1836.23303222656	12.2717227935791	12.3710384368896	12.1162786483765	2071.47827148438	1388.38354492188	2739.0478515625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	12.3539209365845	12.3719539642334	12.3432703018188	981.538024902344	875.344604492188	5.67647933959975	1056.99194335938	12.3688354492188	12.3718070983887	12.3595790863037	830.276794433594	684.924560546875	989.147521972656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	12.3312540054321	12.3718595504761	12.3128795623779	964.400695800781	845.557800292969	5.42389106750503	1019.12231445313	12.3688354492188	12.3718070983887	12.3595790863037	830.276794433594	684.924560546875	989.147521972656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	12.3536357879639	12.3719539642334	12.3453521728516	945.414245605469	839.007385253906	4.80965995788575	992.91650390625	12.3688354492188	12.3718070983887	12.3595790863037	830.276794433594	684.924560546875	989.147521972656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	12.3683280944824	12.3719539642334	12.3665246963501	927.119506835938	819.344177246094	4.96498107910156	978.223571777344	12.3688354492188	12.3718070983887	12.3595790863037	830.276794433594	684.924560546875	989.147521972656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	12.3600082397461	12.3719539642334	12.3545417785645	776.462585449219	670.665466308594	5.70177268981934	853.122192382813	12.3688354492188	12.3718070983887	12.3595790863037	830.276794433594	684.924560546875	989.147521972656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	12.3597059249878	12.3719539642334	12.3543434143066	767.793884277344	663.301513671875	5.62340164184572	843.250366210938	12.3688354492188	12.3718070983887	12.3595790863037	830.276794433594	684.924560546875	989.147521972656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	12.3634805679321	12.3719539642334	12.354793548584	750.720886230469	648.16943359375	5.54600143432619	825.641479492188	12.3688354492188	12.3718070983887	12.3595790863037	830.276794433594	684.924560546875	989.147521972656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	12.3636245727539	12.3719539642334	12.3550853729248	733.980590820313	635.639404296875	5.33512496948244	806.363403320313	12.3688354492188	12.3718070983887	12.3595790863037	830.276794433594	684.924560546875	989.147521972656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	12.3687725067139	12.3719539642334	12.3656129837036	660.53173828125	559.271057128906	5.41906356811525	732.681091308594	12.3688354492188	12.3718070983887	12.3595790863037	830.276794433594	684.924560546875	989.147521972656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	12.3679838180542	12.3719539642334	12.3641223907471	637.869506835938	547.704284667969	4.82652664184569	702.153137207031	12.3688354492188	12.3718070983887	12.3595790863037	830.276794433594	684.924560546875	989.147521972656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	12.3060779571533	12.3719539642334	12.2597780227661	1244.58361816406	1035.43005371094	10.9974403381347	1387.34814453125	12.3539123535156	12.3717784881592	12.3148508071899	1138.35681152344	867.160888671875	1418.9267578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	12.3041620254517	12.3516931533813	12.2817707061768	1241.69458007813	1005.11291503906	10.7874107360841	1350.31005859375	12.3539123535156	12.3717784881592	12.3148508071899	1138.35681152344	867.160888671875	1418.9267578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	12.3376350402832	12.3719539642334	12.3224000930786	1213.75183105469	994.432189941406	9.90814781188981	1311.49291992188	12.3539123535156	12.3717784881592	12.3148508071899	1138.35681152344	867.160888671875	1418.9267578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	12.3317394256592	12.3719539642334	12.3120098114014	1195.90087890625	976.770690917969	10.0805187225341	1299.34729003906	12.3539123535156	12.3717784881592	12.3148508071899	1138.35681152344	867.160888671875	1418.9267578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	12.3361358642578	12.3719539642334	12.303129196167	1038.86669921875	839.098571777344	10.6885890960693	1181.13342285156	12.3539123535156	12.3717784881592	12.3148508071899	1138.35681152344	867.160888671875	1418.9267578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	12.3350982666016	12.371919631958	12.3011484146118	1027.39599609375	828.077087402344	10.6603374481202	1169.20788574219	12.3539123535156	12.3717784881592	12.3148508071899	1138.35681152344	867.160888671875	1418.9267578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	12.3487710952759	12.3719539642334	12.3322057723999	998.729370117188	807.146240234375	10.306755065918	1136.96240234375	12.3539123535156	12.3717784881592	12.3148508071899	1138.35681152344	867.160888671875	1418.9267578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	12.3437395095825	12.3719539642334	12.3220071792603	977.377197265625	791.484802246094	10.0186672210693	1112.08215332031	12.3539123535156	12.3717784881592	12.3148508071899	1138.35681152344	867.160888671875	1418.9267578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	12.3547096252441	12.3719539642334	12.3443183898926	897.468383789063	711.823669433594	9.91637229919425	1029.14758300781	12.3539123535156	12.3717784881592	12.3148508071899	1138.35681152344	867.160888671875	1418.9267578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	12.3635339736938	12.3719539642334	12.3614540100098	858.918701171875	689.678283691406	9.0259075164795	978.50732421875	12.3539123535156	12.3717784881592	12.3148508071899	1138.35681152344	867.160888671875	1418.9267578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	12.2305374145508	12.3523721694946	12.1413297653198	1602.98486328125	1242.46887207031	18.9339523315431	1848.35534667969	12.3205528259277	12.3717184066772	12.2261638641357	1542.71569824219	1099.44458007813	1988.25549316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	12.183479309082	12.3315620422363	12.1131238937378	1615.26904296875	1212.16906738281	18.3774337768556	1800.24694824219	12.3205528259277	12.3717184066772	12.2261638641357	1542.71569824219	1099.44458007813	1988.25549316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	12.2455787658691	12.3527994155884	12.1970062255859	1571.9775390625	1194.9619140625	17.0290679931641	1739.89208984375	12.3205528259277	12.3717184066772	12.2261638641357	1542.71569824219	1099.44458007813	1988.25549316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	12.2918281555176	12.3717927932739	12.2529706954956	1556.38696289063	1181.56030273438	17.2344360351563	1733.06225585938	12.3205528259277	12.3717184066772	12.2261638641357	1542.71569824219	1099.44458007813	1988.25549316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	12.3006019592285	12.3719539642334	12.242657661438	1385.27294921875	1046.322265625	18.1151237487794	1626.00622558594	12.3205528259277	12.3717184066772	12.2261638641357	1542.71569824219	1099.44458007813	1988.25549316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	12.2837924957275	12.3719539642334	12.2060375213623	1368.51538085938	1037.06848144531	17.7180633544922	1604.04650878906	12.3205528259277	12.3717184066772	12.2261638641357	1542.71569824219	1099.44458007813	1988.25549316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	12.3173503875732	12.3719539642334	12.278883934021	1332.61071777344	1013.56353759766	17.0771732330322	1560.03308105469	12.3205528259277	12.3717184066772	12.2261638641357	1542.71569824219	1099.44458007813	1988.25549316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	12.3163299560547	12.3719539642334	12.2698545455933	1303.18005371094	990.74267578125	16.7273979187013	1526.01940917969	12.3205528259277	12.3717184066772	12.2261638641357	1542.71569824219	1099.44458007813	1988.25549316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	12.3167896270752	12.3719539642334	12.2686214447021	1212.27661132813	903.196594238281	16.4736843109131	1430.3544921875	12.3205528259277	12.3717184066772	12.2261638641357	1542.71569824219	1099.44458007813	1988.25549316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	12.3330345153809	12.3719539642334	12.3041248321533	1150.13415527344	863.948486328125	15.2062721252442	1350.54919433594	12.3205528259277	12.3717184066772	12.2261638641357	1542.71569824219	1099.44458007813	1988.25549316406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	12.2222557067871	12.2906198501587	12.1600723266602	2597.24853515625	1810.58996582031	41.0874366760256	3125.38793945313	12.2352151870728	12.3537244796753	12.1604928970337	2707.78149414063	1732.22180175781	3642.77880859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	12.2052755355835	12.3119535446167	12.1572313308716	2641.5009765625	1773.48303222656	39.5485420227053	3039.03637695313	12.2352151870728	12.3537244796753	12.1604928970337	2707.78149414063	1732.22180175781	3642.77880859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	12.2043418884277	12.339617729187	12.1446132659912	2575.87255859375	1736.34948730469	37.9122505187987	2949.54150390625	12.2352151870728	12.3537244796753	12.1604928970337	2707.78149414063	1732.22180175781	3642.77880859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	12.2548227310181	12.351616859436	12.2120513916016	2552.54711914063	1730.67272949219	37.7436408996581	2938.46923828125	12.2352151870728	12.3537244796753	12.1604928970337	2707.78149414063	1732.22180175781	3642.77880859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	12.2131929397583	12.3311672210693	12.1191473007202	2358.88061523438	1625.83740234375	38.8320770263672	2868.4638671875	12.2352151870728	12.3537244796753	12.1604928970337	2707.78149414063	1732.22180175781	3642.77880859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	12.2712020874023	12.351526260376	12.2455215454102	2335.19873046875	1610.06457519531	38.4421043395997	2840.2119140625	12.2352151870728	12.3537244796753	12.1604928970337	2707.78149414063	1732.22180175781	3642.77880859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	12.2759380340576	12.3320264816284	12.2279005050659	2283.23852539063	1581.17431640625	37.49764251709	2781.09887695313	12.2352151870728	12.3537244796753	12.1604928970337	2707.78149414063	1732.22180175781	3642.77880859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	12.2841739654541	12.3245439529419	12.2551202774048	2233.7548828125	1544.04748535156	36.8051261901856	2721.8115234375	12.2352151870728	12.3537244796753	12.1604928970337	2707.78149414063	1732.22180175781	3642.77880859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	12.2538318634033	12.3348178863525	12.1786851882935	2121.39770507813	1443.015625	35.9446105957031	2593.2431640625	12.2352151870728	12.3537244796753	12.1604928970337	2707.78149414063	1732.22180175781	3642.77880859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	12.3031244277954	12.363712310791	12.2510871887207	1988.96630859375	1353.54992675781	33.5594062805178	2427.45092773438	12.2352151870728	12.3537244796753	12.1604928970337	2707.78149414063	1732.22180175781	3642.77880859375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	12.0928115844727	12.3044338226318	11.9359827041626	3224.50048828125	2154.0029296875	55.8687286376953	3941.80224609375	12.176230430603	12.353949546814	11.8843507766724	3432.50634765625	2118.39624023438	4684.5263671875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	12.0838985443115	12.2723321914673	11.9979915618896	3276.38623046875	2113.55493164063	52.9681472778319	3808.53564453125	12.176230430603	12.353949546814	11.8843507766724	3432.50634765625	2118.39624023438	4684.5263671875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	12.0899963378906	12.3199663162231	11.9876174926758	3199.27783203125	2061.01196289063	51.4017181396484	3705.86694335938	12.176230430603	12.353949546814	11.8843507766724	3432.50634765625	2118.39624023438	4684.5263671875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	12.1378688812256	12.3445196151733	12.0441608428955	3190.1826171875	2064.671875	51.6927261352541	3718.83911132813	12.176230430603	12.353949546814	11.8843507766724	3432.50634765625	2118.39624023438	4684.5263671875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	12.1605138778687	12.3602571487427	12.0102834701538	2976.59228515625	1981.38134765625	52.8159561157228	3671.49194335938	12.176230430603	12.353949546814	11.8843507766724	3432.50634765625	2118.39624023438	4684.5263671875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	12.1302309036255	12.3323907852173	11.9637718200684	2943.97290039063	1960.65270996094	52.2310905456544	3632.04760742188	12.176230430603	12.353949546814	11.8843507766724	3432.50634765625	2118.39624023438	4684.5263671875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	12.1614561080933	12.344446182251	12.0487442016602	2882.48388671875	1920.14477539063	51.2729568481444	3560.87939453125	12.176230430603	12.353949546814	11.8843507766724	3432.50634765625	2118.39624023438	4684.5263671875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	12.2323760986328	12.3645439147949	12.1714305877686	2818.04248046875	1879.54870605469	49.9600791931153	3478.27124023438	12.176230430603	12.353949546814	11.8843507766724	3432.50634765625	2118.39624023438	4684.5263671875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	12.1922178268433	12.3401679992676	12.1211099624634	2690.49829101563	1777.70178222656	48.2488975524903	3321.66650390625	12.176230430603	12.353949546814	11.8843507766724	3432.50634765625	2118.39624023438	4684.5263671875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	12.190749168396	12.3663291931152	12.0873126983643	2521.12329101563	1655.41638183594	45.6086540222169	3114.89331054688	12.176230430603	12.353949546814	11.8843507766724	3432.50634765625	2118.39624023438	4684.5263671875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	11.8693876266479	12.2654619216919	11.5693483352661	3899.67602539063	2527.32788085938	71.5828628540038	4817.9794921875	12.0154571533203	12.348801612854	11.5317401885986	4227.7099609375	2543.52221679688	5824.76171875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	11.7832136154175	12.1677742004395	11.6022310256958	3986.947265625	2487.26342773438	68.3250350952147	4673.66455078125	12.0154571533203	12.348801612854	11.5317401885986	4227.7099609375	2543.52221679688	5824.76171875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	11.9157342910767	12.3446855545044	11.7253379821777	3872.18237304688	2412.54174804688	65.912757873535	4521.75	12.0154571533203	12.348801612854	11.5317401885986	4227.7099609375	2543.52221679688	5824.76171875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	11.9088335037231	12.3495044708252	11.6994581222534	3869.8505859375	2427.36328125	66.2339324951172	4546.84912109375	12.0154571533203	12.348801612854	11.5317401885986	4227.7099609375	2543.52221679688	5824.76171875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	11.9275207519531	12.344931602478	11.6126022338867	3628.0791015625	2368.25219726563	66.5564804077147	4498.0595703125	12.0154571533203	12.348801612854	11.5317401885986	4227.7099609375	2543.52221679688	5824.76171875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	11.9462995529175	12.346531867981	11.660270690918	3592.83447265625	2342.78540039063	66.0185012817381	4455.37744140625	12.0154571533203	12.348801612854	11.5317401885986	4227.7099609375	2543.52221679688	5824.76171875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	12.0367860794067	12.323769569397	11.8129749298096	3518.01000976563	2297.60278320313	64.7997360229491	4371.1943359375	12.0154571533203	12.348801612854	11.5317401885986	4227.7099609375	2543.52221679688	5824.76171875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	12.0679025650024	12.3181314468384	11.8595504760742	3444.3583984375	2250.55200195313	63.3686599731444	4278.34912109375	12.0154571533203	12.348801612854	11.5317401885986	4227.7099609375	2543.52221679688	5824.76171875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	12.0658655166626	12.3464450836182	11.8545093536377	3291.82666015625	2140.15673828125	60.6941223144531	4082.36865234375	12.0154571533203	12.348801612854	11.5317401885986	4227.7099609375	2543.52221679688	5824.76171875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Trunk-City/60/St+Go	60	Stopgo	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	12.0966386795044	12.3529691696167	11.8849840164185	3082.044921875	1986.01171875	57.6758880615234	3831.64013671875	12.0154571533203	12.348801612854	11.5317401885986	4227.7099609375	2543.52221679688	5824.76171875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	27.4154186248779	27.4165153503418	27.4144115447998	807.203918457031	643.2470703125	8.64842796325684	919.996765136719	27.4157581329346	27.4164733886719	27.4142189025879	798.030883789063	597.253845214844	1006.03240966797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	27.4148197174072	27.4165153503418	27.4140110015869	808.768493652344	627.064880371094	8.28645896911622	892.231567382813	27.4157581329346	27.4164733886719	27.4142189025879	798.030883789063	597.253845214844	1006.03240966797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	27.4165153503418	27.4165153503418	27.4165153503418	788.405822753906	617.289245605469	7.72995376586913	864.647766113281	27.4157581329346	27.4164733886719	27.4142189025879	798.030883789063	597.253845214844	1006.03240966797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	27.4165153503418	27.4165153503418	27.4165153503418	808.665161132813	642.186767578125	7.65519523620606	887.153015136719	27.4157581329346	27.4164733886719	27.4142189025879	798.030883789063	597.253845214844	1006.03240966797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	27.3872776031494	27.4165153503418	27.3578548431396	718.451721191406	567.705261230469	8.06782913208006	825.875793457031	27.4157581329346	27.4164733886719	27.4142189025879	798.030883789063	597.253845214844	1006.03240966797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	27.3822345733643	27.4165153503418	27.3477344512939	711.750305175781	562.3017578125	7.99974441528322	818.293579101563	27.4157581329346	27.4164733886719	27.4142189025879	798.030883789063	597.253845214844	1006.03240966797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	27.3824520111084	27.4165153503418	27.347562789917	706.396850585938	563.522399902344	7.67012214660644	808.96630859375	27.4157581329346	27.4164733886719	27.4142189025879	798.030883789063	597.253845214844	1006.03240966797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	27.4164333343506	27.4165153503418	27.4163494110107	692.605041503906	552.749450683594	7.50589752197266	792.938171386719	27.4157581329346	27.4164733886719	27.4142189025879	798.030883789063	597.253845214844	1006.03240966797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	27.4165153503418	27.4165153503418	27.4165153503418	632.129272460938	486.847137451172	7.74621486663819	734.726013183594	27.4157581329346	27.4164733886719	27.4142189025879	798.030883789063	597.253845214844	1006.03240966797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	27.4165153503418	27.4165153503418	27.4165153503418	613.927368164063	486.7568359375	6.74924659729003	702.732727050781	27.4157581329346	27.4164733886719	27.4142189025879	798.030883789063	597.253845214844	1006.03240966797\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	27.4165153503418	27.4165153503418	27.4165153503418	131.557846069336	131.277572631836	0.0357279777526873	132.420867919922	27.4164981842041	27.4164981842041	27.4165000915527	88.5963592529297	82.9054565429688	95.7062149047852\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	27.4165153503418	27.4165153503418	27.4165153503418	130.895278930664	130.13151550293	0.03667163848875	131.30500793457	27.4164981842041	27.4164981842041	27.4165000915527	88.5963592529297	82.9054565429688	95.7062149047852\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	27.4165153503418	27.4165153503418	27.4165153503418	128.980514526367	128.637084960938	0.0160856246948118	129.151824951172	27.4164981842041	27.4164981842041	27.4165000915527	88.5963592529297	82.9054565429688	95.7062149047852\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	27.4165153503418	27.4165153503418	27.4165153503418	125.199508666992	122.611511230469	0.119797468185406	126.445030212402	27.4164981842041	27.4164981842041	27.4165000915527	88.5963592529297	82.9054565429688	95.7062149047852\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	27.4165153503418	27.4165153503418	27.4165153503418	72.7231292724609	69.3141632080078	0.190822362899781	75.4204788208008	27.4164981842041	27.4164981842041	27.4165000915527	88.5963592529297	82.9054565429688	95.7062149047852\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	27.4165153503418	27.4165153503418	27.4165153503418	72.0807647705078	68.7045211791992	0.188990592956543	74.7522201538086	27.4164981842041	27.4164981842041	27.4165000915527	88.5963592529297	82.9054565429688	95.7062149047852\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	27.4165153503418	27.4165153503418	27.4165153503418	81.0226287841797	77.5847091674805	0.192561626434325	83.7466812133789	27.4164981842041	27.4164981842041	27.4165000915527	88.5963592529297	82.9054565429688	95.7062149047852\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	27.4165153503418	27.4165153503418	27.4165153503418	79.7835388183594	76.4125366210938	0.188810110092162	82.454460144043	27.4164981842041	27.4164981842041	27.4165000915527	88.5963592529297	82.9054565429688	95.7062149047852\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	27.4165153503418	27.4165153503418	27.4165153503418	73.256965637207	68.183837890625	0.283861875534056	77.2674179077148	27.4164981842041	27.4164981842041	27.4165000915527	88.5963592529297	82.9054565429688	95.7062149047852\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	27.4165153503418	27.4165153503418	27.4165153503418	72.3023071289063	68.0733947753906	0.237097501754763	75.660514831543	27.4164981842041	27.4164981842041	27.4165000915527	88.5963592529297	82.9054565429688	95.7062149047852\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	27.4165153503418	27.4165153503418	27.4165153503418	174.35661315918	169.809005737305	0.3140025138855	179.857086181641	27.4164009094238	27.4165000915527	27.4160957336426	130.954025268555	113.815505981445	153.269668579102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	27.4165153503418	27.4165153503418	27.4165153503418	172.964889526367	165.90315246582	0.327529430389406	176.384094238281	27.4164009094238	27.4165000915527	27.4160957336426	130.954025268555	113.815505981445	153.269668579102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	27.4165153503418	27.4165153503418	27.4165153503418	171.561889648438	167.431732177734	0.189238548278812	173.487365722656	27.4164009094238	27.4165000915527	27.4160957336426	130.954025268555	113.815505981445	153.269668579102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	27.4165153503418	27.4165153503418	27.4165153503418	167.050811767578	166.501052856445	0.0412750244140625	167.821853637695	27.4164009094238	27.4165000915527	27.4160957336426	130.954025268555	113.815505981445	153.269668579102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	27.4165153503418	27.4165153503418	27.4165153503418	108.64079284668	96.7023620605469	0.674424886703503	118.283958435059	27.4164009094238	27.4165000915527	27.4160957336426	130.954025268555	113.815505981445	153.269668579102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	27.4165153503418	27.4165153503418	27.4165153503418	108.176712036133	95.8293304443359	0.691915988922128	117.970642089844	27.4164009094238	27.4165000915527	27.4160957336426	130.954025268555	113.815505981445	153.269668579102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	27.4165153503418	27.4165153503418	27.4165153503418	117.23917388916	104.475776672363	0.7167637348175	127.412216186523	27.4164009094238	27.4165000915527	27.4160957336426	130.954025268555	113.815505981445	153.269668579102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	27.4165153503418	27.4165153503418	27.4165153503418	115.286148071289	102.772811889648	0.702715635299688	125.259712219238	27.4164009094238	27.4165000915527	27.4160957336426	130.954025268555	113.815505981445	153.269668579102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	27.4165153503418	27.4165153503418	27.4165153503418	106.089599609375	92.8542022705078	0.734000682830819	116.342224121094	27.4164009094238	27.4165000915527	27.4160957336426	130.954025268555	113.815505981445	153.269668579102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	27.4165153503418	27.4165153503418	27.4165153503418	103.187591552734	91.5724258422852	0.64310002326965	112.151626586914	27.4164009094238	27.4165000915527	27.4160957336426	130.954025268555	113.815505981445	153.269668579102\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	27.4163970947266	27.4165153503418	27.4162864685059	320.838439941406	347.072662353516	-1.20163345336916	308.620391845703	27.4162082672119	27.4165153503418	27.4155197143555	255.86604309082	259.62646484375	275.433898925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	27.4164409637451	27.4165153503418	27.4164047241211	310.808074951172	334.155120849609	-1.04897880554197	300.587799072266	27.4162082672119	27.4165153503418	27.4155197143555	255.86604309082	259.62646484375	275.433898925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	27.4165153503418	27.4165153503418	27.4165153503418	307.932739257813	333.695190429688	-1.1579875946045	296.639587402344	27.4162082672119	27.4165153503418	27.4155197143555	255.86604309082	259.62646484375	275.433898925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	27.4165153503418	27.4165153503418	27.4165153503418	316.826446533203	354.238433837891	-1.69541168212891	299.985260009766	27.4162082672119	27.4165153503418	27.4155197143555	255.86604309082	259.62646484375	275.433898925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	27.4164848327637	27.4165153503418	27.4164543151855	239.43115234375	254.507019042969	-0.634768486022969	234.194427490234	27.4162082672119	27.4165153503418	27.4155197143555	255.86604309082	259.62646484375	275.433898925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	27.4165153503418	27.4165153503418	27.4165153503418	236.257263183594	252.115295410156	-0.686807155609125	230.137466430664	27.4162082672119	27.4165153503418	27.4155197143555	255.86604309082	259.62646484375	275.433898925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	27.4163837432861	27.4165153503418	27.4162502288818	241.207656860352	255.597915649414	-0.619676113128657	235.768280029297	27.4162082672119	27.4165153503418	27.4155197143555	255.86604309082	259.62646484375	275.433898925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	27.4165153503418	27.4165153503418	27.4165153503418	236.801986694336	250.900924682617	-0.607085704803469	231.474182128906	27.4162082672119	27.4165153503418	27.4155197143555	255.86604309082	259.62646484375	275.433898925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	27.4165153503418	27.4165153503418	27.4165153503418	207.963180541992	208.481506347656	0.0648012161254998	210.555145263672	27.4162082672119	27.4165153503418	27.4155197143555	255.86604309082	259.62646484375	275.433898925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	27.4165153503418	27.4165153503418	27.4165153503418	205.495101928711	213.744567871094	-0.336035251617438	202.991439819336	27.4162082672119	27.4165153503418	27.4155197143555	255.86604309082	259.62646484375	275.433898925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	27.311466217041	27.4165153503418	27.2127323150635	1528.05773925781	1012.70043945313	27.1233825683594	1880.64868164063	27.406831741333	27.4164142608643	27.378454208374	1656.48510742188	1030.52001953125	2296.33618164063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	27.3712692260742	27.4165153503418	27.3528633117676	1565.03601074219	991.580322265625	26.142894744873	1828.15295410156	27.406831741333	27.4164142608643	27.378454208374	1656.48510742188	1030.52001953125	2296.33618164063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	27.3771171569824	27.4165153503418	27.3592948913574	1510.68627929688	968.322021484375	24.499710083008	1752.31274414063	27.406831741333	27.4164142608643	27.378454208374	1656.48510742188	1030.52001953125	2296.33618164063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	27.4165153503418	27.4165153503418	27.4165153503418	1523.8955078125	1001.85461425781	23.9981880187991	1769.79663085938	27.406831741333	27.4164142608643	27.378454208374	1656.48510742188	1030.52001953125	2296.33618164063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	27.3917350769043	27.4165153503418	27.41330909729	1418.51525878906	948.398498535156	25.0802516937257	1750.96655273438	27.406831741333	27.4164142608643	27.378454208374	1656.48510742188	1030.52001953125	2296.33618164063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	27.3883457183838	27.4165153503418	27.3599967956543	1404.74914550781	939.338195800781	24.8566226959228	1734.75012207031	27.406831741333	27.4164142608643	27.378454208374	1656.48510742188	1030.52001953125	2296.33618164063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	27.3394412994385	27.4165153503418	27.285623550415	1380.50903320313	936.319396972656	23.8456211090089	1699.37927246094	27.406831741333	27.4164142608643	27.378454208374	1656.48510742188	1030.52001953125	2296.33618164063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	27.3825168609619	27.4165153503418	27.3476600646973	1353.40124511719	918.223754882813	23.3647956848146	1665.89721679688	27.406831741333	27.4164142608643	27.378454208374	1656.48510742188	1030.52001953125	2296.33618164063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	27.4165153503418	27.4165153503418	27.4165153503418	1312.03540039063	865.185119628906	23.7601070404054	1625.50854492188	27.406831741333	27.4164142608643	27.378454208374	1656.48510742188	1030.52001953125	2296.33618164063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	27.4165153503418	27.4165153503418	27.4165153503418	1210.40258789063	812.602722167969	21.1169033050538	1488.34362792969	27.406831741333	27.4164142608643	27.378454208374	1656.48510742188	1030.52001953125	2296.33618164063\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	27.0632247924805	27.4165153503418	26.7523536682129	2329.89501953125	1421.14868164063	47.8875427246094	2953.55004882813	27.3783702850342	27.4163265228271	27.26589012146	2583.1640625	1497.31298828125	3695.9306640625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	27.2798461914063	27.4165153503418	27.2180843353271	2404.57788085938	1395.39453125	46.0118484497072	2867.77368164063	27.3783702850342	27.4163265228271	27.26589012146	2583.1640625	1497.31298828125	3695.9306640625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	27.3231678009033	27.4165153503418	27.2809448242188	2306.79174804688	1355.04296875	42.9907836914063	2730.748046875	27.3783702850342	27.4163265228271	27.26589012146	2583.1640625	1497.31298828125	3695.9306640625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	27.3685970306396	27.4165153503418	27.3446846008301	2314.98852539063	1395.740234375	42.2598037719728	2748.05395507813	27.3783702850342	27.4163265228271	27.26589012146	2583.1640625	1497.31298828125	3695.9306640625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	27.3056678771973	27.4165153503418	27.2142696380615	2186.8994140625	1366.50927734375	43.7955169677734	2767.9658203125	27.3783702850342	27.4163265228271	27.26589012146	2583.1640625	1497.31298828125	3695.9306640625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	27.3040351867676	27.4165153503418	27.2116508483887	2164.26123046875	1353.43225097656	43.2682914733888	2738.017578125	27.3783702850342	27.4163265228271	27.26589012146	2583.1640625	1497.31298828125	3695.9306640625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	27.342357635498	27.4165153503418	27.2893142700195	2114.3466796875	1338.18579101563	41.5584411621094	2668.05590820313	27.3783702850342	27.4163265228271	27.26589012146	2583.1640625	1497.31298828125	3695.9306640625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	27.387414932251	27.4165153503418	27.3576507568359	2073.02368164063	1312.20471191406	40.7408103942872	2615.91064453125	27.3783702850342	27.4163265228271	27.26589012146	2583.1640625	1497.31298828125	3695.9306640625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	27.4164409637451	27.4165153503418	27.4163684844971	2027.49682617188	1282.359375	39.5196533203125	2546.98828125	27.3783702850342	27.4163265228271	27.26589012146	2583.1640625	1497.31298828125	3695.9306640625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	27.4165153503418	27.4165153503418	27.4165153503418	1857.45642089844	1164.23168945313	36.6906356811522	2338.33203125	27.3783702850342	27.4163265228271	27.26589012146	2583.1640625	1497.31298828125	3695.9306640625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	26.8102416992188	27.3541126251221	26.3629016876221	3197.2119140625	1849.36572265625	71.1830749511719	4127.22412109375	27.1782550811768	27.4162998199463	26.5080642700195	3556.068359375	1984.81823730469	5199.0517578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	27.1170234680176	27.3538932800293	27.010892868042	3318.99365234375	1820.85095214844	68.3202095031737	4007.09765625	27.1782550811768	27.4162998199463	26.5080642700195	3556.068359375	1984.81823730469	5199.0517578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	26.7359256744385	27.4165153503418	26.4287014007568	3194.06640625	1766.04821777344	64.5162391662597	3830.56787109375	27.1782550811768	27.4162998199463	26.5080642700195	3556.068359375	1984.81823730469	5199.0517578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	27.3022994995117	27.4165153503418	27.2477359771729	3182.30126953125	1806.05834960938	63.2982177734375	3831.60131835938	27.1782550811768	27.4162998199463	26.5080642700195	3556.068359375	1984.81823730469	5199.0517578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	27.3100757598877	27.4165153503418	27.2285842895508	2999.71557617188	1804.16052246094	63.9992637634278	3852.13696289063	27.1782550811768	27.4162998199463	26.5080642700195	3556.068359375	1984.81823730469	5199.0517578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	27.2664470672607	27.4165153503418	27.1419410705566	2970.59423828125	1786.87280273438	63.3619155883788	3814.4541015625	27.1782550811768	27.4162998199463	26.5080642700195	3556.068359375	1984.81823730469	5199.0517578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	27.1918869018555	27.4164485931396	27.0096588134766	2894.10424804688	1768.931640625	60.4725723266603	3704.05395507813	27.1782550811768	27.4162998199463	26.5080642700195	3556.068359375	1984.81823730469	5199.0517578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	27.3042469024658	27.4165153503418	27.214391708374	2836.71606445313	1734.50524902344	59.2351875305175	3630.03125	27.1782550811768	27.4162998199463	26.5080642700195	3556.068359375	1984.81823730469	5199.0517578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	27.3848094940186	27.4165153503418	27.3533191680908	2757.62841796875	1705.91857910156	55.9694099426272	3496.93969726563	27.1782550811768	27.4162998199463	26.5080642700195	3556.068359375	1984.81823730469	5199.0517578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Freeflow	30	Freeflow	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	27.4147891998291	27.4165153503418	27.4131107330322	2548.04467773438	1541.75122070313	53.5139236450194	3254.19677734375	27.1782550811768	27.4162998199463	26.5080642700195	3556.068359375	1984.81823730469	5199.0517578125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	14.8353815078735	14.8359899520874	14.8348073959351	1303.42517089844	1031.36413574219	14.3792495727538	1491.50012207031	14.8354215621948	14.8359622955322	14.8344593048096	1274.58435058594	935.086547851563	1629.6240234375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	14.833625793457	14.8359899520874	14.834376335144	1307.35205078125	1005.72985839844	13.7542076110841	1445.86450195313	14.8354215621948	14.8359622955322	14.8344593048096	1274.58435058594	935.086547851563	1629.6240234375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	14.8090047836304	14.8359899520874	14.796989440918	1287.95837402344	1004.80688476563	12.7919578552244	1414.14953613281	14.8354215621948	14.8359622955322	14.8344593048096	1274.58435058594	935.086547851563	1629.6240234375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	14.8358840942383	14.8359899520874	14.8358316421509	1279.85046386719	1003.69866943359	12.7009830474856	1410.13012695313	14.8354215621948	14.8359622955322	14.8344593048096	1274.58435058594	935.086547851563	1629.6240234375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	14.8283805847168	14.8359899520874	14.8334836959839	1150.03454589844	897.300964355469	13.5184612274169	1329.89172363281	14.8354215621948	14.8359622955322	14.8344593048096	1274.58435058594	935.086547851563	1629.6240234375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	14.8351306915283	14.8359899520874	14.8342657089233	1139.01391601563	888.715209960938	13.3996162414551	1317.5029296875	14.8354215621948	14.8359622955322	14.8344593048096	1274.58435058594	935.086547851563	1629.6240234375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	14.8357076644897	14.8359899520874	14.8354263305664	1113.19421386719	875.413330078125	12.8099784851073	1285.33264160156	14.8354215621948	14.8359622955322	14.8344593048096	1274.58435058594	935.086547851563	1629.6240234375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	14.816707611084	14.8359899520874	14.7969379425049	1090.51440429688	858.392028808594	12.5041980743408	1258.5263671875	14.8354215621948	14.8359622955322	14.8344593048096	1274.58435058594	935.086547851563	1629.6240234375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	14.8359899520874	14.8359899520874	14.8359899520874	1008.55236816406	768.52294921875	12.7971801757813	1178.03271484375	14.8354215621948	14.8359622955322	14.8344593048096	1274.58435058594	935.086547851563	1629.6240234375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	14.8359899520874	14.8359899520874	14.8359899520874	964.329040527344	750.363891601563	11.3780097961427	1114.46020507813	14.8354215621948	14.8359622955322	14.8344593048096	1274.58435058594	935.086547851563	1629.6240234375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	14.8359899520874	14.8359899520874	14.8359899520874	432.858795166016	464.698394775391	-1.56807136535647	414.520111083984	14.8359889984131	14.8359899520874	14.8359899520874	299.931213378906	318.077545166016	294.725036621094\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	14.8359899520874	14.8359899520874	14.8359899520874	416.968872070313	450.192901611328	-1.50600814819334	402.000640869141	14.8359889984131	14.8359899520874	14.8359899520874	299.931213378906	318.077545166016	294.725036621094\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	14.8359899520874	14.8359899520874	14.8359899520874	424.370208740234	462.562194824219	-1.72127819061281	407.481292724609	14.8359889984131	14.8359899520874	14.8359899520874	299.931213378906	318.077545166016	294.725036621094\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	14.8359899520874	14.8359899520874	14.8359899520874	407.117370605469	439.914611816406	-1.49404430389403	392.105194091797	14.8359889984131	14.8359899520874	14.8359899520874	299.931213378906	318.077545166016	294.725036621094\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	14.8359899520874	14.8359899520874	14.8359899520874	292.280822753906	310.266052246094	-0.876270294189467	282.225402832031	14.8359889984131	14.8359899520874	14.8359899520874	299.931213378906	318.077545166016	294.725036621094\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	14.8359899520874	14.8359899520874	14.8359899520874	289.512420654297	307.324310302734	-0.867822647094718	279.553985595703	14.8359889984131	14.8359899520874	14.8359899520874	299.931213378906	318.077545166016	294.725036621094\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	14.8359899520874	14.8359899520874	14.8359899520874	299.096160888672	317.114776611328	-0.886305809021	288.752990722656	14.8359889984131	14.8359899520874	14.8359899520874	299.931213378906	318.077545166016	294.725036621094\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	14.8359899520874	14.8359899520874	14.8359899520874	293.447906494141	311.107177734375	-0.868619918823249	283.311340332031	14.8359889984131	14.8359899520874	14.8359899520874	299.931213378906	318.077545166016	294.725036621094\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	14.8359899520874	14.8359899520874	14.8359899520874	248.966400146484	259.636169433594	-0.508500099182124	243.364166259766	14.8359889984131	14.8359899520874	14.8359899520874	299.931213378906	318.077545166016	294.725036621094\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	14.8359899520874	14.8359899520874	14.8359899520874	251.194274902344	264.278350830078	-0.633782386779782	243.997314453125	14.8359889984131	14.8359899520874	14.8359899520874	299.931213378906	318.077545166016	294.725036621094\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	14.8359899520874	14.8359899520874	14.8359899520874	584.567077636719	575.157287597656	0.607378005981467	594.593383789063	14.8359823226929	14.8359899520874	14.8359718322754	465.621704101563	433.487701416016	514.352416992188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	14.8359899520874	14.8359899520874	14.8359899520874	579.240600585938	562.343811035156	0.77911758422853	587.275573730469	14.8359823226929	14.8359899520874	14.8359718322754	465.621704101563	433.487701416016	514.352416992188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	14.8359899520874	14.8359899520874	14.8359899520874	588.367370605469	577.156860351563	0.508771896362283	593.437561035156	14.8359823226929	14.8359899520874	14.8359718322754	465.621704101563	433.487701416016	514.352416992188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	14.8359899520874	14.8359899520874	14.8359899520874	569.131042480469	559.545471191406	0.454622268676779	574.093383789063	14.8359823226929	14.8359899520874	14.8359718322754	465.621704101563	433.487701416016	514.352416992188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	14.8359899520874	14.8359899520874	14.8359899520874	439.526458740234	423.182373046875	0.980901718139656	454.571228027344	14.8359823226929	14.8359899520874	14.8359718322754	465.621704101563	433.487701416016	514.352416992188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	14.8359899520874	14.8359899520874	14.8359899520874	435.343109130859	419.155151367188	0.971521377563468	450.243835449219	14.8359823226929	14.8359899520874	14.8359718322754	465.621704101563	433.487701416016	514.352416992188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	14.8359899520874	14.8359899520874	14.8359899520874	430.69482421875	415.772186279297	0.90625381469725	444.772308349609	14.8359823226929	14.8359899520874	14.8359718322754	465.621704101563	433.487701416016	514.352416992188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	14.8359899520874	14.8359899520874	14.8359899520874	422.259765625	407.816131591797	0.876595497131344	435.8671875	14.8359823226929	14.8359899520874	14.8359718322754	465.621704101563	433.487701416016	514.352416992188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	14.8359899520874	14.8359899520874	14.8359899520874	369.397491455078	344.964569091797	1.379301071167	389.102203369141	14.8359823226929	14.8359899520874	14.8359718322754	465.621704101563	433.487701416016	514.352416992188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	14.8359899520874	14.8359899520874	14.8359899520874	365.516021728516	347.732696533203	1.01986980438234	380.368530273438	14.8359823226929	14.8359899520874	14.8359718322754	465.621704101563	433.487701416016	514.352416992188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	14.8359899520874	14.8359899520874	14.8359899520874	854.576171875	760.205139160156	5.06891822814944	922.410522460938	14.835825920105	14.8359727859497	14.8355722427368	768.028198242188	635.597045898438	917.849670410156\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	14.8358840942383	14.8359899520874	14.8358335494995	849.413879394531	739.420349121094	5.02332496643066	900.166748046875	14.835825920105	14.8359727859497	14.8355722427368	768.028198242188	635.597045898438	917.849670410156\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	14.7989120483398	14.8359899520874	14.7821407318115	852.815734863281	751.185852050781	4.59480285644531	898.219543457031	14.835825920105	14.8359727859497	14.8355722427368	768.028198242188	635.597045898438	917.849670410156\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	14.8359899520874	14.8359899520874	14.8359899520874	836.726196289063	742.851623535156	4.32847976684572	881.362976074219	14.835825920105	14.8359727859497	14.8355722427368	768.028198242188	635.597045898438	917.849670410156\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	14.8354034423828	14.8359899520874	14.8348140716553	709.003723144531	615.136962890625	5.08012008666994	777.700805664063	14.835825920105	14.8359727859497	14.8355722427368	768.028198242188	635.597045898438	917.849670410156\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	14.8359642028809	14.8359899520874	14.8359384536743	700.467407226563	609.263977050781	4.97140121459962	768.348815917969	14.835825920105	14.8359727859497	14.8355722427368	768.028198242188	635.597045898438	917.849670410156\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	14.82630443573	14.8359899520874	14.8163738250732	689.068115234375	603.079467773438	4.72206687927244	754.185607910156	14.835825920105	14.8359727859497	14.8355722427368	768.028198242188	635.597045898438	917.849670410156\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	14.8359899520874	14.8359899520874	14.8359899520874	674.978332519531	591.425720214844	4.58591079711913	738.174865722656	14.835825920105	14.8359727859497	14.8355722427368	768.028198242188	635.597045898438	917.849670410156\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	14.8359899520874	14.8359899520874	14.8359899520874	608.921630859375	510.617248535156	5.30290412902834	680.310180664063	14.835825920105	14.8359727859497	14.8355722427368	768.028198242188	635.597045898438	917.849670410156\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	14.8359899520874	14.8359899520874	14.8359899520874	590.753967285156	508.324188232422	4.45184040069581	650.783081054688	14.835825920105	14.8359727859497	14.8355722427368	768.028198242188	635.597045898438	917.849670410156\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	14.8158330917358	14.8359899520874	14.7969913482666	1921.71166992188	1363.73937988281	29.4449958801272	2305.97924804688	14.8257141113281	14.8359746932983	14.7929391860962	1990.08728027344	1313.85498046875	2691.900390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	14.8094234466553	14.8359899520874	14.7967891693115	1951.95593261719	1330.41479492188	28.3425140380859	2237.37524414063	14.8257141113281	14.8359746932983	14.7929391860962	1990.08728027344	1313.85498046875	2691.900390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	14.8079738616943	14.8359899520874	14.7956562042236	1898.94885253906	1316.98364257813	26.2889938354491	2158.2314453125	14.8257141113281	14.8359746932983	14.7929391860962	1990.08728027344	1313.85498046875	2691.900390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	14.8106088638306	14.8359899520874	14.7979440689087	1897.05688476563	1326.59362792969	26.2339210510253	2166.0791015625	14.8257141113281	14.8359746932983	14.7929391860962	1990.08728027344	1313.85498046875	2691.900390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	14.8162517547607	14.8359899520874	14.7971305847168	1749.13073730469	1243.18420410156	27.0613517761231	2109.1474609375	14.8257141113281	14.8359746932983	14.7929391860962	1990.08728027344	1313.85498046875	2691.900390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	14.7995710372925	14.8359899520874	14.7756910324097	1731.15295410156	1227.48022460938	26.9291458129881	2089.212890625	14.8257141113281	14.8359746932983	14.7929391860962	1990.08728027344	1313.85498046875	2691.900390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	14.8251953125	14.8359899520874	14.8344049453735	1691.67639160156	1209.39819335938	25.8966789245603	2038.09191894531	14.8257141113281	14.8359746932983	14.7929391860962	1990.08728027344	1313.85498046875	2691.900390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	14.8352842330933	14.8359899520874	14.8345603942871	1655.69213867188	1185.55773925781	25.2646446228028	1994.0263671875	14.8257141113281	14.8359746932983	14.7929391860962	1990.08728027344	1313.85498046875	2691.900390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	14.8357563018799	14.8359899520874	14.835524559021	1565.53015136719	1093.55590820313	25.1173744201659	1897.31188964844	14.8257141113281	14.8359746932983	14.7929391860962	1990.08728027344	1313.85498046875	2691.900390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	14.8080072402954	14.8359899520874	14.780797958374	1475.53063964844	1044.42138671875	22.9074935913088	1777.46118164063	14.8257141113281	14.8359746932983	14.7929391860962	1990.08728027344	1313.85498046875	2691.900390625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	14.7309293746948	14.8359899520874	14.643946647644	2657.1240234375	1745.6123046875	48.0570983886719	3283.439453125	14.798656463623	14.8359050750732	14.7010431289673	2833.67041015625	1742.50268554688	3957.41064453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	14.723671913147	14.8163509368896	14.6809015274048	2724.1943359375	1709.66540527344	46.2591285705566	3189.95751953125	14.798656463623	14.8359050750732	14.7010431289673	2833.67041015625	1742.50268554688	3957.41064453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	14.8120470046997	14.8359899520874	14.8014183044434	2629.12915039063	1675.61315917969	43.0731697082519	3053.95458984375	14.798656463623	14.8359050750732	14.7010431289673	2833.67041015625	1742.50268554688	3957.41064453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	14.7749547958374	14.8359899520874	14.7445402145386	2621.00634765625	1691.58544921875	42.7344512939453	3059.087890625	14.798656463623	14.8359050750732	14.7010431289673	2833.67041015625	1742.50268554688	3957.41064453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	14.762752532959	14.7798261642456	14.7393083572388	2451.34716796875	1630.37158203125	43.8775024414062	3034.45166015625	14.798656463623	14.8359050750732	14.7010431289673	2833.67041015625	1742.50268554688	3957.41064453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	14.7599153518677	14.8359899520874	14.7166948318481	2428.01782226563	1612.96411132813	43.5394287109375	3006.22583007813	14.798656463623	14.8359050750732	14.7010431289673	2833.67041015625	1742.50268554688	3957.41064453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	14.8263263702393	14.8359899520874	14.8166961669922	2367.15649414063	1585.42077636719	41.9232063293456	2926.96337890625	14.798656463623	14.8359050750732	14.7010431289673	2833.67041015625	1742.50268554688	3957.41064453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	14.7971048355103	14.8359899520874	14.7778558731079	2320.5126953125	1554.26171875	41.1224670410156	2870.1806640625	14.798656463623	14.8359050750732	14.7010431289673	2833.67041015625	1742.50268554688	3957.41064453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	14.7684059143066	14.8359899520874	14.7012777328491	2219.47485351563	1470.0712890625	39.8968429565431	2746.77026367188	14.798656463623	14.8359050750732	14.7010431289673	2833.67041015625	1742.50268554688	3957.41064453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	14.806209564209	14.8359899520874	14.7772512435913	2071.38134765625	1376.68444824219	36.8987159729003	2557.443359375	14.798656463623	14.8359050750732	14.7010431289673	2833.67041015625	1742.50268554688	3957.41064453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	14.6162014007568	14.8358993530273	14.43958568573	3459.125	2152.93334960938	68.8086929321288	4354.8115234375	14.7107381820679	14.8359088897705	14.4714059829712	3759.43115234375	2206.73486328125	5352.20166015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	14.6203126907349	14.8352937698364	14.5198726654053	3560.60546875	2110.26220703125	66.1209564208984	4226.1328125	14.7107381820679	14.8359088897705	14.4714059829712	3759.43115234375	2206.73486328125	5352.20166015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	14.6362619400024	14.8354377746582	14.5465574264526	3434.50927734375	2063.04272460938	61.9533996582031	4045.55151367188	14.7107381820679	14.8359088897705	14.4714059829712	3759.43115234375	2206.73486328125	5352.20166015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	14.6814889907837	14.8359899520874	14.6059055328369	3429.33227539063	2089.00537109375	61.6249084472656	4061.00244140625	14.7107381820679	14.8359088897705	14.4714059829712	3759.43115234375	2206.73486328125	5352.20166015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	14.7344703674316	14.8355083465576	14.6395454406738	3221.31640625	2045.82104492188	62.8038177490234	4055.54321289063	14.7107381820679	14.8359088897705	14.4714059829712	3759.43115234375	2206.73486328125	5352.20166015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	14.6644906997681	14.8359384536743	14.5127420425415	3190.55078125	2026.30798339844	62.2007637023925	4016.732421875	14.7107381820679	14.8359088897705	14.4714059829712	3759.43115234375	2206.73486328125	5352.20166015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	14.7684173583984	14.8359899520874	14.7206115722656	3108.59765625	1996.353515625	59.6395874023438	3904.8203125	14.7107381820679	14.8359088897705	14.4714059829712	3759.43115234375	2206.73486328125	5352.20166015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	14.7118148803711	14.8359899520874	14.6060409545898	3047.68896484375	1956.62902832031	58.5426368713381	3829.99340820313	14.7107381820679	14.8359088897705	14.4714059829712	3759.43115234375	2206.73486328125	5352.20166015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	14.758599281311	14.8359899520874	14.7010250091553	2928.66796875	1873.20361328125	56.1595993041994	3670.31079101563	14.7107381820679	14.8359088897705	14.4714059829712	3759.43115234375	2206.73486328125	5352.20166015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/Satur.	30	Saturated	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	14.8030500411987	14.8359899520874	14.7776594161987	2724.17456054688	1730.91723632813	52.7059173583984	3417.50659179688	14.7107381820679	14.8359088897705	14.4714059829712	3759.43115234375	2206.73486328125	5352.20166015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	9.43255615234375	9.48793506622314	9.38921070098877	2036.34204101563	1608.93334960938	22.5407104492187	2330.23608398438	9.46315670013428	9.49419975280762	9.40286827087402	1955.25158691406	1431.42724609375	2494.9501953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	9.4578857421875	9.49431133270264	9.44160556793213	2035.19116210938	1563.83288574219	21.4877815246581	2251.44189453125	9.46315670013428	9.49419975280762	9.40286827087402	1955.25158691406	1431.42724609375	2494.9501953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	9.40488529205322	9.46664047241211	9.37689971923828	2005.75390625	1560.12023925781	20.13041305542	2204.29345703125	9.46315670013428	9.49419975280762	9.40286827087402	1955.25158691406	1431.42724609375	2494.9501953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	9.4418773651123	9.49431133270264	9.41628551483154	2004.39331054688	1563.1884765625	20.2938919067384	2212.59301757813	9.46315670013428	9.49419975280762	9.40286827087402	1955.25158691406	1431.42724609375	2494.9501953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	9.46171188354492	9.49396991729736	9.43275356292725	1770.87194824219	1378.72619628906	21.0023536682131	2050.80151367188	9.46315670013428	9.49419975280762	9.40286827087402	1955.25158691406	1431.42724609375	2494.9501953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	9.43937206268311	9.48006439208984	9.40395736694336	1754.92639160156	1366.65905761719	20.7888870239256	2031.90344238281	9.46315670013428	9.49419975280762	9.40286827087402	1955.25158691406	1431.42724609375	2494.9501953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	9.45919895172119	9.49431133270264	9.42831611633301	1707.27893066406	1335.23059082031	20.0010147094728	1975.26306152344	9.46315670013428	9.49419975280762	9.40286827087402	1955.25158691406	1431.42724609375	2494.9501953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	9.4572925567627	9.49431133270264	9.42451763153076	1673.41381835938	1309.13708496094	19.6018104553222	1936.39501953125	9.46315670013428	9.49419975280762	9.40286827087402	1955.25158691406	1431.42724609375	2494.9501953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	9.46898460388184	9.49431133270264	9.44391918182373	1544.88354492188	1181.97839355469	19.3280181884766	1800.47497558594	9.46315670013428	9.49419975280762	9.40286827087402	1955.25158691406	1431.42724609375	2494.9501953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	9.46729946136475	9.49431133270264	9.44108486175537	1474.55554199219	1144.08166503906	17.5515632629397	1705.73168945313	9.46315670013428	9.49419975280762	9.40286827087402	1955.25158691406	1431.42724609375	2494.9501953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	9.49242973327637	9.49431133270264	9.49065208435059	1016.95861816406	980.383056640625	2.10192108154297	1047.64453125	9.49410247802734	9.49425411224365	9.49391651153564	805.729553222656	730.884643554688	911.231506347656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	9.49429702758789	9.49431133270264	9.49429035186768	987.587036132813	939.942504882813	2.18865585327147	1009.9794921875	9.49410247802734	9.49425411224365	9.49391651153564	805.729553222656	730.884643554688	911.231506347656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	9.48674488067627	9.49431133270264	9.48332405090332	992.772705078125	951.472351074219	1.87164306640628	1011.36492919922	9.49410247802734	9.49425411224365	9.49391651153564	805.729553222656	730.884643554688	911.231506347656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	9.49158382415771	9.49431133270264	9.4902229309082	974.171508789063	940.120849609375	1.58725357055665	990.912963867188	9.49410247802734	9.49425411224365	9.49391651153564	805.729553222656	730.884643554688	911.231506347656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	9.4925479888916	9.49431133270264	9.49077320098877	767.94384765625	721.773071289063	2.63781356811522	806.18310546875	9.49410247802734	9.49425411224365	9.49391651153564	805.729553222656	730.884643554688	911.231506347656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	9.49240779876709	9.49431133270264	9.49049282073975	760.524719238281	714.870727539063	2.60785865783691	798.322204589844	9.49410247802734	9.49425411224365	9.49391651153564	805.729553222656	730.884643554688	911.231506347656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	9.49431133270264	9.49431133270264	9.49431133270264	736.316162109375	694.084594726563	2.44111251831053	772.2001953125	9.49410247802734	9.49425411224365	9.49391651153564	805.729553222656	730.884643554688	911.231506347656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	9.49431133270264	9.49431133270264	9.49431133270264	722.050354003906	680.635559082031	2.39380455017091	757.2373046875	9.49410247802734	9.49425411224365	9.49391651153564	805.729553222656	730.884643554688	911.231506347656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	9.49431133270264	9.49431133270264	9.49431133270264	642.219299316406	587.332580566406	3.02841949462891	684.242004394531	9.49410247802734	9.49425411224365	9.49391651153564	805.729553222656	730.884643554688	911.231506347656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	9.49431133270264	9.49431133270264	9.49431133270264	622.670104980469	581.033325195313	2.31692504882812	655.174926757813	9.49410247802734	9.49425411224365	9.49391651153564	805.729553222656	730.884643554688	911.231506347656\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	9.47746849060059	9.49431133270264	9.46267414093018	1248.90551757813	1136.30895996094	6.05258941650375	1329.99182128906	9.4909496307373	9.49424934387207	9.47988033294678	1064.48254394531	901.69580078125	1259.046875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	9.46962547302246	9.49431133270264	9.45785903930664	1224.15747070313	1093.15905761719	5.98638916015625	1284.72351074219	9.4909496307373	9.49424934387207	9.47988033294678	1064.48254394531	901.69580078125	1259.046875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	9.48933029174805	9.49431133270264	9.48727035522461	1216.27416992188	1101.54077148438	5.18643569946281	1267.50671386719	9.4909496307373	9.49424934387207	9.47988033294678	1064.48254394531	901.69580078125	1259.046875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	9.48606967926025	9.49431133270264	9.48197078704834	1207.33386230469	1093.12805175781	5.26793670654313	1261.70202636719	9.4909496307373	9.49424934387207	9.47988033294678	1064.48254394531	901.69580078125	1259.046875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	9.48892116546631	9.49431133270264	9.48748970031738	988.33251953125	873.931396484375	6.23118591308609	1073.32934570313	9.4909496307373	9.49424934387207	9.47988033294678	1064.48254394531	901.69580078125	1259.046875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	9.48838996887207	9.49431133270264	9.48656272888184	978.784851074219	865.566162109375	6.16782379150391	1062.9365234375	9.4909496307373	9.49424934387207	9.47988033294678	1064.48254394531	901.69580078125	1259.046875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	9.49252128601074	9.49431133270264	9.49068546295166	967.833740234375	862.49951171875	5.76256942749031	1046.90173339844	9.4909496307373	9.49424934387207	9.47988033294678	1064.48254394531	901.69580078125	1259.046875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	9.49258899688721	9.49431133270264	9.49082279205322	949.021362304688	845.726623535156	5.65107917785637	1026.56115722656	9.4909496307373	9.49424934387207	9.47988033294678	1064.48254394531	901.69580078125	1259.046875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	9.49431133270264	9.49431133270264	9.49431133270264	857.169799804688	740.205871582031	6.31953239440919	942.430908203125	9.4909496307373	9.49424934387207	9.47988033294678	1064.48254394531	901.69580078125	1259.046875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	9.49431133270264	9.49431133270264	9.49431133270264	824.390686035156	726.840026855469	5.27904129028319	895.769348144531	9.4909496307373	9.49424934387207	9.47988033294678	1064.48254394531	901.69580078125	1259.046875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	9.46374702453613	9.49431133270264	9.44678020477295	1568.88598632813	1344.03088378906	11.9254646301272	1725.64575195313	9.48568820953369	9.49420261383057	9.46140575408936	1419.88891601563	1132.43298339844	1734.2587890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	9.44615364074707	9.49431133270264	9.42368602752686	1554.990234375	1305.126953125	11.3998985290528	1669.92370605469	9.48568820953369	9.49420261383057	9.46140575408936	1419.88891601563	1132.43298339844	1734.2587890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	9.45920658111572	9.49420642852783	9.4434986114502	1543.18115234375	1305.11791992188	10.7573280334472	1649.35241699219	9.48568820953369	9.49420261383057	9.46140575408936	1419.88891601563	1132.43298339844	1734.2587890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	9.46805095672607	9.49431133270264	9.45571994781494	1532.86596679688	1304.52795410156	10.5090942382813	1640.81896972656	9.48568820953369	9.49420261383057	9.46140575408936	1419.88891601563	1132.43298339844	1734.2587890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	9.46903610229492	9.49431133270264	9.44849967956543	1290.47229003906	1083.408203125	11.1691703796388	1440.82165527344	9.48568820953369	9.49420261383057	9.46140575408936	1419.88891601563	1132.43298339844	1734.2587890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	9.47666645050049	9.49431133270264	9.4643030166626	1277.39880371094	1073.02978515625	11.0208015441894	1425.69543457031	9.48568820953369	9.49420261383057	9.46140575408936	1419.88891601563	1132.43298339844	1734.2587890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	9.47268581390381	9.49431133270264	9.45062351226807	1267.25695800781	1070.73852539063	10.7005004882813	1413.15454101563	9.48568820953369	9.49420261383057	9.46140575408936	1419.88891601563	1132.43298339844	1734.2587890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	9.47715282440186	9.49431133270264	9.45970058441162	1242.26904296875	1049.8583984375	10.4743919372559	1385.03894042969	9.48568820953369	9.49420261383057	9.46140575408936	1419.88891601563	1132.43298339844	1734.2587890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	9.48782825469971	9.49431133270264	9.48138904571533	1135.36804199219	935.214050292969	10.723653793335	1278.37097167969	9.48568820953369	9.49420261383057	9.46140575408936	1419.88891601563	1132.43298339844	1734.2587890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	9.47582912445068	9.49431133270264	9.45785808563232	1086.3330078125	912.228881835938	9.32464218139663	1210.61743164063	9.48568820953369	9.49420261383057	9.46140575408936	1419.88891601563	1132.43298339844	1734.2587890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	9.40761661529541	9.47402667999268	9.3667688369751	2633.50708007813	1932.32678222656	36.87992477417	3112.484375	9.4376916885376	9.49409580230713	9.35920906066895	2647.16088867188	1788.75537109375	3522.5712890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	9.37727069854736	9.49419403076172	9.3248348236084	2662.13012695313	1877.27856445313	35.7761840820312	3022.11645507813	9.4376916885376	9.49409580230713	9.35920906066895	2647.16088867188	1788.75537109375	3522.5712890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	9.41021633148193	9.48161029815674	9.37845039367676	2592.61206054688	1856.68518066406	33.2413673400881	2920.40893554688	9.4376916885376	9.49409580230713	9.35920906066895	2647.16088867188	1788.75537109375	3522.5712890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	9.45048713684082	9.48996448516846	9.4318380355835	2591.75317382813	1872.9599609375	33.0396270751953	2930.22802734375	9.4376916885376	9.49409580230713	9.35920906066895	2647.16088867188	1788.75537109375	3522.5712890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	9.46603775024414	9.49431133270264	9.44682025909424	2344.69213867188	1706.4599609375	34.0485534667969	2796.013671875	9.4376916885376	9.49409580230713	9.35920906066895	2647.16088867188	1788.75537109375	3522.5712890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	9.43993473052979	9.49425506591797	9.39799690246582	2324.9287109375	1690.10095214844	33.8822059631347	2774.33154296875	9.4376916885376	9.49409580230713	9.35920906066895	2647.16088867188	1788.75537109375	3522.5712890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	9.44855403900146	9.49431133270264	9.41281127929688	2270.64575195313	1659.89709472656	32.755657196045	2708.078125	9.4376916885376	9.49409580230713	9.35920906066895	2647.16088867188	1788.75537109375	3522.5712890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	9.43516826629639	9.49431133270264	9.38460159301758	2227.81689453125	1627.41918945313	32.2148361206053	2658.2939453125	9.4376916885376	9.49409580230713	9.35920906066895	2647.16088867188	1788.75537109375	3522.5712890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	9.43620681762695	9.49431133270264	9.38559436798096	2088.24462890625	1493.1865234375	31.6719055175781	2506.6875	9.4376916885376	9.49409580230713	9.35920906066895	2647.16088867188	1788.75537109375	3522.5712890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	9.44769954681396	9.49431133270264	9.40462207794189	1971.45092773438	1425.64331054688	28.9586105346678	2352.31884765625	9.4376916885376	9.49409580230713	9.35920906066895	2647.16088867188	1788.75537109375	3522.5712890625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	9.36758708953857	9.4812593460083	9.26777648925781	3280.35180664063	2274.70068359375	52.7904129028322	3963.99389648438	9.41427326202393	9.49397754669189	9.32746887207031	3416.83032226563	2185.02124023438	4645.37646484375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	9.34058856964111	9.47461891174316	9.27926635742188	3334.14111328125	2220.93823242188	50.7307510375975	3844.322265625	9.41427326202393	9.49397754669189	9.32746887207031	3416.83032226563	2185.02124023438	4645.37646484375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	9.36632823944092	9.47419548034668	9.31814670562744	3252.92163085938	2188.271484375	48.0888214111328	3727.11376953125	9.41427326202393	9.49397754669189	9.32746887207031	3416.83032226563	2185.02124023438	4645.37646484375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	9.34764194488525	9.49009895324707	9.27869129180908	3255.81811523438	2209.50390625	48.0876312255859	3748.30810546875	9.41427326202393	9.49397754669189	9.32746887207031	3416.83032226563	2185.02124023438	4645.37646484375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	9.36746883392334	9.48270225524902	9.28759002685547	2993.60913085938	2071.13037109375	49.1564865112306	3644.13793945313	9.41427326202393	9.49397754669189	9.32746887207031	3416.83032226563	2185.02124023438	4645.37646484375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	9.38998985290527	9.48201274871826	9.33131885528564	2957.57153320313	2051.40087890625	48.2470321655275	3595.30590820313	9.41427326202393	9.49397754669189	9.32746887207031	3416.83032226563	2185.02124023438	4645.37646484375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	9.41537570953369	9.49431133270264	9.35716342926025	2896.521484375	2011.23291015625	47.43017578125	3528.99853515625	9.41427326202393	9.49397754669189	9.32746887207031	3416.83032226563	2185.02124023438	4645.37646484375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	9.39829158782959	9.49431133270264	9.32219982147217	2841.31323242188	1971.85498046875	46.6201858520509	3463.70092773438	9.41427326202393	9.49397754669189	9.32746887207031	3416.83032226563	2185.02124023438	4645.37646484375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	9.41940021514893	9.49431133270264	9.36372375488281	2689.34643554688	1833.64282226563	45.5472335815428	3291.154296875	9.41427326202393	9.49397754669189	9.32746887207031	3416.83032226563	2185.02124023438	4645.37646484375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	9.43750381469727	9.49431133270264	9.39652729034424	2523.71069335938	1731.56311035156	42.0358467102053	3076.71020507813	9.41427326202393	9.49397754669189	9.32746887207031	3416.83032226563	2185.02124023438	4645.37646484375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	9.32273483276367	9.46737194061279	9.22355842590332	3979.99267578125	2649.58837890625	69.6025543212891	4876.8701171875	9.36694812774658	9.4918155670166	9.2175350189209	4247.8427734375	2612.43920898438	5846.3232421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	9.28130626678467	9.46763896942139	9.19566059112549	4069.36669921875	2590.4306640625	67.3970794677734	4747.13720703125	9.36694812774658	9.4918155670166	9.2175350189209	4247.8427734375	2612.43920898438	5846.3232421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	9.31716251373291	9.47941780090332	9.24467277526855	3956.06494140625	2542.97192382813	63.8205947875975	4585.23095703125	9.36694812774658	9.4918155670166	9.2175350189209	4247.8427734375	2612.43920898438	5846.3232421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	9.36098670959473	9.48330593109131	9.30189800262451	3957.9482421875	2573.42895507813	63.6137924194334	4609.0703125	9.36694812774658	9.4918155670166	9.2175350189209	4247.8427734375	2612.43920898438	5846.3232421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	9.33775520324707	9.4730167388916	9.22311687469482	3677.79614257813	2458.90844726563	64.7983932495116	4532.45703125	9.36694812774658	9.4918155670166	9.2175350189209	4247.8427734375	2612.43920898438	5846.3232421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	9.31867504119873	9.49678421020508	9.18106842041016	3634.42797851563	2432.55053710938	63.8059921264647	4474.34228515625	9.36694812774658	9.4918155670166	9.2175350189209	4247.8427734375	2612.43920898438	5846.3232421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	9.38031005859375	9.48268604278564	9.29541397094727	3560.59741210938	2395.53662109375	62.335693359375	4390.27880859375	9.36694812774658	9.4918155670166	9.2175350189209	4247.8427734375	2612.43920898438	5846.3232421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	9.3593111038208	9.48262882232666	9.25996017456055	3488.41479492188	2346.82446289063	61.0354537963866	4299.958984375	9.36694812774658	9.4918155670166	9.2175350189209	4247.8427734375	2612.43920898438	5846.3232421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	9.38868236541748	9.49431133270264	9.31055736541748	3328.6240234375	2204.42309570313	59.6261062622069	4112.45849609375	9.36694812774658	9.4918155670166	9.2175350189209	4247.8427734375	2612.43920898438	5846.3232421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/30/St+Go	30	Stopgo	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	9.41788768768311	9.49431133270264	9.36145114898682	3109.25122070313	2060.24658203125	55.4913101196291	3835.96850585938	9.36694812774658	9.4918155670166	9.2175350189209	4247.8427734375	2612.43920898438	5846.3232421875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	33.3899116516113	33.5318565368652	33.3179969787598	861.1796875	642.116333007813	11.5644054412843	1012.17730712891	33.4894523620605	33.5312767028809	33.4357109069824	875.154418945313	612.494079589844	1145.85461425781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	33.3898429870605	33.5318565368652	33.3270263671875	866.717834472656	625.007385253906	11.0199203491211	977.644836425781	33.4894523620605	33.5312767028809	33.4357109069824	875.154418945313	612.494079589844	1145.85461425781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	33.471004486084	33.5318565368652	33.4434776306152	838.436279296875	610.783386230469	10.2839393615723	939.869445800781	33.4894523620605	33.5312767028809	33.4357109069824	875.154418945313	612.494079589844	1145.85461425781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	33.4701881408691	33.5318565368652	33.4394149780273	843.473449707031	622.442504882813	10.1637763977051	947.683349609375	33.4894523620605	33.5312767028809	33.4357109069824	875.154418945313	612.494079589844	1145.85461425781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	33.5115432739258	33.5318565368652	33.5224533081055	770.712646484375	574.726806640625	10.4734973907471	909.878723144531	33.4894523620605	33.5312767028809	33.4357109069824	875.154418945313	612.494079589844	1145.85461425781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	33.451244354248	33.5318565368652	33.4398460388184	763.190673828125	568.029174804688	10.4242191314697	901.604187011719	33.4894523620605	33.5312767028809	33.4357109069824	875.154418945313	612.494079589844	1145.85461425781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	33.492919921875	33.5318565368652	33.5246620178223	750.639038085938	563.455261230469	10.0247745513916	884.248046875	33.4894523620605	33.5312767028809	33.4357109069824	875.154418945313	612.494079589844	1145.85461425781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	33.487663269043	33.5318565368652	33.4423866271973	736.226440429688	552.604309082031	9.84510231018069	867.647583007813	33.4894523620605	33.5312767028809	33.4357109069824	875.154418945313	612.494079589844	1145.85461425781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	33.4338836669922	33.5318565368652	33.3365745544434	694.161376953125	502.977630615234	10.1895132064819	829.042053222656	33.4894523620605	33.5312767028809	33.4357109069824	875.154418945313	612.494079589844	1145.85461425781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	33.4329452514648	33.5318565368652	33.3367652893066	656.629943847656	487.176940917969	8.99870300292969	775.135437011719	33.4894523620605	33.5312767028809	33.4357109069824	875.154418945313	612.494079589844	1145.85461425781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	33.5318565368652	33.5318565368652	33.5318565368652	162.595458984375	147.542022705078	0.801522731781	173.19075012207	33.5315971374512	33.5318069458008	33.5309867858887	124.148307800293	101.246658325195	148.303436279297\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	33.5318565368652	33.5318565368652	33.5318565368652	160.287643432617	144.657531738281	0.71425676345825	167.513748168945	33.5315971374512	33.5318069458008	33.5309867858887	124.148307800293	101.246658325195	148.303436279297\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	33.5318565368652	33.5318565368652	33.5318565368652	157.650344848633	141.574676513672	0.726371288299563	164.818557739258	33.5315971374512	33.5318069458008	33.5309867858887	124.148307800293	101.246658325195	148.303436279297\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	33.5318565368652	33.5318565368652	33.5318565368652	153.17903137207	135.753753662109	0.801762580871594	161.41015625	33.5315971374512	33.5318069458008	33.5309867858887	124.148307800293	101.246658325195	148.303436279297\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	33.5318565368652	33.5318565368652	33.5318565368652	107.851440429688	89.9745788574219	0.982031822204597	121.399597167969	33.5315971374512	33.5318069458008	33.5309867858887	124.148307800293	101.246658325195	148.303436279297\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	33.5318565368652	33.5318565368652	33.5318565368652	106.859771728516	89.1549072265625	0.972580432891859	120.277481079102	33.5315971374512	33.5318069458008	33.5309867858887	124.148307800293	101.246658325195	148.303436279297\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	33.5318565368652	33.5318565368652	33.5318565368652	106.962074279785	91.6303176879883	0.826206922531116	118.068939208984	33.5315971374512	33.5318069458008	33.5309867858887	124.148307800293	101.246658325195	148.303436279297\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	33.5318565368652	33.5318565368652	33.5318565368652	105.119277954102	90.0879898071289	0.810014247894284	116.008445739746	33.5315971374512	33.5318069458008	33.5309867858887	124.148307800293	101.246658325195	148.303436279297\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	33.5318565368652	33.5318565368652	33.5318565368652	101.410362243652	85.4938278198242	0.851058721542369	112.72770690918	33.5315971374512	33.5318069458008	33.5309867858887	124.148307800293	101.246658325195	148.303436279297\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	33.5318565368652	33.5318565368652	33.5318565368652	97.8984146118164	83.947639465332	0.743309259414688	107.733535766602	33.5315971374512	33.5318069458008	33.5309867858887	124.148307800293	101.246658325195	148.303436279297\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	33.5315475463867	33.5318565368652	33.5312538146973	219.707580566406	186.683258056641	1.77892494201659	243.608856201172	33.5218086242676	33.5318031311035	33.4923286437988	187.297409057617	140.148635864258	238.72265625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	33.5318565368652	33.5318565368652	33.5318565368652	219.705581665039	181.410980224609	1.74860620498659	237.36637878418	33.5218086242676	33.5318031311035	33.4923286437988	187.297409057617	140.148635864258	238.72265625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	33.5318565368652	33.5318565368652	33.5318565368652	213.080215454102	177.236373901367	1.62022972106934	229.083724975586	33.5218086242676	33.5318031311035	33.4923286437988	187.297409057617	140.148635864258	238.72265625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	33.5318565368652	33.5318565368652	33.5318565368652	207.32356262207	173.333740234375	1.56753540039063	223.494873046875	33.5218086242676	33.5318031311035	33.4923286437988	187.297409057617	140.148635864258	238.72265625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	33.434440612793	33.5318565368652	33.3364028930664	159.873809814453	123.52783203125	1.94841146469116	185.876998901367	33.5218086242676	33.5318031311035	33.4923286437988	187.297409057617	140.148635864258	238.72265625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	33.4802551269531	33.5318565368652	33.4283218383789	158.019073486328	122.385719299316	1.90691399574281	183.406967163086	33.5218086242676	33.5318031311035	33.4923286437988	187.297409057617	140.148635864258	238.72265625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	33.5318565368652	33.5318565368652	33.5318565368652	158.457946777344	125.099853515625	1.82191419601441	183.401107788086	33.5218086242676	33.5318031311035	33.4923286437988	187.297409057617	140.148635864258	238.72265625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	33.5318565368652	33.5318565368652	33.5318565368652	154.19206237793	122.898056030273	1.69694042205813	177.200149536133	33.5218086242676	33.5318031311035	33.4923286437988	187.297409057617	140.148635864258	238.72265625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	33.5318565368652	33.5318565368652	33.5318565368652	150.150726318359	116.400566101074	1.80598664283753	174.192138671875	33.5218086242676	33.5318031311035	33.4923286437988	187.297409057617	140.148635864258	238.72265625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	33.5318565368652	33.5318565368652	33.5318565368652	142.183975219727	112.377578735352	1.59263277053831	163.341827392578	33.5218086242676	33.5318031311035	33.4923286437988	187.297409057617	140.148635864258	238.72265625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	33.4865264892578	33.5318565368652	33.4437561035156	369.487060546875	347.104248046875	1.32856559753419	389.618347167969	33.5130996704102	33.5317153930664	33.5102424621582	332.396179199219	282.937255859375	403.360504150391\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	33.3989067077637	33.5318565368652	33.3355407714844	364.843872070313	335.071685791016	1.37002754211425	378.912567138672	33.5130996704102	33.5317153930664	33.5102424621582	332.396179199219	282.937255859375	403.360504150391\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	33.5318260192871	33.5318565368652	33.531810760498	353.440063476563	329.948944091797	1.06587219238281	364.056854248047	33.5130996704102	33.5317153930664	33.5102424621582	332.396179199219	282.937255859375	403.360504150391\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	33.5318565368652	33.5318565368652	33.5318565368652	353.478881835938	333.670257568359	0.929625511169439	363.418273925781	33.5130996704102	33.5317153930664	33.5102424621582	332.396179199219	282.937255859375	403.360504150391\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	33.4530487060547	33.5318565368652	33.4431571960449	295.058807373047	263.440826416016	1.78339481353759	320.509460449219	33.5130996704102	33.5317153930664	33.5102424621582	332.396179199219	282.937255859375	403.360504150391\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	33.4881324768066	33.5318565368652	33.444263458252	291.507690429688	260.95263671875	1.74209880828856	316.699798583984	33.5130996704102	33.5317153930664	33.5102424621582	332.396179199219	282.937255859375	403.360504150391\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	33.4353256225586	33.5318565368652	33.3363494873047	292.526123046875	261.853363037109	1.7401514053345	317.538208007813	33.5130996704102	33.5317153930664	33.5102424621582	332.396179199219	282.937255859375	403.360504150391\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	33.5317077636719	33.5318565368652	33.5315551757813	286.091827392578	256.946350097656	1.68245410919191	310.784881591797	33.5130996704102	33.5317153930664	33.5102424621582	332.396179199219	282.937255859375	403.360504150391\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	33.5318565368652	33.5318565368652	33.5318565368652	263.188385009766	223.308685302734	2.19630622863772	293.590484619141	33.5130996704102	33.5317153930664	33.5102424621582	332.396179199219	282.937255859375	403.360504150391\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	33.5318565368652	33.5318565368652	33.5318565368652	251.294586181641	221.193420410156	1.68513870239259	275.117858886719	33.5130996704102	33.5317153930664	33.5102424621582	332.396179199219	282.937255859375	403.360504150391\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	33.2367286682129	33.5318565368652	33.0201797485352	1578.884765625	1014.18524169922	29.7258968353272	1965.41394042969	33.4593124389648	33.5302505493164	33.2398910522461	1719.60375976563	1039.40588378906	2416.4599609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	33.2497749328613	33.5315780639648	33.1205635070801	1620.11779785156	993.116333007813	28.5861320495605	1907.87255859375	33.4593124389648	33.5302505493164	33.2398910522461	1719.60375976563	1039.40588378906	2416.4599609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	33.3181419372559	33.5318565368652	33.2233772277832	1558.83251953125	963.9140625	26.8727531433106	1823.84216308594	33.4593124389648	33.5302505493164	33.2398910522461	1719.60375976563	1039.40588378906	2416.4599609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	33.3293914794922	33.5318565368652	33.2283706665039	1563.46032714844	984.876708984375	26.6005096435548	1836.09301757813	33.4593124389648	33.5302505493164	33.2398910522461	1719.60375976563	1039.40588378906	2416.4599609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	33.2924156188965	33.4391860961914	33.114185333252	1468.69665527344	954.511596679688	27.4689712524413	1833.51867675781	33.4593124389648	33.5302505493164	33.2398910522461	1719.60375976563	1039.40588378906	2416.4599609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	33.3108367919922	33.5318565368652	33.1202583312988	1454.31579589844	943.997253417969	27.2508296966553	1816.02380371094	33.4593124389648	33.5302505493164	33.2398910522461	1719.60375976563	1039.40588378906	2416.4599609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	33.411205291748	33.5318565368652	33.3206825256348	1422.86401367188	934.058227539063	26.1922225952149	1772.20935058594	33.4593124389648	33.5302505493164	33.2398910522461	1719.60375976563	1039.40588378906	2416.4599609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	33.4653167724609	33.5318565368652	33.4292297363281	1394.94152832031	915.930908203125	25.6605224609377	1737.06762695313	33.4593124389648	33.5302505493164	33.2398910522461	1719.60375976563	1039.40588378906	2416.4599609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	33.4863700866699	33.5318565368652	33.4411888122559	1359.2685546875	885.220153808594	25.173475265503	1690.77136230469	33.4593124389648	33.5302505493164	33.2398910522461	1719.60375976563	1039.40588378906	2416.4599609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	33.4868621826172	33.5318565368652	33.4431076049805	1250.56604003906	813.429443359375	23.1906890869142	1555.53149414063	33.4593124389648	33.5302505493164	33.2398910522461	1719.60375976563	1039.40588378906	2416.4599609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	32.4630126953125	33.4391899108887	31.7666530609131	2399.71948242188	1421.32006835938	51.5987396240234	3072.47973632813	33.27392578125	33.5203666687012	32.4964027404785	2647.7216796875	1505.53125	3834.40698242188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	32.8486442565918	33.3363456726074	32.6264114379883	2475.80932617188	1398.27563476563	49.1334457397459	2970.5458984375	33.27392578125	33.5203666687012	32.4964027404785	2647.7216796875	1505.53125	3834.40698242188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	32.7755928039551	33.5317420959473	32.4345207214355	2372.580078125	1354.65319824219	45.9847221374513	2826.16430664063	33.27392578125	33.5203666687012	32.4964027404785	2647.7216796875	1505.53125	3834.40698242188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	33.3904647827148	33.5318565368652	33.3233413696289	2374.37866210938	1382.61572265625	45.6071243286134	2842.04370117188	33.27392578125	33.5203666687012	32.4964027404785	2647.7216796875	1505.53125	3834.40698242188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	33.3677139282227	33.4447059631348	33.3366241455078	2239.12060546875	1377.49755859375	46.0653610229494	2851.58911132813	33.27392578125	33.5203666687012	32.4964027404785	2647.7216796875	1505.53125	3834.40698242188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	33.2609024047852	33.4393348693848	33.1257362365723	2217.875	1363.89379882813	45.6585540771484	2824.96752929688	33.27392578125	33.5203666687012	32.4964027404785	2647.7216796875	1505.53125	3834.40698242188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	33.2348937988281	33.336612701416	33.1007690429688	2161.35131835938	1348.52062988281	43.6614418029787	2745.68676757813	33.27392578125	33.5203666687012	32.4964027404785	2647.7216796875	1505.53125	3834.40698242188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	33.3185501098633	33.5318565368652	33.1028938293457	2117.62255859375	1318.63781738281	42.9153556823731	2691.92919921875	33.27392578125	33.5203666687012	32.4964027404785	2647.7216796875	1505.53125	3834.40698242188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	33.3974456787109	33.5318565368652	33.3313293457031	2063.33154296875	1288.68835449219	41.1883735656738	2606.71630859375	33.27392578125	33.5203666687012	32.4964027404785	2647.7216796875	1505.53125	3834.40698242188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	33.238655090332	33.5318565368652	33.0180015563965	1905.20764160156	1172.12756347656	38.9223442077637	2417.642578125	33.27392578125	33.5203666687012	32.4964027404785	2647.7216796875	1505.53125	3834.40698242188\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	30.9973754882813	33.527214050293	28.6647529602051	3239.74584960938	1846.19775390625	73.1938629150391	4188.4013671875	31.8555870056152	33.505126953125	27.4543991088867	3625.60546875	1986.75207519531	5294.1474609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	29.6114711761475	33.4226608276367	27.8195934295654	3350.67065429688	1817.90075683594	69.8576087951659	4053.34423828125	31.8555870056152	33.505126953125	27.4543991088867	3625.60546875	1986.75207519531	5294.1474609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	32.1301078796387	33.3365020751953	31.5873527526855	3232.29809570313	1761.52502441406	66.4363899230959	3887.48950195313	31.8555870056152	33.505126953125	27.4543991088867	3625.60546875	1986.75207519531	5294.1474609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	32.2848815917969	33.5318565368652	31.6787548065186	3250.2421875	1798.43762207031	66.7500495910647	3934.43920898438	31.8555870056152	33.505126953125	27.4543991088867	3625.60546875	1986.75207519531	5294.1474609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	31.9137001037598	33.4430847167969	30.6972599029541	3060.76293945313	1808.60046386719	66.8849449157716	3948.91870117188	31.8555870056152	33.505126953125	27.4543991088867	3625.60546875	1986.75207519531	5294.1474609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	32.1785926818848	33.4446334838867	31.2274894714355	3021.56884765625	1791.44934082031	65.6166191101075	3891.18115234375	31.8555870056152	33.505126953125	27.4543991088867	3625.60546875	1986.75207519531	5294.1474609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	32.3661346435547	33.4437446594238	31.4150371551514	2956.58081054688	1768.55847167969	63.9002342224122	3813.36596679688	31.8555870056152	33.505126953125	27.4543991088867	3625.60546875	1986.75207519531	5294.1474609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	32.3858337402344	33.5316619873047	31.3975200653076	2893.10717773438	1731.46325683594	62.4186744689941	3728.86083984375	31.8555870056152	33.505126953125	27.4543991088867	3625.60546875	1986.75207519531	5294.1474609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	32.6140441894531	33.5318565368652	31.7733249664307	2800.39526367188	1699.72424316406	58.6595268249513	3576.8291015625	31.8555870056152	33.505126953125	27.4543991088867	3625.60546875	1986.75207519531	5294.1474609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Freeflow	40	Freeflow	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	32.5561141967773	33.5318565368652	31.6794395446777	2612.599609375	1542.43701171875	56.9357757568359	3364.3818359375	31.8555870056152	33.505126953125	27.4543991088867	3625.60546875	1986.75207519531	5294.1474609375\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	18.2062931060791	18.5223731994629	17.9271945953369	1355.71154785156	997.736328125	18.9603614807128	1604.46789550781	18.4534759521484	18.5220203399658	18.2169780731201	1349.48156738281	920.746337890625	1802.17919921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	18.4181003570557	18.5225830078125	18.3706455230713	1367.26928710938	976.140869140625	17.833408355713	1546.80993652344	18.4534759521484	18.5220203399658	18.2169780731201	1349.48156738281	920.746337890625	1802.17919921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	18.2506408691406	18.5225830078125	18.1276607513428	1326.46545410156	955.553833007813	16.7580604553224	1491.81176757813	18.4534759521484	18.5220203399658	18.2169780731201	1349.48156738281	920.746337890625	1802.17919921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	18.360221862793	18.5225830078125	18.2791996002197	1320.43640136719	958.41552734375	16.6519737243653	1491.27868652344	18.4534759521484	18.5220203399658	18.2169780731201	1349.48156738281	920.746337890625	1802.17919921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	18.3861560821533	18.5225830078125	18.2496585845947	1188.72839355469	863.564086914063	17.4295616149902	1421.31005859375	18.4534759521484	18.5220203399658	18.2169780731201	1349.48156738281	920.746337890625	1802.17919921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	18.431303024292	18.5225830078125	18.3401260375977	1175.86267089844	855.305053710938	17.1804771423341	1405.08032226563	18.4534759521484	18.5220203399658	18.2169780731201	1349.48156738281	920.746337890625	1802.17919921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	18.4495239257813	18.5224227905273	18.3972606658936	1157.82702636719	859.610534667969	16.0448513031007	1373.04577636719	18.4534759521484	18.5220203399658	18.2169780731201	1349.48156738281	920.746337890625	1802.17919921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	18.4773750305176	18.5225830078125	18.4317970275879	1134.97412109375	842.970947265625	15.7160072326661	1345.88317871094	18.4534759521484	18.5220203399658	18.2169780731201	1349.48156738281	920.746337890625	1802.17919921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	18.5211849212646	18.5225830078125	18.5197944641113	1064.80737304688	771.502746582031	15.6366100311281	1271.87426757813	18.4534759521484	18.5220203399658	18.2169780731201	1349.48156738281	920.746337890625	1802.17919921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	18.5217151641846	18.5225830078125	18.5208702087402	1005.22222900391	738.139282226563	14.2124633789062	1192.93811035156	18.4534759521484	18.5220203399658	18.2169780731201	1349.48156738281	920.746337890625	1802.17919921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	18.5222625732422	18.5225830078125	18.5219593048096	479.988189697266	463.374176025391	0.927918434143061	493.067565917969	18.5224761962891	18.5225734710693	18.5223922729492	363.514404296875	328.999603271484	407.186096191406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	18.5225830078125	18.5225830078125	18.5225830078125	470.338836669922	449.882659912109	0.937605857849126	479.886047363281	18.5224761962891	18.5225734710693	18.5223922729492	363.514404296875	328.999603271484	407.186096191406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	18.5224876403809	18.5225830078125	18.5224437713623	463.458892822266	445.077667236328	0.832201957702656	471.708129882813	18.5224761962891	18.5225734710693	18.5223922729492	363.514404296875	328.999603271484	407.186096191406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	18.5225830078125	18.5225830078125	18.5225830078125	451.462860107422	432.678619384766	0.871363639831531	460.562255859375	18.5224761962891	18.5225734710693	18.5223922729492	363.514404296875	328.999603271484	407.186096191406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	18.522066116333	18.5225830078125	18.5215473175049	339.857666015625	316.802490234375	1.30128574371338	358.443634033203	18.5224761962891	18.5225734710693	18.5223922729492	363.514404296875	328.999603271484	407.186096191406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	18.5222301483154	18.5225830078125	18.5218753814697	335.987609863281	313.798980712891	1.24833488464353	353.745697021484	18.5224761962891	18.5225734710693	18.5223922729492	363.514404296875	328.999603271484	407.186096191406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	18.5225830078125	18.5225830078125	18.5225830078125	341.895324707031	320.542572021484	1.18317985534669	358.404327392578	18.5224761962891	18.5225734710693	18.5223922729492	363.514404296875	328.999603271484	407.186096191406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	18.5225830078125	18.5225830078125	18.5225830078125	335.412261962891	314.475158691406	1.160138130188	351.599578857422	18.5224761962891	18.5225734710693	18.5223922729492	363.514404296875	328.999603271484	407.186096191406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	18.5225830078125	18.5225830078125	18.5225830078125	297.391876220703	273.564758300781	1.30399703979494	315.292663574219	18.5224761962891	18.5225734710693	18.5223922729492	363.514404296875	328.999603271484	407.186096191406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	18.5225830078125	18.5225830078125	18.5225830078125	292.373901367188	273.381866455078	1.04346370697022	306.772705078125	18.5224761962891	18.5225734710693	18.5223922729492	363.514404296875	328.999603271484	407.186096191406\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	18.5211067199707	18.5225830078125	18.5198822021484	637.85791015625	567.323913574219	3.77502250671387	688.124633789063	18.5222854614258	18.5224609375	18.5219898223877	536.675537109375	438.867523193359	646.295715332031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	18.5208911895752	18.5225830078125	18.5200843811035	630.989135742188	552.696228027344	3.57514953613281	667.101013183594	18.5222854614258	18.5224609375	18.5219898223877	536.675537109375	438.867523193359	646.295715332031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	18.5217437744141	18.5225830078125	18.5213642120361	617.214965820313	544.609313964844	3.28194046020506	649.631408691406	18.5222854614258	18.5224609375	18.5219898223877	536.675537109375	438.867523193359	646.295715332031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	18.5225830078125	18.5225830078125	18.5225830078125	606.485290527344	534.284729003906	3.32699203491212	640.748474121094	18.5222854614258	18.5224609375	18.5219898223877	536.675537109375	438.867523193359	646.295715332031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	18.5215511322021	18.5225830078125	18.5208950042725	488.751647949219	417.925750732422	3.848650932312	541.082580566406	18.5222854614258	18.5224609375	18.5219898223877	536.675537109375	438.867523193359	646.295715332031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	18.521764755249	18.5225830078125	18.5211791992188	482.806488037109	413.950378417969	3.72954559326172	533.295837402344	18.5222854614258	18.5224609375	18.5219898223877	536.675537109375	438.867523193359	646.295715332031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	18.4786853790283	18.5225830078125	18.4336757659912	489.034240722656	425.738067626953	3.46456050872803	536.60400390625	18.5222854614258	18.5224609375	18.5219898223877	536.675537109375	438.867523193359	646.295715332031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	18.5225830078125	18.5225830078125	18.5225830078125	479.035766601563	417.596221923828	3.35763072967531	525.040405273438	18.5222854614258	18.5224609375	18.5219898223877	536.675537109375	438.867523193359	646.295715332031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	18.5225830078125	18.5225830078125	18.5225830078125	430.608673095703	361.078002929688	3.74178409576416	480.815093994141	18.5222854614258	18.5224609375	18.5219898223877	536.675537109375	438.867523193359	646.295715332031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	18.5225830078125	18.5225830078125	18.5225830078125	417.328094482422	356.758239746094	3.26029777526856	461.087768554688	18.5222854614258	18.5224609375	18.5219898223877	536.675537109375	438.867523193359	646.295715332031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	18.4128360748291	18.5225830078125	18.3098659515381	906.156616210938	738.178039550781	8.95680046081559	1024.79565429688	18.5110759735107	18.5222148895264	18.4736518859863	837.111877441406	628.971069335938	1062.65148925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	18.4216651916504	18.5225830078125	18.3743724822998	907.924865722656	721.452941894531	8.50870132446291	993.731384277344	18.5110759735107	18.5222148895264	18.4736518859863	837.111877441406	628.971069335938	1062.65148925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	18.4805660247803	18.5225830078125	18.4615688323975	878.22998046875	710.159118652344	7.59461212158203	953.186706542969	18.5110759735107	18.5222148895264	18.4736518859863	837.111877441406	628.971069335938	1062.65148925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	18.5218524932861	18.5225830078125	18.5214881896973	870.284729003906	705.520446777344	7.58409881591797	948.211608886719	18.5110759735107	18.5222148895264	18.4736518859863	837.111877441406	628.971069335938	1062.65148925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	18.4904403686523	18.5225830078125	18.4586772918701	753.9697265625	597.991149902344	8.43357467651366	867.865539550781	18.5110759735107	18.5222148895264	18.4736518859863	837.111877441406	628.971069335938	1062.65148925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	18.4929599761963	18.5225830078125	18.46360206604	744.557434082031	592.284729003906	8.21360778808594	855.120178222656	18.5110759735107	18.5222148895264	18.4736518859863	837.111877441406	628.971069335938	1062.65148925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	18.5217971801758	18.5225830078125	18.5210571289063	735.343078613281	594.956237792969	7.60612297058106	838.352172851563	18.5110759735107	18.5222148895264	18.4736518859863	837.111877441406	628.971069335938	1062.65148925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	18.5222015380859	18.5225830078125	18.5218086242676	721.250427246094	583.478881835938	7.46495437622069	822.357421875	18.5110759735107	18.5222148895264	18.4736518859863	837.111877441406	628.971069335938	1062.65148925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	18.5225830078125	18.5225830078125	18.5225830078125	664.942749023438	519.731811523438	7.78236579895019	768.767517089844	18.5110759735107	18.5222148895264	18.4736518859863	837.111877441406	628.971069335938	1062.65148925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	18.5225830078125	18.5225830078125	18.5225830078125	635.918579101563	506.569091796875	6.92184066772462	728.067993164063	18.5110759735107	18.5222148895264	18.4736518859863	837.111877441406	628.971069335938	1062.65148925781\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	18.1189994812012	18.5212936401367	17.8714370727539	1977.26879882813	1326.83178710938	34.2642669677734	2423.28833007813	18.4118881225586	18.5218505859375	18.2569389343262	2063.3828125	1292.79248046875	2855.97631835938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	18.306661605835	18.4333629608154	18.2508277893066	2011.33093261719	1299.947265625	32.4314117431641	2337.75244140625	18.4118881225586	18.5218505859375	18.2569389343262	2063.3828125	1292.79248046875	2855.97631835938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	18.1328964233398	18.4642581939697	17.9845104217529	1942.974609375	1268.13024902344	30.4846458435059	2243.63891601563	18.4118881225586	18.5218505859375	18.2569389343262	2063.3828125	1292.79248046875	2855.97631835938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	18.1622333526611	18.5225830078125	17.9871520996094	1939.361328125	1278.19665527344	30.4053916931153	2251.16918945313	18.4118881225586	18.5218505859375	18.2569389343262	2063.3828125	1292.79248046875	2855.97631835938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	18.227352142334	18.5211391448975	17.985372543335	1789.498046875	1204.47424316406	31.2808952331544	2205.462890625	18.4118881225586	18.5218505859375	18.2569389343262	2063.3828125	1292.79248046875	2855.97631835938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	18.2044277191162	18.5215892791748	17.9276390075684	1772.84436035156	1192.65014648438	31.0234985351562	2185.40209960938	18.4118881225586	18.5218505859375	18.2569389343262	2063.3828125	1292.79248046875	2855.97631835938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	18.4618892669678	18.5217151641846	18.4009857177734	1731.72265625	1186.50598144531	29.3229560852053	2124.84057617188	18.4118881225586	18.5218505859375	18.2569389343262	2063.3828125	1292.79248046875	2855.97631835938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	18.4625301361084	18.5224227905273	18.401517868042	1698.17651367188	1163.62585449219	28.7504005432128	2083.638671875	18.4118881225586	18.5218505859375	18.2569389343262	2063.3828125	1292.79248046875	2855.97631835938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	18.4159088134766	18.5225830078125	18.3099536895752	1619.98596191406	1091.45080566406	28.1510963439944	1992.28588867188	18.4118881225586	18.5218505859375	18.2569389343262	2063.3828125	1292.79248046875	2855.97631835938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	18.3992443084717	18.5225830078125	18.2793121337891	1515.54956054688	1022.71563720703	26.17653465271	1860.36474609375	18.4118881225586	18.5218505859375	18.2569389343262	2063.3828125	1292.79248046875	2855.97631835938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.9521465301514	18.4609985351563	17.615966796875	2694.23754882813	1697.43493652344	52.3977699279784	3374.16357421875	18.2394542694092	18.5219306945801	17.7361679077148	2885.68872070313	1709.5703125	4092.03369140625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	18.1293487548828	18.4306163787842	17.9879722595215	2760.01611328125	1663.09838867188	50.0083236694334	3263.36474609375	18.2394542694092	18.5219306945801	17.7361679077148	2885.68872070313	1709.5703125	4092.03369140625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	18.3109683990479	18.5208187103271	18.2186698913574	2652.51684570313	1616.17272949219	46.8115425109863	3114.14208984375	18.2394542694092	18.5219306945801	17.7361679077148	2885.68872070313	1709.5703125	4092.03369140625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	18.2529754638672	18.5225830078125	18.1324691772461	2653.89379882813	1637.15893554688	46.7391128540038	3132.810546875	18.2394542694092	18.5219306945801	17.7361679077148	2885.68872070313	1709.5703125	4092.03369140625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	18.2232036590576	18.520694732666	18.0736217498779	2477.25512695313	1581.90478515625	47.7432403564453	3109.6884765625	18.2394542694092	18.5219306945801	17.7361679077148	2885.68872070313	1709.5703125	4092.03369140625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	18.0775375366211	18.5211391448975	17.8408164978027	2455.43408203125	1566.81909179688	47.3628692626953	3082.43090820313	18.2394542694092	18.5219306945801	17.7361679077148	2885.68872070313	1709.5703125	4092.03369140625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	18.1777839660645	18.4641723632813	17.9283027648926	2394.685546875	1551.33862304688	45.2797088623047	3000.28930664063	18.2394542694092	18.5219306945801	17.7361679077148	2885.68872070313	1709.5703125	4092.03369140625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	18.2357158660889	18.5225830078125	17.9857273101807	2346.3203125	1520.10302734375	44.3585052490234	2939.5751953125	18.2394542694092	18.5219306945801	17.7361679077148	2885.68872070313	1709.5703125	4092.03369140625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	18.3255634307861	18.5225830078125	18.1611404418945	2247.99096679688	1452.4375	42.3419494628906	2807.3798828125	18.2394542694092	18.5219306945801	17.7361679077148	2885.68872070313	1709.5703125	4092.03369140625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	18.414379119873	18.5225830078125	18.3095932006836	2100.1259765625	1343.82312011719	40.1683616638184	2629.21069335938	18.2394542694092	18.5219306945801	17.7361679077148	2885.68872070313	1709.5703125	4092.03369140625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	17.5382080078125	18.4295921325684	16.7682704925537	3461.79321289063	2107.63842773438	71.0375442504881	4380.83984375	17.9565143585205	18.5210342407227	16.947660446167	3771.90063476563	2161.28491210938	5375.27001953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	17.2756652832031	18.4034194946289	16.747049331665	3562.98999023438	2066.94384765625	68.1861114501953	4248.8994140625	17.9565143585205	18.5210342407227	16.947660446167	3771.90063476563	2161.28491210938	5375.27001953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	17.5350322723389	18.4316596984863	17.1305809020996	3434.92333984375	1999.3203125	64.8451614379884	4074.36547851563	17.9565143585205	18.5210342407227	16.947660446167	3771.90063476563	2161.28491210938	5375.27001953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	17.9280853271484	18.5225830078125	17.6349563598633	3437.22338867188	2031.29821777344	64.6209907531737	4099.169921875	17.9565143585205	18.5210342407227	16.947660446167	3771.90063476563	2161.28491210938	5375.27001953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	17.9829025268555	18.5202560424805	17.569845199585	3219.28442382813	1993.93835449219	65.2423591613769	4081.69384765625	17.9565143585205	18.5210342407227	16.947660446167	3771.90063476563	2161.28491210938	5375.27001953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	17.8401374816895	18.5206565856934	17.2861480712891	3192.58935546875	1974.81567382813	64.8839263916016	4051.10131835938	17.9565143585205	18.5210342407227	16.947660446167	3771.90063476563	2161.28491210938	5375.27001953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	18.0703639984131	18.5208053588867	17.696626663208	3115.10791015625	1947.93090820313	62.5397720336913	3949.20361328125	17.9565143585205	18.5210342407227	16.947660446167	3771.90063476563	2161.28491210938	5375.27001953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	17.9935741424561	18.5218334197998	17.56223487854	3054.98950195313	1910.10205078125	61.3505477905275	3873.31958007813	17.9565143585205	18.5210342407227	16.947660446167	3771.90063476563	2161.28491210938	5375.27001953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	18.1443576812744	18.5225830078125	17.8416805267334	2928.78979492188	1840.7724609375	57.8965301513672	3693.46142578125	17.9565143585205	18.5210342407227	16.947660446167	3771.90063476563	2161.28491210938	5375.27001953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/Satur.	40	Saturated	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	18.0528182983398	18.5225830078125	17.6459140777588	2744.31274414063	1694.41528320313	55.6985168457031	3476.76782226563	17.9565143585205	18.5210342407227	16.947660446167	3771.90063476563	2161.28491210938	5375.27001953125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	11.6445484161377	11.6987428665161	11.6114053726196	1881.4296875	1420.44946289063	24.1866607666016	2194.42260742188	11.6695623397827	11.7072553634644	11.6143255233765	1873.91284179688	1307.76611328125	2442.17553710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	11.6145496368408	11.6994924545288	11.5748348236084	1903.95495605469	1392.9697265625	23.2900543212891	2138.25146484375	11.6695623397827	11.7072553634644	11.6143255233765	1873.91284179688	1307.76611328125	2442.17553710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	11.5440616607666	11.7012596130371	11.4732618331909	1872.40209960938	1380.11730957031	22.2372627258303	2091.70971679688	11.6695623397827	11.7072553634644	11.6143255233765	1873.91284179688	1307.76611328125	2442.17553710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	11.6509437561035	11.7079067230225	11.6224889755249	1862.53918457031	1387.51196289063	21.8363342285156	2086.27465820313	11.6695623397827	11.7072553634644	11.6143255233765	1873.91284179688	1307.76611328125	2442.17553710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	11.6438150405884	11.6996536254883	11.59375	1669.44396972656	1241.40515136719	22.8686332702638	1973.20141601563	11.6695623397827	11.7072553634644	11.6143255233765	1873.91284179688	1307.76611328125	2442.17553710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	11.6451177597046	11.6995325088501	11.5967025756836	1651.75036621094	1229.50109863281	22.5573348999025	1951.33581542969	11.6695623397827	11.7072553634644	11.6143255233765	1873.91284179688	1307.76611328125	2442.17553710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	11.6525230407715	11.6997756958008	11.6149969100952	1626.71020507813	1218.78381347656	21.8844718933106	1919.0869140625	11.6695623397827	11.7072553634644	11.6143255233765	1873.91284179688	1307.76611328125	2442.17553710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	11.6680707931519	11.6996536254883	11.641827583313	1593.76672363281	1192.25708007813	21.5583801269531	1882.12524414063	11.6695623397827	11.7072553634644	11.6143255233765	1873.91284179688	1307.76611328125	2442.17553710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	11.6735420227051	11.7079477310181	11.6411170959473	1485.64489746094	1090.20922851563	21.0517349243162	1763.86474609375	11.6695623397827	11.7072553634644	11.6143255233765	1873.91284179688	1307.76611328125	2442.17553710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	11.67396068573	11.7079477310181	11.643913269043	1404.53991699219	1041.77844238281	19.2530403137209	1657.87573242188	11.6695623397827	11.7072553634644	11.6143255233765	1873.91284179688	1307.76611328125	2442.17553710938\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	11.7020702362061	11.7079477310181	11.6993408203125	885.549743652344	815.097595214844	3.82354545593262	937.451049804688	11.7061738967896	11.7078056335449	11.7013235092163	745.554443359375	640.98974609375	867.698059082031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	11.7021102905273	11.7079477310181	11.6993312835693	877.236145019531	796.892333984375	3.67115974426269	914.369445800781	11.7061738967896	11.7078056335449	11.7013235092163	745.554443359375	640.98974609375	867.698059082031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	11.7019128799438	11.7079477310181	11.699182510376	874.731201171875	806.583312988281	3.08119583129884	905.181579589844	11.7061738967896	11.7078056335449	11.7013235092163	745.554443359375	640.98974609375	867.698059082031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	11.7021570205688	11.7079477310181	11.6992673873901	863.603149414063	796.463134765625	3.09569740295409	895.525451660156	11.7061738967896	11.7078056335449	11.7013235092163	745.554443359375	640.98974609375	867.698059082031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	11.6993455886841	11.7079477310181	11.6906890869141	698.860534667969	622.619018554688	4.15637779235838	755.623107910156	11.7061738967896	11.7078056335449	11.7013235092163	745.554443359375	640.98974609375	867.698059082031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	11.6986474990845	11.7079477310181	11.6892871856689	691.650329589844	616.673034667969	4.08309936523438	747.332214355469	11.7061738967896	11.7078056335449	11.7013235092163	745.554443359375	640.98974609375	867.698059082031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	11.7037572860718	11.7079477310181	11.6994600296021	685.735656738281	620.561462402344	3.59055328369141	735.459167480469	11.7061738967896	11.7078056335449	11.7013235092163	745.554443359375	640.98974609375	867.698059082031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	11.7037992477417	11.7079477310181	11.6995449066162	672.473205566406	608.570922851563	3.52044296264647	721.22509765625	11.7061738967896	11.7078056335449	11.7013235092163	745.554443359375	640.98974609375	867.698059082031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	11.7037324905396	11.7079477310181	11.6995449066162	601.844055175781	529.530029296875	3.92427253723144	655.106750488281	11.7061738967896	11.7078056335449	11.7013235092163	745.554443359375	640.98974609375	867.698059082031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	11.7037477493286	11.7079477310181	11.6996631622314	581.958068847656	521.155700683594	3.30499076843262	626.915405273438	11.7061738967896	11.7078056335449	11.7013235092163	745.554443359375	640.98974609375	867.698059082031\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	11.6834325790405	11.6995830535889	11.6706295013428	1121.96325683594	967.673156738281	8.19614982604997	1229.94995117188	11.7000856399536	11.7078304290771	11.6893520355225	1010.64739990234	804.211791992188	1235.60632324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	11.668830871582	11.7079477310181	11.6504249572754	1125.73486328125	947.915405273438	8.11462020874038	1207.58325195313	11.7000856399536	11.7078304290771	11.6893520355225	1010.64739990234	804.211791992188	1235.60632324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	11.6962728500366	11.7079477310181	11.6910743713379	1113.92993164063	949.729248046875	7.42020416259766	1187.17578125	11.7000856399536	11.7078304290771	11.6893520355225	1010.64739990234	804.211791992188	1235.60632324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	11.7024192810059	11.7079477310181	11.6999912261963	1102.09143066406	941.763427734375	7.38192367553703	1177.98498535156	11.7000856399536	11.7078304290771	11.6893520355225	1010.64739990234	804.211791992188	1235.60632324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	11.6985387802124	11.7079477310181	11.6990718841553	924.396728515625	776.259338378906	7.98127555847169	1031.66015625	11.7000856399536	11.7078304290771	11.6893520355225	1010.64739990234	804.211791992188	1235.60632324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	11.6826295852661	11.7079477310181	11.66383934021	916.133178710938	768.836059570313	7.94898605346678	1023.20361328125	11.7000856399536	11.7078304290771	11.6893520355225	1010.64739990234	804.211791992188	1235.60632324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	11.6976451873779	11.7079477310181	11.6901559829712	900.61376953125	764.594665527344	7.37158775329581	1000.48547363281	11.7000856399536	11.7078304290771	11.6893520355225	1010.64739990234	804.211791992188	1235.60632324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	11.7023267745972	11.7079477310181	11.6966361999512	880.396240234375	745.569030761719	7.33400535583497	980.257202148438	11.7000856399536	11.7078304290771	11.6893520355225	1010.64739990234	804.211791992188	1235.60632324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	11.7030620574951	11.7079477310181	11.6982765197754	803.817504882813	658.502014160156	7.81852912902834	908.694946289063	11.7000856399536	11.7078304290771	11.6893520355225	1010.64739990234	804.211791992188	1235.60632324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	11.7032461166382	11.7079477310181	11.6987009048462	767.662841796875	640.474731445313	6.83513259887694	859.198974609375	11.7000856399536	11.7078304290771	11.6893520355225	1010.64739990234	804.211791992188	1235.60632324219\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	11.6470232009888	11.6989278793335	11.6043033599854	1448.90979003906	1168.47778320313	14.815185546875	1642.56372070313	11.6929578781128	11.7078161239624	11.6561899185181	1373.97705078125	1021.20550537109	1742.82666015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	11.617377281189	11.7078742980957	11.5749816894531	1458.54516601563	1144.509765625	14.3208961486816	1602.77844238281	11.6929578781128	11.7078161239624	11.6561899185181	1373.97705078125	1021.20550537109	1742.82666015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	11.6953268051147	11.7079477310181	11.6896715164185	1434.13146972656	1140.07958984375	13.2838745117188	1565.16357421875	11.6929578781128	11.7078161239624	11.6561899185181	1373.97705078125	1021.20550537109	1742.82666015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	11.6888008117676	11.7079477310181	11.6794986724854	1422.56665039063	1135.5634765625	13.2018280029297	1558.02197265625	11.6929578781128	11.7078161239624	11.6561899185181	1373.97705078125	1021.20550537109	1742.82666015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	11.6871538162231	11.7079477310181	11.6690855026245	1235.97241210938	976.203430175781	13.910707473755	1421.34606933594	11.6929578781128	11.7078161239624	11.6561899185181	1373.97705078125	1021.20550537109	1742.82666015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	11.6860971450806	11.7079477310181	11.6701784133911	1223.31823730469	966.858154296875	13.7255249023439	1406.07495117188	11.6929578781128	11.7078161239624	11.6561899185181	1373.97705078125	1021.20550537109	1742.82666015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	11.6916017532349	11.7079477310181	11.6799468994141	1205.69018554688	959.291687011719	13.3170528411866	1385.43737792969	11.6929578781128	11.7078161239624	11.6561899185181	1373.97705078125	1021.20550537109	1742.82666015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	11.6944742202759	11.7079477310181	11.6822452545166	1179.80029296875	937.232849121094	13.0855541229249	1355.97058105469	11.6929578781128	11.7078161239624	11.6561899185181	1373.97705078125	1021.20550537109	1742.82666015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	11.6939001083374	11.7079477310181	11.6817607879639	1090.21057128906	842.292175292969	13.2567462921144	1266.50805664063	11.6929578781128	11.7078161239624	11.6561899185181	1373.97705078125	1021.20550537109	1742.82666015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	-0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	11.6969051361084	11.7079477310181	11.6913108825684	1035.00988769531	811.659851074219	11.9140911102294	1192.91076660156	11.6929578781128	11.7078161239624	11.6561899185181	1373.97705078125	1021.20550537109	1742.82666015625\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.02	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	11.5530891418457	11.6993436813354	11.4359979629517	2455.466796875	1729.34191894531	38.1327857971194	2949.59106445313	11.6398267745972	11.7002258300781	11.500789642334	2537.32836914063	1657.48364257813	3420.89453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.02	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	11.5317535400391	11.6797304153442	11.4641246795654	2499.83374023438	1698.82092285156	36.5068550109866	2867.04028320313	11.6398267745972	11.7002258300781	11.500789642334	2537.32836914063	1657.48364257813	3420.89453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.02	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	11.6091814041138	11.6990203857422	11.5690050125122	2437.67846679688	1673.2099609375	34.5286331176759	2778.12622070313	11.6398267745972	11.7002258300781	11.500789642334	2537.32836914063	1657.48364257813	3420.89453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.02	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	11.6496238708496	11.6985216140747	11.6274108886719	2433.40478515625	1687.12158203125	34.3009109497072	2784.75073242188	11.6398267745972	11.7002258300781	11.500789642334	2537.32836914063	1657.48364257813	3420.89453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.02	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	11.6504545211792	11.6895427703857	11.6104249954224	2226.84057617188	1564.63415527344	35.2933921813966	2694.02270507813	11.6398267745972	11.7002258300781	11.500789642334	2537.32836914063	1657.48364257813	3420.89453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.02	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	11.6140174865723	11.6986846923828	11.5386238098145	2200.85791015625	1547.86767578125	34.7596282958984	2660.17578125	11.6398267745972	11.7002258300781	11.500789642334	2537.32836914063	1657.48364257813	3420.89453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.02	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	11.6308794021606	11.7001876831055	11.5658359527588	2155.77416992188	1530.75341796875	33.4852905273438	2602.28271484375	11.6398267745972	11.7002258300781	11.500789642334	2537.32836914063	1657.48364257813	3420.89453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.02	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	11.626558303833	11.6998987197876	11.5580701828003	2111.98657226563	1496.58569335938	32.9582748413084	2551.25048828125	11.6398267745972	11.7002258300781	11.500789642334	2537.32836914063	1657.48364257813	3420.89453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.02	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	11.6356372833252	11.6997413635254	11.5852174758911	1992.94445800781	1387.73413085938	32.1602096557616	2416.86083984375	11.6398267745972	11.7002258300781	11.500789642334	2537.32836914063	1657.48364257813	3420.89453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.02	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	11.6458625793457	11.6992673873901	11.5996656417847	1877.80407714844	1313.40856933594	29.9238777160644	2270.97265625	11.6398267745972	11.7002258300781	11.500789642334	2537.32836914063	1657.48364257813	3420.89453125\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.04	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	11.4719533920288	11.6765556335449	11.3093404769897	3101.18139648438	2076.021484375	53.7205734252931	3795.07983398438	11.5756559371948	11.6953277587891	11.3877859115601	3295.76049804688	2050.59716796875	4525.044921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.04	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	11.4392957687378	11.6873731613159	11.3250036239624	3170.2802734375	2038.54638671875	51.57381439209	3688.90844726563	11.5756559371948	11.6953277587891	11.3877859115601	3295.76049804688	2050.59716796875	4525.044921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.04	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	11.4728136062622	11.6793336868286	11.3798847198486	3093.74243164063	2003.00646972656	49.2651176452638	3579.490234375	11.5756559371948	11.6953277587891	11.3877859115601	3295.76049804688	2050.59716796875	4525.044921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.04	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	11.4650688171387	11.7020606994629	11.3501806259155	3092.52685546875	2024.42932128906	49.0902748107913	3595.31811523438	11.5756559371948	11.6953277587891	11.3877859115601	3295.76049804688	2050.59716796875	4525.044921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.04	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	11.5157289505005	11.6906108856201	11.3580503463745	2862.26879882813	1925.70764160156	49.9098854064944	3522.82397460938	11.5756559371948	11.6953277587891	11.3877859115601	3295.76049804688	2050.59716796875	4525.044921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.04	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	11.5056076049805	11.7027893066406	11.3699674606323	2830.82177734375	1905.97326660156	49.1901130676272	3480.05688476563	11.5756559371948	11.6953277587891	11.3877859115601	3295.76049804688	2050.59716796875	4525.044921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.04	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	11.5787620544434	11.6894721984863	11.4698400497437	2768.20239257813	1877.81188964844	47.7081489562988	3404.47265625	11.5756559371948	11.6953277587891	11.3877859115601	3295.76049804688	2050.59716796875	4525.044921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.04	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	11.5382385253906	11.7009658813477	11.4144802093506	2715.2822265625	1835.76879882813	47.0671463012694	3341.91748046875	11.5756559371948	11.6953277587891	11.3877859115601	3295.76049804688	2050.59716796875	4525.044921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.04	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	11.5639944076538	11.7007665634155	11.4440031051636	2582.83056640625	1727.02978515625	45.4829864501953	3182.4853515625	11.5756559371948	11.6953277587891	11.3877859115601	3295.76049804688	2050.59716796875	4525.044921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.04	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	11.5756921768188	11.6987133026123	11.4622163772583	2422.19775390625	1617.85888671875	42.6130294799806	2981.47583007813	11.5756559371948	11.6953277587891	11.3877859115601	3295.76049804688	2050.59716796875	4525.044921875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.06	1423708	RT >28-32t 80ties	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-0	Euro-0	343956.40625	8.00524940132163E-05	11.3455390930176	11.6701278686523	11.1200304031372	3803.603515625	2453.48217773438	70.5923690795897	4712.43798828125	11.4385776519775	11.6976022720337	11.1008186340332	4122.00927734375	2476.87451171875	5721.091796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.06	1423710	RT >28-32t Euro-I	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-I	Euro-I	501250.4375	1.63474414875964E-05	11.2580986022949	11.6312780380249	11.0853519439697	3901.41625976563	2421.44995117188	67.4357833862303	4579.39501953125	11.4385776519775	11.6976022720337	11.1008186340332	4122.00927734375	2476.87451171875	5721.091796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.06	1423720	RT >28-32t Euro-II	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-II	Euro-II	498680.125	8.71762385941111E-05	11.3620624542236	11.705005645752	11.2094173431396	3795.4208984375	2361.044921875	64.7799224853516	4434.00244140625	11.4385776519775	11.6976022720337	11.1008186340332	4122.00927734375	2476.87451171875	5721.091796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.06	1423730	RT >28-32t Euro-III	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-III	Euro-III	449296.9375	0.000364974315743893	11.2878198623657	11.6908950805664	11.0921115875244	3799.21655273438	2391.50756835938	64.6779098510741	4461.20068359375	11.4385776519775	11.6976022720337	11.1008186340332	4122.00927734375	2476.87451171875	5721.091796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.06	1423741	RT >28-32t Euro-IV EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.00014195749827195	11.4243507385254	11.7003240585327	11.2099952697754	3543.57348632813	2314.07421875	65.3104248046875	4404.0078125	11.4385776519775	11.6976022720337	11.1008186340332	4122.00927734375	2476.87451171875	5721.091796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.06	1423742	RT >28-32t Euro-IV SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-IV	Euro-IV	415801.53125	0.000425872509367764	11.3642768859863	11.7036552429199	11.0782270431519	3502.79052734375	2290.5224609375	64.3539886474609	4349.85009765625	11.4385776519775	11.6976022720337	11.1008186340332	4122.00927734375	2476.87451171875	5721.091796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.06	1423751	RT >28-32t Euro-V EGR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00087857770267874	11.4088773727417	11.6731739044189	11.1661806106567	3436.02880859375	2256.04418945313	63.1758346557616	4277.6708984375	11.4385776519775	11.6976022720337	11.1008186340332	4122.00927734375	2476.87451171875	5721.091796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.06	1423752	RT >28-32t Euro-V SCR	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-V	Euro-V	359842.15625	0.00263573322445154	11.4627885818481	11.699143409729	11.2570037841797	3364.95239257813	2208.89379882813	61.8291397094725	4187.42626953125	11.4385776519775	11.6976022720337	11.1008186340332	4122.00927734375	2476.87451171875	5721.091796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.06	1423764	RT >28-32t Euro-VI A-C	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	239102.84375	0.00951311271637678	11.5060882568359	11.6983451843262	11.3426189422607	3219.61962890625	2092.00854492188	59.9137725830078	4009.24926757813	11.4385776519775	11.6976022720337	11.1008186340332	4122.00927734375	2476.87451171875	5721.091796875\\\
HGV	2022	REF N HB42	CO2e	Urban	URB/Distr/40/St+Go	40	Stopgo	0.06	1423766	RT >28-32t Euro-VI D-E	diesel	RT >28-32t	RT	28 I 32	28	32	HGV D Euro-VI	Euro-VI	88945.84375	0.00730367191135883	11.5075044631958	11.701789855957	11.3531484603882	3007.61279296875	1947.05639648438	56.1238937377928	3743.02099609375	11.4385776519775	11.6976022720337	11.1008186340332	4122.00927734375	2476.87451171875	5721.091796875';
