import { InitialValues } from '@customTypes/forms/selfDeclaration.types';
import {
  DeclarationStatus,
  DeliveryType,
  PhysicalProperties,
  PreTreatmentMethods,
  SubmitButtonType,
  WasteCategoryType,
} from '@enums/declaration.enum';
import { InfoAsset } from '../asset/InfoAsset.dto';
import { InfoCompany } from '../companies/InfoCompany.dto';
import { InfoCustomer } from '../customer/createNewCustomer.dto';
import { CreateUpdateProject } from '../project/Project.dto';
import { ReceptionControl } from '../reception-control/reception-control.dto';
import { SettingsMaterial } from '../settings/SettingsMaterial.dto';
import { InfoUser } from '../users/InfoUser.dto';
import { DepotSpecific } from './depot-specific.dto';
import { EalCode } from './eal-code.dto';
import { NsCode } from './ns-code.dto';

export interface Declaration {
  id?: number;
  customerId: number;
  customer?: InfoCustomer;
  contactPersonId?: number;
  contactPerson?: InfoUser;
  projectId?: number;
  project?: CreateUpdateProject;
  deliveryType?: DeliveryType;
  transportTypeId?: number;
  packaging?: string;
  volume?: number;
  wasteCategoryType?: WasteCategoryType;
  wasteChoiceReason?: string;
  ealCodeIds?: number[];
  ealCodes?: EalCode[];
  ealCodesStringified?: string;
  nsCodeIds?: number[];
  nsCodes?: NsCode[];
  nsCodesStringified?: string;
  handling?: string;
  industrial?: string;
  material?: string;
  municipality?: string;
  conditionClass?: number;
  wasteAnalyzing: boolean;
  nonAnalysisReason?: string;
  analysisFiles?: Array<InfoAsset | File>;
  containsDangerousSubstance: boolean;
  containsOrganicCarbon: boolean;
  tocPercentage?: number;
  heatLossPercentage?: number;
  leachingColumnTest: boolean;
  leachingShakeTest: boolean;
  wasteTypeId?: number;
  wasteType?: SettingsMaterial;
  wasteTypeStringified?: string;
  physicalProperties?: PhysicalProperties;
  preTreatment?: PreTreatmentMethods;
  requirePreTreatment: boolean;
  smell: string;
  color: string;
  depotSpecifics?: DepotSpecific[];
  depotSpecificAnswers?: string;
  pricePerVolume?: number;
  signedPersonName?: string;
  signedDate?: Date | string;
  signature?: string;
  status: DeclarationStatus;
  contentRejectionReason?: string;
  priceRequestReason?: string;
  sendToCustomer?: boolean;
  sendToDepot?: boolean;
  depotId?: number;
  depot?: InfoCompany;
  // TODO: Add when implement reception control for declaration
  receptionControls?: ReceptionControl[];
}

export const mapDeclarationFormikToDto = (
  formikProps: InitialValues
): Declaration => {
  const declaration: Declaration = {
    // Step 1
    customerId: formikProps.customerContactInfo.customerId,
    contactPersonId: !!formikProps.customerContactInfo.contactPersonId
      ? formikProps.customerContactInfo.contactPersonId
      : null,
    projectId: formikProps.customerContactInfo.projectId
      ? formikProps.customerContactInfo.projectId
      : null,
    // Step 2
    deliveryType: formikProps.transport.delivery as DeliveryType,
    transportTypeId: formikProps.transport.transportTypeId,
    packaging: formikProps.transport.packaging,
    volume: formikProps.transport.volume,
    // Step 3.1
    wasteCategoryType: formikProps.wasteType.depotCategory as WasteCategoryType,
    wasteChoiceReason: formikProps.wasteType.reasonDepotCategory,
    wasteTypeId: formikProps.wasteType.wasteTypeId,
    wasteTypeStringified: JSON.stringify(formikProps.wasteType.wasteType),
    ealCodeIds: formikProps.wasteType.ealCodeIDs as unknown as number[],
    ealCodesStringified: formikProps.wasteType.ealCodes.toString(),
    nsCodeIds: formikProps.wasteType.nsCodeIDs as unknown as number[],
    nsCodesStringified: formikProps.wasteType.nsCodes.toString(),
    // Step 3.2
    wasteAnalyzing: formikProps.wasteTesting.analyzed,
    analysisFiles: formikProps.wasteTesting.analysisFiles,
    nonAnalysisReason: formikProps.wasteTesting.reasonForMissingAnalysis,
    containsDangerousSubstance: formikProps.wasteTesting.dangerousSubstances,
    containsOrganicCarbon: formikProps.wasteTesting.organicCarbon,
    tocPercentage: formikProps.wasteTesting.tocPercent,
    heatLossPercentage: formikProps.wasteTesting.lossOnIgnitionPercent,
    leachingColumnTest: formikProps.wasteTesting.columnLeachingTest,
    leachingShakeTest: formikProps.wasteTesting.vibrationTest,
    // Step 3.3
    conditionClass: Number(formikProps.wasteProperties.conditionClass),
    physicalProperties: formikProps.wasteProperties
      .physicalProperties as PhysicalProperties,
    preTreatment: formikProps.wasteProperties
      .preTreatment as PreTreatmentMethods,
    requirePreTreatment: formikProps.wasteProperties.requirePreTreatment,
    smell: formikProps.wasteProperties.odourDescription,
    color: formikProps.wasteProperties.colorDescription,
    // Step 4
    depotSpecifics: formikProps.depotSpecifics.depotSpecifics,
    depotSpecificAnswers: JSON.stringify(
      formikProps.depotSpecifics.depotSpecificAnswers
    ),
    // Step 5
    pricePerVolume: formikProps.offer.unitPriceTon,
    // Step 6
    signedPersonName: formikProps.signing.name,
    signedDate: formikProps.signing.date,
    signature: formikProps.signing.signature,
    // Status, popup information and email recipients
    status: formikProps.status,
    contentRejectionReason: formikProps.popupInformation.contentRejectionReason,
    priceRequestReason: formikProps.popupInformation.priceRequestReason,
    sendToCustomer:
      formikProps.submitButton === SubmitButtonType.SEND_TO_CUSTOMER ||
      formikProps.submitButton === SubmitButtonType.SIGN,
    sendToDepot:
      formikProps.submitButton !== SubmitButtonType.SEND_TO_CUSTOMER &&
      formikProps.submitButton !== SubmitButtonType.SAVE,
  };
  return declaration;
};

export const mapDeclarationDtoToFormik = (
  declaration: Declaration
): InitialValues => {
  const formikDeclaration: InitialValues = {
    id: declaration.id,
    customerContactInfo: {
      customerId: declaration.customerId,
      contactPersonId: declaration.contactPersonId,
      projectId: declaration.projectId,
      customer: declaration.customer,
      contactPerson: declaration.contactPerson,
      project: declaration.project,
    },
    transport: {
      delivery: declaration.deliveryType,
      transportTypeId: declaration.transportTypeId,
      packaging: declaration.packaging,
      volume: declaration.volume,
    },
    wasteType: {
      depotCategory: declaration.wasteCategoryType,
      reasonDepotCategory: declaration.wasteChoiceReason,
      ealCodes: (declaration.ealCodes || []).map((ec) => ec.code),
      ealCodeIDs: (declaration.ealCodes || []).map((ec) => ec.id),
      nsCodes: (declaration.nsCodes || []).map((ec) => ec.code),
      nsCodeIDs: (declaration.nsCodes || []).map((ec) => ec.id),
      wasteTypeId: declaration.wasteTypeId,
    },
    wasteTesting: {
      analyzed: declaration.wasteAnalyzing,
      analysisFiles: declaration.analysisFiles,
      reasonForMissingAnalysis: declaration.nonAnalysisReason,
      dangerousSubstances: declaration.containsDangerousSubstance,
      organicCarbon: declaration.containsOrganicCarbon,
      tocPercent: declaration.tocPercentage,
      lossOnIgnitionPercent: declaration.heatLossPercentage,
      columnLeachingTest: declaration.leachingColumnTest,
      vibrationTest: declaration.leachingShakeTest,
    },
    wasteProperties: {
      conditionClass: String(declaration.conditionClass),
      physicalProperties: declaration.physicalProperties,
      preTreatment: declaration.preTreatment,
      requirePreTreatment: declaration.requirePreTreatment,
      odourDescription: declaration.smell,
      colorDescription: declaration.color,
    },
    depotSpecifics: {
      depotSpecifics: declaration.depotSpecifics,
      depotSpecificAnswers: JSON.parse(declaration.depotSpecificAnswers),
    },
    offer: {
      price: declaration.pricePerVolume * declaration.volume,
      unitPriceTon: declaration.pricePerVolume,
      volume: declaration.volume,
    },
    signing: {
      name: declaration.signedPersonName,
      date: declaration.signedDate,
      signature: declaration.signature,
    },
    status: declaration.status,
    submitButton: undefined,
  };
  return formikDeclaration;
};

export const isDeclarationSigned = (status: DeclarationStatus) => {
  return status === DeclarationStatus.SIGNED || isDeclarationCompleted(status);
};

export const isDeclarationCompleted = (status: DeclarationStatus) => {
  return (
    status === DeclarationStatus.PARTIALLY_COMPLETED ||
    status === DeclarationStatus.FULLY_COMPLETED
  );
};

export const isDeclarationSentToCustomer = (status: DeclarationStatus) => {
  return (
    status !== DeclarationStatus.DRAFT && status !== DeclarationStatus.REQUESTED
  );
};
