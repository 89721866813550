import { ASSET_REFERENCE_TYPE } from '@/enums/asset-reference-type.enum';
import { BASE64_KEY } from '@utils/file.utils';
import { IImageFile } from '../commons/IImageFile.dto';

export const AVAILABLE_IMAGE_EXTENSION = ['jpeg', 'jpg', 'png'];
export const PDF_EXTENSION = 'pdf';

export interface InfoAsset {
  id: string;
  referenceType: ASSET_REFERENCE_TYPE;
  referenceId: number;
  fileName: string;
  extension: string;
  isActive: boolean;
}

export const isFileInstanceOfInfoAsset = (
  file: File | IImageFile | InfoAsset
): file is InfoAsset => {
  return 'id' in file;
};

export const isInstanceOfIImageFile = (
  file: File | IImageFile | InfoAsset
): file is IImageFile => {
  return BASE64_KEY in file;
};

export const isImageFile = (
  file: File | IImageFile | InfoAsset
): file is IImageFile | InfoAsset => {
  if ('extension' in file)
    return AVAILABLE_IMAGE_EXTENSION.includes(file.extension);
  if ('type' in file)
    return AVAILABLE_IMAGE_EXTENSION.map(
      (extension) => `image/${extension}`
    ).includes(file.type);
  return isInstanceOfIImageFile(file);
};

export const isPdfFile = (
  file: File | IImageFile | InfoAsset
): file is File | InfoAsset => {
  if ('type' in file) return file.type === 'application/pdf';
  return 'extension' in file ? file.extension === PDF_EXTENSION : false;
};
