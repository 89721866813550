import React from 'react';
import CommonStore from './common.store';

const commonStore = new CommonStore();

export const CommonStoreContext = React.createContext(commonStore);

export default function initStore() {
  return {
    commonStore,
  };
}
