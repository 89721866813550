import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { Action, DeclarationExtendedAction } from '@enums/casl.enum';
import {
  AbilityBuilder,
  createMongoAbility,
  MongoAbility,
} from '@casl/ability';
import { Declaration } from '@dto/declaration/declaration.dto';
import { Customer } from '@dto/customer/customer.dto';
import { Material } from '@dto/materials/Material.dto';

type Subjects =
  | Declaration
  | Customer
  | Material
  | 'Declaration'
  | 'Customer'
  | 'Material'
  | 'Transport'
  | 'Hub'
  | 'Company'
  | 'all';
type ExtendedAction = Action | DeclarationExtendedAction;

const { build } = new AbilityBuilder<AppAbility>(createMongoAbility);
export const appAbility = build();
export const AbilityContext = createContext<AppAbility>(appAbility);
export type AppAbility = MongoAbility<[ExtendedAction, Subjects]>;
export const Can = createContextualCan(AbilityContext.Consumer);
