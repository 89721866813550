export enum TRANSPORT_EURO_STANDARD {
  EURO_I = 'EURO_I',
  EURO_II = 'EURO_II',
  EURO_III = 'EURO_III',
  EURO_IV = 'EURO_IV',
  EURO_V = 'EURO_V',
  EURO_VI = 'EURO_VI',
}

export const getEuroStandardName = (key: string): string => {
  let result = '';
  switch (key) {
    case TRANSPORT_EURO_STANDARD.EURO_I:
      result = 'Euro I';
      break;
    case TRANSPORT_EURO_STANDARD.EURO_II:
      result = 'Euro II';
      break;
    case TRANSPORT_EURO_STANDARD.EURO_III:
      result = 'Euro III';
      break;
    case TRANSPORT_EURO_STANDARD.EURO_IV:
      result = 'Euro IV';
      break;
    case TRANSPORT_EURO_STANDARD.EURO_V:
      result = 'Euro V';
      break;
    case TRANSPORT_EURO_STANDARD.EURO_VI:
      result = 'Euro VI';
      break;
    default:
      result = 'Euro I';
  }

  return result;
};

export enum FUEL {
  DIESEL = 'Diesel',
  PETROL = 'Petrol',
  ELECTRIC = 'Electric',
  HYDROGEN = 'Hydrogen',
}

export enum TRUCK_TYPE {
  RIGID_TRUCK = 'Rigid Truck',
  ARTICULATED_TRUCK = 'Articulated Truck',
}
