export enum TRANSPORT {
  REQUEST = 'REQUEST',
  ARRANGE = 'ARRANGE',
}

export enum ACTIVITIES_RESULT_TYPE {
  SUPPLY = 'SUPPLY',
  REQUEST = 'REQUEST',
  RESERVATION = 'RESERVATION',
  OFFER = 'OFFER',
}

export enum ACTIVITIES_RESULT_STATUS {
  EXPIRED = 'EXPIRED',
  RESERVED = 'RESERVED',
  PRE_CREATED = 'PRE_CREATED',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export enum REGISTERED_ACTIVITIES {
  ALL = 'ALL',
  SUPPLY = 'SUPPLY',
  REQUEST = 'REQUEST',
}

export enum TRANSACTION_ACTIVITIES {
  ALL = 'ALL',
  RESERVATION = 'RESERVATION',
  OFFER = 'OFFER',
}

export enum MASS_TRANSACTION_TYPES {
  RESERVATION = 'RESERVATION',
  OFFER = 'OFFER',
}

export enum MASS_ORIGIN_TYPES {
  RECYCLED = 'RECYCLED',
  NATURAL = 'NATURAL',
  CRUSHED = 'CRUSHED',
}

export enum MASS_TYPE {
  SUPPLY = 'SUPPLY',
  REQUEST = 'REQUEST',
}
