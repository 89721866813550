import { CustomRoute, RouteCategory } from '@/routers/routes';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { getTitle } from '@utils/name.utils';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStore } from '@/RootStoreProvider';

interface ComponentProps {
  category: RouteCategory;
  subItems: CustomRoute[];
  open: boolean;
  handleDrawerOpen: () => void;
}
const MenuItem = (props: ComponentProps) => {
  const { category, subItems, open, handleDrawerOpen } = props;

  const { massStore } = useStore();

  const history = useHistory();
  const handleChange = (path: string): void => {
    if (path === '/home/request') {
      massStore.setRequestSearchResult([]);
    }
    if (path === '/home/supply') {
      massStore.setSupplySearchResult([]);
    }
    history.push(path);
  };

  const [showDrawer, setShowDrawer] = React.useState(true);

  const handleDrawer = () => {
    setShowDrawer(!showDrawer);
    handleDrawerOpen();
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      setShowDrawer(true);
    }
  }, [open]);

  return (
    <>
      {category.dropdown ? (
        <>
          <ListItemButton
            sx={{
              mt: (theme) => theme.spacing(1),
              justifyContent: 'center',
            }}
            onClick={handleDrawer}
            key={`${category.name}_list_button`}
          >
            <ListItemIcon
              sx={{
                color: 'text.secondary',
                minWidth: 45,
                justifyContent: 'center',
              }}
            >
              {category.icon}
            </ListItemIcon>

            {open && (
              <>
                <ListItemText
                  sx={{ opacity: open ? 1 : 0 }}
                  disableTypography
                  primary={
                    <Typography color='text.secondary' variant='subtitle1'>
                      {getTitle(category.name, t)}
                    </Typography>
                  }
                />
                {showDrawer ? <ExpandLess /> : <ExpandMore />}
              </>
            )}
          </ListItemButton>
          {open && (
            <>
              {subItems
                .filter((item: CustomRoute) => item.category === category.name)
                .map((item: CustomRoute) => (
                  <Collapse
                    in={showDrawer}
                    timeout='auto'
                    unmountOnExit
                    key={`${item.name}_collapse`}
                  >
                    <List
                      component='div'
                      disablePadding
                      key={`${item.name}_list`}
                    >
                      <ListItemButton
                        selected={history.location.pathname === item.path}
                        onClick={() => handleChange(item.path)}
                        sx={{ pl: (theme) => theme.spacing(7.8) }}
                      >
                        {open && (
                          <ListItemText
                            primary={item.title}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        )}
                      </ListItemButton>
                    </List>
                  </Collapse>
                ))}
              <Divider sx={{ mt: (theme) => theme.spacing(1) }} />
            </>
          )}
        </>
      ) : (
        <>
          {subItems
            .filter((item: CustomRoute) => item.category === category.name)
            .map((item: CustomRoute) => (
              <Box key={`${item.name}_list_button`}>
                <ListItemButton
                  selected={history.location.pathname === item.path}
                  sx={{
                    mt: (theme) => theme.spacing(1),
                    justifyContent: 'center',
                  }}
                  onClick={() => handleChange(item.path)}
                >
                  <ListItemIcon
                    sx={{
                      color: 'text.secondary',
                      minWidth: 45,
                      justifyContent: 'center',
                    }}
                  >
                    {category.icon}
                  </ListItemIcon>
                  {open && (
                    <>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            color='text.secondary'
                            variant='subtitle1'
                          >
                            {item.title}
                          </Typography>
                        }
                      />
                      <Divider sx={{ mt: (theme) => theme.spacing(1) }} />
                    </>
                  )}
                </ListItemButton>
              </Box>
            ))}
        </>
      )}
    </>
  );
};

export default MenuItem;
