import { CompanyFormValidation } from '@/validations/company.validation';
import { CompanyPermission } from '@dto/companies/CompanyPermission.dto';
import { BusinessModule } from '@enums/casl.enum';

export const INIT_COMPANY_FORM_ERROR: Record<string, boolean> = {
  companyName: false,
  website: false,
  phoneNumber: false,
  location: false,
  contactPrimary: false,
  contactSales: false,
  assetImage: false,
  assetLogo: false,
};

export const INIT_COMPANY_FORM_VALIDATION: CompanyFormValidation = {
  errorForm: INIT_COMPANY_FORM_ERROR,
  isError: false,
};

export const INIT_COMPANY_PERMISSION_TABLE: CompanyPermission[] = [
  { moduleName: BusinessModule.Marketplace, actions: [] },
  { moduleName: BusinessModule.Declaration, actions: [] },
  { moduleName: BusinessModule.Planner, actions: [] },
  { moduleName: BusinessModule.Shared, actions: [] },
];
