import { TextField } from '@mui/material';
import { Box, color } from '@mui/system';
import { useEffect, useState } from 'react';
import { FilterVolume } from '@/stores/marketplace-filter.store';
import FilterSelectionButton from './FilterSelectButton';

interface ComponentProps {
  onReturn: () => void;
  volume: FilterVolume;
  onSelect?: () => void;
  searchOnChange?: boolean;
}

const FilterSelectVolume = (props: ComponentProps) => {
  const { onReturn, volume, onSelect, searchOnChange } = props;

  const [minVolume, setMinVolume] = useState<number | undefined>(volume.min);

  const [maxVolume, setMaxVolume] = useState<number | undefined>(volume.max);

  const [selectedItems, setSelectedItems] = useState<number>(0);

  useEffect(() => {
    setSelectedItems(getSelectedItems());
  }, [minVolume, maxVolume]);

  const getSelectedItems = () => {
    let selectedItems = 0;

    if (minVolume !== undefined) {
      selectedItems++;
    }

    if (maxVolume !== undefined) {
      selectedItems++;
    }

    return selectedItems;
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextField
          label='Minimum'
          variant='filled'
          color='white'
          type={'number'}
          value={minVolume}
          onChange={(value) => {
            const val = Number(value.target.value);

            setMinVolume(val);
          }}
          placeholder='Velg minimum volum'
          sx={{ marginBottom: '16px' }}
          inputProps={{
            min: 0,
          }}
        />

        <TextField
          label='Maximum'
          variant='filled'
          color='white'
          type={'number'}
          value={maxVolume}
          onChange={(value) => {
            const val = Number(value.target.value);

            setMaxVolume(val);
          }}
          placeholder='Velg maximum volum'
          inputProps={{
            min: 0,
          }}
        />
      </Box>
      {!searchOnChange && (
        <FilterSelectionButton
          selectedItems={selectedItems}
          onReturn={onReturn}
          onSelect={function (): void {
            volume.max = maxVolume;
            volume.min = minVolume;
            onSelect();
          }}
        ></FilterSelectionButton>
      )}
    </>
  );
};

export default FilterSelectVolume;
