import co2EmissionService from '@/apis/co2.emission.service';
import { INIT_SETTINGS_CO2EMISSIONS } from '@/constants/settings.containts';
import { InfoCo2Emission } from '@/dto/settings/InfoCo2Emission.dto';
import { action, makeObservable, observable } from 'mobx';

class Co2EmissionStore {
  co2EmissionForm: InfoCo2Emission;
  savedCo2EmissionForm: InfoCo2Emission;
  isValueUpdated: boolean;

  constructor() {
    this.co2EmissionForm = INIT_SETTINGS_CO2EMISSIONS;
    this.savedCo2EmissionForm = this.co2EmissionForm;
    this.isValueUpdated = true;

    makeObservable(this, {
      co2EmissionForm: observable,
      isValueUpdated: observable,
      getCo2Emission: action,
      editCo2Emission: action,
      handleReset: action,
    });
  }

  async getCo2Emission(): Promise<void> {
    const data = await co2EmissionService.getCo2Emission();

    this.co2EmissionForm = data;
    this.savedCo2EmissionForm = data;
  }

  async editCo2Emission(model: InfoCo2Emission): Promise<void> {
    if (model.value !== this.savedCo2EmissionForm.value) {
      this.isValueUpdated = true;
    }

    await co2EmissionService.editCo2Emission(model);

    this.co2EmissionForm = model;
  }

  async handleReset(): Promise<void> {
    await this.getCo2Emission();
  }
}

export default Co2EmissionStore;
