export const debounce = (func: any, wait: number) => {
  let timeout: NodeJS.Timeout | null;
  return function (this: any, ...args: []) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(this, args);
    }, wait);
  };
};
