import React, { FC, useEffect } from 'react';
import * as serviceWorker from '../../../serviceWorkerRegistration';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { I18N } from '@/enums/i18n.enum';

const ServiceWorkerWrapper: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null);

  const { t } = useTranslation();
  const { BUTTONS_UPDATE, NOTIFY_NEW_VERSION } = I18N;

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  };
  const handleClose = () => {
    setShowReload(false);
  };

  return (
    <Snackbar
      open={showReload}
      message={t(NOTIFY_NEW_VERSION)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <Box sx={{ p: 1 }}>
          <Button
            sx={{ mr: 1 }}
            color='primary'
            size='small'
            variant='contained'
            onClick={reloadPage}
          >
            {t(BUTTONS_UPDATE)}
          </Button>
          <IconButton aria-label='close' color='inherit' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      }
    />
  );
};

export default ServiceWorkerWrapper;
