export const SIDE_BAR_WIDTH = 304;
export const SEARCH_SIDE_BAR_WIDTH = 300;
export const MOBILE_SEARCH_SIDE_BAR_WIDTH = 350;
export const TOOLBAR_HEIGHT = 64;
export const BOTTOM_NAVIGATION_HEIGHT = 80;
export const MAP_SWIPER_SIDE_WIDTH = 400;
export const MAP_SWIPERSLIDE_HEIGHT = 128;
export const FOOTER_HEIGHT = 162;
export const ASPECT_RATIO = '21/9';
export const BOTTOM_GAP_SELECTED_CARD = 10;
export const SELECT_CARD_HEIGHT = `calc(100vh - ${TOOLBAR_HEIGHT}px - ${BOTTOM_GAP_SELECTED_CARD}px)`;
export const SKETCH_PICKER_DEFAULT_COLOR = '#22194d';
export const SEARCH_SIDE_BAR_ZINDEX = 1300;
export const DECLARATION_FORM_FOOTER_HEIGHT = 90;
export const LEFT_BAR_WIDTH = MAP_SWIPER_SIDE_WIDTH;
