export const INIT_CUSTOMER_FORM = {
  email: '',
  name: '',
  phoneNumber: '',
  companyId: null,
  company: null,
};

export const INIT_CREATE_CUSTOMER_FORM_ERROR: Record<string, boolean> = {
  email: false,
  name: false,
  phoneNumber: false,
};

export const INIT_CREATE_CUSTOMER_FORM_VALIDATION = {
  errorForm: INIT_CREATE_CUSTOMER_FORM_ERROR,
  isError: false,
};
