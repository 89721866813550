import reportService from '@/apis/report.service';
import { Report } from '@/dto/reports/Report.dto';
import { ReportExport } from '@/dto/reports/ReportExport.dto';
import { ReportPeriod } from '@/dto/reports/ReportPeriod.dto';
import { REPORT_TYPE } from '@/enums/report.enum';
import { calculatePrecision } from '@utils/number.utils';
import { action, makeObservable, observable } from 'mobx';

class ReportStore {
  report: Report[];
  carbonReduced: number;

  constructor() {
    this.report = [];
    this.carbonReduced = 0;

    makeObservable(this, {
      report: observable,
      carbonReduced: observable,
      setReport: action,
    });
  }

  async getReport(period: ReportPeriod): Promise<void> {
    const result = await reportService.getReport(period);
    this.setReport(result);
  }

  async export(period: ReportPeriod): Promise<ReportExport[]> {
    const result = await reportService.export(period);
    return result;
  }

  setReport(data: Report[]): void {
    this.report = data;
    const co2Emission = data.find((r) => r.name === REPORT_TYPE.CO2_EMISSION);
    this.carbonReduced = co2Emission
      ? calculatePrecision(
          (+co2Emission.reduction / co2Emission.withoutMarketplace) * 100,
          0
        )
      : 0;
  }
}

export default ReportStore;
