import hubService from '@/apis/hub.service';
import {
  INIT_HUB_FILTER,
  INIT_SETTINGS_HUB,
} from '@/constants/settings.containts';
import { SelectOption } from '@/dto/commons/SelectOption.dto';
import { HubPaginationRequest } from '@/dto/hub/HubPaginationRequest.dto';
import { SettingsHubFilter, SettingsHub } from '@/dto/settings/SettingsHub.dto';
import { SettingsHubCreate } from '@/dto/settings/SettingsHubCreate.dto';
import { PaginationRequest } from '@dto/commons/PaginationRequest.dto';
import { action, computed, makeObservable, observable } from 'mobx';

class HubStore {
  hubs: SettingsHub[];
  hub: SettingsHub;
  hubCount: number;
  filters: SettingsHubFilter;
  loadingGet: boolean;

  constructor() {
    this.hubs = [];
    this.hub = INIT_SETTINGS_HUB;
    this.hubCount = 0;
    this.filters = INIT_HUB_FILTER;
    this.loadingGet = false;

    makeObservable(this, {
      hubs: observable,
      hub: observable,
      hubCount: observable,
      filters: observable,
      loadingGet: observable,
      createHub: action,
      editHub: action,
      deleteHub: action,
      getHub: action,
      getHubs: action,
      setHubs: action,
      setHubForm: action,
      hubOptions: computed,
      setHubCount: action,
      setEmptyHubForm: action,
      setHubFilter: action,
      setLoadingGet: action,
    });
  }

  async createHub(
    hub: SettingsHubCreate,
    logoImage: File | null,
    profileImage: File | null
  ): Promise<void> {
    await hubService.createHub(hub, logoImage, profileImage);

    return history.back();
  }

  async getHubs(query?: HubPaginationRequest): Promise<void> {
    const data = await hubService.getHubs(query);
    this.setHubs(data.data);
    this.setHubCount(data.count);
  }

  async editHub(
    hub: SettingsHub,
    logoImage: File | null = null,
    profileImage: File | null = null
  ): Promise<void> {
    await hubService.editHub(hub, logoImage, profileImage);

    const hubs = [...this.hubs];
    const index = this.hubs.findIndex((x) => x.id == hub.id);
    hubs[index] = hub;
    this.setHubs(hubs);
  }

  async deleteHub(id: number): Promise<void> {
    await hubService.deleteHub(id);
  }

  async getHub(id: number): Promise<void> {
    this.setLoadingGet(true);
    const data = await hubService.getHub(id);
    this.setHubForm(data);
    this.setLoadingGet(false);
  }

  async handleReset(id: number): Promise<void> {
    await this.getHub(id);
  }

  setEmptyHubForm(): void {
    this.hub = INIT_SETTINGS_HUB;
  }

  async minified(query?: PaginationRequest) {
    const result = await hubService.minified(query);
    this.setHubs(result);
  }

  get hubOptions(): SelectOption[] {
    const activeHubs: SettingsHub[] = this.hubs
      .filter((hub) => {
        return hub.isActive === true;
      })
      .sort((a: SettingsHub, b: SettingsHub) => a.name.localeCompare(b.name));

    return activeHubs.map((c) => ({
      label: `${c.name}`,
      value: c.id || 0,
    }));
  }

  setHubs(data: any[]) {
    this.hubs = data;
  }

  setHubForm(data: SettingsHub) {
    this.hub = data;
  }

  setHubFilter(query: SettingsHubFilter) {
    this.filters = query;
  }

  setHubCount(count: number) {
    this.hubCount = count;
  }

  setLoadingGet(isLoading: boolean) {
    this.loadingGet = isLoading;
  }
}

export default HubStore;
