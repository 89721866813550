import { InfoDynamicForm } from '@/dto/settings/InfoDynamicForm.dto';

export const INIT_FORM_SUPPLY_DECLARATION: InfoDynamicForm = {
  id: 0,
  data: '',
  isActive: true,
};

export const DYNAMIC_FORM_COMPONENT = {
  INPUT: 'input',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  SWITCH: 'switch',
};
