import http from '@/apis';
import { InfoAsset, isInstanceOfIImageFile } from '@/dto/asset/InfoAsset.dto';
import { IImageFile } from '@/dto/commons/IImageFile.dto';
import { ASSET_REFERENCE_TYPE } from '@enums/asset-reference-type.enum';

class AssetService {
  prefix = 'asset';

  public async getFile(assetId: string, isImage?: boolean): Promise<void> {
    const url = `${this.prefix}/${
      isImage ? 'image-file' : 'document-file'
    }/${assetId}`;
    const response = await http.get(url, {
      responseType: 'blob',
    });
    const filename =
      response.headers['content-disposition'].split('filename=')[1];
    const blob = new Blob([response.data], {
      type: 'application/octet-stream',
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename ? filename : `${new Date().getTime()}`;
    link.click();
  }

  public async uploadFile(
    file: File | null,
    referenceType: ASSET_REFERENCE_TYPE
  ): Promise<InfoAsset> {
    const url = `${this.prefix}/upload/${referenceType}`;
    const form = new FormData();
    form.append('file', file);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await http.post(url, form, config);
    return result.data.result;
  }

  public getImageUrl(assetId: string) {
    const imageUrl = `${process.env.REACT_APP_BACKEND_URL}/asset/image-file/${assetId}`;
    return imageUrl;
  }

  public getImageBase64OrUrl(imageFile: IImageFile | InfoAsset) {
    return isInstanceOfIImageFile(imageFile)
      ? imageFile.base64
      : this.getImageUrl(imageFile.id);
  }
}

export default new AssetService();
