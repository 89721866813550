import http from '@/apis';
import { PaginationRequest } from '@/dto/commons/PaginationRequest.dto';
import { NsCode } from '@dto/declaration/ns-code.dto';
import { prepareGetQuery } from '@utils/api.utils';

class NsCodeService {
  prefix = 'ns-code';

  public async getNsCodes(query?: PaginationRequest<NsCode>) {
    let url = `${this.prefix}`;
    if (query) {
      url += prepareGetQuery(query);
    }
    const result = await http.get(url);

    return result.data.result;
  }
}

export default new NsCodeService();
