import { theme } from '@/themes/MolunderTheme';
import { Button } from '@mui/material';
import { Box } from '@mui/system';

interface ComponentProps {
  selectedItems?: number;
  onSelect: () => void;
  onReturn: () => void;
}

const FilterSelectButton = (props: ComponentProps) => {
  const { selectedItems, onReturn, onSelect } = props;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          width: '100%',
          mt: 2,
        }}
      >
        <Button
          key={'filteractive btn'}
          variant='contained'
          disabled={selectedItems === 0}
          sx={{
            backgroundColor: '#fff',
            color: theme.palette.primary.main,
            '&:hover': {
              color: '#fff',
            },
          }}
          onClick={() => (selectedItems > 0 ? onSelect() : onReturn())}
        >
          Velg
        </Button>
      </Box>
    </>
  );
};

export default FilterSelectButton;
