import { OBJECT_TYPE } from './../enums/conversation.enum';
import http from '@/apis';
import {
  Conversation,
  CreateConversation,
} from '@dto/conversation/conversation.dto';

class ConversationService {
  prefix = 'conversation';

  public async getConversationByObjectType(
    objectType: OBJECT_TYPE,
    objectTypeId: number
  ): Promise<Conversation | null> {
    const result = await http.get(
      `${this.prefix}/objectType/${objectType}/${objectTypeId}`
    );
    return result.data.result;
  }

  public async createConversation(
    model: CreateConversation
  ): Promise<Conversation> {
    const result = await http.post(`${this.prefix}`, model);
    return result.data.result;
  }
}

export default new ConversationService();
