import { action, makeObservable, observable } from 'mobx';
import { Location } from '@/dto/locations/Location.dto';
import { getDistanceFromLatLonInKm } from '@utils/location.utils';
import hash from 'object-hash';
import { MarketItem } from '@/dto/marketplace/MarketItem.dto';
import { SearchMass } from '@dto/mass/searchMass.dto';

export type FilterVolume = {
  min?: number;
  max?: number;
};

export type FilterPrice = {
  min?: number;
  max?: number;
};

export type FilterLocation = {
  location?: Location;
  // radius in km
  radius?: number;
};

export type FilterMaterialCondition = {
  hasLosAngelesVal: boolean;
  hasMicroDevalVal: boolean;
};

abstract class FilterStore {
  materials: Set<string>;
  volume: FilterVolume;
  price: FilterPrice;
  location: FilterLocation;
  dateFrom?: Date;
  dateTo?: Date;
  materialCondition: FilterMaterialCondition;
  hash: string;
  searchKey: string;
  currentSearchKey: string;

  constructor() {
    this.materials = new Set();
    this.volume = {};
    this.price = {};
    this.location = {};
    this.hash = '';
    this.materialCondition = {
      hasLosAngelesVal: false,
      hasMicroDevalVal: false,
    };
    this.searchKey = '';
    this.currentSearchKey = '';

    makeObservable(this, {
      materials: observable,
      volume: observable,
      hash: observable,
      searchKey: observable,
      reset: action,
      filterItemCount: action,
      update: action,
      setSearchKey: action,
      setCurrentSearchKey: action,
    });
  }

  update(): void {
    this.hash = hash(this);
  }

  reset(): void {
    this.materials.clear();
    this.volume = {};
    this.price = {};
    this.location = {};
    this.materialCondition = {
      hasLosAngelesVal: false,
      hasMicroDevalVal: false,
    };
    this.update();
  }

  filterItemCount(): number {
    let count = this.materials.size;

    if (this.volume !== null) {
      if (this.volume.min !== undefined) {
        count++;
      }
      if (this.volume.max !== undefined) {
        count++;
      }
    }

    if (this.price !== null) {
      if (this.price.min !== undefined) {
        count++;
      }
      if (this.price.max !== undefined) {
        count++;
      }
    }

    if (this.location !== null) {
      if (this.location.location !== undefined) {
        count++;
      }

      if (this.location.radius !== undefined) {
        count++;
      }
    }

    if (this.materialCondition.hasLosAngelesVal) {
      count++;
    }

    if (this.materialCondition.hasMicroDevalVal) {
      count++;
    }

    return count;
  }

  filter(unfiltered: MarketItem[]): MarketItem[] {
    const filteredSupply: MarketItem[] = [];

    for (const item of unfiltered) {
      const valid = this.filterItem(item);

      if (valid) filteredSupply.push(item);
    }

    return filteredSupply;
  }

  filterItem = (item: MarketItem): boolean => {
    /// filter by material
    if (this.materials.size > 0) {
      let hasMaterial = false;

      this.materials.forEach((material) => {
        if (item.materialName.toLowerCase() === material.toLowerCase()) {
          hasMaterial = true;
        }
      });

      if (!hasMaterial) return false;
    }

    /// filter by volume
    if (this.volume !== undefined && item.volume !== undefined) {
      if (this.volume.min !== undefined)
        if (item.volume < this.volume.min) return false;

      if (this.volume.max !== undefined)
        if (item.volume > this.volume.max) return false;
    }

    /// filter by price
    if (this.price !== undefined && item.price !== undefined) {
      if (this.price.min !== undefined)
        if (item.price < this.price.min) return false;

      if (this.price.max !== undefined)
        if (item.price > this.price.max) return false;
    }

    /// filter by location
    if (this.location !== null) {
      if (this.location.location !== undefined) {
        if (this.location.radius !== undefined) {
          const distance = getDistanceFromLatLonInKm(
            this.location.location.latitude,
            this.location.location.longitude,
            item.location.latitude,
            item.location.longitude
          );

          if (distance > this.location.radius) return false;
        }
      }
    }

    /// filter by material condition
    if (this.materialCondition.hasLosAngelesVal) {
      if (item.losAngelesValue < 1) return false;
    }

    if (this.materialCondition.hasMicroDevalVal) {
      if (item.microDeval < 1) return false;
    }

    return true;
  };

  setSearchKey(searchKey: string): void {
    this.searchKey = searchKey;
  }

  setCurrentSearchKey(currentSearchKey: string): void {
    this.currentSearchKey = currentSearchKey;
  }

  transformToFilterQuery(): SearchMass {
    const filter: any = {};

    if (this.materialCondition.hasLosAngelesVal) {
      filter.losAngelesValue = this.materialCondition.hasLosAngelesVal;
    }

    if (this.materialCondition.hasMicroDevalVal) {
      filter.microDeval = this.materialCondition.hasMicroDevalVal;
    }

    if (this.volume.min) {
      filter.minVolume = this.volume.min;
    }

    if (this.volume.max) {
      filter.maxVolume = this.volume.max;
    }

    if (this.price.min) {
      const minPrice = parseFloat(this.price.min.toString()).toFixed(3);
      filter.minPrice = minPrice;
    }

    if (this.price.max) {
      const maxPrice = parseFloat(this.price.max.toString()).toFixed(3);
      filter.maxPrice = maxPrice;
    }

    if (this.location.radius) {
      filter.radius = this.location.radius;
    }

    if (this.location.location) {
      filter.latitude = this.location.location.latitude;
      filter.longitude = this.location.location.longitude;
    }

    if (!!this.materials) {
      filter.materialNames = Array.from(this.materials);
    }

    return filter;
  }
}

class SupplyFilterStore extends FilterStore {
  constructor() {
    super();
  }
}

class RequestFilterStore extends FilterStore {
  constructor() {
    super();
  }
}

class ExchangeFilterStore extends FilterStore {
  constructor() {
    super();
  }
}

export { SupplyFilterStore, RequestFilterStore, ExchangeFilterStore };
