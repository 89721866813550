import commonService from '@/apis/common.service';
import { MAP_TYPE } from '@/enums/map.enum';
import { action, makeObservable, observable } from 'mobx';

class CommonStore {
  testOffineModeResponse: string;
  isSidebarOpen: boolean;
  isFilterOpen: boolean;
  lastMapTypeOpen: MAP_TYPE;
  // true == mapview, false == listview
  mapView: boolean;

  constructor() {
    this.testOffineModeResponse = '';
    this.isSidebarOpen = true;
    this.isFilterOpen = false;
    this.mapView = true;
    this.lastMapTypeOpen = MAP_TYPE.SUPPLY;

    makeObservable(this, {
      testOffineModeResponse: observable,
      isSidebarOpen: observable,
      isFilterOpen: observable,
      lastMapTypeOpen: observable,
      setLastMapTypeOpen: action,
      setSidebarOpen: action,
      setFilterOpen: action,
    });
  }

  async testOffineMode(): Promise<string> {
    const result = await commonService.testOffineMode();
    this.testOffineModeResponse = result;
    return result;
  }

  setSidebarOpen(toggle: boolean): void {
    this.isSidebarOpen = toggle;
  }

  setFilterOpen(toggle: boolean): void {
    this.isFilterOpen = toggle;
  }

  setLastMapTypeOpen(mapType: MAP_TYPE): void {
    this.lastMapTypeOpen = mapType;
  }
}

export default CommonStore;
