import http from '@/apis';
import { InfoDynamicForm } from '@/dto/settings/InfoDynamicForm.dto';

class DynamicFormService {
  prefix = 'dynamic-form';

  public async getSupplyDeclarationForm(): Promise<InfoDynamicForm> {
    const result = await http.get(`${this.prefix}/supply-declaration`);
    return result.data.result;
  }
}

export default new DynamicFormService();
