import http from '@/apis';
import { isFileInstanceOfInfoAsset } from '@/dto/asset/InfoAsset.dto';
import { ReceptionControl } from '@/dto/reception-control/reception-control.dto';

class ReceptionControlService {
  prefix = 'reception-control';

  public async getReceptionControl(id: number): Promise<ReceptionControl> {
    const result = await http.get(`${this.prefix}/${id}`);
    const declaration: ReceptionControl = result.data.result;

    return declaration;
  }

  public async getReceptionControlsByDeclaration(
    declarationId: number
  ): Promise<ReceptionControl[]> {
    const result = await http.get(
      `${this.prefix}/declaration/${declarationId}`
    );
    const receptionControls: ReceptionControl[] = result.data.result;

    return receptionControls;
  }

  public async createReceptionControl(
    receptionControl: ReceptionControl
  ): Promise<number> {
    const { image, ...model } = receptionControl;
    const form = new FormData();
    form.append('model', JSON.stringify(model));
    if (image && !isFileInstanceOfInfoAsset(image)) {
      form.append('file', image);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await http.post(`${this.prefix}`, form, config);
    return result.data.result;
  }

  public async editReceptionControl(
    receptionControl: ReceptionControl
  ): Promise<boolean> {
    const { image, ...model } = receptionControl;
    const form = new FormData();
    form.append('model', JSON.stringify(model));
    if (image && !isFileInstanceOfInfoAsset(image)) {
      form.append('file', image);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await http.put(`${this.prefix}/${model.id}`, form, config);
    return result.data.result.data;
  }
}

export default new ReceptionControlService();
