import { Location, LocationMapBox } from '@/dto/locations/Location.dto';
import { retrieveFromStorage } from './storage.util';

export function toLocationEntity(value: LocationMapBox): Location {
  return {
    mapBoxId: value.mapBoxId,
    place_name: value.place_name,
    place_name_is: value.place_name_is,
    city: value.city,
    postcode: value.postcode,
    longitude: value.geometry.coordinates[0],
    latitude: value.geometry.coordinates[1],
    type: value.geometry.type,
    region: value.region,
  };
}

export const getDirectionGoogleMap = (
  sLat: number,
  sLng: number,
  dLat: number,
  dLng: number
) => {
  return `https://www.google.com/maps?saddr=${sLat},${sLng}&daddr=${dLat},${dLng}`;
};

export const retreiveStoreSelectedLocation = (): Location | null => {
  const selectedLocationString = retrieveFromStorage('selectedLocation');

  if (!selectedLocationString) {
    return null;
  }

  return JSON.parse(selectedLocationString) as Location;
};

export const getLocationGoogleMap = (lat: number, lng: number) => {
  return `https://www.google.com/maps?q=${lat},${lng}`;
};

export const getDistanceFromLatLonInKm = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};
