import http from '@/apis';
import { isFileInstanceOfInfoAsset } from '@/dto/asset/InfoAsset.dto';
import {
  PaginationRequest,
  PaginationResult,
} from '@/dto/commons/PaginationRequest.dto';
import { DeclarationProceedDto } from '@/dto/declaration/declaration-proceed.dto';
import { Declaration } from '@/dto/declaration/declaration.dto';
import { InfoUserWidthCredential } from '@/dto/users/InfoUser.dto';
import { ShareDeclaration } from '@dto/declaration/share-declaration.dto';
import { prepareGetQuery } from '@utils/api.utils';

class DeclarationService {
  prefix = 'declaration';

  public async createDeclaration(declaration: Declaration): Promise<number> {
    const model = declaration;
    const form = new FormData();
    form.append('model', JSON.stringify(model));
    // Uncomment below code and refactor in case we do not use upload on select anymore
    // model.analysisFiles.forEach((analysisFile) => {
    //   if (!isFileInstanceOfInfoAsset(analysisFile)) {
    //     form.append('file', analysisFile);
    //   }
    // });

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await http.post(`${this.prefix}`, form, config);
    return result.data.result;
  }

  public async getDeclaration(id: number): Promise<Declaration> {
    const result = await http.get(`${this.prefix}/${id}`);
    const declaration: Declaration = result.data.result;

    return declaration;
  }

  public async getDeclarations(
    query?: PaginationRequest
  ): Promise<PaginationResult<Declaration>> {
    let url = this.prefix;
    if (query) {
      url += prepareGetQuery(query as Record<string, unknown>);
    }

    const result = await http.get(url);
    return { data: result.data.result.data, count: result.data.result.count };
  }

  public async getDeclarationSummaryFile(
    id: number,
    tz?: number
  ): Promise<void> {
    const query = tz ? `?tz=${tz}` : '';
    const url = `${this.prefix}/download/${id}/${query}`;
    const response = await http.get(url, {
      responseType: 'blob',
    });
    const filename =
      response.headers['content-disposition'].split('filename=')[1];
    const blob = new Blob([response.data], {
      type: 'application/octet-stream',
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename ? filename : `${new Date().getTime()}`;
    link.click();
  }

  public async getDeclarationSummaryFileMulti(ids: number[]): Promise<void> {
    let url = `${this.prefix}/download/multiple`;
    url += prepareGetQuery({ ids: ids });

    const response = await http.get(url, {
      responseType: 'blob',
    });
    const filename =
      response.headers['content-disposition'].split('filename=')[1];
    const blob = new Blob([response.data], {
      type: 'application/octet-stream',
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename ? filename : `${new Date().getTime()}`;
    link.click();
  }

  public async editDeclaration(declaration: Declaration): Promise<boolean> {
    const model = declaration;
    const form = new FormData();
    form.append('model', JSON.stringify(model));
    // Uncomment below code and refactor in case we do not use upload on select anymore
    // model.analysisFiles.forEach((analysisFile) => {
    //   if (!isFileInstanceOfInfoAsset(analysisFile)) {
    //     form.append('file', analysisFile);
    //   }
    // });

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await http.put(`${this.prefix}/${model.id}`, form, config);
    return result.data.result.data;
  }

  public async handleDeclarationProceed(
    model: DeclarationProceedDto
  ): Promise<InfoUserWidthCredential> {
    const result = await http.post(`${this.prefix}/customer/proceed`, model);
    return result.data.result;
  }

  public async shareDeclarationLink(model: ShareDeclaration): Promise<boolean> {
    const result = await http.post(`${this.prefix}/share`, model);
    return result.data.result;
  }
}

export default new DeclarationService();
