import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { FilterPrice } from '@/stores/marketplace-filter.store';
import FilterSelectionButton from './FilterSelectButton';

interface ComponentProps {
  onReturn: () => void;
  price: FilterPrice;
  onSelect?: () => void;
  searchOnChange?: boolean;
}

const FilterSelectPrice = (props: ComponentProps) => {
  const { onReturn, price, onSelect, searchOnChange } = props;

  const [minPrice, setMinPrice] = useState<number | undefined>(price.min);

  const [maxPrice, setMaxPrice] = useState<number | undefined>(price.max);

  const [selectedItems, setSelectedItems] = useState<number>(0);

  useEffect(() => {
    setSelectedItems(getSelectedItems());
  }, [maxPrice, minPrice]);

  const getSelectedItems = () => {
    let selectedItems = 0;

    if (minPrice !== undefined) {
      selectedItems++;
    }

    if (maxPrice !== undefined) {
      selectedItems++;
    }

    return selectedItems;
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextField
          label='Minimum'
          variant='filled'
          color='white'
          type={'number'}
          value={minPrice}
          onChange={(value) => {
            const val = Number(value.target.value);

            setMinPrice(val);
          }}
          placeholder='Velg minimum pris'
          sx={{ marginBottom: '16px' }}
          inputProps={{
            min: 0,
          }}
        />

        <TextField
          label='Maximum'
          variant='filled'
          color='white'
          type={'number'}
          value={maxPrice}
          onChange={(value) => {
            const val = Number(value.target.value);

            setMaxPrice(val);
          }}
          placeholder='Velg maximum pris'
          inputProps={{
            min: 0,
          }}
        />
      </Box>
      {!searchOnChange && (
        <FilterSelectionButton
          selectedItems={selectedItems}
          onReturn={onReturn}
          onSelect={function (): void {
            price.min = minPrice;
            price.max = maxPrice;

            onSelect();
          }}
        ></FilterSelectionButton>
      )}
    </>
  );
};

export default FilterSelectPrice;
