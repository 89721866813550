import associationService from '@/apis/association.service';
import { INIT_ASSOCIATION } from '@/constants/association.constants';
import { Association } from '@/dto/association/association.dto';
import { PaginationRequest } from '@/dto/commons/PaginationRequest.dto';
import { action, makeObservable, observable } from 'mobx';

class AssociationStore {
  associations: Association[];
  association: Association;
  associationCount: number;

  constructor() {
    this.associations = [];
    this.association = INIT_ASSOCIATION;
    this.associationCount = 0;

    makeObservable(this, {
      associations: observable,
      association: observable,
      associationCount: observable,
      getAssociation: action,
      getAssociations: action,
      setAssociations: action,
      setAssociation: action,
      setAssociationCount: action,
      createAssociation: action,
      editAssociation: action,
    });
  }

  async getAssociations(query?: PaginationRequest): Promise<void> {
    const data = await associationService.getAssociations(query);
    this.setAssociations(data.data);
    this.setAssociationCount(data.count);
  }

  async getAssociation(id: number): Promise<void> {
    const association = await associationService.getAssociation(id);
    this.setAssociation(association);
  }

  setAssociations(data: any[]) {
    this.associations = data;
  }

  setAssociation(data: Association): void {
    this.association = data;
  }

  setAssociationCount(count: number): void {
    this.associationCount = count;
  }

  async createAssociation(association: Association): Promise<Association> {
    const result = await associationService.createAssociation(association);
    return result;
  }

  async editAssociation(association: Association): Promise<Association> {
    const result = await associationService.editAssociation(association);
    return result;
  }
}

export default AssociationStore;
