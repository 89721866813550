import materialService from '@/apis/material.service';
import { INIT_SETTINGS_MATERIAL } from '@/constants/settings.containts';
import { PaginationRequest } from '@/dto/commons/PaginationRequest.dto';
import { SettingsMaterial } from '@/dto/settings/SettingsMaterial.dto';
import { SettingsMaterialCreate } from '@/dto/settings/SettingsMaterialCreate.dto';
import { action, makeObservable, observable } from 'mobx';

class MaterialStore {
  materials: SettingsMaterial[];
  material: SettingsMaterial;
  materialCount: number;

  constructor() {
    this.materials = [];
    this.material = INIT_SETTINGS_MATERIAL;
    this.materialCount = 0;

    makeObservable(this, {
      materials: observable,
      material: observable,
      materialCount: observable,
      createMaterial: action,
      editMaterial: action,
      getMaterial: action,
      deleteMaterial: action,
      getMaterials: action,
      setMaterials: action,
      setMaterial: action,
      setMaterialIfNoTranslation: action,
      setMaterialCount: action,
    });
  }

  async createMaterial(material: SettingsMaterialCreate): Promise<void> {
    await materialService.createMaterial(material);

    return history.back();
  }

  async editMaterial(material: SettingsMaterial): Promise<void> {
    await materialService.editMaterial(material);

    const materials = [...this.materials];
    const index = this.materials.findIndex((x) => x.id == material.id);
    materials[index] = material;
    this.setMaterials(materials);
  }

  async getMaterials(
    languageCode: string,
    query?: PaginationRequest
  ): Promise<void> {
    const data = await materialService.getMaterials(languageCode, query);
    this.setMaterials(data.data);
    this.setMaterialCount(data.count);
  }

  async getMaterial(id: number, languageCode: string): Promise<void> {
    const translatedMaterial = await materialService.getMaterial(
      id,
      languageCode
    );

    if (!translatedMaterial) {
      this.setMaterialIfNoTranslation(languageCode);
    } else {
      this.setMaterial(translatedMaterial);
    }
  }

  async deleteMaterial(id: number): Promise<void> {
    await materialService.deleteMaterial(id);
  }

  async minified(LanguageCode: string) {
    const result = await materialService.minified(LanguageCode);
    this.setMaterials(result);
  }

  setMaterialIfNoTranslation(languageCode: string): void {
    this.material.languageCode = languageCode;
    this.material.name = '';
    this.material.description = '';
  }
  setMaterials(data: any[]) {
    this.materials = data;
  }

  setMaterial(data: SettingsMaterial): void {
    this.material = data;
  }

  setMaterialCount(count: number): void {
    this.materialCount = count;
  }
}

export default MaterialStore;
