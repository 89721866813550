import dynamicFormService from '@/apis/dynamic.form.service';
import { INIT_FORM_SUPPLY_DECLARATION } from '@/constants/dynamic.form.constant';
import { InfoDynamicForm } from '@/dto/settings/InfoDynamicForm.dto';
import { makeObservable, observable } from 'mobx';

class DynamicFormStore {
  supplyDeclarationForm: InfoDynamicForm;

  constructor() {
    this.supplyDeclarationForm = INIT_FORM_SUPPLY_DECLARATION;

    makeObservable(this, {
      supplyDeclarationForm: observable,
    });
  }

  async getSupplyDeclarationForm(): Promise<void> {
    const data = await dynamicFormService.getSupplyDeclarationForm();
    this.supplyDeclarationForm = data;
  }

  async handleReset(): Promise<void> {
    await this.getSupplyDeclarationForm();
  }
}

export default DynamicFormStore;
