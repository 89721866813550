import http from '@/apis';
import { InfoAsset } from '@/dto/asset/InfoAsset.dto';
import {
  PaginationRequest,
  PaginationResult,
} from '@/dto/commons/PaginationRequest.dto';
import { CreateUpdateProject } from '@/dto/project/Project.dto';
import { SettingsMaterialApiTranslation } from '@/dto/settings/SettingsMaterial.dto';
import { ProjectMaterial } from '@/dto/project/ProjectMaterial.dto';
import { prepareGetQuery } from '@utils/api.utils';
import { LANGUAGE } from '@/enums/lang.enum';
import { TransferProjectMass } from '@dto/project/TransferProjectMass.dto';
import { ProjectTableExportDto } from '@dto/project/export.dto';
import { TransferProjectMassResult } from '@dto/project/TransferProjectMassResult.';

class ProjectService {
  prefix = 'project';
  prefixAdmin = this.prefix;

  public async getProjects(
    query?: PaginationRequest,
    languageCode?: string
  ): Promise<PaginationResult<CreateUpdateProject>> {
    const result = !!query
      ? await http.get(
          `${this.prefix}/${prepareGetQuery(query as Record<string, unknown>)}`
        )
      : await http.get(`${this.prefix}`);
    const projects = result.data.result.data;
    const projectCount = result.data.result.count;
    const formatProjects = projects.map((p) => {
      const mainMaterial = this.getMainMaterial(p, languageCode);
      const secondaryMaterial = this.getSecondaryMaterial(p, languageCode);
      return { ...p, mainMaterial, secondaryMaterial };
    });
    return { data: formatProjects, count: projectCount };
  }

  public async getProject(
    id: number,
    languageCode?: string
  ): Promise<CreateUpdateProject> {
    const result = await http.get(`${this.prefix}/${id}`);
    const project = result.data.result;
    const mainMaterial = this.getMainMaterial(project, languageCode);
    const secondaryMaterial = this.getSecondaryMaterial(project, languageCode);
    return { ...project, mainMaterial, secondaryMaterial };
  }

  public async createProject(
    model: CreateUpdateProject,
    file: File | null
  ): Promise<boolean> {
    const form = new FormData();
    form.append('model', JSON.stringify(model));

    if (file) {
      form.append('file', file);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await http.post(`${this.prefixAdmin}`, form, config);
    return result.data.result.data;
  }

  public async editProject(
    model: CreateUpdateProject,
    asset: InfoAsset | null,
    file: File | null
  ): Promise<boolean> {
    const form = new FormData();
    form.append('model', JSON.stringify(model));

    if (asset) {
      form.append('assetId', asset.id);
    }

    if (file) {
      form.append('file', file);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await http.put(
      `${this.prefixAdmin}/${model.id}`,
      form,
      config
    );

    return result.data.result.data;
  }

  public async deleteProject(id: number): Promise<boolean> {
    const result = await http.delete(`${this.prefixAdmin}/${id}`);
    return result.data.result.data;
  }

  // Minified method is used to get project list for Select instead of data table
  public async minified() {
    const result = await http.get(`${this.prefix}/minified`);
    const projects = result.data.result.data;
    return projects.sort((a: CreateUpdateProject, b: CreateUpdateProject) =>
      a.name.localeCompare(b.name)
    );
  }

  public getMainMaterial(project: CreateUpdateProject, languageCode: string) {
    const localizedProjectMaterials = this.localizeProjectMaterials(
      project.projectMaterials,
      languageCode
    );

    return (
      localizedProjectMaterials.find((pm) => pm.materialLevel === 1) ?? null
    );
  }

  public getSecondaryMaterial(
    project: CreateUpdateProject,
    languageCode: string
  ) {
    const localizedProjectMaterials = this.localizeProjectMaterials(
      project.projectMaterials,
      languageCode
    );

    return (
      localizedProjectMaterials.find((pm) => pm.materialLevel === 2) ?? null
    );
  }

  public localizeProjectMaterials(
    projectMaterials: ProjectMaterial[],
    languageCode: string
  ): ProjectMaterial[] {
    const localizedProjectMaterials = projectMaterials.map(
      (projectMaterial) => {
        return this.localizeProjectMaterial(projectMaterial, languageCode);
      }
    );

    return localizedProjectMaterials;
  }

  public localizeProjectMaterial(
    projectMaterial: ProjectMaterial,
    languageCode: string
  ): ProjectMaterial {
    let translations = projectMaterial.translations.filter(
      (translation: SettingsMaterialApiTranslation) =>
        translation.languageCode === languageCode
    );

    if (!translations.length) {
      translations = projectMaterial.translations.filter(
        (translation: SettingsMaterialApiTranslation) =>
          translation.languageCode === LANGUAGE.NORWAY
      );
    }

    return {
      materialId: projectMaterial.materialId,
      materialLevel: projectMaterial.materialLevel,
      translations: translations,
    };
  }

  public async getProjectsByCompany(
    companyId: number,
    customerId?: number,
    declarationDepotId?: number
  ) {
    const result = await http.post(`${this.prefix}/company/${companyId}`, {
      customerId,
      depotId: declarationDepotId,
    });
    return result.data.result;
  }

  public async getDFOReportOfProject(id: number): Promise<void> {
    const url = `${this.prefix}/report/dfo/${id}`;
    const response = await http.get(url, {
      responseType: 'blob',
    });
    const filename =
      response.headers['content-disposition'].split('filename=')[1];
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename ? filename : `${new Date().getTime()}`;
    link.click();
  }

  public async transferMass(
    model: TransferProjectMass
  ): Promise<TransferProjectMassResult> {
    const result = await http.post(`${this.prefixAdmin}/transfer-mass`, model);
    return result.data.result;
  }

  public async exportProjectOverviewTable(
    projectId: number,
    model: ProjectTableExportDto
  ): Promise<void> {
    const response = await http.post(
      `${this.prefixAdmin}/overview-export/${projectId}`,
      model,
      {
        responseType: 'blob',
      }
    );
    const filename =
      response.headers['content-disposition'].split('filename=')[1];
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename ? filename : `${new Date().getTime()}`;
    link.click();
  }
}

export default new ProjectService();
