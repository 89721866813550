import { Association } from '@/dto/association/association.dto';

export enum ASSOCIATION_RELATED_OBJECT {
  USER = 'USER',
  COMPANY = 'COMPANY',
}

export const INIT_ASSOCIATION: Association = {
  id: 0,
  name: '',
  description: '',
  users: [],
  projects: [],
  companies: [],
};

export const INIT_ASSOCIATION_SUB_FORM_ERROR: Record<string, boolean> = {
  name: false,
};
