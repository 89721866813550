import { I18N } from './i18n.enum';

// For declaration's packaging, might changed in the future
export enum PackagingTypes {
  GLASS = 'glass',
  ALUMINIUM = 'aluminium',
  STEEL = 'steel',
  PAPER = 'paper and paperboard',
  WOOD = 'wood',
  PLASTIC = 'plastic',
}

export enum PhysicalProperties {
  POWDER = 'Powder',
  LIQUID = 'Liquid',
  LARGE_OBJECT = 'Large object (monolithic)',
  COMPOSITE = 'Composite / heterogeneous',
  UNIFORM = 'Uniform / homogeneous',
  OTHER = 'Other',
}

export enum PreTreatmentMethods {
  SORTING_FACILITY = 'Sorting facility',
  BIOLOGICAL = 'Biological treatment',
  INCINERATION = 'Incineration',
  GRINDIND = 'Grinding',
  NONE = 'None',
  OTHER = 'Other treatment',
}

export enum WasteCategoryType {
  DANGEROUS = 'dangerous',
  GENERAL = 'general',
  INERT = 'inert',
  UNDEFINED = 'undefined',
}

export enum DeliveryType {
  SINGLE = 'single',
  EVENLY = 'evenly',
}

export enum DeclarationStatus {
  DRAFT = 'draft', // Declaration saved as draft
  SENT = 'sent', // Declaration sent to customer
  REJECTED = 'dealt', // Customer reject declaration content
  APPROVED = 'approved', // Customer approve declaration content
  PRICING = 'pricing', // Customer requesting new price
  ACCEPTED = 'accepted', // Customer accepted price
  SIGNED = 'signed', // Customer signed
  PARTIALLY_COMPLETED = 'partially_completed', // Partial completed
  FULLY_COMPLETED = 'fully_completed',
  REQUESTED = 'requested', // Customer create declaration and send back to depot
}

export const DECLARATION_DISPLAYED_STATUSES = [
  DeclarationStatus.DRAFT,
  DeclarationStatus.SENT,
  DeclarationStatus.REJECTED,
  DeclarationStatus.APPROVED,
  DeclarationStatus.PRICING,
  DeclarationStatus.ACCEPTED,
  DeclarationStatus.SIGNED,
  DeclarationStatus.FULLY_COMPLETED,
  DeclarationStatus.REQUESTED,
];

export const getDeclarationStatusText = (status: DeclarationStatus, t: any) => {
  switch (status) {
    case DeclarationStatus.DRAFT:
      return t(I18N.STATUS_DECLARATIONS_DRAFT);
    case DeclarationStatus.SENT:
      return t(I18N.STATUS_DECLARATIONS_SENT);
    case DeclarationStatus.REJECTED:
      return 'Content rejected';
    case DeclarationStatus.APPROVED:
      return 'Content approved';
    case DeclarationStatus.PRICING:
      return 'Price requested';
    case DeclarationStatus.ACCEPTED:
      return 'Price accepted';
    case DeclarationStatus.SIGNED:
      return t(I18N.STATUS_DECLARATIONS_SIGNED);
    case DeclarationStatus.PARTIALLY_COMPLETED:
    case DeclarationStatus.FULLY_COMPLETED:
      return 'Completed';
    case DeclarationStatus.REQUESTED:
      return t(I18N.STATUS_DECLARATIONS_REQUESTED);
  }
};

export enum SubmitButtonType {
  SAVE = 'save',
  SEND_TO_CUSTOMER = 'send_to_customer',
  REJECT_CONTENT = 'reject_content',
  APPROVE_CONTENT = 'approve_content',
  REQUEST_PRICE = 'request_price',
  ACCEPT_PRICE = 'accept_price',
  SIGN = 'sign',
  SEND_TO_DEPOT = 'send_to_depot',
}

export enum DeclarationProceedMethod {
  GUEST = 'guest',
  COMPANY = 'company',
}

export enum DeclarationAccessType {
  ADMIN = 'admin',
  DEPOT = 'depot',
  CUSTOMER = 'customer',
  UNAUTHORIZED_CUSTOMER = 'unauthorize_customer',
}
