import http from '@/apis';
import { PaginationRequest } from '@/dto/commons/PaginationRequest.dto';
import { prepareGetQuery } from '@utils/api.utils';
import { InfoCustomer } from '@/dto/customer/createNewCustomer.dto';

class CustomerService {
  prefix = 'customer';

  public async getCustomer(id: number) {
    const result = await http.get(`${this.prefix}/${id}`);

    return result.data.result;
  }

  public async getCustomerByRefreshToken(token: string) {
    const result = await http.get(`${this.prefix}/token/${token}`);

    return result.data.result;
  }

  public async getCustomers(query?: PaginationRequest) {
    let url = `${this.prefix}`;
    if (query) {
      url += prepareGetQuery(query as Record<string, unknown>);
    }
    const result = await http.get(url);

    return result.data.result;
  }

  public async getCustomerMinified(query?: PaginationRequest) {
    let url = `${this.prefix}/minified`;
    if (query) {
      url += prepareGetQuery(query as Record<string, unknown>);
    }
    const result = await http.get(url);

    return result.data.result.data;
  }

  public async createCustomer(customer: InfoCustomer): Promise<boolean> {
    const result = await http.post(`${this.prefix}`, customer);
    return result.data.result;
  }

  public async editCustomer(model: InfoCustomer): Promise<boolean> {
    const result = await http.put(`${this.prefix}/${model.id}`, model);
    return result.data.result.data;
  }
}

export default new CustomerService();
