import MapStore from '@/stores/map.store';
import HubStore from '@/stores/hub.store';
import TripStore from '@/stores/trip.store';
import MassStore from '@/stores/mass.store';
import UserStore from '@/stores/user.store';
import AssetStore from '@/stores/asset.store';
import ReportStore from '@/stores/report.store';
import CommonStore from '@/stores/common.store';
import ProjectStore from '@/stores/project.store';
import CompanyStore from '@/stores/company.store';
import MaterialStore from '@/stores/material.store';
import LanguageStore from '@/stores/language.store';
import TransportStore from '@/stores/transport.store';
import MapLayerStore from '@/stores/map-layer.store';
import DynamicFormStore from '@/stores/dynamic.form.store';
import Co2EmissionStore from '@/stores/co2.emission.store';
import NotificationStore from '@/stores/notification.store';
import TripFeedbackStore from '@/stores/trip-feedback.store';
import AuthenticationStore from '@/stores/authentication.store';
import {
  SupplyFilterStore,
  RequestFilterStore,
  ExchangeFilterStore,
} from '@/stores/marketplace-filter.store';
import DeclarationStore from '@/stores/declaration.store';
import CustomerStore from '@/stores/customer.store';
import ReceptionControlStore from './reception-control.store';
import AssociationStore from './association.store';
import ConversationStore from './conversation.store';

class RootStore {
  mapStore: MapStore;
  hubStore: HubStore;
  tripStore: TripStore;
  massStore: MassStore;
  userStore: UserStore;
  assetStore: AssetStore;
  reportStore: ReportStore;
  commonStore: CommonStore;
  projectStore: ProjectStore;
  companyStore: CompanyStore;
  materialStore: MaterialStore;
  languageStore: LanguageStore;
  transportStore: TransportStore;
  mapLayerStore: MapLayerStore;
  associationStore: AssociationStore;
  dynamicFormStore: DynamicFormStore;
  co2EmissionStore: Co2EmissionStore;
  notificationStore: NotificationStore;
  tripFeedbackStore: TripFeedbackStore;
  supplyFilterStore: SupplyFilterStore;
  requestFilterStore: RequestFilterStore;
  exchangeFilterStore: ExchangeFilterStore;
  authenticationStore: AuthenticationStore;
  declarationStore: DeclarationStore;
  customerStore: CustomerStore;
  receptionControlStore: ReceptionControlStore;
  conversationStore: ConversationStore;

  constructor() {
    this.mapStore = new MapStore();
    this.hubStore = new HubStore();
    this.tripStore = new TripStore();
    this.massStore = new MassStore();
    this.userStore = new UserStore();
    this.assetStore = new AssetStore();
    this.reportStore = new ReportStore();
    this.commonStore = new CommonStore();
    this.projectStore = new ProjectStore();
    this.companyStore = new CompanyStore();
    this.materialStore = new MaterialStore();
    this.languageStore = new LanguageStore();
    this.transportStore = new TransportStore();
    this.mapLayerStore = new MapLayerStore();
    this.associationStore = new AssociationStore();
    this.dynamicFormStore = new DynamicFormStore();
    this.co2EmissionStore = new Co2EmissionStore();
    this.notificationStore = new NotificationStore();
    this.tripFeedbackStore = new TripFeedbackStore();
    this.supplyFilterStore = new SupplyFilterStore();
    this.requestFilterStore = new RequestFilterStore();
    this.exchangeFilterStore = new ExchangeFilterStore();
    this.authenticationStore = new AuthenticationStore();
    this.declarationStore = new DeclarationStore();
    this.customerStore = new CustomerStore();
    this.receptionControlStore = new ReceptionControlStore();
    this.conversationStore = new ConversationStore();
  }
}

export default RootStore;
