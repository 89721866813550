import { InfoAsset } from '@/dto/asset/InfoAsset.dto';
import { InfoCustomer } from '@/dto/customer/createNewCustomer.dto';
import { DepotSpecific } from '@/dto/declaration/depot-specific.dto';
import { CreateUpdateProject } from '@/dto/project/Project.dto';
import { SettingsMaterial } from '@/dto/settings/SettingsMaterial.dto';
import { InfoUser } from '@/dto/users/InfoUser.dto';
import {
  DeclarationStatus,
  SubmitButtonType,
  WasteCategoryType,
} from '@enums/declaration.enum';

// Form
interface InitialValues {
  id?: number;
  customerContactInfo: ClientContactInfo;
  transport: Transport;
  wasteType: WasteType;
  wasteTesting: WasteTesting;
  wasteProperties: WasteProperties;
  depotSpecifics: DepotSpecifics;
  offer: Offer;
  signing: Signing;
  // Below fields are not related to the form
  status?: DeclarationStatus;
  submitButton?: SubmitButtonType;
  popupInformation?: DeclarationPopupInformation;
}

interface ClientContactInfo {
  // name: string;
  // entityType: string;
  // contactPerson: string;
  // project: string;
  customerId: number | null;
  contactPersonId: number | null;
  projectId: number | null;
  customer?: InfoCustomer;
  contactPerson?: InfoUser;
  project?: CreateUpdateProject;
}

interface Transport {
  delivery: string;
  volume: number;
  transportTypeId: number | null;
  packaging: string;
}

interface WasteType {
  depotCategory: string;
  reasonDepotCategory: string;
  ealCodes: string[];
  ealCodeIDs: string[];
  nsCodes: string[];
  nsCodeIDs: string[];
  wasteTypeId: number;
  wasteType?: Partial<SettingsMaterial>;
}

interface WasteTesting {
  analyzed: boolean;
  analysisFiles?: Array<File | InfoAsset>;
  reasonForMissingAnalysis: string;
  dangerousSubstances: boolean;
  organicCarbon: boolean;
  tocPercent: number;
  lossOnIgnitionPercent: number;
  columnLeachingTest: boolean;
  vibrationTest: boolean;
}

interface WasteProperties {
  conditionClass: string;
  physicalProperties: string;
  preTreatment: string;
  requirePreTreatment: boolean;
  odourDescription: string;
  colorDescription: string;
}

interface DepotSpecifics {
  depotSpecifics: DepotSpecific[];
  depotSpecificAnswers?: { [index: string]: any };
}

interface Offer {
  volume: number;
  unitPriceTon: number;
  price: number;
}

interface Signing {
  name: string;
  date: Date | string;
  signature: string | null;
}

///dummy data
interface Client {
  id?: number;
  name: string;
  type: string;
  email: string;
}
interface Project {
  id?: number;
  name: string;
}

interface Contact {
  id?: number;
  name: string;
  email: string;
}

interface DeclarationPopupInformation {
  customer?: {
    name: string;
  };
  contactPerson?: {
    name: string;
    email: string;
  };
  project?: {
    name: string;
  };
  contentRejectionReason?: string;
  priceRequestReason?: string;
}

// data grid
interface DeclarationsData {
  id: string;
  customer: string;
  project: string;
  status: string;
  massType: string;
  amount: string;
  signingDate: Date;
}

export type {
  InitialValues,
  Client,
  Project,
  Contact,
  DeclarationsData,
  DeclarationPopupInformation,
};

export const initialValues: InitialValues = {
  customerContactInfo: {
    customerId: null,
    contactPersonId: null,
    projectId: null,
  },
  transport: {
    delivery: 'single',
    volume: 0,
    transportTypeId: null,
    packaging: '',
  },
  wasteType: {
    depotCategory: WasteCategoryType.UNDEFINED,
    reasonDepotCategory: '',
    ealCodes: [],
    ealCodeIDs: [],
    nsCodes: [],
    nsCodeIDs: [],
    wasteTypeId: null,
  },
  wasteTesting: {
    analyzed: true,
    analysisFiles: undefined,
    reasonForMissingAnalysis: '',
    dangerousSubstances: false,
    organicCarbon: false,
    tocPercent: 0,
    lossOnIgnitionPercent: 0,
    columnLeachingTest: false,
    vibrationTest: false,
  },
  wasteProperties: {
    conditionClass: '1',
    physicalProperties: '',
    preTreatment: '',
    requirePreTreatment: false,
    odourDescription: '',
    colorDescription: '',
  },
  depotSpecifics: {
    depotSpecifics: [],
    depotSpecificAnswers: {},
  },
  offer: {
    price: 0,
    unitPriceTon: 0,
    volume: 0,
  },
  signing: {
    name: '',
    date: null,
    signature: null,
  },
  popupInformation: {
    contentRejectionReason: '',
    priceRequestReason: '',
  },
  status: undefined,
  submitButton: undefined,
};
