import { Checkbox, Divider, FormControlLabel } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import FilterSelectionButton from './FilterSelectButton';

interface ComponentProps {
  onReturn: () => void;
  selectedMaterials: Set<string>;
  materials: Set<string>;
  onUpdateCount?: () => void;
  onSelect?: () => void;
  searchOnChange?: boolean;
}

const FilterSelectMaterial = (props: ComponentProps) => {
  const { onReturn, selectedMaterials, materials, onSelect, searchOnChange } =
    props;

  const [selected, setSelected] = useState<Set<string>>(
    new Set(selectedMaterials)
  );

  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const onChangeSelectedMaterial = (checked: boolean, material: string) => {
    const newSelected = selected;

    if (checked) {
      newSelected.add(material);
    } else {
      newSelected.delete(material);
    }
    setSelected(newSelected);

    if (searchOnChange) {
      selectedMaterials.clear();
      newSelected.forEach((material) => {
        selectedMaterials.add(material);
      });
      onSelect();
    }
    forceUpdate();
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          px: 2,
        }}
      >
        {Array.from(materials).map((material) => (
          <Box key={material}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    '&.MuiCheckbox-root': {
                      color: '#fff',
                    },
                  }}
                  checked={selected.has(material)}
                  onChange={(_, checked) => {
                    onChangeSelectedMaterial(checked, material);
                  }}
                />
              }
              sx={{
                '&.MuiFormControlLabel-root': {
                  color: '#fff',
                },
              }}
              label={material}
            />
            <Divider />
          </Box>
        ))}
        {!searchOnChange && (
          <FilterSelectionButton
            selectedItems={selected.size}
            onReturn={onReturn}
            onSelect={() => {
              selectedMaterials.clear();
              selected.forEach((material) => {
                selectedMaterials.add(material);
              });
              onSelect();
            }}
          ></FilterSelectionButton>
        )}
      </Box>
    </>
  );
};

export default FilterSelectMaterial;
