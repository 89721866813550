import {
  Autocomplete,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { MapOutlined, MyLocation } from '@mui/icons-material';
import { debounce } from '@utils/debounce';
import { toast } from 'react-toastify';
import ChooseLocation from '@components/commons/Map/ChooseLocation';
import { useTranslation } from 'react-i18next';
import { I18N } from '@/enums/i18n.enum';
import React, { SyntheticEvent } from 'react';
import { SxProps } from '@mui/system';
import { INIT_LOCATION } from '@/constants/location.constants';
import { toLocationEntity } from '@utils/location.utils';
import { OverridableStringUnion } from '@mui/types';
import { TextFieldPropsColorOverrides } from '@mui/material/TextField/TextField';
import { isNorway } from '@utils/api.utils';
interface ComponentProps {
  onChange: (value: any) => void;
  defaultOption?: any;
  defaultLocation?: [];
  label: string;
  required?: boolean;
  sx?: SxProps<Theme>;
  strictSearch?: boolean;
  readOnly?: boolean;
  hasError?: boolean;
  errorText?: string;
  helperText?: string;
  color?: OverridableStringUnion<
    'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    TextFieldPropsColorOverrides
  >;
  name?: string;
}
const AddressAutocomplete = (props: ComponentProps) => {
  const {
    onChange,
    defaultOption,
    defaultLocation,
    label,
    required,
    sx,
    strictSearch = true,
    readOnly,
    hasError,
    errorText,
    helperText,
    color,
  } = props;

  const [options, setOptions] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [inputValue, setInputValue] = React.useState<string>('');
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState<any | null>(null);
  const [showPickLocation, setShowPickLocation] =
    React.useState<boolean>(false);

  const { t } = useTranslation();
  const {
    MAP_GEOLOCATION_NOT_FOUND,
    MAP_GEOLOCATION_PERMISSION,
    MAP_FIND_MY_LOCATION,
    MAP_SELECT_LOCATION,
    MAP_SELECT_LOCATION_NOT_FOUND,
  } = I18N;
  const [isShowingHelperText, setIsShowingHelperText] = React.useState<boolean>(
    !!(helperText || errorText)
  );
  const [computedHelperText, setComputedHelperText] = React.useState<string>(
    helperText || ''
  );

  const country = isNorway() ? 'no' : 'is';
  const resetAutoComplete = () => {
    setOptions([]);
    setInputValue('');
    setValue(null);
    setLoading(false);
    setOpen(false);
    onChange(INIT_LOCATION);
  };

  const updateValues = (options: any) => {
    setOptions(options.features);
    setInputValue(options.features[0].place_name);
    setValue(options.features[0]);
    callBackHandler(options.features[0]);
    setLoading(false);
    setOpen(false);
  };

  const getGeoLocationApi = async (value: string) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?country=${country}${
          strictSearch ? '&types=address' : ''
        }&language=en,is&access_token=${process.env.REACT_APP_MAPBOX_TOKEN} `
      );
      return await response.json();
    } catch (err: any) {
      console.log(err);
    }
  };

  const onChangeHandle = async (value: any) => {
    setLoading(true);
    const locations = await getGeoLocationApi(value);

    if (locations.features && locations.features.length > 0) {
      setOptions(
        Object.keys(locations.features).map((key) => locations.features[key])
      );
      setLoading(false);
    } else {
      // setOptions([]);
      setLoading(false);
    }
  };

  const debounceOnChange = React.useCallback(debounce(onChangeHandle, 300), []);

  const mapboxContextSplitStr = (contextId: any) => {
    return contextId.split('.', 1).toString();
  };

  const callBackHandler = (value: any) => {
    let filteredObject: any;

    const allowed = ['place_name', 'place_name_is', 'geometry'];
    filteredObject = Object.fromEntries(allowed.map((k) => [k, value[k]]));

    const postcode = value.context.find(
      (e: any) => mapboxContextSplitStr(e.id) === 'postcode'
    );
    const city = value.context.find(
      (e: any) => mapboxContextSplitStr(e.id) === 'place'
    );
    const region = value.context.find(
      (e: any) => mapboxContextSplitStr(e.id) === 'region'
    );

    filteredObject = {
      ...filteredObject,
      mapBoxId: value.id,
      postcode: postcode.text ?? undefined,
      city: city.text_is ?? undefined,
      region: region?.text_is ?? undefined,
    };
    onChange(toLocationEntity(filteredObject));
  };

  const getGeoLocation = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (currentLocation) => {
          const data = await getGeoLocationApi(
            `${currentLocation.coords.longitude},${currentLocation.coords.latitude}`
          );
          if (data.features.length > 0) {
            updateValues(data);
          } else {
            resetAutoComplete();
            setError(t(MAP_GEOLOCATION_NOT_FOUND));
          }
        },
        () => {
          toast.error(t(MAP_GEOLOCATION_PERMISSION), {
            autoClose: 5000,
          });
          setLoading(false);
        }
      );
    }
  };

  React.useEffect(() => {
    if (defaultOption) {
      setOptions([defaultOption]);
      setInputValue(defaultOption.place_name);
      setValue(defaultOption);
    } else {
      resetAutoComplete();
    }
  }, [defaultOption]);

  React.useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error]);

  React.useEffect(() => {
    setComputedHelperText(hasError ? errorText || '' : helperText || '');
  }, [hasError, errorText, helperText]);

  // React.useEffect(() => {
  //   console.log('onChange Options', options);
  // }, [options]);
  React.useEffect(() => {
    setIsShowingHelperText(!!(helperText || errorText));
  }, [helperText, errorText]);

  return (
    <>
      <Autocomplete
        sx={sx}
        // freeSolo
        fullWidth
        disableClearable
        autoHighlight
        clearOnEscape
        // openOnFocus
        filterOptions={(x: any) => x}
        value={value}
        inputValue={inputValue}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setShowPickLocation(false);
          if (inputValue.length > 2 && options.length > 0) {
            setOpen(true);
          }
        }}
        onChange={(e: SyntheticEvent, value: any) => {
          if (value) {
            setValue(value);
            callBackHandler(value);
            setOpen(false);
          }
        }}
        onInputChange={(event: any, newInputValue) => {
          if (event?.target?.value !== '' || event?.target?.value.length > 1) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            debounceOnChange(event?.target?.value);
            setOpen(true);
          }
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option: any) => option.place_name}
        options={options}
        loading={loading}
        isOptionEqualToValue={(option, value) =>
          option.place_name === value.place_name
        }
        renderInput={(params: any) => {
          return (
            <TextField
              name={name}
              helperText={
                isShowingHelperText && computedHelperText ? (
                  <>{computedHelperText}</>
                ) : null
              }
              error={hasError}
              {...params}
              label={label}
              required={required ?? false}
              variant='filled'
              color={color ?? null}
              // sx={sx}
              InputProps={{
                ...params.InputProps,
                readOnly: readOnly ?? false,
                endAdornment: (
                  <Stack
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='center'
                    sx={{
                      mt: -2,
                    }}
                  >
                    {loading ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : null}
                    <IconButton
                      onClick={() => {
                        getGeoLocation();
                        setOpen(false);
                      }}
                      sx={{
                        color: (theme) => theme.palette.text.medium,
                      }}
                      aria-label={t(MAP_FIND_MY_LOCATION)}
                      component='span'
                      disabled={readOnly ?? false}
                    >
                      <MyLocation />
                    </IconButton>
                    <Divider
                      sx={{ m: (theme) => theme.spacing(0, 1) }}
                      orientation='vertical'
                      variant='middle'
                      flexItem
                    />
                    <IconButton
                      onClick={() => {
                        setShowPickLocation(!showPickLocation);
                        setOpen(false);
                      }}
                      sx={{
                        color: (theme) => theme.palette.text.medium,
                      }}
                      aria-label={t(MAP_SELECT_LOCATION)}
                      component='span'
                      disabled={readOnly ?? false}
                    >
                      <MapOutlined />
                    </IconButton>
                    {params.InputProps.endAdornment}
                  </Stack>
                ),
              }}
            />
          );
        }}
      />
      {error ? (
        <Typography mt={1} variant='body2' color='error'>
          {error}
        </Typography>
      ) : null}
      <ChooseLocation
        open={showPickLocation}
        defaultLocation={defaultLocation}
        locationCallback={async (cb) => {
          const locations = await getGeoLocationApi(`${cb[0]},${cb[1]}`);
          if (locations.features.length > 0) {
            updateValues(locations);
          } else {
            resetAutoComplete();
            setError(t(MAP_SELECT_LOCATION_NOT_FOUND));
          }
          setShowPickLocation(false);
        }}
      />
    </>
  );
};

export default AddressAutocomplete;
