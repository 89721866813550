import http from '@/apis';
import {
  CreateSaveTemplate,
  SaveTemplate,
  SaveTemplateVersion,
  UpdateSaveTemplate,
} from '@/dto/declaration/save-template.dto';

class SaveTemplateService {
  prefix = 'save-template';

  public async getSaveTemplates(depotId: number): Promise<SaveTemplate[]> {
    const result = await http.get(`${this.prefix}/depots/${depotId}`);
    return result.data.result;
  }

  public async createSaveTemplate(
    model: CreateSaveTemplate
  ): Promise<SaveTemplate> {
    const result = await http.post(`${this.prefix}`, model);
    return result.data.result;
  }

  public async updateSaveTemplate(
    templateId: number,
    model: UpdateSaveTemplate
  ) {
    const result = await http.put(`${this.prefix}/${templateId}`, model);
    return result.data.result;
  }

  public async getSaveTemplateVersions(
    templateId: number
  ): Promise<SaveTemplateVersion[]> {
    const result = await http.get(`${this.prefix}/${templateId}/versions`);
    return result.data.result;
  }

  public async createSaveTemplateVersion(
    model: SaveTemplateVersion
  ): Promise<SaveTemplateVersion> {
    const result = await http.post(
      `${this.prefix}/${model.templateId}/versions`,
      model
    );
    return result.data.result;
  }
}

export default new SaveTemplateService();
