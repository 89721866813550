import tripFeedbackService from '@/apis/trip-feedback.service';
import { InfoAsset } from '@/dto/asset/InfoAsset.dto';
import { CreateUpdateTripFeedback } from '@/dto/trip-feedback/CreateUpdateTripFeedback.dto';
import { InfoTripFeedback } from '@/dto/trip-feedback/InfoTripFeedback.dto';

class TripFeedbackStore {
  async createTripFeedback(
    model: CreateUpdateTripFeedback,
    file: File | null
  ): Promise<boolean> {
    return await tripFeedbackService.createTripFeedback(model, file);
  }

  async updateTripFeedback(
    id: number,
    model: CreateUpdateTripFeedback,
    asset: InfoAsset | null,
    file: File | null
  ): Promise<boolean> {
    return await tripFeedbackService.updateTripFeedback(id, model, asset, file);
  }

  async getTripFeedback(tripId: number): Promise<InfoTripFeedback> {
    return await tripFeedbackService.getTripFeedback(tripId);
  }
}

export default TripFeedbackStore;
