import { action, makeObservable, observable } from 'mobx';

class MapLayerStore {
  isShowChooseLayer: boolean;
  gravelAndHardRockLayers: string[];
  contaminationLayers: string[];
  mineralExtractionLayers: string[];

  constructor() {
    this.isShowChooseLayer = false;
    this.gravelAndHardRockLayers = [];
    this.contaminationLayers = [];
    this.mineralExtractionLayers = [];

    makeObservable(this, {
      isShowChooseLayer: observable,
      gravelAndHardRockLayers: observable,
      contaminationLayers: observable,
      mineralExtractionLayers: observable,
      setGravelAndHardRockLayers: action,
      setContaminationLayers: action,
      setMineralExtractionLayers: action,
      removeGravelAndHardRockLayers: action,
      removeContaminationLayers: action,
      removeMineralExtractionLayers: action,
      clearAllLayers: action,
    });
  }

  setIsShowChooseLayer(isShowChooseLayer: boolean): void {
    this.isShowChooseLayer = isShowChooseLayer;
  }

  setGravelAndHardRockLayers(layerKeys: string[]): void {
    this.gravelAndHardRockLayers = layerKeys;
  }

  removeGravelAndHardRockLayers(): void {
    this.gravelAndHardRockLayers = [];
  }

  setContaminationLayers(layerKeys: string[]): void {
    this.contaminationLayers = layerKeys;
  }

  removeContaminationLayers(): void {
    this.contaminationLayers = [];
  }

  setMineralExtractionLayers(layerKeys: string[]): void {
    this.mineralExtractionLayers = layerKeys;
  }

  removeMineralExtractionLayers(): void {
    this.mineralExtractionLayers = [];
  }

  clearAllLayers(): void {
    this.removeGravelAndHardRockLayers();
    this.removeContaminationLayers();
    this.removeMineralExtractionLayers();
  }
}

export default MapLayerStore;
