import {
  CreateInfoCompany,
  InfoCompany,
} from '@/dto/companies/InfoCompany.dto';
import http from '@/apis';
import { CompanyAsset } from '@/dto/companies/CompanyAssets.dto';
import {
  PaginationRequest,
  PaginationResult,
} from '@dto/commons/PaginationRequest.dto';
import { prepareGetQuery } from '@utils/api.utils';

class CompanyService {
  prefix = 'companies';

  public async getAllCompanies(
    query?: PaginationRequest
  ): Promise<PaginationResult<InfoCompany>> {
    let url = this.prefix;
    if (query) {
      url += prepareGetQuery(query as Record<string, unknown>);
    }

    const result = await http.get(url);
    const companies = result.data.result.data;
    const formatCompany = companies.map((company) => {
      const assetLogo = this.getAssetLogo(company.companyAssets)[0]?.asset;
      const assetImage = this.getAssetImage(company.companyAssets)[0]?.asset;
      return { ...company, assetLogo, assetImage };
    });
    return { data: formatCompany, count: result.data.result.count };
  }

  public async getCompanyById(Id: number): Promise<InfoCompany> {
    const result = await http.get(`${this.prefix}/${Id}`);
    return result.data.result;
  }

  public async updateCompanyById(
    id: number,
    company: InfoCompany,
    imageFile?: File | null,
    logoFile?: File | null
  ): Promise<boolean> {
    const form = new FormData();

    form.append('model', JSON.stringify(company));

    if (imageFile) {
      form.append('image', imageFile);
    }

    if (logoFile) {
      form.append('logo', logoFile);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await http.put(`${this.prefix}/${id}`, form, config);
    return result.data.result;
  }

  public async createCompany(
    company: CreateInfoCompany,
    imageFile?: File | null,
    logoFile?: File | null
  ): Promise<boolean> {
    const form = new FormData();
    form.append('model', JSON.stringify(company));

    if (imageFile) {
      form.append('image', imageFile);
    }

    if (logoFile) {
      form.append('logo', logoFile);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await http.post(`${this.prefix}`, form, config);
    return result.data.result;
  }

  public async updateOwnerId(companyId: number, ownerId: number) {
    const company = await this.getCompanyById(companyId);
    company.ownerId = ownerId;
    await this.updateCompanyById(companyId, company);
  }

  public async lookupCompanyByOrgNumber(orgNumber: string): Promise<any> {
    const result = await http.get(`${this.prefix}/brr/${orgNumber}`);
    return result.data.result;
  }

  public getAssetLogo(companyAssets: CompanyAsset[]) {
    return companyAssets.filter(
      (companyAsset) => companyAsset.assetType === 'COMPANY_LOGO'
    );
  }

  public getAssetImage(companyAssets: CompanyAsset[]) {
    return companyAssets.filter(
      (companyAsset) => companyAsset.assetType === 'COMPANY_IMAGE'
    );
  }
}

export default new CompanyService();
