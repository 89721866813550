import http from '@/apis';
import { Association } from '@/dto/association/association.dto';
import {
  PaginationRequest,
  PaginationResult,
} from '@/dto/commons/PaginationRequest.dto';
import { prepareGetQuery } from '@utils/api.utils';

class AssociationService {
  prefix = 'association';
  prefixAdmin = 'admin/' + this.prefix;

  public async getAssociations(
    query: PaginationRequest
  ): Promise<PaginationResult<Association[]>> {
    const result = !!query
      ? await http.get(
          `${this.prefix}/${prepareGetQuery(query as Record<string, unknown>)}`
        )
      : await http.get(`${this.prefix}`);
    const associations = result.data.result.data;
    const associationCount = result.data.result.count;
    return { data: associations, count: associationCount };
  }

  public async getAssociation(id: number): Promise<Association | null> {
    const result = await http.get(`${this.prefix}/${id}`);
    return result.data.result;
  }

  public async createAssociation(model: Association): Promise<Association> {
    const result = await http.post(`${this.prefix}`, model);
    return result.data.result;
  }

  public async editAssociation(model: Association): Promise<Association> {
    const result = await http.put(`${this.prefix}/${model.id}`, model);
    return result.data.result.data;
  }
}

export default new AssociationService();
