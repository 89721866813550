import * as fs from 'fs';
import * as path from 'path';
import parse from 'csv-parse';

interface VehicleData {
  VehicleCat: string;
  Year: number;
  TrafficScenario: string;
  Component: string;
  RoadCat: string;
  TrafficSit: string;
  TrafficSit_1: number;
  Trafikk: string;
  Gradient: number;
  IDSubsegment: number;
  Subsegment: string;
  Technology: string;
  SizeClass: string;
  Type: string;
  VekILasse: string;
  VektFra: number;
  VektMax: number;
  EmConcept: string;
  EURO: string;
  KM: number;
  OfSubsegment: number;
  V: number;
  V_0: number;
  V_100: number;
  EFA: number;
  EFA_0: number;
  EFA_pr_KG_load: number;
  EFA_100: number;
  V_weighted: number;
  V_weighted_0: number;
  V_weighted_100: number;
  EFA_weighted: number;
  EFA_weighted_0: number;
  EFA_weighted_100: number;
}

export function parseVehicleData(data: string): VehicleData[] {
  console.log(data);
  const rows = data.split('\\');
  const objects: VehicleData[] = [];

  rows.forEach((row) => {
    if (row.trim() !== '') {
      const values = row.split('\t');
      // Make sure that the first value of the row is "HGV"
      if (values[0] === 'HGV') {
        const object: VehicleData = {
          VehicleCat: values[0],
          Year: +values[1],
          TrafficScenario: values[2],
          Component: values[3],
          RoadCat: values[4],
          TrafficSit: values[5],
          TrafficSit_1: +values[6],
          Trafikk: values[7],
          Gradient: +values[8],
          IDSubsegment: +values[9],
          Subsegment: values[10],
          Technology: values[11],
          SizeClass: values[12],
          Type: values[13],
          VekILasse: values[14],
          VektFra: +values[15],
          VektMax: +values[16],
          EmConcept: values[17],
          EURO: values[18],
          KM: +values[19],
          OfSubsegment: +values[20],
          V: +values[21],
          V_0: +values[22],
          V_100: +values[23],
          EFA: +values[24],
          EFA_0: +values[25],
          EFA_pr_KG_load: +values[26],
          EFA_100: +values[27],
          V_weighted: +values[28],
          V_weighted_0: +values[29],
          V_weighted_100: +values[30],
          EFA_weighted: +values[31],
          EFA_weighted_0: +values[32],
          EFA_weighted_100: +values[33],
        };
        objects.push(object);
      }
    }
  });
  return objects;
}

export function searchVehicleData(
  speedLimit: number,
  trafikk: string,
  gradient: number,
  vehicleDataList: VehicleData[],
  euroClass?: string
): VehicleData[] {
  const result: VehicleData[] = [];
  for (let i = 0; i < vehicleDataList.length; i++) {
    const vehicle = vehicleDataList[i];

    if (
      vehicle.TrafficSit_1 === speedLimit &&
      vehicle.Trafikk === trafikk &&
      vehicle.Gradient === gradient
    ) {
      if (euroClass !== undefined) {
        if (vehicle.EURO === euroClass) result.push(vehicle);
      } else result.push(vehicle);
    }
  }
  return result;
}

export function computeCO2EmissionsPerKm(
  vehicleDataList: VehicleData[],
  vehicleWeight: number
): number {
  let EFA_0 = 0;
  let EFA_pr_KG_load = 0;

  let emissions = 0;
  for (let i = 0; i < vehicleDataList.length; i++) {
    EFA_0 += vehicleDataList[i].EFA_0;
    EFA_pr_KG_load += vehicleDataList[i].EFA_pr_KG_load;
  }

  EFA_0 = EFA_0 / vehicleDataList.length;
  EFA_pr_KG_load = EFA_pr_KG_load / vehicleDataList.length;

  emissions = EFA_0 + EFA_pr_KG_load * vehicleWeight;

  return emissions;
}

export const roundToClosestValidValue = (value: number): number => {
  const validValues = [-0.06, -0.04, -0.02, 0, 0.02, 0.04, 0.06];
  let closestValue = validValues[0];
  validValues.forEach((v) => {
    if (Math.abs(value - v) < Math.abs(value - closestValue)) {
      closestValue = v;
    }
  });
  return closestValue;
};
