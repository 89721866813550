import projectService from '@/apis/project.service';
import { INIT_PROJECT } from '@/constants/project.constants';
import { InfoAsset } from '@/dto/asset/InfoAsset.dto';
import { PaginationRequest } from '@/dto/commons/PaginationRequest.dto';
import { CreateUpdateProject } from '@/dto/project/Project.dto';
import { TransferProjectMass } from '@dto/project/TransferProjectMass.dto';
import { TransferProjectMassResult } from '@dto/project/TransferProjectMassResult.';
import { MASS_TYPE } from '@enums/mass.enum';
import { ProjectMassType } from '@enums/project.enum';
import { action, makeObservable, observable } from 'mobx';

class ProjectStore {
  projects: CreateUpdateProject[];
  projectForm: CreateUpdateProject;
  projectCount: number;

  constructor() {
    this.projects = [];
    this.projectForm = INIT_PROJECT;
    this.projectCount = 0;

    makeObservable(this, {
      projects: observable,
      projectForm: observable,
      projectCount: observable,
      createProject: action,
      editProject: action,
      getProject: action,
      deleteProject: action,
      getProjects: action,
      setProjects: action,
      setProject: action,
      handleReset: action,
      setEmptyProjectForm: action,
      transferMass: action,
    });
  }

  async createProject(
    project: CreateUpdateProject,
    file: File | null
  ): Promise<void> {
    await projectService.createProject(project, file);
  }

  async editProject(
    project: CreateUpdateProject,
    asset: InfoAsset | null,
    file: File | null
  ): Promise<void> {
    const { massSupplies, massRequests, ...plainProject } = project;
    await projectService.editProject(plainProject, asset, file);
    const projects = [...this.projects];
    const index = this.projects.findIndex((x) => x.id == project.id);
    projects[index] = plainProject;
    this.projects = projects;
  }

  async getProjects(
    query?: PaginationRequest,
    languageCode?: string
  ): Promise<void> {
    const data = await projectService.getProjects(query, languageCode);
    this.projects = data.data;
    this.projectCount = data.count;
  }

  async getProject(id: number, languageCode?: string): Promise<void> {
    const data = await projectService.getProject(id, languageCode);
    this.projectForm = data;
  }

  async deleteProject(id: number): Promise<void> {
    await projectService.deleteProject(id);
  }

  async handleReset(id: number): Promise<void> {
    await this.getProject(id);
  }

  setEmptyProjectForm(): void {
    this.projectForm = INIT_PROJECT;
  }

  async minified() {
    const result = await projectService.minified();
    this.setProjects(result);
  }

  async getMinified() {
    const result = await projectService.minified();
    return result;
  }

  public async transferMass(
    model: TransferProjectMass
  ): Promise<TransferProjectMassResult> {
    const result = await projectService.transferMass(model);
    // Set project volume shown in data table
    const projects = [...this.projects];
    const fromProjectIndex = this.projects.findIndex(
      (project) => project.id == model.projectId
    );

    // Minus transferred volume from current volume
    const fromMassIndex =
      model.massType === MASS_TYPE.SUPPLY
        ? projects[fromProjectIndex].massSupplies.findIndex(
            (ms) => ms.id === model.massId
          )
        : projects[fromProjectIndex].massRequests.findIndex(
            (ms) => ms.id === model.massId
          );

    if (model.massType === MASS_TYPE.SUPPLY) {
      projects[fromProjectIndex].massSupplies[fromMassIndex] = {
        ...projects[fromProjectIndex].massSupplies[fromMassIndex],
        volume:
          projects[fromProjectIndex].massSupplies[fromMassIndex].volume -
          Number(model.volume),
      };
    } else {
      projects[fromProjectIndex].massRequests[fromMassIndex] = {
        ...projects[fromProjectIndex].massRequests[fromMassIndex],
        volume:
          projects[fromProjectIndex].massRequests[fromMassIndex].volume -
          Number(model.volume),
      };
    }

    // Add volume to destinated project / storage if it is in data table
    if (
      [
        ProjectMassType.INTERNAL_PROJECT,
        ProjectMassType.INTERNAL_PROJECT,
      ].includes(model.transferType)
    ) {
      const toProjectIndex = this.projects.findIndex(
        (project) => project.id == model.destinationProjectId
      );
      if (!!result.newMassSupply) {
        projects[toProjectIndex].massSupplies = [
          ...projects[toProjectIndex].massSupplies,
          result.newMassSupply,
        ];
      }
      if (!!result.newMassRequest) {
        projects[toProjectIndex].massRequests = [
          ...projects[toProjectIndex].massRequests,
          result.newMassRequest,
        ];
      }
    }

    return result;
  }

  setProjects(data: any[]) {
    this.projects = data;
  }

  setProject(data: CreateUpdateProject) {
    this.projectForm = data;
  }
}

export default ProjectStore;
