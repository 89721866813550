import { LANGUAGE } from '@/enums/lang.enum';
import { SettingsMaterialApiTranslation } from '@/dto/settings/SettingsMaterial.dto';
import { SettingsHubTranslationsApi } from '@/dto/settings/SettingsHub.dto';
import { retrieveFromStorage } from './storage.util';

export const getLanguageCurrent = (): LANGUAGE => {
  const lang = (retrieveFromStorage('lang') ?? LANGUAGE.NORWAY) as LANGUAGE;
  return lang;
};

export const filterByLanguage = (
  translations: SettingsTranslationApi[]
): SettingsTranslationApi[] => {
  const currentLang = getLanguageCurrent();
  const languageCode = translations
    .map((t) => t.languageCode)
    .includes(currentLang)
    ? currentLang
    : LANGUAGE.NORWAY;
  return translations.filter(
    (translation: SettingsTranslationApi) =>
      translation.languageCode === languageCode
  );
};

type SettingsTranslationApi =
  | SettingsHubTranslationsApi
  | SettingsMaterialApiTranslation;
