import http from '@/apis';
import {
  PaginationRequest,
  PaginationResult,
} from '@/dto/commons/PaginationRequest.dto';
import { HubPaginationRequest } from '@/dto/hub/HubPaginationRequest.dto';
import {
  SettingsHub,
  SettingsHubTranslationsApi,
  SettingsHubApi,
} from '@/dto/settings/SettingsHub.dto';
import { SettingsHubCreate } from '@/dto/settings/SettingsHubCreate.dto';
import { LANGUAGE } from '@/enums/lang.enum';
import i18n from '@/i18n';
import { prepareGetQuery } from '@utils/api.utils';
import { getLanguageCurrent } from '@utils/language.utils';

class HubService {
  prefix = 'hubs';
  prefixAdmin = 'admin/' + this.prefix;

  public async getHubs(
    query?: HubPaginationRequest
  ): Promise<PaginationResult<SettingsHub>> {
    let url = this.prefix;
    if (query) {
      url += prepareGetQuery(query as Record<string, unknown>);
    }

    const result = await http.get(url);
    const hubs = result.data.result.data;
    const translations: SettingsHub[] = hubs.map((hub: SettingsHubApi) => {
      const translation = hub.translations.filter(
        (translation: SettingsHubTranslationsApi) =>
          translation.languageCode === 'no'
      )[0];

      return {
        ...hub,
        name: translation.name,
        description: translation.description,
      };
    });

    return { data: translations, count: result.data.result.count };
  }

  public async getHub(id: number): Promise<SettingsHub | null> {
    const result = await http.get(`${this.prefix}/${id}`);
    const hub: SettingsHubApi = result.data.result;
    const translation = hub.translations.filter(
      (translation: SettingsHubTranslationsApi) =>
        translation.languageCode === 'no'
    )[0];

    return {
      ...hub,
      description: translation.description,
      name: translation.name,
      languageCode: translation.languageCode,
    };
  }

  public async createHub(
    model: SettingsHubCreate,
    logoImage: File | null,
    profileImage: File | null
  ): Promise<boolean> {
    const form = new FormData();
    form.append('model', JSON.stringify(model));

    if (logoImage) {
      form.append('logo', logoImage);
    }

    if (profileImage) {
      form.append('image', profileImage);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await http.post(`${this.prefixAdmin}`, form, config);
    return result.data.result.data;
  }

  public async editHub(
    model: SettingsHub,
    logoImage: File | null,
    profileImage: File | null
  ): Promise<boolean> {
    const form = new FormData();
    form.append('model', JSON.stringify(model));

    if (logoImage) {
      form.append('logo', logoImage);
    }

    if (profileImage) {
      form.append('image', profileImage);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await http.put(
      `${this.prefixAdmin}/${model.id}`,
      form,
      config
    );
    return result.data.result.data;
  }

  public async deleteHub(id: number): Promise<boolean> {
    const result = await http.delete(`${this.prefixAdmin}/${id}`);
    return result.data.result.data;
  }

  public async minified(query?: PaginationRequest): Promise<SettingsHub[]> {
    let url = `${this.prefix}/minified`;
    if (query) {
      url += prepareGetQuery(query as Record<string, unknown>);
    }

    const result = await http.get(url);
    const hubs = result.data.result.data;
    const languageCode = getLanguageCurrent();

    const localizedHubs: SettingsHub[] = hubs.map((hub: SettingsHubApi) => {
      const translation = hub.translations.filter(
        (translation: SettingsHubTranslationsApi) =>
          translation.languageCode === languageCode ||
          translation.languageCode === LANGUAGE.NORWAY
      )[0];

      return {
        ...hub,
        name: translation.name,
        description: translation.description,
        languageCode: translation.languageCode,
      };
    });

    return localizedHubs.sort((a: SettingsHub, b: SettingsHub) =>
      a.name.localeCompare(b.name)
    );
  }
}

export default new HubService();
