import mapboxgl from 'mapbox-gl';

const createCustomIcon = (
  icon: string,
  iconName: string,
  map: mapboxgl.Map
) => {
  const depotImage = new Image(40, 40);
  depotImage.onload = () => {
    map.addImage(iconName, depotImage);
  };
  depotImage.src = icon;
};

export default createCustomIcon;
