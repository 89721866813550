import { Location } from '@/dto/locations/Location.dto';
import { LOCATION_TYPE } from '@/enums/location.enum';

export const INIT_LOCATION: Location = {
  mapBoxId: '',
  place_name: '',
  place_name_is: '',
  city: '',
  postcode: 0,
  longitude: 0,
  latitude: 0,
  type: LOCATION_TYPE.POINT,
  region: '',
};
