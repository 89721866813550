import AppSkeleton from '@components/commons/AppSkeleton';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import './App.css';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import { theme as MolunderTheme } from './themes/MolunderTheme';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/300-italic.css';
import '@fontsource/open-sans/400-italic.css';
import '@fontsource/open-sans/500-italic.css';
import '@fontsource/open-sans/600-italic.css';
import '@fontsource/open-sans/700-italic.css';
import '@fontsource/raleway/300.css';
import '@fontsource/raleway/400.css';
import '@fontsource/raleway/500.css';
import '@fontsource/raleway/600.css';
import '@fontsource/raleway/700.css';
import '@fontsource/raleway/300-italic.css';
import '@fontsource/raleway/400-italic.css';
import '@fontsource/raleway/500-italic.css';
import '@fontsource/raleway/600-italic.css';
import '@fontsource/raleway/700-italic.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@/i18n';
import ServiceWorkerWrapper from '@components/commons/ServiceWorkerWrapper/serviceWorkerWrapper';
import { InstallPWA } from '@components/commons/InstallPwa';
import { RootStoreProvider } from './RootStoreProvider';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={MolunderTheme}>
      <React.Suspense fallback={<AppSkeleton />}>
        <BrowserRouter>
          <ServiceWorkerWrapper />
          <InstallPWA />
          <RootStoreProvider>
            <App />
          </RootStoreProvider>
          <ToastContainer
            position='top-center'
            autoClose={2000}
            hideProgressBar
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            theme={'colored'}
          />
        </BrowserRouter>
      </React.Suspense>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     const waitingServiceWorker = registration.waiting;
//
//     if (waitingServiceWorker) {
//       waitingServiceWorker.addEventListener('statechange', (event: any) => {
//         if (event.target?.state === 'activated') {
//           window.location.reload();
//         }
//       });
//       waitingServiceWorker.postMessage({
//         type: 'SKIP_WAITING',
//       });
//     }
//   },
// });

window['COMMIT_HASH'] = process.env.GIT_COMMIT || 'GIT_COMMIT';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
