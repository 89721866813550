export enum MAP_TYPE {
  SUPPLY = 'SUPPLY',
  REQUEST = 'REQUEST',
  EXCHANGE = 'EXCHANGE',
  HUB = 'HUB',
  HUB_CUSTOM = 'HUB_CUSTOM',
}

export enum MAP_LAYER_FILTER {
  SUPPLY = 'SUPPLY',
  REQUEST = 'REQUEST',
  HUB_DEPOT = 'HUB_DEPOT',
  HUB_RECYCLE = 'HUB_RECYCLE',
  HUB_PORT = 'HUB_PORT',
  HUB_CUSTOM = 'HUB_CUSTOM',
  HUB_OCCURRENCE = 'HUB_OCCURRENCE',
  CLUSTER = 'CLUSTER',
}

export enum MAP_SEARCH_MODE {
  NONE = 'NONE',
  SUPPLY = 'SUPPLY',
  REQUEST = 'REQUEST',
  EXCHANGE = 'EXCHANGE',
}

export enum MAP_SOURCE {
  MASS = 'mass-source',
}

export enum FILTER_MENU_ITEM_TYPE {
  MATERIAL = 'material',
  VOLUMNE = 'volumne',
  PRICE = 'price',
  LOCATION = 'location',
  MATERIAL_CONDITION = 'material_condition',
}
