import assetService from '@/apis/asset.service';
import { InfoAsset } from '@/dto/asset/InfoAsset.dto';
import { ASSET_REFERENCE_TYPE } from '@enums/asset-reference-type.enum';

class AssetStore {
  async getFile(assetId: string, isImage?: boolean): Promise<void> {
    await assetService.getFile(assetId, isImage);
  }

  async uploadFile(
    file: File | null,
    referenceType: ASSET_REFERENCE_TYPE
  ): Promise<InfoAsset> {
    const uploadedFile = await assetService.uploadFile(file, referenceType);
    return uploadedFile;
  }
}

export default AssetStore;
