import { isNorway } from '@utils/api.utils';
import { MapLayerData } from '@/dto/layer/MapLayerData.dto';

export const MAP_MAX_BOUNDS = isNorway()
  ? [
      [0.0464107, 56.948284], // Southwest coordinates
      [35.37482, 71.788251], // Northeast coordinates
    ]
  : [
      [-29.147305, 61.941822],
      [-7.745938, 67.55289],
    ];

export const MAP_CENTER = isNorway()
  ? [7.6291801, 59.1765826]
  : [-18.625728473621592, 65.04605039332972]; // starting position [lng, lat]

export const MAP_LAYER_DATA_GRAVEL_AND_HARD_ROCK: MapLayerData = {
  serverUrl:
    'https://geo.ngu.no/mapserver/GrusPukkWMS5?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.3.0&request=GetMap&CRS=EPSG:3857&width=1024&height=1024&transparent=true&layers=',
  sourceId: 'wms-source-layer1',
  layerId: 'wms-layer1',
};

export const MAP_LAYER_DATA_SUPERFICIAL: MapLayerData = {
  serverUrl:
    'https://geo.ngu.no/mapserver/LosmasserWMS?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.3.0&request=GetMap&CRS=EPSG:3857&width=1024&height=1024&transparent=true&layers=',
  sourceId: 'wms-source-layer3',
  layerId: 'wms-layer3',
};

export const MAP_LAYER_DATA_POLLUTION: MapLayerData = {
  serverUrl:
    'https://kart.miljodirektoratet.no/arcgis/services/grunnforurensning2/MapServer/WMSServer?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.3.0&request=GetMap&CRS=EPSG:3857&width=1024&height=1024&transparent=true&styles=&layers=',
  sourceId: 'wms-source-layer4',
  layerId: 'wms-layer4',
};

// export const MAP_LAYER_DATA_MINERAL_EXTRACTION: MapLayerData = {
//   serverUrl:
//     'https://kart.dirmin.no/dirmin/services/UttaksregisterDMF/MapServer/WmsServer?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.3.0&request=GetMap&CRS=EPSG:3857&width=2048&height=2048&transparent=true&styles=&layers=',
//   sourceId: 'wms-source-layer5',
//   layerId: 'wms-layer5',
// };
export const MAP_LAYER_DATA_MINERAL_EXTRACTION: MapLayerData = {
  serverUrl:
    'https://minit.dirmin.no/drift/kart/?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.3.0&request=GetMap&CRS=EPSG:3857&width=2048&height=2048&transparent=true&styles=&filter=DriftsstatusUttakstype%3A%22pg%22%20%3D%20%27Bygger%C3%A5stoff%27%20OR%20%22pg%22%20%3D%20%27Industrimineraler%27%20OR%20%22pg%22%20%3D%20%27Metallisk%20malm%27%20OR%20%22pg%22%20%3D%20%27Naturstein%27%20OR%20%22pg%22%20%3D%20%27Udefinert%27&layers=',
  sourceId: 'wms-source-layer5',
  layerId: 'wms-layer5',
};

export const MAX_ITEM_COUNT = 9;
export const CLUSTER_LAYER_PREFIX = 'cluster_';
export const SUPPLY_KEY_PREFIX = 'supply_';
export const REQUEST_KEY_PREFIX = 'request_';
export const DEFAULT_ZOOM_LEVEL = 7.5;
export const DEFAULT_ZOOM_LEVEL_MOBILE = 5.5;
export const MIN_ZOOM_LEVEL = 5;
