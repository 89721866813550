import http from '@/apis';
import { TransportPaginationRequest } from '@/dto/transports/TransportPaginationRequest.dto';
import { SettingsTransport } from '@/dto/settings/SettingsTransport.dto';
import { SettingsTransportCreate } from '@/dto/settings/SettingsTransportCreate.dto';
import { prepareGetQuery } from '@utils/api.utils';
import { PaginationResult } from '@/dto/commons/PaginationRequest.dto';

class TransportService {
  prefix = 'transports';
  prefixAdmin = 'admin/' + this.prefix;

  public async getTransports(
    query?: TransportPaginationRequest
  ): Promise<PaginationResult<SettingsTransport>> {
    let url = this.prefix;
    if (query) {
      url += prepareGetQuery(query as Record<string, unknown>);
    }
    const result = await http.get(url);
    return result.data.result;
  }

  public async getTransport(id: number): Promise<SettingsTransport> {
    const result = await http.get(`${this.prefix}/${id}`);
    return result.data.result;
  }

  public async createTransport(
    model: SettingsTransportCreate
  ): Promise<boolean> {
    const result = await http.post(`${this.prefixAdmin}`, model);
    return result.data.result.data;
  }

  public async editTransport(model: SettingsTransport): Promise<boolean> {
    const result = await http.put(`${this.prefixAdmin}/${model.id}`, model);
    return result.data.result.data;
  }

  public async minified() {
    const result = await http.get(`${this.prefix}/minified`);
    return result.data.result.data;
  }
}

export default new TransportService();
