import changeLogService from '@/apis/change-log.service';
import customerService from '@/apis/customer.service';
import declarationService from '@/apis/declaration.service';
import ealCodeService from '@/apis/eal-code.service';
import materialService from '@/apis/material.service';
import nsCodeService from '@/apis/ns-code.service';
import projectService from '@/apis/project.service';
import saveTemplateService from '@/apis/save-template.service';
import transportService from '@/apis/transport.service';
import userService from '@/apis/user.service';
import { InfoChangeLog } from '@/dto/change-log/change-log.dto';
import { PaginationRequest } from '@/dto/commons/PaginationRequest.dto';
import { Customer } from '@/dto/customer/customer.dto';
import { DeclarationProceedDto } from '@/dto/declaration/declaration-proceed.dto';
import {
  Declaration,
  mapDeclarationFormikToDto,
} from '@/dto/declaration/declaration.dto';
import { EalCode } from '@/dto/declaration/eal-code.dto';
import {
  CreateSaveTemplate,
  SaveTemplate,
  SaveTemplateVersion,
  UpdateSaveTemplate,
} from '@/dto/declaration/save-template.dto';
import { CreateUpdateProject } from '@/dto/project/Project.dto';
import { SettingsMaterial } from '@/dto/settings/SettingsMaterial.dto';
import { SettingsTransport } from '@/dto/settings/SettingsTransport.dto';
import { InfoUser, InfoUserWidthCredential } from '@/dto/users/InfoUser.dto';
import { initialValues } from '@customTypes/forms/selfDeclaration.types';
import { NsCode } from '@dto/declaration/ns-code.dto';
import { action, makeObservable, observable } from 'mobx';

class DeclarationStore {
  customers: Customer[];
  contactPersons: InfoUser[];
  projects: CreateUpdateProject[];
  transportTypes: SettingsTransport[];
  ealCodes: EalCode[];
  nsCodes: NsCode[];
  wasteTypes: SettingsMaterial[];
  declaration: Declaration;
  declarations: Declaration[];
  declarationCount: number;
  changeLogs: InfoChangeLog[];
  saveTemplates: SaveTemplate[];

  constructor() {
    this.customers = [];
    this.contactPersons = [];
    this.projects = [];
    this.transportTypes = [];
    this.ealCodes = [];
    this.nsCodes = [];
    this.wasteTypes = [];
    this.declaration = mapDeclarationFormikToDto(initialValues);
    this.declarations = [];
    this.declarationCount = 0;
    this.changeLogs = [];
    this.saveTemplates = [];

    makeObservable(this, {
      customers: observable,
      contactPersons: observable,
      projects: observable,
      transportTypes: observable,
      ealCodes: observable,
      nsCodes: observable,
      wasteTypes: observable,
      declaration: observable,
      declarations: observable,
      declarationCount: observable,
      changeLogs: observable,
      saveTemplates: observable,
      getCustomers: action,
      getContactPersonsByCompany: action,
      getProjectsByCompany: action,
      getTransportTypes: action,
      getEalCodes: action,
      getNsCodes: action,
      getWasteTypes: action,
      getChangeLogs: action,
      getSaveTemplates: action,
      getSaveTemplateVersions: action,
      createSaveTemplate: action,
      updateSaveTemplate: action,
      createSaveTemplateVersion: action,
      getDeclaration: action,
      getDeclarations: action,
      setContactPersons: action,
      createDeclaration: action,
      editDeclaration: action,
    });
  }

  async getCustomers(): Promise<void> {
    const customers = await customerService.getCustomerMinified();
    this.customers = customers;
  }

  async getContactPersonsByCompany(companyId: number): Promise<void> {
    const contactPersons = await userService.getUsersByCompany(companyId);
    this.contactPersons = contactPersons;
  }

  async getProjectsByCompany(
    companyId: number,
    customerId?: number,
    declarationDepotId?: number
  ): Promise<void> {
    const projects = await projectService.getProjectsByCompany(
      companyId,
      customerId,
      declarationDepotId
    );
    this.projects = projects;
  }

  async getTransportTypes(): Promise<void> {
    const transportTypes = await transportService.getTransports();
    this.transportTypes = transportTypes.data;
  }

  async getEalCodes(query?: PaginationRequest<EalCode>): Promise<void> {
    const ealCodes = await ealCodeService.getEalCodes(query);
    this.ealCodes = ealCodes.data;
  }

  async getNsCodes(query?: PaginationRequest<NsCode>): Promise<void> {
    const result = await nsCodeService.getNsCodes(query);
    this.nsCodes = result.data;
  }

  async getWasteTypes(
    query?: PaginationRequest<SettingsMaterial>
  ): Promise<void> {
    const materials = await materialService.getMaterialsMinified(query);
    this.wasteTypes = materials;
  }

  async getChangeLogs(query?: PaginationRequest<InfoChangeLog>): Promise<void> {
    const result = await changeLogService.getChangeLogs(query);
    this.changeLogs = [...this.changeLogs, ...result];
  }

  async getSaveTemplates(depotId: number): Promise<void> {
    const result = await saveTemplateService.getSaveTemplates(depotId);
    this.saveTemplates = result;
  }

  async getSaveTemplateVersions(
    templateId: number
  ): Promise<SaveTemplateVersion[]> {
    const result = await saveTemplateService.getSaveTemplateVersions(
      templateId
    );
    return result;
  }

  async createSaveTemplate(model: CreateSaveTemplate): Promise<SaveTemplate> {
    const result = await saveTemplateService.createSaveTemplate(model);
    return result;
  }

  async updateSaveTemplate(id: number, model: UpdateSaveTemplate) {
    const result = await saveTemplateService.updateSaveTemplate(id, model);
    return result;
  }

  async createSaveTemplateVersion(
    model: SaveTemplateVersion
  ): Promise<SaveTemplateVersion> {
    const result = await saveTemplateService.createSaveTemplateVersion(model);
    return result;
  }

  async getDeclaration(id: number): Promise<void> {
    const declaration = await declarationService.getDeclaration(id);
    this.declaration = declaration;
  }

  async getDeclarations(query?: PaginationRequest): Promise<void> {
    const result = await declarationService.getDeclarations(query);
    this.declarations = result.data;
    this.declarationCount = result.count;
  }

  async createDeclaration(model: Declaration): Promise<number> {
    const result = await declarationService.createDeclaration(model);
    return result;
  }

  async editDeclaration(model: Declaration): Promise<boolean> {
    const result = await declarationService.editDeclaration(model);
    return result;
  }

  async handleDeclarationProceed(
    model: DeclarationProceedDto
  ): Promise<InfoUserWidthCredential> {
    const result = await declarationService.handleDeclarationProceed(model);
    return result;
  }

  setContactPersons(contactPersons: InfoUser[]) {
    this.contactPersons = contactPersons;
  }
}

export default DeclarationStore;
