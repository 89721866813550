import { Conversation } from '@dto/conversation/conversation.dto';
import { OBJECT_TYPE } from '@enums/conversation.enum';

export const INIT_CONVERSATION: Conversation = {
  id: 0,
  objectType: OBJECT_TYPE.DECLARATION,
  objectTypeId: 0,
  messages: [],
  lastMessageSent: '',
  lastMessageSentAt: new Date(),
};
